import { langCheck } from "./langCheck";
const fr: langCheck = {
  welcome:
    "Bienvenue aux Mapnom!\n Mapnom est le créateur de carte occasionnel.\n Nous sommes toujours en train de développer pour la prochaine version officielle.\n Si vous avez des suggestions, s'il vous plaît contactez-nous!\n Profitez avec nous!",
  createMap: "Créer une carte",
  login: "Connexion",
  logout: "Se déconnecter",
  home: "Home",
  map: "Carte",
  chat: "Chat",
  userSettings: "Compte",
  mapSettings: "Paramètres de la carte",
  addFriend: "Ajouter un ami",
  searchMap: "Rechercher une nouvelle carte",
  search: "Rechercher",
  chatInput: "Tapez votre message...",
  sendImages: "Envoyer des images",
  existingPlaces: "Lieux existants",
  newPlaces: "Résultats de recherche",
  updateMap: "Mettre à jour la carte",
  deleteMap: "Supprimer la carte",
  showInSearch: "Afficher dans la recherche",
  cancel: "Annuler",
  createPlace: "Créer comme nouveau",
  choosePlace: "Choisir le lieu",
  addMembers: "Ajouter membres",
  friends: "Amis",
  title: "Titre",
  description: "Description",
  tags: "Mot clés",
  friendCode: "Code ami",
  mapIsCreated: "La carte est créée!",
  mapIsUpdated: "La carte est mise à jour!",
  uploadImage: "Télécharger l'image",
  addOverlays: "Ajouter des superpositions d'images",
  exploreSentence: "Expolorez le monde que d'autres personnes ont créé !!",
  currentLocation: "Ici",
  fitToCoordinates: "Ajuster l'écran aux marqueurs",
  mapStyle: "Style de carte",
  tutorial:
    "Welcome to Rounds! Now, you can start editing your map. Simply say, we create a map by chatting. it means you just send a message and choose the place, then this map is developped. You press the ",
  hitResults: "résultats pour",
  noTitle: "Sans titre!",
  language: "Langue",
  members: "Membres",
  userIsUpdated: "Les données utilisateur sont mises à jour",
  street: "Street",
  satellite: "Satellite",
  white: "Blanc",
  black: "Noir",
  where: "C'est où?",
  deleteConfirmation:
    "Voulez vous supprimer la carte? Vous ne pouvez pas la restaurer une fois que c'est fait. Tous les messages et images qu'elle contient seront supprimés.",
  storage: "Stockage",
  searchPlaces: "Rechercher des lieux",
  familyName: "Nom de famille",
  givenName: "Prénom",
  noMember: "Il n'y a pas encore de membre",
  noTag: "",
  createUser: "Créer un compte utilisateur",
  updateUser: "Mettre à jour le compte utilisateur",
  emailCopied: "E-mail est copié!",
  friendIsAdded: "Votre ami est ajouté!",
  goToMap: "Allons-y!",
  orJoin: "ou rejoindre la carte d'un autre",
  uploading: "télécharge...",
  overlays: "superpositions",
  mapImage: "image titre",
  advancedSettings: "Réglages avancés",
  showAllMessages: "Afficher tous les messages ",
  howTo: "Comment utiliser",
  nonMember: "Non membre",
  notifications: "Notifications",
  underDevelopment: "nous développons ce contenu. c'est pour bientôt!",
  canJoin: "Tout le monde peut participer",
  userPage: "Profil",
  mapHome: "accueil de la carte",
  requestToMapkid: "Demende à Mapkid",
  editPlaces: "Modifier des lieux",
  post: "Poste",
  placePosts: "Postes en place",
  pinOn: "Épingle sur cette page",
  pinOff: "Supprimer l'épingle sur cette page",
  reportAsSpam: "Signaler comme indésirable",
  reportAsSpamDescription: "Signaler cette poste comme indésirable",
  report: "Signaler",
  delete: "Supprimer",
  comment: "Commenter",
  accessByLink: "Access by link",
  nonMemberEdit: "Can edit",
  canCreatePlace: "Can create place",
  canComment: "Can comment",

  conceptWelcomeTitle: "Bienvenue sur Mapnom",
  conceptMapWithUSTitle: "Carte avec nous",
  conceptWarmMapTitle: "Carte chaude",
  conceptPostWithPlaceTitle: "Poster avec un lieu",
  conceptTogetherTitle: "Créer ensemble",
  conceptFindAndWalkTitle: "Trouver des cartes et marcher dessus",
  conceptFromNowOnTitle: "Mapnom à partir de maintenant",
  conceptHowWeAreTitle: "Comment nous sommes",
  conceptClosingTitle: "Closing",
  conceptSloganTitle: "Mémoires sur la Terre",
  conceptWelcomeText:
    "Merci de votre visite.\nIci, nous expliquons le concept de Mapnom.\n\nQu'est-ce que c'est, Mapnom?\nQue pouvez-vous faire avec Mapnom?\nEt comment nous faisons face à l'entreprise?\n\nVous allez sentir l'air.",
  conceptMapWithUSText:
    "Il existe de nombreuses sortes de cartes dans ce monde.\n\nElles indiquent où se trouvent les restaurants et les cafés.\nElles vous guident dans une certaine zone.\nElles visualisent les statistiques dans le monde.\n\nElles contiennent beaucoup d'informations et nous permettent de comprendre facilement notre monde.\nLes cartes existeront avec nous maintenant et pour toujours",
  conceptWarmMapText:
    "Il est rare de voir des blagues inutiles et des souvenirs chaleureux sur des cartes.\nNous avons tendance à penser que la commodité est tout.\nC'est le courant principal de notre époque.\nMais ça crée notre bonheur?\n\nMapnom veut rendre la terre intéressante et remplie de souvenirs chaleureux.\nLes utilisateurs expriment le monde comme ils l'ont ressenti.\nMapnom est né dans ce but.",
  conceptPostWithPlaceText:
    "Il n'y a pas d'interface utilisateur difficile.\nIl suffit de poster avec du texte et des médias comme les SNS.\nUn autre point est le besoin d'un lieu.\nLe lieu ajouté n'a pas beaucoup d'informations, comme les adresses et les informations sur les magasins etc...\nSeuls vos souvenirs sont stockés au fil du temps.",
  conceptTogetherText:
    "C'est un travail difficile de créer une grande carte.\nCréons-la avec vos familles, vos amis et les gens du monde entier.\nRien ne change pour créer.\nIl suffit de poster avec eux",
  conceptFindAndWalkText:
    "Quand tu veux aller quelque part, cherchez sur Mapnom.\nPeut-être que des personnes locales créeront une carte adaptée à vos besoins.\nEt vous pouvez leur demander comment s'y rendre. Vous pouvez marcher avec la carte",
  conceptFromNowOnText:
    "Nous grandissons ensemble avec vous.\nNous travaillons pour vous pour créer vos cartes préférées.\n\nChanger le style de la carte, mettre des objets sur la carte...\nEt rechercher toutes les cartes à partir d'une seule carte.\n\nNous créons de nouvelles choses d'une manière polie",
  conceptHowWeAreText:
    "Nous poursuivons des choses universelles à travers les générations.\n\nFaire un moment de détente.\nApprécier les choses que quelqu'un apprécie ensemble.\nRessentir des connexions au niveau local.\n\nNous pensons toujours à la façon dont nous augmentons les chances.\nNous laissons des souvenirs chaleureux sur la terre avec vous",
  conceptClosingText: "Merci de votre lecture.\nA bientôt sur Mapnom !",
  conceptSloganText: "Mapnom",

  seeOurConceptBook: "Voir notre livre de concepts.",
  underBetaTest:
    "(Nous sommes actuellement en test bêta, la version iOS est disponible via TestFlight)",
  askMoreMaps: "Vous voulez voir d'autres cartes ?",
  searchMoreMaps: "Rechercher plus de cartes",
  showQrCode: "Montrer QR code",
  shareUrl: "Partager URL",
  download: "Télécharger",
  saved: "Enregistré dans Photos",
  addPlace: "Ajouter de place à ce poste",
  changePlace: "Changer de place pour ce poste",
  recent: "Récents",
  placeName: "Nom du lieu",
  savePlace: "Sauvegarder la place",
  fallbackText: "Si cet écran apparaît toujours, essayez de recharger.",
  concept: "Concept",
  privacyPolicy: "Politique de confidentialité",
  termsOfService: "Conditions d'utilisation",
  and: "et",
  continueWithConsent:
    "Bienvenue à Mapnom. Continuez avec le consentement à notre",
  mapTypeTitle: "What is map type?",
  mapTypeDescription:
    "\n Map type determines what visitors can do. \n \n Close: Only members can see this map. \n Publish: Anyone can search and view this map but cannot edit. \n Open: Anyone can search and edit it together.",

  changeMapStyle: "Changer style de la carte",
  background: "Contexte",
  originalStyle: "Style original",
  printMap: "Imprimer la carte",
  emailVerification: "Vérification de l'E-mail ",
  blockUser: "Bloquer  l’utilisateur ",
  subscription: "Abonnement",
  functions: "Fonctions",
  comparison: "Comparison",
  moreMaps: "Plus de cartes",
  autoTranslation: "Traduction automatique ",
  autoTranslationDescription:
    "Vous pouvez rechercher des messages dans n'importe quelle langue. facile à communiquer avec les étrangers.",
  choosePricingPlan:
    "Choisissez le plan tarifaire. essayez-le gratuitement dans 1 mois",
  "check the price in the website": "Vérifiez le prix sur le site Mapnom",
  "By continuing, you agree to terms of service and acknowledge you’ve read our privacy policy":
    "En continuant, vous acceptez les conditions de service et reconnaissez avoir lu notre politique de confidentialité.",
  "Pan map to take the snapshot": "Déplacer la carte pour prendre l'instantané",
  style: "Style",
  "Error occured. Please attempt to login again.":
    "Une erreur s'est produite. Veuillez essayer de vous connecter à nouveau.",
  "Smileys & Emotion": "Smileys & Emotion",
  "People & Body": "Personnes et corps",
  "Animals & Nature": "Animaux et Nature",
  "Food & Drink": "Nourriture et boissons",
  "Travel & Places": "Voyages et Lieux",
  Activities: "Activités",
  Objects: "Objets",
  Symbols: "Symboles",
  Flags: "Drapeaux",
  "Sexual contents": "Contenu à caractère sexuel",
  "Illegal product or service": "Produit ou service illégal",
  Violence: "Violence",
  "Political ads": "Publicités politiques",
  Discrimination: "Discrimination",
  Other: "Autre",
  "Your subscription is started!": "Votre abonnement est lancé !",
  "Now, you can use all functions on Mapnom!":
    "Maintenant, vous pouvez utiliser toutes les fonctions sur Mapnom !",
  "Back to Home": "Retourner à l'accueil",
  "Reported and Blocked this user": "Signalé et bloqué cet utilisateur",
  "Reported as spam": "Signalé comme spam",
  "Sent a mail link to your email box. Please login from it.":
    "Un lien a été envoyé à votre boîte aux lettres électronique. Veuillez vous connecter à partir de celle-ci.",

  sentEmailLink:
    "Un lien a été envoyé à votre boîte aux lettres électronique. Veuillez vous connecter à partir de celle-ci.",

  moreMapsTitle: "Pour plus de cartes",
  moreMapsDescription: `Il y avait des limites pour le nombre d'articles, de lieux et de cartes avec le plan gratuit. Il suffit de créer plus de contenu sur la carte avec le plan de base. 
  Souvenirs quotidiens, voyages avec vos amis, fêtes locales,,, Mapnom vous permet de créer des cartes avec tous vos souvenirs.. 
  `,
  styleMapsTitle: "Dessine des cartes",
  styleMapsDescription:
    "Mapnom met à votre disposition différents styles de cartes. Changez le style et la couleur pour qu'ils correspondent au thème de la carte. Nous continuons à ajouter des styles de cartes au fil du temps. Vous pouvez imprimer vos cartes pour les joindre à vos notes et créer des cartes papier.",
  connectWithTitle: "Ensemble avec plus de personnes",
  connectWithDescription:
    "Vous pouvez créer des cartes avec plus de personnes. Invitons plus d'amis et de personnes locales pour plus de contenu. Et vous pouvez activer l'ouverture des commentaires pour tout le monde.",

  Subscribe: "Abonnez-vous",
  "Free 1 month trial": "Essai gratuit d'un mois",
  "Free plan": "Gratuit",

  SubscriptionPageTitle: "Plus sur Mapnom !",
  SubscriptionPageDescription: `On peut profiter de Mapnom avec le plan gratuit.
  Mais si vous utilisez Mapnom depuis longtemps ou avec vos amis, les fonctions ne sont pas suffisantes pour vos besoins. 
  Vous pouvez changer le style de votre carte et l'imprimer pour écrire dessus au lieu de simplement l'afficher.
  Nous continuerons à ajouter des fonctions comme la traduction automatique et l'écriture sur la carte.
  `,
  FunctionsTitle: "Fonctions supplémentaires",
  FunctionsDescription:
    "Ici, nous expliquons les fonctions supplémentaires en souscrivant notre plan.",
  PlansTitle: "Liste des plans",
  PlansDescription: `Voulez-vous utiliser les fonctions ?
  Vous pouvez commencer à les utiliser avec un essai gratuit.
  Veuillez essayer toutes les fonctions avant de vous abonner.
  `,
  TableTitle: "Comparaison",
  TableDescription:
    "Nous résumons les fonctions disponibles sur un seul tableau",
  mo: "mo",
  "Thank you for subscribing!": "Merci de vous abonner!",
  "Check more details on mapnom.com!":
    "Vérifiez plus de détails sur mapnom.com!",
  detailsOnWeb: "Vérifiez plus de détails sur mapnom.com!",
  "Free trial": "Essai gratuit",
  "More freedom": "Plus de liberté",
  "Create memory maps with friends": "Créer des cartes mémoire avec des amis",
  "Search maps of the world": "Rechercher des cartes du monde",
  "Unlimited posts": "Messages et photos illimités",
  "Up to 3000 places per map, 30 maps":
    "Jusqu'à 3000 lieux par carte, 30 cartes",
  "Styling maps and print out": "Dessin des cartes et impression",
  account: "Compte",
  Price: "Prix",
  Prices: "Prix",
  Maps: "Cartes",
  Places: "Places",
  Posts: "Postes",
  Printing: "Imprimer",
  Styling: "Style de la carte",
  Emoji: "Emoji",
  Join: "Rejoindre",
  global: "Mondial",
  bbox: "Zone actuelle",
  nearby: "Proche",
  whereToSearch: "Où voulez-vous chercher?",
};

export default fr;

import { TextProps } from "react-native";
import { Text, useTheme } from "react-native-paper";

{
  /* <Text type="body" /> */
}
// Caption, Body, TItle, Description, Headline, SubHeading, Display
// check details in memo.txt!

type TextType =
  | "displayLarge"
  | "display"
  | "headline"
  | "subheading"
  | "title"
  | "description"
  | "body"
  | "caption";

const fontSizes = {
  displayLarge: 48,
  display: 36,
  headline: 32,
  subheading: 24,
  title: 22,
  description: 14,
  body: 16,
  caption: 12,
};
const fontWeights = {
  displayLarge: "900",
  display: "900",
  headline: "700",
  subheading: "700",
  title: "500",
  description: "300",
};

const defaultFontWeight = "300";

const boldTypes = [];
const secondaries = [];

export default function StyledText(props: TextProps & { type?: TextType }) {
  const { type } = props;
  const fontSize = fontSizes[type] || 16;
  const fontWeight = fontWeights[type] || defaultFontWeight;

  const { disabled } = useTheme().colors;

  const colors = {
    caption: disabled,
  };

  const color = colors[type] || "black";
  return (
    <Text {...props} style={{ fontSize, fontWeight, color, ...props.style }}>
      {props.children}
    </Text>
  );
}

// export const Headline = (props: TextProps) => {
//   return <Text style={{ fontSize: 48, ...props.style }}>{props.children}</Text>;
// };

import React, { useState, useEffect, useContext, useRef } from "react";

import { View, Platform, Alert, Linking } from "react-native";
import { GlobalContext } from "../Context";

import {
  loginWithUid,
  debug,
  isWebOS,
  getCurrenciesJson,
} from "../localFunctions";

import Constants from "expo-constants";
// import { getRemoteConfigValue, isRemoteConfigActivated } from "../functions";

import * as Network from "expo-network";
import { listenAuthUser, signInAnonymously } from "../localFunctions/firebase";

export default function App() {
  const { setUserInfo, setLogin, appConfig, setAppConfig } =
    useContext(GlobalContext);

  useEffect(() => {
    (async () => {
      // appconfig should be null in initial state, then get it
      //Now, appconfig does not do anything, never mind. future features

      if (appConfig) return;

      //user have to login anonymously or with signature because cannot acceess DB without login by security rule

      //for dynamic link for mobile app
      // https://stackoverflow.com/questions/61564203/how-to-setup-sendsigninlinktoemail-from-firebase-in-react-native

      // if (!isWebOS && !__DEV__) {
      if (!isWebOS) {
        const appVersion = Constants.manifest.version;
        // const isActivated = await isRemoteConfigActivated();
        const isIos = Platform.OS == "ios";
        // if (isActivated) {
        //   const key = isIos ? "minimum_ios_version" : "minimum_android_version";
        //   const minimumVersion = await getRemoteConfigValue(key);

        //   // const needToUpdate = appVersion?.localeCompare(minimumVersion) < 0;
        //   const needToUpdate = minimumVersion?.localeCompare(appVersion) < 0;

        //   // const recommendUpdate = Number(appVersion) < Number(appConfig.latest)
        //   const recommendUpdate = true;

        //   // debug(appVersion);
        //   if (needToUpdate) {
        //     const key = isIos ? "ios_app_store_url" : "android_google_play_url";

        //     const url = await getRemoteConfigValue(key);

        //     const noticeUpdate = () =>
        //       Alert.alert(
        //         "Update!",
        //         "Get the new version!",
        //         [
        //           {
        //             text: "Update",
        //             onPress: () => {
        //               Linking.openURL(url);
        //               noticeUpdate();
        //             },
        //           },
        //         ],
        //         { cancelable: false }
        //       );
        //     noticeUpdate();
        //   } else if (recommendUpdate) {
        //     // alert("The newer version is released!!")
        //     // setAppConfig(doc.data());
        //   } else {
        //     // setAppConfig(doc.data());
        //   }

        //   // const =  getRemoteConfigValue()
        // }
      }

      const currencies = await getCurrenciesJson();
      setAppConfig({ currencies });

      const authCallback = async (user) => {
        const isUserLogin = user && !user?.isAnonymous;
        const isAnonymousLogin = user?.isAnonymous;

        if (isUserLogin) {
          const { uid } = user;
          const res = await loginWithUid({ uid });
          const { type } = res;

          const message =
            "Problem happens while logging in. Please check your internet.";

          if (type == "error") return alert(message);

          if (type == "exist") {
            const { userData } = res;
            setUserInfo(userData);
            setLogin(true);
          }
          // else {
          //   setUserInfo(userData);
          // }

          //auth persistence is expired or not signin yet
        } else if (isAnonymousLogin) {
          setLogin(true);
          // setUserMaps((userMaps) => ({ ...userMaps, anonymous: true }));
        } else {
          await signInAnonymously()
            .then((res) => {})
            .catch((error) => {
              debug(error);
            });
        }
      };

      // use each firebase in platform to get login info or persisted data
      listenAuthUser(authCallback);
    })();
  }, [appConfig]);

  useEffect(() => {
    // if(!isWebOS){
    //     const getAuthCustomToken = firebase
    //       .functions()
    //       .httpsCallable("getAuthCustomToken");
    //     const { data } = await getAuthCustomToken({  });
    //     console.log(103, data);
    //     const res = await firebase.auth().signInWithCustomToken(data);
    //     // from now, web firebase is authorized
    //     user = res.user;
    // }
  }, []);

  // useEffect(() => {
  //   Network.getNetworkStateAsync().then((res) => {
  //     const { isConnected, isInternetReachable, type } = res;
  //     console.log(type, isInternetReachable, isConnected);
  //     // alert(type, isInternetReachable,);
  //   });
  //   const id = setInterval(() => {}, 1000 * 60);

  //   return () => clearInterval(id);
  // }, []);

  return <View />;
}

// anonymous login
// setUsers((users) => {});
// setAppConfig(true);
// setLogin(true);
// temporary solution to update Home page for landing
// setUserMaps((userMaps) => ({ ...userMaps, anonymous: true }));

// // now auth != null, then we can access to db over App check and Auth
// firebase
//   .firestore()
//   .collection("appConfig")
//   .doc("mapkid")
//   .get()
//   .then((doc) => {
//     const config = doc.data();

//     if (isWebOS) {
//       setAppConfig(doc.data());
//     } else {
//       const appConfig = config.app;

// const sortVersions = (unsortedVersions: string[]) => {
//   return unsortedVersions
//     .slice()
//     .sort((q, w) => w.localeCompare(q, [], { numeric: true }));
// };

//     }
//   })
//   .catch((err) => {});

import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
  createRef,
  Suspense,
  lazy,
} from "react";
import { Text, View } from "react-native";
import { ActivityIndicator, useTheme } from "react-native-paper";
import { langDict } from "../languages/langCheck";
import i18n from "../languages/language";

export default function FallbackScreen() {
  const [notice, setNotice] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setNotice(true);
    }, 10000);
  }, []);

  const { background } = useTheme().colors;

  return (
    <View
      style={{
        backgroundColor: background,
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        padding: 10,
      }}
    >
      <ActivityIndicator color="blue" size="large" />
      {notice && (
        <Text style={{ padding: 10 }}>
          {i18n.t(langDict.fallbackText)}
          {/* If this screen still appears, maybe because of slow internet, or you
          need to reload */}
        </Text>
      )}
    </View>
  );
}

import {
  getReactNativePersistence,
  // initializeAuth,
} from "firebase/auth/react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
// import { initializeAuth } from "firebase/auth";

import {
  AuthCredential,
  initializeAuth,
  onAuthStateChanged,
  updateEmail,
} from "firebase/auth";

import firebase from "firebase/compat/app";
import { initializeApp, getApps, getApp } from "firebase/app";

let app;

const isWebOS = Platform.OS == "web";

if (getApps().length === 0) {
  // __DEV__ && (window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = true);
  app = initializeApp(firebaseConfig);
  firebase.initializeApp(firebaseConfig);

  if (!isWebOS) {
    initializeAuth(app, {
      persistence: getReactNativePersistence(AsyncStorage),
    });
    initializeFirestore(app, { experimentalForceLongPolling: true });
    firebase.firestore().settings({ experimentalForceLongPolling: true });
  }
}

import "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/database";
// import "firebase/compat/app-check";

import {
  signInAnonymously as signInAno,
  signInWithCredential as signInWithCre,
  getAuth,
  GoogleAuthProvider,
} from "firebase/auth";
import { firebaseConfig } from "../constants";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";

// const firebaseAppInstance = getApp();
const firebaseAuth = getAuth(app);
const firebaseFunction = getFunctions();

console.log(60, firebaseAuth.currentUser?.email);

export const getHttpsFunction = (name: string) =>
  httpsCallable(firebaseFunction, name);

export const authUser = firebaseAuth;

export const signInAnonymously = () => signInAno(firebaseAuth);

export const listenAuthUser = (
  callback: (user: firebase.User | null) => void,
  error?
) => onAuthStateChanged(firebaseAuth, callback, error);

export const getCredential = (token: string) =>
  GoogleAuthProvider.credential(token);

export const signInWithCredential = (credential: AuthCredential) =>
  signInWithCre(firebaseAuth, credential);

import { signOut as signOut0 } from "firebase/auth";

export const signOut = () => signOut0(firebaseAuth);

///////

// only for test use
// export const testsRef = collection(db, "tests");

// export const appRef = doc(db, appName, appVersion);
// export const adminRef = doc(appRef, "admin", "v1");
// export const mailsRef = collection(adminRef, "mails");
// export const translationsRef = collection(adminRef, "translations");

// export const mapRef = (mapID) => doc(appRef, "maps", mapID);

// export const placesRef = (mapID: string) => collection(mapRef(mapID), "places");
// export const placeRef = (mapID: string, placeID: string) =>
//   doc(placesRef(mapID), placeID);
// export const featuresRef = (mapID: string) =>
//   collection(mapRef(mapID), "features");

// export const userRef = (uid, target: "private" | "public" | "display") =>
//   doc(appRef, `${target}/${"v1"}/${"users"}/${uid}`);

// export const userTransfersRef = (uid: string) =>
//   collection(userRef(uid, "private"), "transfers");

// export const userDestinationsRef = (uid: string) =>
//   collection(userRef(uid, "private"), "destinations");

// export const userPaymentsRef = (uid: string) =>
//   collection(userRef(uid, "private"), "payments");

// export const chatRoomRef = (chatRoomID: string) =>
//   doc(db, `${"private"}/v1/chatRooms/${chatRoomID}`);

// const activitiesRef = collection(appRef, "activities");

// export const addActivity = ({ user }) => {
//   addDoc(activitiesRef, { user });
// };

// export const getActivity = (activityID) =>
//   getDoc(doc(activitiesRef, activityID)).then((doc) => doc.data());

// export const updateActivity = (activityID: string, data: any) => {
//   const actRef = doc(activitiesRef, activityID);

//   return updateDoc(actRef, data);
// };

// const getPlaceRef =(mapID, placeID)=>  doc(placesRef(mapID), placeID)

// export const geocode = (query: string) => {
//   const func = firebase.functions().httpsCallable("geocode");
//   return func({ query });
// };

// useEffect(() => {
//   const translateText = firebase.functions().httpsCallable("translateText");
//   translateText({
//     text: "I am surprised at their opinions",
//     language: "fr",
//   })
//     .then((res) => debug(res.data))
//     .catch((err) => debug(err));
// }, []);

export const generateAgoraVideoCallToken = () =>
  getHttpsFunction("generateAgoraVideoCallToken")();

export const updatePostLikes = () => {
  //  incrementSeverValue(userRef(uid, "public"), "likes" ,isLiked ? "arrayRemove" : "arrayUnion",mapID )
  //     userRef(uid, "public")
  //       .update({
  //         likes:
  //           firebase.firestore.FieldValue[
  //             isLiked ? "arrayRemove" : "arrayUnion"
  //           ](mapID),
  //       })
  //       .then(async () => {
  //         await likesRef.set(
  //           firebase.database.ServerValue.increment(incrementNumber)
  //         );
  //       });
};

// // export const deletePost = async (mapID: string, postID: string) => {
// //   const deletePost = firebase.functions().httpsCallable("deletePost");

// //   return await deletePost({ mapID, postID }).catch((err) => debug(err));
// // };

// // const bucketName = "mapkid";

// // export async function testS3() {
// //   debug(await firebase.firestore().collection("appConfig").doc("mapkid").get());
// //   // debug(
// //   //   await s3
// //   //     .listObjects({ Bucket: "mapkid" })
// //   //     .promise()
// //   //     .then((res) => debug(res.Contents))
// //   //     .catch((err) => debug(err))
// //   // );
// //   // debug(
// //   //   await s3
// //   //     .getBucketPolicy({ Bucket: bucketName })
// //   //     .promise()
// //   //     .then((res) => debug(res.Contents))
// //   //     .catch((err) => debug(err))
// //   // );
// // }

export const useEmulator = (host, port) =>
  connectFunctionsEmulator(firebaseFunction, host, port);

export const createAccountLink = () =>
  httpsCallable(firebaseFunction, "createAccountLink");

export const createStripeLoginLink = () =>
  // .httpsCallable("createStripeLoginLink");
  httpsCallable(firebaseFunction, "createAccountLoginLink");

// export const incrementUserCounts = (num: number) =>
//   firebase
//     .database()
//     .ref(registerDir + "count")
//     .set(firebase.database.ServerValue.increment(num));

export const getUserAuthState = () => firebaseAuth.currentUser;

// import { initializeAppCheck, ReCaptchaV3Provider, getToken } from 'firebase/app-check'

// //migrate to v9 at first!
// // https://zenn.dev/kakimoty/articles/9cc7f766b56bab
// export const activateAppCheck = () => {

//   const appCheck = initializeAppCheck(app, {
//     provider: new ReCaptchaV3Provider(recap),
//     isTokenAutoRefreshEnabled: true,
//   })

//   firebase.appCheck().activate(recaptchaPublicKey, true);
// };

export const deleteUser = (uid: string) => {
  const deleteAllUserContents = httpsCallable(
    firebaseFunction,
    "deleteAllUserContents"
  );
  deleteAllUserContents().then((res) => {});
};

export const deleteMap = (data) => getHttpsFunction("deleteMap")(data);
// firebase.functions().httpsCallable("deleteMap");
export const createMap = (data) => getHttpsFunction("createMap")(data);
// firebase.functions().httpsCallable("createMap")(data);
export const updateMap = (data) => getHttpsFunction("updateMap")(data);
// firebase.functions().httpsCallable("updateMap")(data);

import { signInWithEmailAndPassword as signInWithEmailAndPassword0 } from "firebase/auth";

// signInWithEmailAndPassword
export const signInWithEmailAndPassword = (email) =>
  signInWithEmailAndPassword0(firebaseAuth, email, "fortest");

export const deletePayoutAccount = (data) =>
  getHttpsFunction("deletePayoutAccount")(data);

export const getTranlateData = async (path: string) => {
  const storedData = await firebase
    .firestore()
    .doc(path)
    .get()
    .then((doc) => {
      if (!doc.exists) return null;
      return doc.data();
    });

  if (!storedData) {
    // const translateText = firebase.functions().httpsCallable("translateText");

    // admin.firestore().collection('translations').add({
    //   first: "My name is Bob",
    //   second: "Hello, friend"
    // })

    const res = await translateText({ text: "......" });

    return res.data;
  }

  return storedData;
};

import { isSignInWithEmailLink as isSignInWithEmailLink0 } from "firebase/auth";

export const isSignInWithEmailLink = (url) =>
  isSignInWithEmailLink0(firebaseAuth, url);

export const acceptFriendRequest = (data) =>
  httpsCallable(firebaseFunction, "acceptFriendRequest")(data);

// type DocRefType =
//   firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;

import {
  signInWithEmailLink as signInWithEmailLinkO,
  sendSignInLinkToEmail as sendSignInLinkToEmail0,
} from "firebase/auth";

export const sendSignInLinkToEmailFirebase = (email, actionCodeSettings) =>
  sendSignInLinkToEmail0(firebaseAuth, email, actionCodeSettings);

export const signInWithEmailLink = (email) =>
  signInWithEmailLinkO(firebaseAuth, email);

export const updateUserEmail = (email: string) =>
  updateEmail(firebaseAuth.currentUser, email);
// firebaseAuth.updateEmail(email);

export { firebase };

import {
  doc,
  setDoc,
  collection,
  getFirestore,
  updateDoc,
  addDoc,
  getDoc,
  writeBatch,
  initializeFirestore,
  DocumentReference,
  SetOptions,
  deleteField,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { Platform } from "react-native";

const db = getFirestore(app);
const checkFirestorePath = (path: string) => {
  const arr = path.split("/");
  // odd num = collection
  if (arr.length % 2 == 1) {
    return "collection";
  } else {
    return "doc";
  }
};

// const q = query(citiesRef, orderBy("name", "desc"), limit(3));
// ["name", "desc"] [3]
// ["where","name", "desc"] ["limit",3]
// const q = query(collection(db, "cities"), where("capital", "==", true));
// const q1 = query(citiesRef, where("state", ">=", "CA"), where("state", "<=", "IN"));
// const q2 = query(citiesRef, where("state", "==", "CA"), where("population", ">", 1000000));
// ["state", "==", "CA"], ["population", ">", 1000000]

export const getDocsRef = (path: string) => {
  if (checkFirestorePath(path) == "doc") {
    alert("path is not colection");
    return null;
  }
  return collection(db, path);
};

export const getDatabaseRef: (path: string) => DocumentReference = (
  path: string
) => {
  if (checkFirestorePath(path) == "collection") {
    alert("path is not doc");
    return null;
  }
  return doc(db, path);
};

export const createRef = (ref: DocumentReference, paths: string[]) => {
  const path = ref.path + "/" + paths.join("/");
  if (checkFirestorePath(path) == "collection") return getDocsRef(path);
  if (checkFirestorePath(path) == "doc") return getDatabaseRef(path);
};

export const getDatabase = (path: string) => {
  if (checkFirestorePath(path) == "collection") alert("path is not doc");
  return getDoc(doc(db, path)).then((doc) => {
    if (!doc.exists) return null;
    return doc.data();
  });
};

export const updateDatabase = (path: string, data: any) => {
  if (checkFirestorePath(path) == "collection") alert("path is not doc");
  return updateDoc(doc(db, path), data);
};

export const addDatabase = (path: string, data: any) => {
  if (checkFirestorePath(path) == "doc") alert("path is not collection");
  return addDoc(collection(db, path), data);
};

export const setDatabase = (path: string, data: any, options?: SetOptions) => {
  if (checkFirestorePath(path) == "collection") alert("path is not doc");
  if (options) setDoc(doc(db, path), data, options);
  return setDoc(doc(db, path), data);
};

export const getBatch = () => writeBatch(db);

export const userRef = (uid, target: "private" | "public" | "display") =>
  appRef.collection(target).doc("v1").collection("users").doc(uid);

export const deleteDataField = () => deleteField();
export const arrayUnionField = (value) => arrayUnion(value);
export const arrayRemoveField = (value) => arrayRemove(value);

export const getUID = () => firebaseAuth.currentUser?.uid;

// check in getRenderedPointFeature to avoid event of pressing markers

import { iconNames } from "./icon-names";

// marker > search maps > search places > press map
export const markerSourceIDs = {
  "custom-symbols": "custom-symbols",
  "search-places": "search-places",
  "search-maps": "search-maps",
  "map-markers": "map-markers",
};

export const mapboxStyleImages = {
  Blueprint:
    "https://s3.us-west-1.wasabisys.com/mapkid/assets/mapStyles/blueprint.jpg",
  Minimo:
    "https://s3.us-west-1.wasabisys.com/mapkid/assets/mapStyles/minimo.jpg",
  Frank: "https://s3.us-west-1.wasabisys.com/mapkid/assets/mapStyles/frank.jpg",
  Pencil:
    "https://s3.us-west-1.wasabisys.com/mapkid/assets/mapStyles/pencil.jpg",
  Standard:
    "https://s3.us-west-1.wasabisys.com/mapkid/assets/mapStyles/standard.jpg",
  Street:
    "https://s3.us-west-1.wasabisys.com/mapkid/assets/mapStyles/street.jpg",
  SatelliteStreet:
    "https://s3.us-west-1.wasabisys.com/mapkid/assets/mapStyles/satellitestreet.jpg",
  Dark: "https://s3.us-west-1.wasabisys.com/mapkid/assets/mapStyles/dark.jpg",
  Light: "https://s3.us-west-1.wasabisys.com/mapkid/assets/mapStyles/light.jpg",
  Outdoors:
    "https://s3.us-west-1.wasabisys.com/mapkid/assets/mapStyles/outdoors.jpg",
};

export const markerImagesNames = { search: "search", existing: "existing" };

//used as id in props of MapView for useMap() in mapbox
export const mapViewID = "main";

// export const cameraPadding= {}

// https://docs.mapbox.com/data/tilesets/reference/mapbox-streets-v8/
export const streetSourceLayerIds = {
  water: "water",
  building: "building",
};

// https://docs.mapbox.com/api/maps/styles/
export const mapboxStyleUrls = {
  Blueprint: "mapbox://styles/mapkid/cl1vqb3fw000e14ojfav17tsq",
  Minimo: "mapbox://styles/mapkid/cl1vqa4n400b714o92ysnnngp",
  Frank: "mapbox://styles/mapkid/cl1vq9a5v000614qbdzn1k3xj",
  Pencil: "mapbox://styles/mapkid/cl1vioe5d000015qr29p7dgrq",
  Standard: "mapbox://styles/mapkid/cl1vinmh2000715mi6jsmuhgv",
  Street: "mapbox://styles/mapbox/streets-v12",
  // Street: "mapbox://styles/mapbox/streets-v10",
  SatelliteStreet: "mapbox://styles/mapbox/satellite-streets-v11",
  Dark: "mapbox://styles/mapbox/dark-v9",
  // Dark: "mapbox://styles/mapbox/dark-v10",
  // Light: "mapbox://styles/mapbox/light-v10",
  Light: "mapbox://styles/mapbox/light-v9",
  // Outdoors: "mapbox://styles/mapkid/cl1vqz9op000715qrf86p7wjz",
  Outdoors: "mapbox://styles/mapbox/outdoors-v10",
};

export const streetLayers = {
  "road-label": "road-label",
  "poi-label": "poi-label",
};

// type ModesType = keyof MapboxDraw.Modes | "static" | "choose_structure";
// type ModesType = "draw_point" | "simple_select" | "static" | "choose_structure";
type ModesType =
  | "draw_point"
  | "simple_select"
  | "static"
  | "draw_line_string"
  | "draw_polygon"
  | "choose_structure";

type PlaceType =
  | "place"
  | "multi-place"
  | "route"
  | "memo"
  | "area"
  | "pick-up"
  | "pre-built"
  | "ruler"
  | "foot-print"
  | "parts"
  | "3d-object";

type ButtonItemType = {
  label?: string;
  icon: string;
  onPress?: () => void;
  mode?: ModesType;
  type?: PlaceType;
  focused?: boolean;
};

export const simpleSelects: ButtonItemType[] = [
  {
    icon: "hand-pointing-up",
    // label: "Select",
    mode: "snap_select",
  },
  {
    icon: "hand-pointing-up",
    // label: "Select",
    mode: "magnet",
  },
];

export const multiSelects: ButtonItemType[] = [
  {
    icon: iconNames["arrow-all"],
    label: "Move",
    mode: "drag",
  },
  {
    icon: iconNames.magnet,
    label: "Align",
    // mode: "magnet",
  },
  {
    icon: iconNames["vector-square-edit"],
    label: "Edit",
    // mode: "magnet",
  },
];

export const editButtons: ButtonItemType[] = [
  {
    icon: "plus",
    mode: "memo",
  },
  {
    icon: "toolbox-outline",
    mode: "route",
  },
  {
    icon: iconNames["delete-outline"],
    mode: "delete",
  },
];

export const addButtons: ButtonItemType[] = [
  {
    icon: iconNames["comment-text-multiple-outline"],
    label: "Memo",
    mode: "memo",
  },
  {
    icon: iconNames["vector-polyline"],
    label: "Route",
    mode: "route",
  },
  {
    icon: iconNames["map-marker-outline"],
    label: "Place",
    mode: "place",
  },
  // {
  //   icon: iconNames["map-marker-multiple-outline"],
  //   label: "Multi Place",
  //   mode: "multi-place",
  // },
  {
    icon: iconNames["vector-square"],
    label: "Area",
    mode: "area",
  },
  {
    icon: iconNames["gesture-tap"],
    label: "Pick up",
    mode: "pick-up",
  },
];
export const toolButtons: ButtonItemType[] = [];

export const drawModes = {
  memo: "draw_point",
  route: "draw_line_string",
  // place: "draw_polygon",
  place: "draw_rectangle",
  area: "draw_polygon",
};

export const draws: ButtonItemType[] = [
  // {
  //   icon: "hand-pointing-up",
  //   // label: "Select",
  //   mode: "simple_select",
  // },
  {
    icon: iconNames["comment-text-multiple-outline"],
    label: "Memo",
    type: "memo",
  },
  {
    icon: iconNames["vector-polyline"],
    label: "Route",
    type: "route",
  },
  {
    icon: iconNames["map-marker-outline"],
    label: "Place",
    type: "place",
  },
  // {
  //   icon: iconNames["map-marker-multiple-outline"],
  //   label: "Multi Place",
  //   mode: "multi-place",
  // },
  {
    icon: iconNames["vector-square"],
    label: "Area",
    type: "area",
  },
  // {
  //   icon: iconNames["gesture-tap"],
  //   label: "Pick up",
  //   mode: "pick-up",
  // },
  {
    icon: iconNames["format-list-bulleted-type"],
    label: "Pre-built",
    type: "pre-built",
  },

  // {
  //   icon: iconNames["ruler"],
  //   label: "Ruler",
  //   mode: "ruler",
  //   // onPress: () => {
  //   //   setMapDrawState(value => ({...value,  }) )
  //   // },
  //   // mode: "area",
  // },
  // {
  //   icon: iconNames["foot-print"],
  //   label: "Footprint",
  //   onPress: () => {
  //     setMapDrawState((value) => ({ ...value, "foot-print": true }));
  //   },

  //   // mode: "area",
  // },
  // {
  //   icon: iconNames["delete-outline"],
  //   // label: "Delete",
  //   mode: "draw_polygon",
  //   // onPress: () => {
  //   //   const ids = drawRef.current?.getSelectedIds();
  //   //   ids?.forEach((id) => drawRef.current?.delete(id));
  //   //   console.log(ids);
  //   //   setFeatures(drawRef.current?.getAll().features);
  //   // },
  // },

  // {
  //   icon: "close",
  //   onPress: () => {
  //     setMapDrawState(null);
  //   },
  // },
];

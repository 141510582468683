export const allCurrencyLabels = {
  ja: { jpy: "日本円", usd: "米ドル", eur: "ユーロ" },
  en: {
    aed: "United Arab Emirates Dirham",
    afn: "Afghan Afghani",
    all: "Albanian Lek",
    amd: "Armenian Dram",
    ang: "Netherlands Antillean Guilder",
    aoa: "Angolan Kwanza",
    ars: "Argentine Peso",
    aud: "Australian Dollar",
    awg: "Aruban Florin",
    azn: "Azerbaijani Manat",
    bam: "Bosnia-Herzegovina Convertible Mark",
    bbd: "Barbadian Dollar",
    bdt: "Bangladeshi Taka",
    bgn: "Bulgarian Lev",
    bhd: "Bahraini Dinar",
    bif: "Burundian Franc",
    bmd: "Bermudan Dollar",
    bnd: "Brunei Dollar",
    bob: "Bolivian Boliviano",
    brl: "Brazilian Real",
    bsd: "Bahamian Dollar",
    btc: "Bitcoin",
    btn: "Bhutanese Ngultrum",
    bwp: "Botswanan Pula",
    byn: "Belarusian Ruble",
    bzd: "Belize Dollar",
    cad: "Canadian Dollar",
    cdf: "Congolese Franc",
    chf: "Swiss Franc",
    clf: "Chilean Unit of Account (UF)",
    clp: "Chilean Peso",
    cnh: "Chinese Yuan (Offshore)",
    cny: "Chinese Yuan",
    cop: "Colombian Peso",
    crc: "Costa Rican Colón",
    cuc: "Cuban Convertible Peso",
    cup: "Cuban Peso",
    cve: "Cape Verdean Escudo",
    czk: "Czech Republic Koruna",
    djf: "Djiboutian Franc",
    dkk: "Danish Krone",
    dop: "Dominican Peso",
    dzd: "Algerian Dinar",
    egp: "Egyptian Pound",
    ern: "Eritrean Nakfa",
    etb: "Ethiopian Birr",
    eur: "Euro",
    fjd: "Fijian Dollar",
    fkp: "Falkland Islands Pound",
    gbp: "British Pound Sterling",
    gel: "Georgian Lari",
    ggp: "Guernsey Pound",
    ghs: "Ghanaian Cedi",
    gip: "Gibraltar Pound",
    gmd: "Gambian Dalasi",
    gnf: "Guinean Franc",
    gtq: "Guatemalan Quetzal",
    gyd: "Guyanaese Dollar",
    hkd: "Hong Kong Dollar",
    hnl: "Honduran Lempira",
    hrk: "Croatian Kuna",
    htg: "Haitian Gourde",
    huf: "Hungarian Forint",
    idr: "Indonesian Rupiah",
    ils: "Israeli New Sheqel",
    imp: "Manx pound",
    inr: "Indian Rupee",
    iqd: "Iraqi Dinar",
    irr: "Iranian Rial",
    isk: "Icelandic Króna",
    jep: "Jersey Pound",
    jmd: "Jamaican Dollar",
    jod: "Jordanian Dinar",
    jpy: "Japanese Yen",
    kes: "Kenyan Shilling",
    kgs: "Kyrgystani Som",
    khr: "Cambodian Riel",
    kmf: "Comorian Franc",
    kpw: "North Korean Won",
    krw: "South Korean Won",
    kwd: "Kuwaiti Dinar",
    kyd: "Cayman Islands Dollar",
    kzt: "Kazakhstani Tenge",
    lak: "Laotian Kip",
    lbp: "Lebanese Pound",
    lkr: "Sri Lankan Rupee",
    lrd: "Liberian Dollar",
    lsl: "Lesotho Loti",
    lyd: "Libyan Dinar",
    mad: "Moroccan Dirham",
    mdl: "Moldovan Leu",
    mga: "Malagasy Ariary",
    mkd: "Macedonian Denar",
    mmk: "Myanma Kyat",
    mnt: "Mongolian Tugrik",
    mop: "Macanese Pataca",
    mru: "Mauritanian Ouguiya",
    mur: "Mauritian Rupee",
    mvr: "Maldivian Rufiyaa",
    mwk: "Malawian Kwacha",
    mxn: "Mexican Peso",
    myr: "Malaysian Ringgit",
    mzn: "Mozambican Metical",
    nad: "Namibian Dollar",
    ngn: "Nigerian Naira",
    nio: "Nicaraguan Córdoba",
    nok: "Norwegian Krone",
    npr: "Nepalese Rupee",
    nzd: "New Zealand Dollar",
    omr: "Omani Rial",
    pab: "Panamanian Balboa",
    pen: "Peruvian Nuevo Sol",
    pgk: "Papua New Guinean Kina",
    php: "Philippine Peso",
    pkr: "Pakistani Rupee",
    pln: "Polish Zloty",
    pyg: "Paraguayan Guarani",
    qar: "Qatari Rial",
    ron: "Romanian Leu",
    rsd: "Serbian Dinar",
    rub: "Russian Ruble",
    rwf: "Rwandan Franc",
    sar: "Saudi Riyal",
    sbd: "Solomon Islands Dollar",
    scr: "Seychellois Rupee",
    sdg: "Sudanese Pound",
    sek: "Swedish Krona",
    sgd: "Singapore Dollar",
    shp: "Saint Helena Pound",
    sll: "Sierra Leonean Leone",
    sos: "Somali Shilling",
    srd: "Surinamese Dollar",
    ssp: "South Sudanese Pound",
    std: "São Tomé and Príncipe Dobra (pre-2018)",
    stn: "São Tomé and Príncipe Dobra",
    svc: "Salvadoran Colón",
    syp: "Syrian Pound",
    szl: "Swazi Lilangeni",
    thb: "Thai Baht",
    tjs: "Tajikistani Somoni",
    tmt: "Turkmenistani Manat",
    tnd: "Tunisian Dinar",
    top: "Tongan Pa'anga",
    try: "Turkish Lira",
    ttd: "Trinidad and Tobago Dollar",
    twd: "New Taiwan Dollar",
    tzs: "Tanzanian Shilling",
    uah: "Ukrainian Hryvnia",
    ugx: "Ugandan Shilling",
    usd: "United States Dollar",
    uyu: "Uruguayan Peso",
    uzs: "Uzbekistan Som",
    vef: "Venezuelan Bolívar Fuerte (Old)",
    ves: "Venezuelan Bolívar Soberano",
    vnd: "Vietnamese Dong",
    vuv: "Vanuatu Vatu",
    wst: "Samoan Tala",
    xaf: "CFA Franc BEAC",
    xag: "Silver Ounce",
    xau: "Gold Ounce",
    xcd: "East Caribbean Dollar",
    xdr: "Special Drawing Rights",
    xof: "CFA Franc BCEAO",
    xpd: "Palladium Ounce",
    xpf: "CFP Franc",
    xpt: "Platinum Ounce",
    yer: "Yemeni Rial",
    zar: "South African Rand",
    zmw: "Zambian Kwacha",
    zwl: "Zimbabwean Dollar",
  },
};

export const currencies = [
  "usd",
  "aed",
  "afn",
  "all",
  "amd",
  "ang",
  "aoa",
  "ars",
  "aud",
  "awg",
  "azn",
  "bam",
  "bbd",
  "bdt",
  "bgn",
  "bif",
  "bmd",
  "bnd",
  "bob",
  "brl",
  "bsd",
  "bwp",
  "byn",
  "bzd",
  "cad",
  "cdf",
  "chf",
  "clp",
  "cny",
  "cop",
  "crc",
  "cve",
  "czk",
  "djf",
  "dkk",
  "dop",
  "dzd",
  "egp",
  "etb",
  "eur",
  "fjd",
  "fkp",
  "gbp",
  "gel",
  "gip",
  "gmd",
  "gnf",
  "gtq",
  "gyd",
  "hkd",
  "hnl",
  "hrk",
  "htg",
  "huf",
  "idr",
  "ils",
  "inr",
  "isk",
  "jmd",
  "jpy",
  "kes",
  "kgs",
  "khr",
  "kmf",
  "krw",
  "kyd",
  "kzt",
  "lak",
  "lbp",
  "lkr",
  "lrd",
  "lsl",
  "mad",
  "mdl",
  "mga",
  "mkd",
  "mmk",
  "mnt",
  "mop",
  "mro",
  "mur",
  "mvr",
  "mwk",
  "mxn",
  "myr",
  "mzn",
  "nad",
  "ngn",
  "nio",
  "nok",
  "npr",
  "nzd",
  "pab",
  "pen",
  "pgk",
  "php",
  "pkr",
  "pln",
  "pyg",
  "qar",
  "ron",
  "rsd",
  "rub",
  "rwf",
  "sar",
  "sbd",
  "scr",
  "sek",
  "sgd",
  "shp",
  "sle",
  "sll",
  "sos",
  "srd",
  "std",
  "szl",
  "thb",
  "tjs",
  "top",
  "try",
  "ttd",
  "twd",
  "tzs",
  "uah",
  "ugx",
  "uyu",
  "uzs",
  "vnd",
  "vuv",
  "wst",
  "xaf",
  "xcd",
  "xof",
  "xpf",
  "yer",
  "zar",
  "zmw",
];

const MAPBOX_TOKEN =
  "sk.eyJ1IjoibWFwa2lkIiwiYSI6ImNrdm9kcWcxMTVzdjQycm9rNzMxd25yenQifQ.exEP7x2UJB29LDfx3NdF8w";

import MapiClient, {
  SdkConfig,
} from "@mapbox/mapbox-sdk/lib/classes/mapi-client";
import { MapiRequestOptions } from "@mapbox/mapbox-sdk/lib/classes/mapi-request";

import geocodingBase, {
  GeocodeRequest,
} from "@mapbox/mapbox-sdk/services/geocoding";

export const geocodingClient = geocodingBase({ accessToken: MAPBOX_TOKEN });

import datasetsBase from "@mapbox/mapbox-sdk/services/datasets";
import stylesBase from "@mapbox/mapbox-sdk/services/styles";
import directionsBase from "@mapbox/mapbox-sdk/services/directions";

const accessToken = MAPBOX_TOKEN;
// "pk.eyJ1IjoibWFwa2lkIiwiYSI6ImNrZ3U0a3d6aTBrOWgyeXRtanh0aHUzOTcifQ.ss4jGIc7hG9VyW80bQ81jw";
// "sk.eyJ1IjoibWFwa2lkIiwiYSI6ImNrdHc2czhjOTFxbTcydnFlODRlaHViZG4ifQ.UCVp2Qii6xrKJdGF-YtFqw";

// datasetsBase({ accessToken });
export const datasetsClient = datasetsBase({ accessToken });

// const tes: MapiClient
export const stylesClient = stylesBase({ accessToken });

export const directionsClient = directionsBase({ accessToken });

const testRequestData: StaticMapRequest = {
  ownerId: "mapbox",
  styleId: "streets-v11",
  width: 200,
  height: 300,
  // position: {

  //   // coordinates: [12, 13],
  //   // zoom: 3,

  // },

  // setfilter: [">","height",300],
  overlays: [
    // Simple markers.
    {
      marker: {
        coordinates: [12.2, 12.8],
      },
    },
    {
      marker: {
        size: "large",
        coordinates: [14, 13.2],
        label: "m",
        color: "#000",
      },
    },
    {
      marker: {
        coordinates: [15, 15.2],
        label: "airport",
        color: "#ff0000",
      },
    },
    // Custom marker
    {
      marker: {
        coordinates: [10, 11],
        url: "https://upload.wikimedia.org/wikipedia/commons/6/6f/0xff_timetracker.png",
      },
    },
  ],
};

import staticImageBase, {
  StaticMapRequest,
} from "@mapbox/mapbox-sdk/services/static";
import { Feature, MultiPolygon, Polygon } from "@turf/turf";
export const staticImageClient = staticImageBase({ accessToken: MAPBOX_TOKEN });

// https://github.com/mapbox/mapbox-sdk-js/blob/main/docs/services.md#directions
export const getDirections = async (features: Feature[]) => {
  const waypoints = features.map((f) => {
    const { coordinates } = f.geometry;

    return { coordinates };
  });

  return directionsClient
    .getDirections({
      profile: "walking",
      waypoints,
      geometries: "geojson",
      // overview: "full",
    })
    .send();
};

export const getDatasetMetaData = (datasetId: string) =>
  datasetsClient
    .getMetadata({ datasetId })
    .send()
    .then((res) => {
      return res.body;
      const updatedAt = Date.parse(res.body.modified);
      // userMaps[mapID].features["updatedAt"] = updatedAt;
    });

export const testStatic: StaticMapRequest = {
  ownerId: "mapbox",
  styleId: "streets-v11",
  width: 200,
  height: 300,
  position: {
    coordinates: [12, 13],
    zoom: 4,
  },
  // decrease points and floor the number.
  overlays: [
    // { path: { coordinates: [], fillColor: "#123456" } },
    // {
    //   geoJson: cleanGeojson({
    //     features: [
    //       {
    //         type: "Feature",
    //         properties: {
    //           "marker-size": "s",
    //           "marker-symbol": "airport",
    //           "marker-color": "#0000FF",
    //         },
    //         geometry: {
    //           type: "MultiPoint",
    //           coordinates: [
    //             [1, 2],
    //             [2, 1],
    //             [3, 2],
    //             [1, 3],
    //           ],
    //         },
    //       },
    //     ],
    //     type: "FeatureCollection",
    //   }),
    // },
  ],
};

export const cleanGeojson = (geojson: FeatureCollection, tolerance = 0.001) => {
  // Removes redundant coordinates from any GeoJSON Geometry.
  const { features } = geojson;
  const cleaned = features.map((f) => turf.cleanCoords(f));
  // const rounded = turf.round(cleaned, 6);
  const truncated = cleaned.map((f) => turf.truncate(f));
  const simplified = turf.simplify(featureCollection(truncated), {
    tolerance,
    highQuality: true,
  });
  return simplified;
};

//check tips in  https://docs.mapbox.com/api/maps/static-images/
// https://github.com/mapbox/mapbox-sdk-js/blob/main/docs/services.md#static

// The Static Images API only accepts requests that are 8,192 or fewer characters long
// use toDataUrl to get base64URL
export const getStaticMapImageURL = (request: StaticMapRequest) =>
  staticImageClient.getStaticImage(request).url();

// get base64
export const getStaticMapDataWithFeatures = (
  features: Feature[],
  tolerance = 0.0001
) => {
  const geoJson = cleanGeojson(featureCollection(features), tolerance);

  const staticParams = {
    ownerId: "mapbox",
    styleId: "streets-v11",
    width: 800,
    height: 450,
    position: "auto",
    padding: "50", //5,8,10,7
    before_layer: "road-label",
  };

  return getStaticMapImageURL({ ...staticParams, overlays: [{ geoJson }] });
  // staticImageClient.getStaticImage(request).url()
};

// https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#within
// supported feature is Point or LIneString
// export const getWithinExpression = (feature: Feature<Polygon | MultiPolygon>) => {};

// https://github.com/mapbox/mapbox-gl-js/issues/9795#issuecomment-901333239
// https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#distance
export const getWithinExpression = (
  feature: Feature<Polygon | MultiPolygon>
) => [">", ["distance", feature], 0];

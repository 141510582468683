import { Feature } from "@turf/turf";
import {
  FirestoreFeatureCollectionType,
  MediaType,
  PlaceCollectionType,
} from "../types/main";
import {
  addDatabase,
  arrayRemoveField,
  createRef,
  deleteDataField,
  firebase,
  getBatch,
  getDatabase,
  getDatabaseRef,
  getDocsRef,
  getHttpsFunction,
  getUID,
  setDatabase,
  updateDatabase,
} from "./firebase";
import { getRandomID } from "./tools";

import type {
  CollectionReference,
  DocumentReference,
  Query,
} from "firebase/firestore";

import {
  arrayUnion,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  or,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { get } from "react-native/Libraries/TurboModule/TurboModuleRegistry";
import { create } from "@mui/material/styles/createTransitions";

// https://firebase.google.com/docs/firestore/query-data/queries?hl=ja#web-version-8_13

// array-contains: where("regions", "array-contains", "west_coast")
// regions field array includes west_coast

// array-contains-any: where('regions', 'array-contains-any',['west_coast', 'east_coast']);
// regions field array includes  west_coast or east_coast

// in: where('country', 'in', ['USA', 'Japan'])
// country is USA or Japan

// not-in: where('country', 'not-in', ['USA', 'Japan'])

const appName = "mapnom";
const appVersion = "v1";

const createDBPath = (arr: string[]) => arr.join("/");

// only for test use
export const testsRef = getDocsRef("tests");
const appRefArray = [appName, appVersion];
export const appRef = getDatabaseRef(appRefArray.join("/"));
export const adminRef = createRef(appRef, ["admin", "v1"]);
// export const mailsRef = adminRef.collection("mails");
// export const translationsRef = adminRef.collection("translations");

// export const mapsCollectionRef = createRef(appRef, ["client", "v1", "maps"]);

// export const getMapRef = (mapID) => createRef(mapsCollectionRef, [mapID]);
export const getMapRef = (mapID) => createRef(appRef, ["maps", mapID]);

export const getPlacesRef = (mapID: string) =>
  createRef(getMapRef(mapID), ["places"]);

const getPlaceRef = (mapID, placeID) => {
  const ref: DocumentReference = createRef(getPlacesRef(mapID), [placeID]);
  return ref;
};

const getPostRef = (mapID, postID) =>
  createRef(getMapRef(mapID), ["posts", postID]);

const getMapCollectionRef = (
  mapID,
  collectionName: "features" | "featureCollection" | "places"
) => createRef(getMapRef(mapID), [collectionName]);

const getFeaturesRef = (mapID: string) =>
  getMapCollectionRef(mapID, "features");

const getFeatureCollectionRef = (mapID: string) =>
  getMapCollectionRef(mapID, "featureCollection");

const getUserRef = (uid, target: "private" | "public" | "display") =>
  createRef(appRef, [target, "v1", "users", uid]);

const getUserCollectionRef = (
  mapID,
  collectionName: "transfers" | "destinations" | "payments"
) => createRef(getMapRef(mapID), [collectionName]);

// export const userTransfersRef = (uid: string) =>
//   userRef(uid, "private").collection("transfers");

// export const userDestinationsRef = (uid: string) =>
//   userRef(uid, "private").collection("destinations");

// export const userPaymentsRef = (uid: string) =>
//   userRef(uid, "private").collection("payments");

export const getChatRoomRef = (chatRoomID: string) =>
  createRef(appRef, ["private", "v1", "chatRooms", chatRoomID]);

const getDocData = (path: string) => getDoc(getDatabaseRef(path));
const getDocsData = (path: string) => getDocs(getDatabaseRef(path));
const getDocsDataWithQuery = (query: Query) => getDocs(query);
const getQuery = (
  ref: CollectionReference,
  ...queries: Array<Query | undefined>
) => query(ref, ...queries);

const getOr = (q1, q2) => or(q1, q2);

const unsubscribeDoc = (q: DocumentReference) =>
  onSnapshot(q, (doc) => {
    if (!doc.exists()) return null;
    return doc.data();
  });

const unsubscribeDocs = (q: Query, callback: (res: object) => void) =>
  onSnapshot(q, (snapshot) => {
    const obj = {};
    snapshot.docChanges().forEach((change) => {
      obj[change.doc.id] = change.doc.data();
    });
    callback(obj);
    return obj;
  });

export const getUserData = (uid: string, key: "private" | "public") => {
  const ref = getUserRef(uid, key);
  return (
    getDocData(ref.path)
      //  userRef(uid, key)
      //   .get()
      .then(async (doc) => {
        if (doc.exists()) {
          return doc.data();
        } else {
          return "No user data.";
        }
      })
      .catch((err) => "Error happens while getting user data.")
  );
};

export const unsubscribeUserData = (uid: string, key: "private" | "public") => {
  const ref = getUserRef(uid, key);
  return (
    getDocData(ref.path)
      //  userRef(uid, key)
      //   .get()
      .then(async (doc) => {
        if (doc.exists()) {
          return doc.data();
        } else {
          return "No user data.";
        }
      })
      .catch((err) => "Error happens while getting user data.")
  );
};

// const setDocData = (path: string, data: any) =>

export const addPost = (mapID, placeID, postID, post) => {
  const batch = getBatch();

  if (placeID) {
    //existing place
    const placeRef = getPlaceRef(mapID, placeID);
    if (!placeRef) return;

    batch.update(placeRef, {
      posts: arrayUnion(postID),
      updatedAt: Date.now(),
    });
    post["place"] = placeID;
  }

  batch.set(getPostRef(mapID, postID), post);

  return batch.commit().then(() => {
    // databaseRef(mapID, "posts").set({ updatedAt: Date.now() });
  });
};

export const createMap = async (data) => {
  const uid = getUID();
  const mapID = getRandomID();

  const batch = getBatch();
  //prepare added members
  // await updateMembers("create", mapInfo, mapID, batch);

  const additonalData = {
    createdAt: Date.now(),
    updatedAt: Date.now(),
    owner: { uid },
    // members:[uid]
    uids: [uid],
  };

  batch.set(getMapRef(mapID), { ...data, ...additonalData });

  // batch.update(getUserRef(uid, "public"), {
  //   [`maps.${mapID}`]: { mapID, title: data.title, type: "owner" },
  //   // hostMaps: arrayUnion(mapID),
  // });

  return batch.commit().then(() => {
    // addFeatureCollection({ mapID, type: "initial", auth: { type: "public" } });
    // addFeatureCollection({ mapID, type: "initial", auth: { type: "private" } });

    return mapID;
  });

  // data["createdAt"] = Date.now();
  // data["updatedAt"] = Date.now();
  // return mapsCollectionRef
  //   .add(data)
  //   .then((res) => ({ type: "success", mapID: res.id }));
};

export const getUserMaps = () => {
  const uid = getUID();

  const mapsRef = createRef(appRef, ["maps"]);

  const query1 = where(`owner.uid`, "==", uid);
  const query2 = where(`members.${uid}`, "!=", null); //このクエリは、capital フィールドが存在し、その値が false または null 以外のすべての city ドキュメントを返します。

  // const query = getQuery(mapsRef, getOr(query1, query2));
  // const query = getQuery(mapsRef, getOr(query1, query2));
  const query = getQuery(mapsRef, query1);

  return getDocsDataWithQuery(query).then((snapshot) => {
    if (snapshot.empty) return;
    return snapshot.docs.map((doc) => doc.data());
    // const doc = snapshot.docs[0];
    // return doc.data();
  });
};

// status, category
export const getSearchMaps = ({
  status,
  categories,
  type,
}: {
  type?: Array<"local-guide" | "festival">;
  status?: Array<"plan" | "design" | "sale" | "ongoing">;
  categories?: Array<"music" | "food" | "">;
}) => {
  // // let mapsRef = appRef.collection("maps").orderBy("start", "asc");
  // let mapsRef = createRef(appRef, ["maps"]);
  // const q1 = type ? where("type", "in", type) : undefined;
  // const q2 = status ? where("status", "in", status) : undefined;
  // const q3 = categories ? where("categories", "in", categories) : undefined;
  // const query = getQuery(mapsRef, orderBy("start", "asc"), q1, q2, q3);
  // return getDocsDataWithQuery(query).then((snapshot) => {
  //   // if (type) {
  //   //   mapsRef = mapsRef.where("type", "in", type);
  //   // }
  //   // if (status) {
  //   //   mapsRef = mapsRef.where("status", "in", status);
  //   // }
  //   // if (categories) {
  //   //   mapsRef = mapsRef.where("status", "in", categories);
  //   // }
  //   // return mapsRef.get().then((snapshot) => {
  //   if (snapshot.empty) return;
  //   return snapshot.docs.map((doc) => doc.data());
  // });
};

export const getEdition = ({
  mapID,
  index,
}: {
  mapID: string;
  index: number;
}) => {
  const mapRef = getMapRef(mapID);

  const editionsRef = createRef(mapRef, ["editions"]);
  const query = getQuery(editionsRef, where("edition", "==", index), limit(1));

  return getDocsDataWithQuery(query).then((snapshot) => {
    if (snapshot.empty) return;
    const doc = snapshot.docs[0];
    return doc.data();
  });
};

// hostMaps ??
export const addUserMaps = (uid, mapID) =>
  setDatabaseArrayUnion(getUserRef(uid, "public"), "maps", mapID);

export const getNotifications = (uid, date?) => {
  const userRef = getUserRef(uid, "public");
  const notificationsRef = createRef(userRef, ["notifications"]);

  return getDocData(notificationsRef.path).then((snapshot) => {
    const notifications = [];
    snapshot.forEach((doc) => {
      const data = doc.data();
      data.id = doc.id;
      notifications.push(data);
    });

    return notifications;
  });
};

// export const updateFeatureData = async (mapID, placeID, feature) => {
//   feature.properties = JSON.stringify(feature.properties);
//   feature.geometry = JSON.stringify(feature.geometry);

//   return mapRef(mapID)
//     .collection("features")
//     .doc(placeID)
//     .update(feature)
//     .then(() => {
//       return;
//     })
//     .catch((err) => {
//       alert("hmmm, something wrong.");
//     });
// };

export const updatePlaceInfo = async (mapID, placeID, place) => {
  const ref = getPlaceRef(mapID, placeID);
  return updateDatabase(ref.path, place);
};

// nested array is not allowed in firestore
const stringifyFeature = (feature: Feature) => {
  return { ...feature, geometry: JSON.stringify(feature.geometry) };
};

const parseFeature = (stringifiedFeature: any) => {
  if (typeof stringifiedFeature.geometry == "object") return stringifiedFeature;
  const feature: Feature = {
    ...stringifiedFeature,
    geometry: JSON.parse(stringifiedFeature.geometry),
    // properties: JSON.parse(stringifiedFeature.properties),
  };
  return feature;
};

// //trigger onUpdateFeature to putFeature on datasets
// export const addPlace = ({
//   mapID,
//   feature,
//   initialFeatureCollectionID,
// }: {
//   mapID: string;
//   feature: Feature;
//   initialFeatureCollectionID: string;
// }) => {
//   const placeID = feature.id || getRandomID();
//   feature.properties["placeID"] = placeID;
//   const stringifiedFeature = stringifyFeature(feature);
//   const batch = getBatch();

//   const currentTime = Date.now();

//   //place data
//   const placeRef = placesRef(mapID).doc(placeID);
//   const placeData = {
//     title: feature.properties?.title || null,
//     feature: stringifiedFeature,
//     createdAt: currentTime,
//     updatedAt: currentTime,
//   };
//   batch.set(placeRef, placeData);

//   //initial featureCollection
//   const initRef = mapRef(mapID)
//     .collection("featureCollection")
//     .doc(initialFeatureCollectionID);
//   const featureField = `features.${placeID}`;
//   batch.update(initRef, {
//     [featureField]: stringifiedFeature,
//     updatedAt: currentTime,
//   });

//   //listen featureCollection
//   const temID = getRandomID();
//   const featureCollectionRef = getFeatureCollectionRef(mapID).doc(temID);
//   const featureCollectionData: FirestoreFeatureCollectionType = {
//     type: "listener",
//     features: {},
//     createdAt: currentTime,
//     updatedAt: currentTime,
//   };
//   batch.set(featureCollectionRef, featureCollectionData);

//   return batch.commit().then(() => {
//     console.log(placeID);

//     return { placeID };
//   });
// };

// // title,
// export const updateFeature = (
//   mapID: string,
//   placeID: string,
//   feature: Feature
// ) => {
//   const stringifiedFeature = stringifyFeature(feature);
//   const batch = getBatch()
//   const placeRef = placesRef(mapID).doc(placeID);

//   const currentTime = Date.now();

//   //place data
//   const placeData = {
//     feature: stringifiedFeature,
//     updatedAt: currentTime,
//   };
//   batch.update(placeRef, placeData);

//   // map info denormalisation
//   const featureField = `features.${placeID}`;
//   const mapInfoData = {
//     [featureField]: stringifiedFeature,
//     updatedAt: currentTime,
//   };
//   batch.update(mapRef(mapID), mapInfoData);

//   return batch.commit().then(() => {
//     return { placeID };
//   });
// };

// place. not feature
export const setPlace = (
  mapID: string,
  placeID: string,
  place: {
    title?: string;
    description?: string;
    images?: { [key: string]: MediaType };
    feature?: Feature;
  }
) => {
  const placeRef = getPlaceRef(mapID, placeID);
  const currentTime = Date.now();
  return setDatabase(
    placeRef.path,
    { ...place, updatedAt: currentTime },
    { merge: true }
  );
};

export const updatePlace = (
  mapID: string,
  placeID: string,
  place: {
    title?: string;
    description?: string;
    feature?: Feature;
    images?: { [key: string]: MediaType };
  }
) => {
  const batch = getBatch();
  const placeRef = getPlaceRef(mapID, placeID);

  const currentTime = Date.now();

  //place data
  const placeData = {
    title: place.title || null,
    feature: stringifyFeature(place.feature),
    updatedAt: currentTime,
  };

  batch.set(placeRef, placeData);

  // const featureField = `features.${placeID}`;
  // batch.update(mapRef(mapID), {
  //   [featureField]: feature,
  //   updatedAt: currentTime,
  // });

  return batch.commit().then(() => {
    return { placeID };
  });
};

export const deletePlace = (
  mapID: string,
  placeID: string,
  datasetId: string
) => {
  // const batch = getBatch();
  const batch = getBatch();
  const placeRef = getPlaceRef(mapID, placeID);
  batch.delete(placeRef);

  const mapRef = getMapRef(mapID);
  batch.update(mapRef, {
    [`features.${placeID}`]: deleteDataField(),
  });

  // const featureRef = featuresRef(mapID).doc(placeID);

  // const mapInfo = userMaps[mapID].info
  // const datasetId = "ckuqyd9pw55rj24pgmhoql4un";
  // datasetsClient
  //   .deleteFeature({ datasetId, featureId: placeID })
  //   .send()
  //   .then((res) => {
  //     console.log(res);
  //   })
  //   .catch((err) => console.log(err));

  // batch.update(featureRef, { deleted: true });

  return batch.commit().then(() => {
    return { placeID };
  });
};

// // listen inside of post card, like comments
// const unsubPosts = mapRef(mapID)
//   .collection("posts")
//   .where("place", "==", placeID)
//   .orderBy("updatedAt", "desc")
//   .where("updatedAt", ">", Date.now())
//   .onSnapshot((snapshot) => {
//     snapshot.docChanges().forEach((change) => {

//       if (change.type == "added") {
//         userMaps[mapID].posts[change.doc.id] = change.doc.data();
//         setPosts([...posts, change.doc.data()]);
//       } else if (change.type == "modified") {
//         setPlaceInfo({ ...placeInfo });
//       } else if (change.type == "removed") {
//       }
//     });
//   });

export const getPlace = ({
  mapID,
  index,
  placeID,
}: {
  index: number;
  placeID: string;
  mapID: string;
}) => {
  const placesRef = getMapCollectionRef(mapID, "places");
  const query = getQuery(placesRef, where("index", "==", index), limit(1));

  return getDocsDataWithQuery(query).then((snapshot) => {
    // query.get().then((snapshot) => {
    if (snapshot.empty) return;
    const doc = snapshot.docs[0];
    return doc.data();
  });
};

export const createPortalLink = (data: any) =>
  getHttpsFunction("ext-firestore-stripe-payments-createPortalLink")(data);

const recaptchaPublicKey = "6LeI2jIiAAAAAOAbJZ3vag7eSnf_aHG_jt64ZQ4n";

export const getTransfersData = (uid: string) => {
  const transfersRef = createRef(getUserRef(uid, "private"), ["transfers"]);

  return getDocsData(transfersRef.path).then((snapshot) => {
    if (snapshot.empty) return null;
    return snapshot.docs.map((doc) => doc.data());
  });
};

export const getPaymentsData = (uid: string) => {
  const paymentsRef = createRef(getUserRef(uid, "private"), ["payments"]);
  return getDocsData(paymentsRef.path).then((snapshot) => {
    if (snapshot.empty) return null;
    return snapshot.docs.map((doc) => doc.data());
  });
};

export const useSubscribeMap = (
  mapID: string,
  callback: (data: any | undefined) => void
) => {
  useEffect(() => {
    const unsubMap = mapRef(mapID).onSnapshot((doc) => {
      if (!doc.exists) {
        return;
      }
      const mapInfo = doc.data();
      return callback(mapInfo);
    });
    return unsubMap;
  }, []);
};

export const unsubscribePlaceInfo = (
  mapID: string,
  placeID: string,
  func: (data: any | undefined) => void
) => {
  const placeRef = getPlaceRef(mapID, placeID);
  return unsubscribeDoc(placeRef);
};

export const incrementSeverValue = (ref: DocRefType, field, value) =>
  ref.update({
    [field]: arrayUnion(value),
  });

export const deletePost = getHttpsFunction("deletePost");

export const setDatabaseArrayRemove = (
  ref: DocRefType,
  field: string,
  value: any
) =>
  ref.update({
    [field]: arrayRemoveField(value),
  });

export const setDatabaseArrayUnion = (
  ref: DocRefType,
  field: string,
  value: any
) =>
  ref.update({
    [field]: arrayUnion(value),
  });

export const getMapInfo = async (mapID: string) => {
  const mapRef = getMapRef(mapID);
  const mapInfo = await getDocData(mapRef.path)
    .then(async (doc) => doc.data())
    .catch((err) => {
      if (err.code == "permission-denied") {
        // console.log(err);
        // blocked with auth by DB
        // alert("You are not authorized to see this map.");
      }
      // debug(err.code);
    });

  // console.log(mapInfo);
  if (!mapInfo) return;

  return mapInfo;
};

export const getMapGroupInfo = async (mapID: string, groupID: string) => {
  const mapRef = getMapRef(mapID);
  const groupRef = createRef(mapRef, ["groups", groupID]);
  const groupInfo = await getDocData(groupRef.path).then((doc) => {
    if (!doc.exists) return null;
    return doc.data();
  });

  return groupInfo;
};

// export const getUserLocations = async (mapID: string, groupID: string) =>
//   mapRef(mapID)
//     .collection("groups")
//     .doc(groupID)
//     .get()
//     .then((doc) => {
//       if (!doc.exists) return null;
//       return doc.data();
//     });

export const unsubscribeMessages = (
  mapID: string,
  callback: (data: any) => void
) => {
  const messagesRef = createRef(getMapRef(mapID), ["messages"]);
  const query = getQuery(
    messagesRef,
    where("createdAt", ">", Date.now()),
    orderBy("createdAt", "desc"),
    limit(20)
  );

  return unsubscribeDocs(query, callback);

  //   .onSnapshot((snapshot) => {
  //       // if change, refer to userMaps
  //       // const messages = Object.values(messagesObj).sort((a, b) => {
  //       //   if (a.createdAt > b.createdAt) return -1;
  //       //   if (a.createdAt < b.createdAt) return 1;
  //       //   return 0;
  //       // })
  //       // console.log("listen in caht", userMaps[mapID]);
  //       // setMessages(composeMessages());
  //     });
};

export const getInitialSearchMaps = () => {
  const mapsRef = createRef(appRef, ["client", "v1", "maps"]);

  // .where("updatedAt", ">", Date.now())

  const query = getQuery(
    mapsRef,
    where("showInSearch", "==", true),
    orderBy("updatedAt", "desc"),
    limit(20)
  );

  return getDocsDataWithQuery(query)
    .then((snapshot) => {
      const results: any[] = [];
      snapshot.docs.forEach((doc) => {
        results.push(doc.data());
      });

      return results;
    })
    .catch((err) => debug(err));
};

// export const submitPrivacyInfo = (data: any) => {
//   appRef.collection("privacy").add(data);
// };

// const activitiesRef = createRef(appRef, ["activities"]);

// export const addActivity = ({ user }) => {
//   activitiesRef.add({ user });
// };

// export const updateActivity = (activityID: string, data: any) =>
//   activitiesRef.doc(activityID).update(data);

// export const getActivity = (activityID) =>
//   activitiesRef
//     .doc(activityID)
//     .get()
//     .then((doc) => doc.data());

// export const getSearchMapInfos = () =>
//   mapRef("searchMap")
//     .get()
//     .then((doc) => {
//       const data = doc.data();

//       const mapInfos = data;
//       return mapInfos;

//       // const items = Object.keys(data).map((key) => {
//       //   const mapID = key;
//       //   return { mapID, ...data[mapID] };
//       // });

//       // const geojson = mapBboxToPointsGeojson(items);
//       // return geojson;
//     });

export const updateMapPlaceCollection = (
  mapID: string,
  collectionID: string,
  data: PlaceCollectionType
) => {
  const newData: { [key: string]: any } = {};
  Object.keys(data).forEach((key) => {
    newData[`placeCollections.${collectionID}.${key}`] = data[key];
  });

  return updateDatabase(getMapRef(mapID).path, newData);
  //   return   mapRef(mapID).update(newData);
};

// used for nested field in firestore
const createDottedObject = (
  data: object,
  prefix: string,
  callback?: Function // process value
) => {
  const dotted: { [key: string]: any } = {};
  Object.keys(data).map((key: string) => {
    dotted[`${prefix}.${key}`] = callback ? callback(data[key]) : data[key];
  });

  return dotted;
};

// add and edit
export const writePlaceTag = (
  mapID: string,
  tagID: string,
  data: { title?: string; placeIDs?: string[] }
) => {
  if (!data) return;
  const mapRef = getMapRef(mapID);
  const tagField = `tags.${tagID}`;
  const dotted: { [key: string]: any } = createDottedObject(data, tagField);
  return updateDatabase(mapRef.path, dotted);
  //   return mapRef.update(dotted);
};

export const addTicket = (
  mapID: string,
  ticketData: { amount: number; curency: string; services: string[] }
) => {
  const batch = getBatch();
  const mapRef = getMapRef(mapID);
  const ticketID = getRandomID();
  const ticketField = `tickets.${ticketID}`;
  batch.update(mapRef, { [ticketField]: ticketData });

  const ticketRef = createRef(mapRef, ["tickets", ticketID]);
  batch.set(ticketRef, { [ticketID]: ticketData });
  batch.commit().then((res) => {});
};

//
export const createFeatureCollectionData = (type: string) => {
  const currentTime = Date.now();
  const uid = getUID();

  const data: FirestoreFeatureCollectionType = {
    // type: "initial", // initial, listener
    features: {},
    createdAt: currentTime,
    updatedAt: currentTime,
    // auth: {
    //   type: "member",
    //   members: [uid],
    // },
    // auth: {
    //   "type": "member",
    //   uids: []
    // }
  };

  return data;
};

export const addFeatureCollection = ({
  mapID,
  type,
  auth,
}: {
  mapID: string;
  type: "initial" | "listener";
  auth?: {};
}) => {
  const featureCollectionRef = getFeatureCollectionRef(mapID);

  const data: FirestoreFeatureCollectionType =
    createFeatureCollectionData(type);

  return addDatabase(featureCollectionRef?.path, data);
  // return featureCollectionRef.add(data).then((res) => res.id);
};

export const updateFeatureCollection = ({
  id,
  mapID,
  features,
}: {
  id: string;
  mapID: string;
  features: { [id: string]: Feature };
  // type: "initial" | "listener",
  auth?: {};
}) => {
  const batch = getBatch();
  const mapRef = getMapRef(mapID);
  const featureCollectionRef = createRef(mapRef, ["featureCollection", id]);
  const currentTime = Date.now();

  const dottedFeatures = createDottedObject(
    features,
    "features",
    stringifyFeature
  );

  // initial
  const data: FirestoreFeatureCollectionType = {
    ...dottedFeatures,
    updatedAt: currentTime,
  };

  console.log(data);

  batch.update(featureCollectionRef, data);

  // // listen
  // const featureCollectionRef = getFeatureCollectionRef(mapID).doc(id)
  // batch.set()

  return batch.commit().then((res) => {
    return "done";
  });
};

export const deleteFeatures = ({
  id,
  mapID,
  featureIDs,
}: {
  id: string;
  mapID: string;
  // features: { [id: string]: Feature };
  featureIDs: string[];
  // // type: "initial" | "listener",
  // auth?: {};
}) => {
  const mapRef = getMapRef(mapID);
  const featureCollectionRef = createRef(mapRef, ["featureCollection", id]);

  const currentTime = Date.now();

  const dottedFeatures = {};
  featureIDs.forEach((id) => {
    const newID = `features.${id}`;
    dottedFeatures[newID] = deleteDataField();
  });

  const data: FirestoreFeatureCollectionType = {
    ...dottedFeatures,
    updatedAt: currentTime,
  };

  return updateDatabase(featureCollectionRef.path, data)
    .then((res) => "success")
    .catch((e) => "error");
};

// day, number of times, category, custom group??
// specify tag in
export const getFeatureCollection = async (
  mapID: string,
  type: "initial" | "",
  auth: "member" | "public" | "custom"
) => {
  const mapRef = getMapRef(mapID);
  const featureCollectionRef = getFeatureCollectionRef(mapID);
  const q = query(
    featureCollectionRef,
    where("type", "==", "initial"),
    where("auth.type", "==", auth),
    limit(1)
  );

  return getDocsDataWithQuery(q)
    .then((snap) => {
      // featureCollectionRef
      //   .get()
      //   .then((snap) => {
      if (snap.empty) return;
      const data: FirestoreFeatureCollectionType = snap.docs[0].data();
      console.log(data);
      Object.keys(data.features).forEach((key) => {
        const f = data.features[key];
        data.features[key] = parseFeature(f);
      });

      return data;
    })
    .catch((e) => {
      console.log(e);
      return null;
    });
};

export const unsubscribeFeatureCollection = (
  mapID: string,
  auth: "member" | "public" | "custom",
  time: number,
  callback: (features: any) => void
) => {
  //   const unsub = onSnapshot(doc(db, "cities", "SF"), (doc) => {
  //     console.log("Current data: ", doc.data());
  // });

  const mapRef = getMapRef(mapID);

  const featureCollectionRef = getMapCollectionRef(mapID, "featureCollection");
  const query = getQuery(
    featureCollectionRef,
    where("type", "==", "listener"),
    // .where("auth.type", "==", auth)
    where("updatedAt", ">", Date.now())
  );

  return unsubscribeDocs(query, (snap) => {
    snap.docChanges().forEach((change) => {
      // console.log(change.doc.data());

      if (change.type !== "added") return;
      const data: FirestoreFeatureCollectionType = change.doc.data();
      const { features } = data;
      const parsedFeatures = {};
      Object.keys(features).forEach((id) => {
        parsedFeatures[id] = parseFeature(features[id]);
      });

      callback(parsedFeatures);
    });
  });
};

// https://blog.ojisan.io/typed-firestore/
// const converter = {
//   toFirestore(user: DataItemType): firebase.firestore.DocumentData {
//     return {
//       name: user.name,
//       age: user.age,
//     }
//   },
//   fromFirestore(
//     snapshot: firebase.firestore.QueryDocumentSnapshot,
//     options: firebase.firestore.SnapshotOptions
//   ): DataItemType {
//     const data = snapshot.data(options)!
//     if (!isValid(data)) {
//       console.error(data)
//       alert("invalid data")
//       throw new Error("invalid data")
//     }
//     return {
//       name: data.name,
//       age: data.age,
//     }
//   },
// }

import * as turf from "@turf/turf";

import {
  along,
  BBox,
  Feature,
  featureCollection,
  FeatureCollection,
  getCoord,
  getCoords,
  getType,
  LineString,
  multiPoint,
  Point,
  point,
  randomLineString,
} from "@turf/turf";
import polylabel from "polylabel";
import React, { useEffect, useMemo, useState } from "react";
import { Image, View, ViewProps } from "react-native";
import { Avatar, FAB, useTheme } from "react-native-paper";
import Animated, {
  Easing,
  Extrapolate,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withRepeat,
  withTiming,
} from "react-native-reanimated";
import {
  markerImagesNames,
  streetLayers,
  streetSourceLayerIds,
} from "../constants";
import {
  devLog,
  getBboxFilter,
  getPolyLabels,
  useIsMobile,
} from "../localFunctions";
import { LayerStateType } from "../types/main";
import {
  CircleLayer,
  FillExtrusionLayer,
  FillLayer,
  LineLayer,
  Marker,
  ShapeSource,
  SymbolLayer,
  useMap,
  VectorSource,
} from "./MapComponents";
import {
  CircleLayerStyle,
  LineLayerStyle,
  SymbolLayerStyle,
  Expression,
  FillLayerStyle,
  LayerBaseProps,
  CircleLayerProps,
  FillExtrusionLayerStyle,
  OnPressEvent,
} from "./MapComponentStyle";
import StyledText from "./StyledText";

type CustomLineType = {
  id: string;
  data: FeatureCollection;
  // name?: string;
  animated?: boolean;
  pattern?: string;
  altId?: string;
  filter: Expression;
  // "symbol-placement": "line", point, line-center
};

type CustomPolygonType = {
  id: string;
  data: FeatureCollection;
  // name?: string;
  animated?: boolean;
  pattern?: string;
  altId?: string;
  filter: Expression;
  opacity?: number;
  // "symbol-placement": "line", point, line-center
};

import MapnomLogo from "../assets/mapnom_logo.png";

// simple...
// ["type": "building"]
type FeaturesFilterType = Expression;

// filter for generating new feature from Polygon and LineString
// ["get", "type"]
const getFilteredFeatures = (
  features: Feature<turf.Properties>[],
  filter: FeaturesFilterType
) => {
  return features.filter((f) => {
    const props = f.properties;
    return props[filter[0]] == filter[1];
  });
};

const getId = (id, altId) => {
  if (altId) {
    return id + "-" + altId;
  } else {
    return id;
  }
};

type CustomLayerPropsType = LayerBaseProps;
// SymbolLayerStyle | CircleLayerStyle | LineLayerStyle ;
export const CustomLine = (props: CustomLineType) => {
  const { data, animated, pattern, altId, filter } = props;

  const circles = data.features.map((feature) => {
    const points = getCoords(feature);

    // const newFeature = multiPoint(points);
    const newFeature = multiPoint([points[0], points[points.length - 1]]);

    return newFeature;
  });

  const [strokeWidth, setStrokeWidth] = useState(false);

  // useEffect(() => {
  //   if (mapRef) {
  //     setTimeout(() => {
  //       // debug(mapRef.getLayer("circle"));
  //     }, 3000);
  //   }
  // }, [mapRef]);

  // useEffect(() => {
  //   setInterval(() => {
  //     setStrokeWidth((strokeWidth) => !strokeWidth);
  //   }, 1000);
  // }, []);

  const getId = (id) => {
    if (altId) {
      return id + "-" + altId;
    } else {
      return id;
    }
  };

  const isAnimatable = data.features.find(
    (feature) => feature.properties.movingPoints
  );

  const {
    lineShadowStyle,
    focusLineStyle,
    lineName,
    normalLineStyle,
    strokeLineStyle,
    lineCircle,
  } = styles;

  const sourceID = getId("line-data");

  return (
    <>
      <ShapeSource id={sourceID} data={data}>
        <LineLayer
          filter={["==", ["get", "shadow"], true]}
          id={getId("line-shadow")}
          style={lineShadowStyle}
        />
        <LineLayer
          id={getId("lien-stroke-line")}
          filter={filter}
          style={strokeLineStyle}
        />
        <LineLayer
          id={getId("main-line")}
          filter={filter}
          style={normalLineStyle}
        />
        {/* <LineLayer
          // filter={["==", ["get", "focus"], true]}
          id={getId("focus-line")}
          style={focusLineStyle}
        /> */}

        <SymbolLayer
          filter={filter}
          // filter={["==", ["get", "label"], true]}
          id={getId("line-name")}
          style={lineName}
        />
      </ShapeSource>
      <ShapeSource id={getId("circles")} data={featureCollection(circles)}>
        <CircleLayer id={getId("circle")} style={lineCircle} />
      </ShapeSource>
      {isAnimatable && (
        <MovingPoints
          mode="round-trip"
          features={data.features}
          sourceID="moving"
          layerID="moving"
          distance={100}
          animationDuration={20000}
          layerComponent={
            <SymbolLayer
              id="moving"
              // filter={["==", ["get", "movingPoints"], true]}
              style={{ iconImage: "car", iconSize: 0.5 }}
            />
          }
          // style={{
          //   iconImage: "car",
          //   iconSize: 0.5,
          //   // iconColor: "red",
          //   // iconOpacity: 0.9,
          // }}
        />
      )}
    </>
  );
};

export const CustomPolygon = (props: CustomPolygonType) => {
  const { data, animated, pattern, altId, filter, opacity = 0.4 } = props;

  const features = data.features
    .filter((f) => getType(f) == "Polygon")
    .map((f) => {
      f.properties["bbox"] = turf.bbox(f);
      return f;
    });

  const getId = (id) => {
    if (altId) {
      return id + "-" + altId;
    } else {
      return id;
    }
  };

  const isAnimatable = data.features.find(
    (feature) => feature.properties.movingPoints
  );

  const { normalLineStyle, strokeLineStyle, mainPolygonStyle } = styles;

  const sourceID = getId("poly-data");

  const isMobile = useIsMobile();

  const bboxFilter = getBboxFilter({ length: isMobile ? 100 : 200 });

  return (
    <>
      <ShapeSource id={sourceID} data={featureCollection(features)}>
        <LineLayer
          id={getId("poly-stroke-line")}
          filter={filter}
          style={strokeLineStyle}
        />
        <LineLayer
          id={getId("poly-main-line")}
          // filter={filter ? ["all", filter, bboxFilter] : bboxFilter}
          style={normalLineStyle}
        />
        <FillLayer
          id={getId("poly-fill")}
          filter={filter ? ["all", filter, bboxFilter] : bboxFilter}
          // style={mainPolygonStyle}
          style={{
            fillColor: ["coalesce", ["get", "fillColor"], "orange"],
            // fillOpacity: filter ? ["all", filter, bboxFilter] :   0.4,
            fillOpacity: filter
              ? ["coalesce", ["all", filter, bboxFilter], opacity, 0.4]
              : 0.4,
          }}
        />
        {/* <LineLayer
          // filter={["==", ["get", "focus"], true]}
          id={getId("focus-line")}
          style={focusLineStyle}
        /> */}

        {/* <SymbolLayer
          // filter={["==", ["get", "label"], true]}
          id={getId("line-name")}
          style={lineName}
        /> */}
      </ShapeSource>
    </>
  );
};

type CustomSymbolType = {
  data: FeatureCollection;
  id: string;
  onPress: (any) => void;
  sourceID?: string;
  style?: SymbolLayerStyle;
  // name?: string;
  size?: "large" | "medium" | "small" | number;
  labelPosition?: "bottom" | "right";
  animated?: boolean;
  cluster?: boolean;
  // shadow?: boolean;
  background?: "shadow" | "light" | "circle";
};

const isPointExpression: Expression = ["!", ["has", "point_count"]];
const isAnimatedExpression: Expression = ["==", ["get", "star"], true];
const bboxFilter = getBboxFilter();
const hasBbox = ["has", "bbox"];
const showArea = ["all", hasBbox, bboxFilter];
const showOrHidden: Expression = ["case", hasBbox, showArea, true];

const commonFilter = ["all", isPointExpression, showOrHidden];

// avoid nest! only one time is allowed, otherwise error is caused

export const CustomSymbols = React.memo((props: CustomSymbolType) => {
  const {
    data,
    id,
    style,
    cluster,
    sourceID = "custom-symbols",
    // size = "medium",
    // labelPosition = "bottom",
    animated = false,
    background,
  } = props;

  // console.log(props);

  //bounce, radius animation
  const [isExpanded, setIsExpanded] = useState(true);

  const isHorizontal = ["match", ["get", "direction"], "horizontal"];

  const matchStar = [
    "match",
    ["get", "star"],
    true,
    // [0, isTop ? -10 : 0],
    // [0, 0],
  ];

  // https://stackoverflow.com/questions/72046546/mapbox-filter-features-based-on-tags-property-which-is-an-array
  function getTagsFilter(tags) {
    //no tags set
    if ((tags || []).length === 0) return;

    //expression for each tag
    const tagFilters = tags.map((tag) => ["in", tag, ["get", "tags"]]);

    return ["any"].concat(tagFilters);
  }

  function getTagsText() {
    return [
      "concat",
      ["at", 1, ["get", "tags"]],
      ["at", 2, ["get", "tags"]],
      ["at", 3, ["get", "tags"]],
    ];
  }

  const tags = ["dog", "cat"];

  const backgroundFilter: Expression = ["==", ["get", "focus"], true];

  const {
    lightStyle,
    clusterImageStyle,
    circleClusterStyle,
    clusterCountStyle,
    symbolStyle,
  } = styles;

  const BackgroundLayers = () => {
    return (
      <>
        {/* <CircleLayer
        id="symbol-shadow"
        filter={["==", ["get", "background"], "shadow"]}
        style={shadowStyle}
      /> */}
        {/* <CircleLayer
          filter={["==", ["get", "focus"], true]}
          id={getId("focus-line")}
          style={focusLineStyle}
        /> */}

        <CircleLayer
          id="symbol-light"
          filter={backgroundFilter}
          sourceID={sourceID}
          // filter={["==", ["get", "background"], "light"]}
          style={lightStyle}
        />
        {/* <CircleLayer
        id="symbol-circle"
        filter={["==", ["get", "background"], "circle"]}
        style={circleStyle}
      /> */}
        {/* <CircleLayer id="icon-circle" style={backgroundStyle} /> */}
      </>
    );
  };

  const ClusterLayers = () => (
    <>
      <SymbolLayer
        id="cluster-image"
        filter={["has", "point_count"]}
        style={clusterImageStyle}
      />

      <CircleLayer
        id="cluster-circle"
        filter={["has", "point_count"]}
        style={circleClusterStyle}
      />
      <SymbolLayer
        id="cluster-count"
        filter={["has", "point_count"]}
        style={clusterCountStyle}
      />
    </>
  );

  // how about points in line?
  const pointData = featureCollection(
    data.features.filter((f) => getType(f) == "Point")
  );

  return (
    <ShapeSource id={sourceID} data={pointData} onPress={props.onPress}>
      {/* <CircleLayer sourceID={sourceID} id="test-circle" /> */}
      {/* <BackgroundLayers />
        <ClusterLayers /> */}
      {/* <Subtitle sourceID={sourceID} /> */}
      <InanimateSymbolLayer id={id} sourceID={sourceID} style={style} />
      {/* <CheckLayer sourceID={sourceID} /> */}
    </ShapeSource>
  );
});

const CheckLayer = ({ sourceID }: CustomLayerPropsType) => {
  return (
    <SymbolLayer
      id="check"
      sourceID={sourceID}
      // filter={inanimateFilter}
      style={{
        iconImage: "check",
        iconOffset: [100, -200],
        iconSize: 0.2,
        iconColor: "red",
      }}
    />
  );
};

const InanimateSymbolLayer = ({
  sourceID,
  id,
  style,
}: CustomLayerPropsType & { style: SymbolLayerStyle }) => {
  const inanimateFilter: Expression = [
    ...commonFilter,
    ["!", isAnimatedExpression],
  ];

  const inanimateStyle = { ...styles.symbolStyle, iconTranslate: [0, 0] };
  return (
    <>
      <SymbolLayer
        id={`${id}-inanimate-symbols`}
        sourceID={sourceID}
        filter={inanimateFilter}
        style={{ ...inanimateStyle, ...style }}
      />

      {/* <SymbolLayer
      id="symbols-tags"
      filter={["!", ["has", "point_count"]]}
      style={tagsStyle}
    /> */}
    </>
  );
};

const AnimatedSymbol = ({ sourceID }) => {
  const [isTop, setIsTop] = useState(true);

  const animatedStyle = {
    ...symbolStyle,
    iconTranslate: [0, isTop ? -10 : 0],
  };

  useEffect(() => {
    setInterval(() => {
      setIsTop((isTop) => !isTop);
      // setIsExpanded((isExpanded) => !isExpanded);
    }, 1000);
  }, []);

  const animatedFilter: Expression = [...commonFilter, isAnimatedExpression];

  return (
    <SymbolLayer
      id="animated-symbols"
      sourceID={sourceID}
      filter={animatedFilter}
      style={animatedStyle}
    />
  );
};

const Subtitle = ({ sourceID }: { sourceID: string }) => {
  const filterByPitch: Expression = [">", ["pitch"], 45];
  // 1 means a distance of 1000px away from the camera from the center.
  // https://docs.mapbox.com/mapbox-gl-js/example/pitch-and-distance-from-center/
  const filterByDistance: Expression = ["<=", ["distance-from-center"], 1];
  const subtitleFilter = ["get", "subtitle"];
  const filter: Expression = [
    "all",
    filterByDistance,
    filterByPitch,
    // subtitleFilter,
  ];

  return (
    <SymbolLayer
      id="symbol-detail"
      sourceID={sourceID}
      filter={filter}
      style={{
        textField: "Detail check",
        textOffset: [0, -3],
        textAllowOverlap: true,
      }}
    />
  );
};

type MovingPoints = {
  mode: "round-trip" | "one-way";
  features: Feature[]; //LineString
  sourceID: string;
  layerID?: string;
  distance?: number; //km
  animationDuration?: number; //mili seconds
  // layout: SymbolLayout;
  // paint: SymbolPaint;
  layerComponent: JSX.Element;
  // layout: SymbolLayout
  // ii: symbol
  // style: SymbolLayerStyle;
};

export const MovingPoints = ({
  features,
  sourceID,
  mode,
  animationDuration,
  layerComponent,
  distance,
}: MovingPoints) => {
  const routes = useMemo(() => {
    const routes = [];

    features.forEach((feature) => {
      if (getType(feature) == "MultiLineString") {
        const coords = getCoords(feature);

        coords.forEach((linePoints) =>
          routes.push(turf.lineString(linePoints))
        );
      } else if (getType(feature) == "LineString") {
        routes.push(feature);
      }
    });

    return routes;
  }, [features]);

  const initialPoints = routes.map((feature) =>
    turf.point(feature.geometry.coordinates[0], { index: 0 })
  );

  useEffect(() => {
    setPointFeatures(initialPoints);
  }, [features]);

  const [pointFeatures, setPointFeatures] = useState(initialPoints);

  // console.log(initialPoints, pointFeatures, routes);

  const mapRef = useMap().current;

  useEffect(() => {
    let start;
    let isAnimatable = true;
    let back = false;
    function frame(time) {
      if (animationDuration) {
        if (!start) start = time;

        // phase determines how far through the animation we are
        let phase = (time - start) / animationDuration;
        // "hsl(5, 50%, 50%)"
        // if (mode == "round-trip" && phase > 1) back = true;

        //reached to end
        if (phase >= 1) {
          //check round-trip mode
          if (mode == "round-trip") {
            //already go and back
            if (phase >= 2) {
              start = time;
            } else {
              phase = 2 - phase;
            }
          } else {
            start = time;
          }
        }

        // console.log(phase, routes);

        const nextPoints = routes.map((feature) => {
          const routeDistance = turf.lineDistance(feature);

          const alongRoute = along(feature, routeDistance * phase);
          return alongRoute;
        });

        setPointFeatures(nextPoints);
      } else {
        // const distancePerFrame = distance || 1;
        const distancePerFrame = 1 / 60;

        const nextPoints = routes.map((feature, index) => {
          if (!pointFeatures[index]) return;

          const linePartIndex = pointFeatures[index].properties.index;
          const lineParts = turf.lineChunk(feature, distancePerFrame);
          const nextPartIndex = linePartIndex + 1;

          if (!lineParts.features[nextPartIndex]) return;

          // console.log(linePartIndex, nextPartIndex);

          const linePart = lineParts.features[linePartIndex];
          const nextPt = getCoords(linePart)[1];

          return point(nextPt, { index: nextPartIndex });

          // const distance = 888;

          // const nextDistance = distance + distancePerFrame;

          // const alongRoute = along(feature, nextDistance);
          // return alongRoute
        });
        // console.log(nextPoints.map((feature) => getCoord(feature))[0]);
        setPointFeatures(nextPoints);
      }

      isAnimatable && requestAnimationFrame(frame);
    }

    requestAnimationFrame(frame);

    return () => (isAnimatable = false);
  }, [mapRef, features]);

  return (
    <>
      {/* <Layer type="background" paint={{ color }} /> */}
      <ShapeSource
        id={sourceID}
        // type="geojson"
        data={featureCollection(pointFeatures)}
      >
        {layerComponent}
      </ShapeSource>
    </>
  );
};

export const MaskPoints = (props: LayerStateType) => {
  const {
    colors: { accent },
  } = useTheme();

  const { mask, maskKey, maskType } = props;

  if (!mask || !maskKey || !maskType) return <></>;

  const id = `masked-markers-${props.id}`;
  const filter: Expression = ["in", maskKey, mask];

  const CircleMask = () => (
    <CircleLayer
      id={id}
      filter={filter}
      style={{ circleRadius: 15, circleColor: accent }}
    />
  );

  const MarkerMask = () => (
    <SymbolLayer
      id={id}
      filter={filter}
      style={{ iconImage: "existing", iconAllowOverlap: true }}
    />
  );

  return (
    <ShapeSource id={id} data={props.data}>
      {maskType == "circle" ? <CircleMask /> : <MarkerMask />}
    </ShapeSource>
  );
};

export const FocusPoints = (props: LayerStateType) => {
  const {
    colors: { accent },
  } = useTheme();

  const { focus, focusKey, focusType } = props;

  if (!focusType || !focusKey || !focus) return <></>;

  const id = `focus-markers-${props.id}`;
  const filter: Expression = ["in", focusKey, focus];

  const Circle = () => (
    <CircleLayer
      id={id}
      filter={filter}
      style={{ circleRadius: 15, circleColor: accent }}
    />
  );

  const Marker = () => (
    <SymbolLayer
      id={id}
      filter={filter}
      style={{ iconImage: "existing", iconAllowOverlap: true }}
    />
  );

  return (
    <ShapeSource id={id} data={props.data}>
      {focusType == "circle" ? <Circle /> : <Marker />}
    </ShapeSource>
  );
};

const ifElse: Expression = (property, fallback) => [
  "coalesce",
  ["get", property],
  fallback,
];

export const markerStyle: (iconImage: string) => SymbolLayerStyle = (
  iconImage: "search" | "existing" | "marker" | string
) => ({
  textField: ["get", "title"],
  // textField: ["get", iconImage == "search" ? "text" : "title"],
  textColor: "black",
  textMaxWidth: 10,
  textHaloBlur: 2,
  // iconTextFit: "both",
  // textLineHeight: 1,
  textOptional: true,
  iconAllowOverlap: true,
  // iconHaloColor: "black",
  // iconOffset: [0, -(1.5 / 0.08) * 10],
  textAnchor: "top",
  iconAnchor: "bottom",
  // iconSize:  0.5,
  iconSize: ["coalesce", ["get", "iconSize"], 0.5],
  // iconImage: iconImage,
  iconImage: [
    "case",
    ["boolean", ["has", "emoji"], true],
    ["get", "emoji"],
    iconImage,
  ],
  // iconImage: [
  //   "coalesce",
  //   ["image", ["get", "emoji"]],
  //   // If no image with the name above exists, show the
  //   // "rocket-15" image instead.
  //   ["image", iconImage],
  // ],
  // textOffset: [0, 2],
  textHaloColor: "white",
  textHaloWidth: 1,
  // symbolPlacement: "line",
  // iconHaloWidth: 30,
  iconTranslateTransition: { delay: 0, duration: 1000 },
});

const styles: {
  [key: string]:
    | CircleLayerStyle
    | SymbolLayerStyle
    | LineLayerStyle
    | FillLayerStyle;
} = {
  mainPolygonStyle: {
    fillColor: ["coalesce", ["get", "fillColor"], "orange"],
    fillOpacity: 0.4,
  },
  normalLineStyle: {
    lineColor: ["coalesce", ["get", "lineColor"], "orange"],
    // lineColor: color,
    lineWidth: ["coalesce", ["get", "lineWidth"], 3],
    lineCap: "round",
    lineJoin: "round",

    // lineDasharray: pattern ? [2, 2] : [2, 2],
    // lineGapWidth: 10,
    // linePattern:""  //iconimage
  },
  strokeLineStyle: {
    lineColor: ["coalesce", ["get", "lineColor"], "white"],
    // lineColor: color,
    lineWidth: ["coalesce", ["get", "lineWidth"], 8],
    lineCap: "round",
    lineJoin: "round",

    // lineDasharray: pattern ? [2, 2] : [2, 2],
    // lineGapWidth: 10,
    // linePattern:""  //iconimage
  },
  lineShadowStyle: {
    lineWidth: 10,
    lineBlur: 10,
    lineCap: "round",
    // lineGapWidth: 3,
    lineOpacity: 0.4,
    // lineOffset: 2,
    lineTranslate: [3, 3],

    // lineColor: "grey",
    // visibility: ["has", "shadow"],
  },
  focusLineStyle: {
    lineGapWidth: 50,
    lineColor: "red",
    lineWidth: 5,
    lineCap: "round",
    lineJoin: "round",
    lineDasharray: [2, 2],
  },

  lineName: {
    textField: "What...",
    // textField: ["coalesce", ["get", "title"], "No title"],
    textHaloWidth: 2,
    textHaloColor: "white",
    symbolPlacement: "line",
    // symbolPlacement: "point",

    // "symbol-placement": ""
    textSize: 15,
  },

  shadowStyle: {
    circleBlur: 2,
    circleRadius: 10,
    circleTranslate: [2, 2],
  },
  lightStyle: {
    circleOpacity: 0.8,
    circleBlur: 0.5,
    circleRadius: 80,
    circleColor: "yellow",
  },
  circleStyle: {
    // circleRadius: isExpanded ? 50 : 20,
    circleRadiusTransition: { duration: 1000, delay: 0 },
    circleOpacity: 0.4,
    circleStrokeColor: "blue",
    circleStrokeWidth: 2,
    circleColor: "white",
  },
  tagsStyle: {
    // textField: ["coalesce", ["at", 1, ["get", "tags"]]],
    // textField: getTagsText(),
    textTranslate: [10, 10],
    // textField: "Test",
    textField: [
      "concat",
      ["coalesce", ["concat", ["get", "tag1"], " "], ""],
      ["get", "tag2"],
    ],
    // textField: ["literal", tags],
    // textField: ["at", 1, ["array", ["get", "tags"]]],
    textAllowOverlap: true,
  },

  circleClusterStyle: {
    circleColor: "white",
    circleStrokeColor: "orange",
    circleStrokeWidth: 3,
    circleRadius: 10,
  },

  clusterCountStyle: {
    textField: "{point_count}",
    textAllowOverlap: true,
  },

  clusterImageStyle: {
    iconSize: 1,
    iconImage: "car",
    iconAllowOverlap: true,
    iconAnchor: "center",
    // textField: "{point_count}",
    // textAllowOverlap: true,
  },
  symbolStyle: {
    ...markerStyle("existing"),
  },
  mapMask: {
    fillColor: "blue",
    fillOpacity: 0.5,
    // fillPattern: "",
  },
  lineCircle: {
    // circleBlur: 2,
    circleRadius: 5,
    // circleBlur: 10,
    // circleOpacity: 0.5,
    // circleStrokeColor: "yellow",
    circleStrokeColor: "white",
    circleStrokeWidth: 2,
    circleColor: "orange",
    // circleStrokeWidth: strokeWidth ? 3 : 1,
    circleStrokeWidthTransition: { duration: 5000, delay: 2000 },
  },
};

export const MapsCluster = (props: {
  mapInfos: {
    [key: string]: {
      title: string;
      bbox: BBox;
    };
  };
  // data: FeatureCollection; // point of bbox {mapID, }
  onPress: any;
}) => {
  const features = Object.keys(props.mapInfos).map((mapID: string) => {
    const { title, bbox } = props.mapInfos[mapID];
    if (!bbox) return;
    const polygon = turf.bboxPolygon(bbox);
    const center = turf.center(polygon, { properties: { bbox, title, mapID } });
    return center;
  });

  return (
    <ShapeSource
      id="test-cluster"
      data={featureCollection(features.filter((f) => f))}
      onPress={props.onPress}
    >
      <SymbolLayer
        id="label-test-cluster"
        filter={getBboxFilter()}
        style={{
          ...styles.symbolStyle,
        }}
      />
    </ShapeSource>
  );
};

// //
// const symbolStyle: SymbolLayerStyle = {
//   textField: "{title}",
//   textAnchor: [...isHorizontal, "left", "top"],
//   iconAnchor: [...isHorizontal, "right", "bottom"],
//   // iconTranslate: [0, isTop ? -10 : 0],
//   // iconTranslate: [
//   //   "case",
//   //   ["==", ["get", "star"], true],
//   //   [0, isTop ? -10 : 0],
//   //   [0, 0],
//   // ],
//   // iconTranslate: [
//   //   "match",
//   //   ["get", "star"],
//   //   true,
//   //   [0, isTop ? -10 : 0],
//   //   [0, 0],
//   // ],
//   // iconTranslate: ["case", ["get", "star"], [0, isTop ? -10 : 0], [0, 0]],
//   iconTranslateTransition: { delay: 0, duration: 1000 },
//   // iconTranslateTransition: { duration: 1000 },
//   iconSize: 0.5,
//   iconImage: "existing",
//   iconAllowOverlap: true,
//   // 'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
//   // iconTextFit: "both",
//   // iconTextFitPadding: [10, 50, 10, 10], //top, right, bottom, left.
//   ...initialMarkerStyle,
//   // iconImage: [
//   //   "case",
//   //   ["boolean", ["has", "emoji"], true],
//   //   ["get", "emoji"],
//   //   iconImage,
//   // ],
// };

//  symbol on extrusion???
export const Buildings = ({
  id,
  onPress,
  style,
}: {
  id?: string;
  onPress?: (event: OnPressEvent) => void;
  style?: FillExtrusionLayerStyle;
}) => (
  <VectorSource
    id={id ? `${id}-buildings` : "buildings"}
    url="mapbox://mapbox.mapbox-streets-v8"
    onPress={onPress}
    // url="mapbox://mapbox.mapbox-terrain-v2"
  >
    <FillExtrusionLayer
      // minZoomLevel={22}
      // filter={[">", ["pitch"], 60]}
      id={id ? `${id}-buildings-extrusion` : "buildings-extrusion"}
      sourceLayerID={streetSourceLayerIds.building}
      belowLayerID={"road-label"}
      style={{
        fillExtrusionHeight: ["*", ["get", "height"], 1],
        fillExtrusionColor: "orange",
        fillExtrusionOpacity: 0.4,
        ...style,
      }}
    />
    {/* <FillLayer
      // filter={["<", ["pitch"], 60]}
      id="test-buildings"
      sourceLayerID={streetSourceLayerIds.building}
    /> */}
    {/* <LineLayer
  id="terrain-data"
  // sourceID="mapbox-terrain"
  // sourceLayerID="contour"
  sourceLayerID="buildings"
/> */}
  </VectorSource>
);

//         url="mapbox://mapbox.mapbox-terrain-v2"

//           sourceID="contours"
//           sourceLayerID="contour"

export const Pulse = ({ repeat, pulseRadius = 100 }) => {
  const animation = useSharedValue(0);
  useEffect(() => {
    setTimeout(() => {
      animation.value = withDelay(
        200,
        withRepeat(
          withTiming(1, {
            duration: 2000,
            easing: Easing.linear,
          }),
          repeat ? -1 : 1,
          false
        )
      );
    }, 3000);
  }, []);
  const animatedStyles = useAnimatedStyle(() => {
    const opacity = interpolate(
      animation.value,
      [0, 1],
      [0.6, 0],
      Extrapolate.CLAMP
    );
    return {
      opacity: opacity,
      transform: [{ scale: animation.value }],
    };
  });

  const circleStyle = {
    width: pulseRadius,
    borderRadius: 150,
    height: pulseRadius,

    position: "absolute",
    borderColor: "#e91e63",
    borderWidth: 4,
    // backgroundColor: "#ff6090", //
    backgroundColor: "#00bfff", //deepskyblue
  };

  return <Animated.View style={[circleStyle, animatedStyles]} />;
};

export const PulseMarker = ({
  coordinate,
  pulseRadius,
  children,
}: ViewProps & {
  pulseRadius: number;
  coordinate: [number, number];
}) => {
  const [pulse, setPulse] = useState([1]);

  useEffect(() => {
    const id = setInterval(() => {
      setPulse((prev) => [...prev, Math.random()]);
    }, 1000);
    return () => clearInterval(id);
  }, []);

  return (
    <Marker
      id="pulse"
      anchor="center"
      // offset={[-pulseRadius / 2, -pulseRadius / 2]}
      coordinate={coordinate}
      // longitude={coordinate[0]}
      // latitude={coordinate[1]}
      // style={{ position: "absolute" }}
    >
      <View
        style={{
          // backgroundColor: "blue",
          // width: 20,
          // height: 20,
          // left: -pulseRadius / 2,
          // top: -pulseRadius / 2,
          // alignSelf: "center",
          // position: "absolute",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {pulse.map((item, index) => (
          <Pulse key={index} repeat={index === 0} pulseRadius={pulseRadius} />
        ))}
        {children}
      </View>

      {/* <img
        style={{
          height: 50,

          width: 50,
        }}
        src={MountainIcon}
      /> */}
    </Marker>
  );
};

// area or building or place
export const PolyLabels = ({
  features,
  id = "area-name",
  textField = "Test area",
  filter,
  style,
  labelVisibilityMode = "static",
  mode = "none",
}: {
  mode?: "circle" | "marker" | "none";
  labelVisibilityMode?: "static" | "inside" | "outside"; // depends on zoom level by bbox of feature properties
  features: Feature[];
  id: string;
  textField?: Expression | string;
  style?: SymbolLayerStyle;
  filter?: Expression;
}) => {
  // const filtered = getFilteredFeatures(features, filter);
  const filtered = features.filter((f) => f.geometry.type == "Polygon");
  const points = getPolyLabels(filtered);

  const bboxFilter = getBboxFilter({ length: 100 });

  const filters: { [key: string]: Expression | undefined } = {
    // static:   filter ||  ["all", true]  ,
    static: filter || ["any", true],
    inside: filter ? ["all", ["!", bboxFilter], filter] : ["!", bboxFilter],
    outside: filter ? ["all", bboxFilter, filter] : bboxFilter,
  };

  // const isBuilding: Expression = ["==", ["get", "type"], "building"];
  const newFilter: Expression = ["==", ["get", "type"], "building"];

  const Pulses = () => {
    if (points.length == 0) return;

    return points
      .filter((feature) => feature?.properties?.status == "highlight")
      .map((feature, index) => (
        <PulseMarker
          key={index}
          pulseRadius={100}
          coordinate={getCoord(feature)}
        >
          {/* <FAB icon="camera" /> */}
          <Image
            style={{ borderRadius: 30, height: 60, width: 60 }}
            source={MapnomLogo}
          />
          <StyledText
            style={{
              textShadowRadius: 1,
              textShadowOffset: { height: 2, width: 2 },
              textShadowColor: "white",
            }}
          >
            AAAA
          </StyledText>
          {/* <Avatar.Image size={30} source={MapnomLogo} /> */}
        </PulseMarker>
      ));
  };

  // symbollayer props filter is empty if filter is undefined.

  console.log(JSON.stringify(filters[labelVisibilityMode]), points);

  return (
    <>
      {/* <Pulses /> */}
      <ShapeSource id={getId(id, "source")} data={featureCollection(points)}>
        {/* error when adding features */}
        {/* {mode == "circle" && (
          <PointCircle
            sourceID={getId(id, "source")}
            layerID={getId(id, "poly")}
            filter={newFilter}
          />
        )} */}
        {mode == "circle" && (
          <CircleLayer
            id={getId(id, "circle")}
            filter={filters[labelVisibilityMode]}
            style={{
              circleRadius: 5,
              circleColor: "orange",
              circleStrokeColor: "white",
              circleStrokeWidth: 2,
            }}
          />
        )}

        <SymbolLayer
          id={getId(id, "symbol")}
          filter={filters[labelVisibilityMode]}
          style={{
            // iconImage: "rocket",
            // iconAllowOverlap: true,
            textField,
            textSize: 15,
            textHaloWidth: 2,
            textAnchor: mode == "circle" ? "top" : "center",
            textTranslate: [0, 2],
            // textAnchor: mode == "circle" ? "top" : "center",
            // textHaloColor: "white",
            textAllowOverlap: true,
            textColor: "#666",
            textHaloColor: "rgba(255,255,255,0.75)",
            ...style,
          }}
        />
      </ShapeSource>
    </>
  );
};

const PointCircle = ({
  sourceID,
  layerID,
  filter = ["all", true],
}: {
  sourceID: string;
  layerID: string;
  filter?: Expression;
}) => {
  const circles: CircleLayerProps[] = [
    {
      id: "edge-stroke-" + layerID,
      style: { circleColor: "white", circleRadius: 8 },
    },
    {
      id: "edge-core-" + layerID,
      style: { circleColor: "orange", circleRadius: 5 },
    },
  ];

  const baseProps = {
    sourceID,
    belowLayerID: streetLayers["road-label"],
    filter,
  };

  return (
    <>
      {circles.map((props) => (
        <CircleLayer key={props.id} {...baseProps} {...props} />
      ))}
    </>
  );
};

// edge and bus stops
export const RoutePoints = ({ features }: { features: Feature[] }) => {
  const lines = features.filter((feature) => getType(feature) == "LineString");
  const points = lines.map((feature) => {
    const coords = getCoords(feature);
    return multiPoint([coords[0], coords.reverse()[0]]);
  });

  const sourceID = "route-edges";

  return (
    <ShapeSource id={sourceID} data={featureCollection(points)}>
      <PointCircle sourceID={sourceID} layerID={"route-points"} />
    </ShapeSource>
  );
};

// meter
const createRectangle = (center, length, width) => {
  const ellipseFeature = turf.ellipse(center, length / 1000, width / 1000);
  const envelopedFeature = turf.envelope(featureCollection([ellipseFeature]));
  return envelopedFeature;
};
type TentType = {
  length: number;
  width: number; // m or mm
  height: number; //
  center: [number, number];
  angle: number; // 0 - 360
};

// maybe we use gltf file on Web?
export const TentFillExtrusion = ({ tents }: { tents: TentType[] }) => {
  const fillExtrusionBaseString = "fillExtrusionBase";
  const objectTypeString = "object-type";

  const num = 50;

  const arr = useMemo(() => {
    const arr = new Array(num);
    arr.fill(1);
    return arr;
  }, []);

  const features = tents
    .map(({ width, length, height, center, angle }) => {
      const halfWidth = width / 2;
      const halfLength = length / 2;

      // triangle top calculated from outside
      const tentTop = arr.map((n, index) => {
        const rate = index / num;
        const baseHeight = height * rate;
        const polyWidth = halfWidth * (1 - rate);
        const feature = createRectangle(center, halfLength, polyWidth);
        feature.properties[fillExtrusionBaseString] = baseHeight;
        return feature;
      });

      // const tentSide = createRectangle(center, halfLength, halfWidth);
      // tentSide.properties = {

      //   [fillExtrusionBaseString] : 0.5
      // }  ;

      let poles: Feature<Polygon | MultiPolygon>;

      const feature = createRectangle(center, halfLength, halfWidth);
      const line: Feature<LineString> = turf.polygonToLine(feature);
      const polePoints = getCoords(line);

      polePoints.forEach((coord, index) => {
        const diameter = 50; // mm
        const mm = 1 / 1000 / 1000; // / km / m
        const pole = turf.circle(coord, diameter * mm);
        poles = poles ? turf.union(poles, pole) : pole;
      });

      poles.properties[objectTypeString] = "pole";

      // const geojson = featureCollection([...tentTop, poles, tentSide]);
      const geojson = featureCollection([...tentTop, poles]);

      const trans = turf.transformRotate(geojson, angle);

      return trans.features;
    })
    .flat();

  const fillExtrusionOpacity = 0.8;
  const poleHeight = 2; //2m

  const fillExtrusionHeight: Expression = [
    "+",
    ["get", fillExtrusionBaseString],
    poleHeight,
  ];
  const fillExtrusionBase: Expression = [
    "+",
    ["get", fillExtrusionBaseString],
    poleHeight,
  ];
  return (
    <ShapeSource id="tent" data={featureCollection(features)}>
      {/* <FillLayer id="tent-2d" maxZoomLevel={18} minZoomLevel={14} /> */}
      <FillExtrusionLayer
        id="tent-3d"
        minZoomLevel={18}
        // belowLayerID={"road-label"}
        style={{
          fillExtrusionOpacity,
          fillExtrusionHeight, //tlen / 100
          fillExtrusionBase,
          fillExtrusionColor: "white",
        }}
      />
      <FillExtrusionLayer
        id="tent-poles"
        minZoomLevel={18}
        filter={["has", objectTypeString]}
        style={{
          fillExtrusionOpacity: 0.7,
          fillExtrusionHeight: poleHeight,
          fillExtrusionColor: "gray",
        }}
      />
    </ShapeSource>
  );
};

export const BuildingFillExtrusion = ({
  features,
}: {
  features: Feature[];
}) => {
  const objects = features.filter(
    (feature) => feature.properties.type == "building"
  );

  const points = objects.map((feature) => {
    const bboxB = turf.bbox(feature);
    const [x, y] = polylabel(getCoords(feature), 50);
    const point0 = point([x, y], { ...feature.properties, bbox: bboxB });
    return point0;
  });

  const bboxFilter = getBboxFilter();

  const sourceID = "objects";

  // polylab
  return (
    <ShapeSource id={sourceID} data={featureCollection(objects)}>
      <FillExtrusionLayer
        id="objects"
        minZoomLevel={16}
        aboveLayerID={"road-label"}
        // belowLayerID={"road-label"}
        style={{
          fillExtrusionOpacity: 0.6,
          fillExtrusionHeight: 1, //tlen / 100
          // fillExtrusionBase,
          fillExtrusionColor: "red",
        }}
      />
      {/* <PointCircle sourceID={sourceID} layerID={""} /> */}
      {/* <FillExtrusionLayer
      minZoomLevel={18}
      filter={["has", objectTypeString]}
      id="tent-poles"
      style={{
        fillExtrusionOpacity: 0.7,
        fillExtrusionHeight: poleHeight,
        fillExtrusionColor: "gray",
      }}
    /> */}
    </ShapeSource>
  );
};

// // length = latitude dir, width = longitude dir
// const getPoint = (center: Coord, length: number, width: number) => {
//   const latDir = destination(center, length / 1000, 0);
//   const endDir = destination(getCoord(latDir), width / 1000, 90);
//   return endDir;
// };

import { AreaPropertiesType, MergedLayersType } from "../types/main";

const isGeometryType = (type: "Polygon" | "LineString" | "Point" | string) => [
  "==",
  ["geometry-type"],
  type,
];

//       // lineOpacity: [
//       //   "match",
//       //   ["get", "type"],
//       //   "route",
//       //   1,
//       //   "area",
//       //   0.5,
//       //   // ["match", getBboxFilter(), true, 1, 0.5],
//       //   // "yellow",
//       //   1,
//       // ],
//     }

const areaStyle: LineLayerStyle = {
  lineOpacity: [
    "case",
    ["all", ["==", ["get", "type"], "area"], getBboxFilter()],
    0.5,
    1,
  ],
  lineDasharray: [
    "match",
    ["get", "type"],
    // "route",
    // ["literal", [2, 2, 6, 2]],
    "area",
    ["literal", [2, 2, 6, 2]],
    ["literal", [1, 0]],
  ],
};

const routeStyle: LineLayerStyle = {
  lineColor: ["coalesce", ["get", "color"], "orange"],
  lineDasharray: [
    "match",
    ["get", "pattern"],
    "dot",
    ["literal", [1, 3]],
    "dash",
    ["literal", [0.5, 3, 3, 3]],
    ["literal", [1, 0]],
  ],
  lineWidth: 3,
  lineCap: "round",
  lineOpacity: 0.8,
};

const placeStyle: FillLayerStyle = {
  fillColor: ["coalesce", ["get", "color"], "orange"],
  fillOpacity: 0.7,
};

const threeStyle: FillExtrusionLayerStyle = {
  fillExtrusionColor: ["coalesce", ["get", "color"], "orange"],
  fillExtrusionOpacity: 0.6,
};

export const PolygonTest = ({ data }) => (
  <ShapeSource id="poly" data={data}>
    <FillLayer id="poly-test" style={placeStyle} />
    <FillExtrusionLayer
      filter={["==", ["get", "mode"], "3d"]}
      id="poly-test-3d"
      style={threeStyle}
    />
  </ShapeSource>
);

export const RouteTest = ({ data }) => (
  <ShapeSource id="route" data={data}>
    <LineLayer id="route-test" style={routeStyle} />
  </ShapeSource>
);

const lineNameStyle: SymbolLayerStyle = {
  symbolPlacement: "line",
  visibility: ["in", ["geometry-type"], ["literal", ["LineString"]]],
  // iconPitchAlignment: "viewport",
  // iconRotationAlignment: "viewport",
  // iconImage: ["match", ["get", "type"], "route", "bus", "..."],
  // iconAnchor: "right",
  textOffset: [0.5, 0],
  textAnchor: "left",
  textField: [
    "match",
    ["get", "type"],
    "route",
    ["get", "title"],
    // " Airport - Toubab Dialao 1h ",
    "Area name",
  ], //which explain this route
  textSize: ["match", ["geometry-type"], "LineString", 15, "Polygon", 8, 5],
  textHaloWidth: 2,
  // textHaloColor: "white",
  textPadding: 1,
  textMaxAngle: 30,
  textPitchAlignment: "viewport",
  textRotationAlignment: "map",
  textColor: [
    "interpolate",
    ["linear"],
    ["zoom"],
    // zoom is 14 (or less) -> circle radius will be 1px
    14,
    "#666",
    // zoom is 16 (or greater) -> circle radius will be 5px
    16,
    "black",
  ],
  textHaloColor: "rgba(255,255,255,0.75)",
};

const staticStyles: MergedLayersType[] = [
  {
    id: "area",
    type: "line",
    beforeId: "area-name",
    filter: isGeometryType("Polygon"),
    style: areaStyle,
  },
  {
    id: "line",
    type: "line",
    beforeId: "road-label",
    // beforeId: "poi-label",
    style: routeStyle,
  },
  // place label
  {
    id: "point-marker-circle-stroke",
    type: "circle",
    filter: isGeometryType("Point"),
    layout: {},
    style: {
      circleRadius: 7,
      circleColor: "white",
      // "circle-color": "#3bb2d0",
    },
  },
  // place label
  {
    id: "point-marker-circle",
    type: "circle",
    filter: isGeometryType("Point"),
    layout: {},
    style: {
      circleRadius: 5,
      circleColor: "orange",
      // "circle-color": "#3bb2d0",
    },
  },

  {
    id: "point-label",
    type: "symbol",
    filter: isGeometryType("Point"),
    layout: {},
    style: {
      // textAllowOverlap
      textAnchor: "top",
      textOffset: [0, 0.5],
      textField: "Test shop",
      textColor: "#666",
      // circleRadius: 10,
      // circleColor: "yellow",
      // "circle-color": "#3bb2d0",
    },
  },
  {
    id: "line-name",
    type: "symbol",
    filter: [
      "all",
      ["in", ["geometry-type"], ["literal", ["LineString", "Polygon"]]],
      ["!=", ["get", "type"], "building"],
    ],
    style: lineNameStyle,
  },

  // area name: marker <=> line name
];

import { StatusBar } from "expo-status-bar";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Ionicons } from "@expo/vector-icons";

import BottomSheet, {
  BottomSheetSectionList,
  BottomSheetFlatList,
  BottomSheetModal,
  BottomSheetModalProvider,
} from "@gorhom/bottom-sheet";

import {
  StyleSheet,
  Text,
  View,
  Button,
  TouchableOpacity,
  Platform,
  SectionList,
  SectionListProps,
} from "react-native";

import UserAvatar from "./UserAvatar";
import CreateMapButton from "./CreateMapButton";
import {
  FAB,
  IconButton,
  useTheme,
  Avatar,
  Provider,
  Badge,
  Menu,
  List,
  Card,
  Paragraph,
} from "react-native-paper";
import {
  updateHistory,
  getHistory,
  userRef,
  debug,
  getUserLimit,
} from "../localFunctions";
import { GlobalProvider, GlobalContext } from "../Context";
import i18n from "../languages/language";
import { useNavigation } from "@react-navigation/native";
import SecureStore from "expo-secure-store";
import PaperButton from "./PaperButton";

import { isWebOS } from "../localFunctions";
import { userLimits } from "../constants";

export default function ({ navigation }) {
  const { login, userInfo, setMapID, appConfig } =
    React.useContext(GlobalContext);

  const OtherOptions = useCallback(() => {
    const [visible, setVisible] = React.useState(false);

    const openMenu = () => setVisible(true);

    const closeMenu = () => setVisible(false);

    let canCreateMap = false;

    // return <View />;
    if (userInfo) {
      const limits = userLimits;
      // const { userAuth } = userInfo;
      // let mapLimit;
      // if (userAuth) {
      //   mapLimit = limits[userAuth].maps;
      // } else {
      //   mapLimit = limits["a"].maps;
      // }

      // debug(userInfo.subscriptions);
      const mapLimit = getUserLimit(userInfo, "maps");
      // debug(u)
      // debug(mapLimit);
      if (userInfo.hostMaps.length < mapLimit) canCreateMap = true;
    }

    return (
      <Menu
        visible={visible}
        onDismiss={closeMenu}
        anchor={
          <IconButton size={20} icon="dots-vertical" onPress={openMenu} />
        }
      >
        {canCreateMap ? (
          <Menu.Item
            icon="map-plus"
            onPress={() => {
              setMapID(null);
              navigation.push("MapSettings", { mode: "create" });
            }}
            title={i18n.t("createMap")}
          />
        ) : (
          <Menu.Item
            icon="map-plus"
            style={{ opacity: 0.5 }}
            // disabled
            onPress={() => {
              alert(
                "You already created max number of maps. Please delete your existing maps before creating a new map "
              );
            }}
            // titleStyle={{ color: "grey" }}
            title={i18n.t("createMap")}
          />
        )}

        <Menu.Item
          icon="account-plus"
          onPress={() => {
            navigation.push("AddFriend");
          }}
          title={i18n.t("addFriend")}
        />
      </Menu>
    );
  }, [userInfo]);

  //Account, terms , privacy, concept, rule,

  const RenderItem = ({ item }) => (
    <View style={{ padding: 3 }}>
      <Card>
        <List.Item
          left={() => <List.Icon icon={item.icon} />}
          // icon={item.icon}
          title={item.title}
          onPress={() => {
            handleCloseModal();
            navigation.navigate(item.name);

            // onPress={() => Linking.openURL('mailto:request@mapkid.info')
          }}
        />
      </Card>
    </View>
  );

  const RenderSectionHeader = ({ section: { title } }) => (
    <List.Subheader>{title}</List.Subheader>
  );

  const sections: { title: string; data: any[] }[] = [
    {
      title: "User",
      data: [
        { name: "UserSettings", title: "Account", icon: "account" },
        {
          name: "Concept",
          title: "Concept book",
          icon: () => <Ionicons name="book-outline" size={25} color={"grey"} />,
        },
      ],
    },
    {
      title: "Docs",
      data: [
        { name: "TermsOfService", title: "Terms of Service" },
        { name: "PrivacyPolicy", title: "Privacy Policy" },
        // { name: "PrivacyPolicy", title: "Request a letter to Mapkid" },
      ],
    },
  ];

  const bottomSheetModalRef = useRef<BottomSheetModal>(null);

  // variables
  const snapPoints = useMemo(() => ["90%"], []);

  // callbacks

  const handleCloseModal = useCallback(() => {
    bottomSheetModalRef.current?.close();
  }, []);

  const handlePresentModalPress = useCallback(() => {
    bottomSheetModalRef.current?.present();
  }, []);
  const handleSheetChanges = useCallback((index: number) => {
    console.log("handleSheetChanges", index);
  }, []);

  const backgroundColor = useTheme().colors.background;
  // const backgroundColor = "red";

  return login && userInfo ? (
    <View style={styles.row}>
      {/* <View>
            <IconButton icon="bell-outline" onPress={() => { navigation.push("AddFriend") }} />
            <Badge style={{ position: "absolute", bottom: 15, right: 15 }} size={10} />

        </View> */}

      {/* <IconButton icon="account-plus" onPress={() => { navigation.push("AddFriend") }} /> */}
      {/* <CreateMapButton navigation={navigation} /> */}
      <NotificationsButton />
      <OtherOptions />
      {/* <TouchableOpacity onPress={handlePresentModalPress}> */}
      <TouchableOpacity onPress={() => navigation.navigate("UserSettings")}>
        <UserAvatar navigation={navigation} type="myself" />
      </TouchableOpacity>
      <BottomSheetModal
        backgroundStyle={{
          backgroundColor,
        }}
        ref={bottomSheetModalRef}
        index={0}
        snapPoints={snapPoints}
        onChange={handleSheetChanges}
      >
        <View
          style={{
            flex: 1,
            backgroundColor,
          }}
        >
          <BottomSheetSectionList
            // contentContainerStyle={{
            //   // flex: 1,
            //   // minWidth: 600,
            //   // justifyContent: "center",
            //   alignItems: "center",
            //   // width: 800,
            //   // maxWidth: contentMaxWidth,
            // }}
            style={{ width: "100%" }}
            sections={sections}
            // keyExtractor={keyExtractor}
            // ref={sectionListRef}
            // keyExtractor={(item, index) => item + index}
            renderItem={RenderItem}
            // renderItem={({ item }) => <Item title={item} />}
            // ItemSeparatorComponent={() => (
            //   <View style={{ height: 20 }}>
            //     <Text>AAA</Text>
            //   </View>
            // )}
            // onEndReached={loadMore}
            // onEndReachedThreshold={0.5}
            // ListFooterComponent={<RenderFooter />}

            stickySectionHeadersEnabled={true}
            renderSectionHeader={RenderSectionHeader}
            // getItemLayout={(event)=>{console.log(event)}}
            onScrollToIndexFailed={(info) => {
              debug(info);
            }}
          />
          {/* <BottomSheetSectionList/>
          {items.map((item) => (
            <View style={{ padding: 3 }}>
              <Card>
                <List.Item
                  left={() => <List.Icon icon={item.icon} />}
                  // icon={item.icon}
                  title={item.title}
                  onPress={() => {
                    handleCloseModal();
                    navigation.navigate(item.name);
                  }}
                />
              </Card>
            </View>
          ))} */}
        </View>
      </BottomSheetModal>
    </View>
  ) : (
    <View style={{ paddingRight: 10 }}>
      {/* {!isWebOS && ( */}
      <PaperButton
        mode="contained"
        icon="login"
        style={{}}
        onPress={() => {
          navigation.push("Login");
        }}
      >
        {i18n.t("login")}
      </PaperButton>
      {/* )} */}
    </View>

    // <UserAvatar navigation={navigation} type="myself" />
  );
}

const NotificationsButton = () => {
  const navigation = useNavigation();

  const { userInfo } = useContext(GlobalContext);
  const { uid } = userInfo;

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    // alert("show up");
    if (!isWebOS) {
      const notificationsRef = userRef(uid, "private")
        .collection("notifications")
        .orderBy("updatedAt", "desc");

      // const notificationsRef = userRef(uid, "public")
      //   .collection("notifications")
      //   .orderBy("createdAt", "desc");

      const { visitAt } = getHistory("notifications");
      const time = visitAt || 0;

      // listen to update in notifications
      const unsubscribe = notificationsRef
        .where("updatedAt", ">", time)
        .onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              const data = change.doc.data();
              alert(JSON.stringify(data));
              setVisible(true);
              updateHistory("notifications", "update");
              //set secure store
            }
          });
        });
      return unsubscribe;
    }
  }, []);

  return (
    <View>
      <IconButton
        size={20}
        icon="bell"
        onPress={() => {
          navigation.push("Notifications");
        }}
      />
      <Badge
        size={10}
        visible={visible}
        style={{ position: "absolute", top: 5, right: 5 }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
});

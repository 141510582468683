import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { feature, featureCollection } from "@turf/turf";
import { forwardRef, memo, useEffect, useState } from "react";
import { LayerProps, MapRef, useControl, useMap } from "react-map-gl";

import { StatelessDrawControlProps } from "../../types/main";
import { createWebProps } from "../MapComponents";
import { defaultStyles } from "./styles";

import DrawRectangle from "./draw_rectangle";

// create drawInstance and wait for map instance
// if done, add drawInstance to map control by useControl
// avoid useControl multi times

//   https://github.com/mapbox/mapbox-gl-draw/blob/main/docs/API.md
// const DrawControl = forwardRef((props: StatelessDrawControlProps, ref) => {
const DrawControl = forwardRef((props: StatelessDrawControlProps, ref) => {
  // https://github.com/mapbox/mapbox-gl-draw/blob/main/docs/API.md#options
  // https://github.com/mapbox/mapbox-gl-draw/blob/main/docs/EXAMPLES.md

  const { features, staticStyles, mode, featureIDs } = props;
  // convert id to inactive

  const styleIds = {
    // area: "gl-draw-polygon-fill-inactive",
    // route: "gl-draw-line-inactive", // should disable edit for rout
    // route: "gl-draw-polygon-stroke-inactive",
    // "line-name": "line-name",
    // "point-marker": "gl-draw-point-inactive",
  };

  staticStyles?.forEach((layer, index) => {
    const { id } = layer;
    const drawId = styleIds[id];

    if (drawId) {
      defaultStyles[index] = {
        ...createWebProps(layer),
        id: drawId,
        // filter: defaultStyles[index].filter,
      };
    }
  });

  const options: MapboxDraw.MapboxDrawOptions = {
    modes: { ...MapboxDraw.modes, draw_rectangle: DrawRectangle },
    styles: defaultStyles,
    displayControlsDefault: false, //display toolbar or not
    ...props,
  };

  const [draw, setDraw] = useState<MapboxDraw | null>(new MapboxDraw(options));

  // // https://stackoverflow.com/a/74560660
  // useImperativeHandle(ref, () => drawRef); // This way I exposed drawRef outside the component

  const map = useMap().current;

  useEffect(() => {
    if (!map || !mode || !draw) return;
    const isSimpleSelect = mode == "simple_select";
    const option =
      featureIDs && isSimpleSelect ? { featureIds: featureIDs } : {};
    draw.changeMode(mode, option);
  }, [featureIDs]);

  useEffect(() => {
    if (!map || !mode || !draw) return;
    console.log(features);
    draw.set(featureCollection(features));
  }, [features]);

  useEffect(() => {
    if (!map || !mode || !draw) return;

    try {
      const isSimpleSelect = mode == "simple_select";
      // console.log(featureIDs);
      const option = featureIDs && isSimpleSelect ? { featureIDs } : {};
      draw.changeMode(mode, option);

      // from onAdd
      map.on("draw.create", props.onCreate);
      map.on("draw.update", props.onUpdate);
      map.on("draw.delete", props.onDelete);
      map.on("draw.selectionchange", props.onSelected);

      return () => {
        // from onRemove
        map.off("draw.create", props.onCreate);
        map.off("draw.update", props.onUpdate);
        map.off("draw.delete", props.onDelete);
        map.off("draw.selectionchange", props.onSelected);
      };
    } catch (e) {
      console.log(e);
    }
  }, [mode]);

  const test = () => {
    draw?.changeMode("simple_select", { featureIds: [] });
    // selectedFeatureIds
  };

  return <DrawInstance draw={draw} />;
});

const DrawInstance = memo(({ draw }) => {
  // https://visgl.github.io/react-map-gl/docs/api-reference/use-control
  const onCreate = () => {
    return draw;
  };
  // const onCreate = () => undefined;
  const onAdd = ({ map }: { map: MapRef }) => {
    console.log("added");
  };

  const onRemove = ({ map }: { map: MapRef }) => {};
  const options = {
    // position: props.position,
  };

  useControl<MapboxDraw>(onCreate, onAdd, onRemove, options);

  return null;
});

DrawControl.defaultProps = {
  onCreate: () => {},
  onUpdate: () => {},
  onDelete: () => {},
  onSelected: () => {},
};

export default memo(DrawControl);

import { DefaultTheme as PaperTheme } from "react-native-paper";
import {
  // NavigationContainer,
  // DarkTheme as NavigationDarkTheme,
  DefaultTheme as NavigationDefaultTheme,
} from "@react-navigation/native";
import { lazy } from "react";
import { LinkingOptions } from "@react-navigation/native";

//adapt to wide screen
export const contentMaxWidth = 800;
export const contentMinWidth = 200;

//for REsponsivePanel
export const panelMinWidth = 400;

//kB
export const imageSizes = {
  mapCover: 100,
  userDisplay: 10,
  userPage: 100,
  postDisplay: 50,
  postDetail: 1000,
};

// NavigationDefaultTheme.colors.

export const paperTheme: typeof PaperTheme = {
  ...PaperTheme,
  // ...NavigationDefaultTheme,
  // fonts: configureFonts(fontsObj),
  // roundness: 10,
  roundness: 20,
  // dark: true,

  colors: {
    ...PaperTheme.colors,
    // ...NavigationDefaultTheme.colors,

    primary: "lightsalmon", // button
    accent: "coral", //lightsalmon, coral  //fab
    // selected: "#b35837",
    // background: "#ffc8b3", //salmon ,
    // background: "antiquewhite",
    background: "floralwhite",
    // background: "red",
    surface: "white", //search bar
    // text: "grey", //
    disabled: "gray",
    // disabled: "grey",
    // // backdrop: "red",
    // placeholder: "whitesmoke",
    // onSurface: "green", //snackbar
    onBackground: "whitesmoke",
    error: "red",
    notifications: "green",
  },
};

const fontConfig = {
  regular: {
    fontFamily: "SFCompactRounded",
    fontWeight: "normal",
  },
  medium: {
    fontFamily: "SFCompactRounded",
    fontWeight: "normal",
  },
  light: {
    fontFamily: "sans-serif-light",
    fontWeight: "normal",
  },
  thin: {
    fontFamily: "sans-serif-light",
    fontWeight: "normal",
  },
};

const fontsObj = { web: fontConfig, ios: fontConfig, android: fontConfig };

export const minBottomSheetHeight = 70;

export const calendarConfig = {
  jp: {
    //   monthNames: [
    //     "1月",
    //     "2月",
    //     "3月",
    //     "4月",
    //     "5月",
    //     "6月",
    //     "7月",
    //     "8月",
    //     "9月",
    //     "10月",
    //     "11月",
    //     "12月",
    //   ],
    //   monthNamesShort: [
    //     "1月",
    //     "2月",
    //     "3月",
    //     "4月",
    //     "5月",
    //     "6月",
    //     "7月",
    //     "8月",
    //     "9月",
    //     "10月",
    //     "11月",
    //     "12月",
    //   ],
    //   dayNames: [
    //     "日曜日",
    //     "月曜日",
    //     "火曜日",
    //     "水曜日",
    //     "木曜日",
    //     "金曜日",
    //     "土曜日",
    //   ],
    //   dayNamesShort: ["日", "月", "火", "水", "木", "金", "土"],
    //   // dayNamesShort: ["r", "r", "r", "r", "r", "r", "r"],
  },
};

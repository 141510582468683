export const iconNames = {
  "checkbox-multiple-outline": "checkbox-multiple-outline",
  "comment-text-multiple-outline": "comment-text-multiple-outline",
  "vector-square-edit": "vector-square-edit",
  "format-list-bulleted-type": "format-list-bulleted-type",
  "vector-polyline-edit": "vector-polyline-edit",
  refresh: "refresh",
  "arrow-all": "arrow-all",
  magnet: "magnet",
  "book-marker-outline": "book-marker-outline",
  "bookmark-check-outline": "bookmark-check-outline",
  "gesture-tap": "gesture-tap",
  "foot-print": "foot-print",
  ruler: "ruler",
  qrcode: "qrcode",
  "qrcode-scan": "qrcode-scan",
  "tray-arrow-up": "tray-arrow-up",
  "hand-pointing-up": "hand-pointing-up",
  "vector-polyline": "vector-polyline",
  "vector-square": "vector-square",
  walk: "walk",
  "navigate-circle-outline": "navigate-circle-outline",
  "card-account-details-outline": "card-account-details-outline",
  "cash-check": "cash-check",
  "account-multiple-plus-outline": "account-multiple-plus-outline",
  "account-group-outline": "account-group-outline",
  "lock-open-variant-outline": "lock-open-variant-outline",
  "dots-vertical": "dots-vertical",
  "lock-outline": "lock-outline",
  "chevron-right": "chevron-right",
  "chevron-left": "chevron-left",
  "chevron-down": "chevron-down",
  "chevron-up": "chevron-up",
  "circle-edit-outline": "circle-edit-outline",
  "tag-plus-outline": "tag-plus-outline",
  "logout-variant": "logout-variant",
  "arrow-left-right": "arrow-left-right",
  "login-variant": "login-variant",
  "map-search-outline": "map-search-outline",
  "map-search": "map-search",
  "map-marker-multiple-outline": "map-marker-multiple-outline",
  "map-marker-outline": "map-marker-outline",
  "checkbox-marked-outline": "checkbox-marked-outline",
  "check-outline": "check-outline",
  "bank-check": "bank-check",
  "map-plus": "map-plus",
  "arrow-left": "arrow-left",
  "arrow-right": "arrow-right",
  "map-outline": "map-outline",
  "map-marker-radius-outline": "map-marker-radius-outline",
  "map-marker-plus-outline": "map-marker-plus-outline",
  "image-outline": "image-outline",
  "comment-text-multiple-outline": "comment-text-multiple-outline",
  "delete-outline": "delete-outline",
  "bell-outline": "bell-outline",
  "account-outline": "account-outline",
  "share-variant-outline": "share-variant-outline",
  "chat-processing-outline": "chat-processing-outline",
  "chat-outline": "chat-outline",
  "information-outline": "information-outline",
  send: "send",
  "send-circle-outline": "send-circle-outline",
  "image-plus": "image-plus",
};

// type IconsType = keyof Iconname

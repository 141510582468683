import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
// import QRCode from "qrcode";

import { Share, Text, View, TouchableOpacity, Platform } from "react-native";

import * as Sharing from "expo-sharing";
import { IconButton, Card, Menu } from "react-native-paper";
import { Dialog, Portal, useTheme } from "react-native-paper";

import i18n from "i18n-js";

import { GlobalContext, GlobalSubContext } from "../Context";
import {
  userRef,
  databaseRef,
  useMapInfo,
  useMapAuth,
  createDynamicLinkInfo,
  getDynamicLink,
  shareURL,
} from "../localFunctions";

import ActivityIndicator from "./ActivityIndicator";

import {} from "../localFunctions";
import { assets, contentMaxWidth, dynamicLinkURL } from "../constants";
import { langDict } from "../languages/langCheck";

export default function ({ mapID }) {
  const iconSize = 20;

  const { userInfo, login } = React.useContext(GlobalContext);
  const mapInfo = useMapInfo(mapID);
  const uid = userInfo?.uid || null;

  const BookmarkButton = () => {
    const myBookmarks = userInfo?.bookmarks ? userInfo.bookmarks : [];
    const [isBookmarked, setIsBookmarked] = useState(
      myBookmarks.includes(mapID)
    );
    // const isBookmarked = myBookmarks.indexOf(mapID) > -1

    const onPress = () => {
      // alert(JSON.stringify(userInfo))
      if (!userInfo || !userInfo.userName) {
        setOpen(true);
      } else {
        if (!isBookmarked) {
          userRef(uid, "public").update({
            bookmarks: firebase.firestore.FieldValue.arrayUnion(mapID),
          });
          userInfo.bookmarks.push(mapID);
          // firebase.firestore().collection("users").doc(uid).update({ bookmarks: firebase.firestore.FieldValue.arrayUnion(mapID) })
        } else {
          userRef(uid, "public").update({
            bookmarks: firebase.firestore.FieldValue.arrayRemove(mapID),
          });

          userInfo.bookmarks = userInfo.bookmarks.filter(
            (item) => item != mapID
          );
        }
        setIsBookmarked(!isBookmarked);
      }
    };

    return (
      <IconButton
        icon={isBookmarked ? "bookmark" : "bookmark-outline"}
        disabled={!login}
        color="gold"
        size={iconSize}
        // color={isBookmarked ? "gold" : "white"}
        // style={{ backgroundColor: isBookmarked ? "gold" : "white" }}
        onPress={onPress}
      />
    );
  };

  const LikeButton = () => {
    const myLikes = userInfo?.likes ? userInfo.likes : [];

    const [isLiked, setIsLiked] = useState(myLikes.includes(mapID));

    const [likesCount, setLikesCount] = useState<null | number>(null);

    const likesRef = databaseRef(mapID, "likes/count/");

    useEffect(() => {
      likesRef.once("value", (snapshot) => {
        setLikesCount(snapshot.exists() ? snapshot.val() : 0);
      });
    }, []);

    const onPress = () => {
      if (!userInfo) {
        setOpen(true);
      } else {
        setIsLiked(!isLiked);
        const incrementNumber = isLiked ? -1 : 1;
        setLikesCount(likesCount + incrementNumber);
      }
    };

    return (
      <TouchableOpacity
        onPress={onPress}
        style={{ width: 70, flexDirection: "row", alignItems: "center" }}
      >
        <IconButton
          color="red"
          size={iconSize}
          icon={isLiked ? "heart" : "heart-outline"}
        />
        <Text>{likesCount} </Text>
      </TouchableOpacity>
    );
  };

  const [loading, setLoading] = useState(false);

  const ShareButton = () => {
    // https://rounds.mapkid.info/

    const backgroundColor = useTheme().colors.primary;

    const { title, description, image } = mapInfo;
    // console.log("dynamic", dynamicLinkInfo(mapID));
    const path = "map/" + mapID;
    const body = {
      dynamicLinkInfo: createDynamicLinkInfo(path, title, description, image),
    };

    const onShare = async () => {
      setLoading(true);

      const res = await getDynamicLink(body);
      console.log(res);

      // const res = await getPageLink();

      if (res && !res.error && res.shortLink) {
        const url = res.shortLink;
        setLoading(false);
        shareURL({ url, title: mapInfo.title });
      } else {
        alert(
          "Problem happens. Please try again. If you still have problems, make a contact with us"
        );
        setLoading(false);
      }
    };

    const [visible, setVisible] = React.useState(false);
    const openMenu = () => setVisible(true);
    const closeMenu = () => setVisible(false);

    const { snackbarText, setSnackbarText } =
      React.useContext(GlobalSubContext);

    return (
      <Menu
        visible={visible}
        onDismiss={closeMenu}
        anchor={
          <IconButton
            size={iconSize}
            icon="share-variant"
            onPress={() => setVisible(true)}
          />
        }
      >
        <Menu.Item
          icon="share"
          onPress={onShare}
          title={i18n.t(langDict.shareUrl)}
        />
      </Menu>
    );
  };

  const [open, setOpen] = useState(false);
  const { isHost, isMember } = useMapAuth(mapID);

  // useEffect(() => {
  //   databaseRef(mapID, "").once("value", (snapshot) => {
  //     debug(snapshot.val());
  //     setCounts(snapshot.val());
  //   });
  // }, []);

  const [counts, setCounts] = useState({ places: undefined, posts: undefined });

  return (
    <View style={{ flexDirection: "row", justifyContent: "flex-end" }}>
      {!isMember && <BookmarkButton />}
      <View style={{ width: 20 }} />

      {loading ? <ActivityIndicator size={30} /> : <ShareButton />}
      {/* <LikeButton /> */}
    </View>
    // <Card.Actions style={{ justifyContent: "flex-end" }}>

    // </Card.Actions>
  );
}

import {
  CircleLayer,
  MapImages,
  MapView,
  SymbolLayer,
  ShapeSource,
  FillLayer,
  FillExtrusionLayer,
  Marker,
  LineLayer,
  Source,
  Layer,
  VectorSource,
} from "./MapComponents";

import MapboxDraw from "@mapbox/mapbox-gl-draw";

import {
  Feature,
  FeatureCollection,
  Point,
  along,
  bbox,
  bboxPolygon,
  bearing,
  booleanPointOnLine,
  buffer,
  centerOfMass,
  circle,
  destination,
  difference,
  distance,
  lineArc,
  lineDistance,
  lineString,
  multiPoint,
  point,
  polygonToLine,
  randomLineString,
  randomPolygon,
  transformRotate,
  transformTranslate,
} from "@turf/turf";

import { featureCollection, getCoord, getCoords, getType } from "@turf/turf";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Card, Chip, FAB, IconButton, TextInput } from "react-native-paper";
import {
  getBboxFilter,
  getStaticMapDataWithFeatures,
  isWebOS,
  testsRef,
  useMapboxFunctions,
} from "../localFunctions";
import { Image, ImageSourcePropType, ScrollView, View } from "react-native";
import { iconNames, paperTheme } from "../constants";
import {
  AreaNameLabels,
  BuildingFillExtrusion,
  Buildings,
  MovingPoints,
  PolyLabels,
  PolygonTest,
  PulseMarker,
  RoutePoints,
  RouteTest,
  TentFillExtrusion,
} from "./CustomMapComponents";

import DrawControl from "./DrawControl";
import StyledCarousel from "./StyledCarousel";
import StyledText from "./StyledText";
import { AreaPropertiesType, MergedLayersType } from "../types/main";
import StyledTextInput from "./StyledTextInput";
import { MapStateContext } from "../Context";
import { useMap } from "./ReactMapGLComponents";
import { AccessaryFeatures, GPSMapping, Ruler } from "./Layers";
import { SymbolLayerProps } from "./MapComponentStyle";
import {
  createFeaturesOnLineString,
  createPointsOnLineString,
  createRectangle,
  snapToLineString,
} from "../localFunctions/map";

const testID = "test";

const InitialMapImages = () => {
  useEffect(() => {
    images["bus"] = busImage;
    setImages({ ...images });
  }, []);

  type ImagesType =
    | ({
        assets?: string[] | undefined;
      } & {
        [key: string]: ImageSourcePropType;
      })
    | undefined;

  const emojis = ["🚐"];
  const [images, setImages] = useState<ImagesType>({});
  // emojis.map((emoji) => {
  //   const twemojiUrl = getTwemojiUrl(emoji);
  //   obj[emoji] = { uri: twemojiUrl };
  // });

  images["bus"] = busImage;
  return <MapImages images={images} />;
};

// [x,y]
// const getBuilding = (point: [number, number]) => {
//   const features = mapObject?.queryRenderedFeatures(point, {
//     // filter: areaFilter,
//     // layers: ["id..."],
//   });

//   if (!features) return;

//   const building = features.find((feature) => {
//     const isPolygon = getType(feature) == "Polygon";
//     const isBuilding = feature.properties.type == "building";
//     return isPolygon && isBuilding;
//   });

//   if (!building) return;
//   const feature = polygon(getCoords(building), {
//     type: "building",
//     title: "Test Poly",
//   });

//   return feature;
// };

// const createTents = (features: Feature[]) => {
//   return features
//     .filter((feature) => getType(feature) == "Point")
//     .map((feature) => {
//       const tent = {
//         width: tlen,
//         height: tlen / 5,
//         length: tlen,
//         angle: 45,
//         center: getCoord(feature),
//       };
//       return tent;
//     });
// };

// const renderTents = useMemo(() => {
//   return <TentFillExtrusion tents={tents} />;
// }, [tents]);

// const Tools = useCallback(() => {
//   type ToolType = {
//     icon: string;
//     label: string;
//     mode?: keyof MapboxDraw.Modes;
//     onPress?: () => void;
//   };

//   const data: ToolType[] =
//     mode == "static"
//       ? [
//           {
//             icon: iconNames["circle-edit-outline"],
//             label: "Edit",
//             mode: "simple_select",
//           },
//         ]
//       : [
//           {
//             icon: "close",
//             // label: mode == "static" ? "Edit" : "Static",
//             mode: mode == "static" ? "simple_select" : "static",
//           },
//           {
//             icon: "hand-pointing-up",
//             // label: "Select",
//             mode: "simple_select",
//           },
//           {
//             icon: iconNames["vector-polyline"],
//             label: "Route",
//             mode: "draw_line_string",
//           },
//           // {
//           //   icon: "home-map-marker",
//           //   // icon: iconNames["map-marker-multiple-outline"], //festival in mui
//           //   label: "Object",
//           //   mode: "draw_point",
//           // },
//           // {
//           //   icon: "office-building-marker-outline",
//           //   // icon: iconNames["map-marker-multiple-outline"], //festival in mui
//           //   label: "Choose structure",
//           //   // mode: "choose_structure",
//           //   mode: "draw_point",
//           // },

//           // {
//           //   icon: iconNames["vector-square"],
//           //   label: "Area",
//           //   mode: "draw_polygon",
//           // },
//           {
//             icon: iconNames["delete-outline"],
//             // label: "Delete",
//             mode: "draw_polygon",
//             onPress: () => {
//               const ids = drawRef.current?.getSelectedIds();
//               ids?.forEach((id) => drawRef.current?.delete(id));
//               console.log(ids);
//               setFeatures(drawRef.current?.getAll().features);
//             },
//           },
//           // {
//           //   icon: "home",
//           //   label: "Tents",
//           //   mode: "draw_polygon",
//           //   onPress: () => {
//           //     const centerLngLat = mapObject?.getCenter();
//           //     const center = [centerLngLat?.lng, centerLngLat?.lat];
//           //     const circle = ellipse(center, 100, 100);
//           //     const rectbbox = bbox(circle);
//           //     const polygon = bboxPolygon(rectbbox);

//           //     mapObject?.on("mousemove", (e) => {
//           //       const { lng, lat } = e.lngLat;
//           //       const { getSelected, set } = drawRef.current;
//           //       const angle = bearing(center, [lng, lat]);
//           //       const geojson = getSelected();
//           //       if (geojson.features.length == 0) return;
//           //       console.log(angle, polygon, geojson);
//           //       const transformed = transformRotate(polygon, angle);

//           //       set(featureCollection([transformed]));
//           //     });

//           //     drawRef.current?.add(polygon);
//           //   },
//           // },
//         ];

//   const ToolFab = ({ item }: { item: ToolType }) => {
//     const { label, icon, onPress } = item;
//     const isSelected = mode == item.mode;
//     return (
//       <View
//         style={{
//           padding: 10,
//           alignItems: "flex-start",
//           justifyContent: "center",
//         }}
//       >
//         {/* <Chip
//           icon={icon}
//           selected={mode == item.mode}
//           onPress={onPress || (() => setMode(mode))}
//         >
//           {label}
//         </Chip> */}
//         <FAB
//           icon={icon}
//           small
//           label={label}
//           onPress={onPress || (() => setMode(item.mode))}
//           style={
//             isSelected && {
//               backgroundColor: paperTheme.colors.selected,
//             }
//           }
//         />
//       </View>
//     );
//   };

//   const height = 70;

//   return (
//     <View
//       style={{
//         position: "absolute",
//         right: 60,
//         bottom: 20,
//         flexDirection: "row",
//         // width: "100%",
//         // backgroundColor: "red",
//       }}
//       // onLayout={(e) => console.log(e.nativeEvent.layout)}
//     >
//       <StyledCarousel
//         // style={{ width: "100%", height, padding: 5 }}
//         style={{ width: "100%", height }}
//         width={150}
//         height={height}
//         loop={false}
//         showPagingDots={false}
//         dynamicWidth={true}
//         data={data}
//         renderItem={ToolFab}
//         showPagingIndicator={true}
//       />
//     </View>
//   );
// }, [mode]);

// const renderTools = useMemo(() => <Tools />, [mode]);

const toubabBbox = [
  -17.156399079988205, 14.59309421726256, -17.14102733321093,
  14.613776479932753,
];

const sw = toubabBbox.slice(0, 2);
const ne = toubabBbox.slice(2);
const mapID = "draw-test";

export default function () {
  // const mapObject = useMap().current;
  // console.log(mapObject);
  type ModesType = keyof MapboxDraw.Modes | "static" | "choose_structure";

  const [mode, setMode] = useState<ModesType>(
    // "simple_select"
    // "draw_point"
    "static"
  );

  const drawRef = useRef<MapboxDraw>();

  const drawActions = {};

  const tlen = 5; //5m

  const [tents, setTents] = useState([]);

  const StaticLayers = () => {
    const EachLayer = (layerProps: MergedLayersType) => {
      layerProps.style["visibility"] = mode == "static" ? "visible" : "none";
      layerProps.key = layerProps.id;
      // layerProps
      const { type } = layerProps;
      switch (type) {
        case "fill":
          return <FillLayer {...layerProps} />;
        case "line":
          return <LineLayer {...layerProps} />;
        case "symbol":
          return <SymbolLayer {...layerProps} />;
        case "circle":
          return <CircleLayer {...layerProps} />;
      }
    };

    console.log(staticStyles.map((layer) => layer.id));

    return (
      <Source
        id="static"
        type="geojson"
        data={featureCollection(
          features.map((feature) => {
            feature.properties["bbox"] = bbox(feature);
            return feature;
          })
        )}
      >
        {staticStyles.map(EachLayer)}
      </Source>
    );
  };

  // const renderStatic = useMemo(() => <StaticLayers />, [features, mode]);

  const RenderDraw = () => {
    const setBounds = useEffect(() => {
      fitBoundsByBbox && fitBoundsByBbox([...sw, ...ne]);
      // get initial geojson
      // if (!mapObject) return;
      // testsRef
      //   .doc(testID)
      //   .get()
      //   .then((doc) => {
      //     if (!doc.exists) return;
      //     const geojson: FeatureCollection = JSON.parse(doc.data().geojson);
      //     const { features } = geojson;
      //     const pointFeature = features.find((f) => getType(f) == "Point");
      //     setFeatures(features);
      //     // fitBoundsByBbox([...sw, ...ne]);
      //     // setTents(createTents(features));
      //     // drawRef.current?.set(geojson);
      //   });
    }, []);

    const { fitBoundsByBbox } = useMapboxFunctions(mapID);
    console.log(fitBoundsByBbox);

    const [features, setFeatures] = useState<Feature[]>([]);

    // console.log(features.filter((feature) => getType(feature) == "LineString"));

    // const onUpdate = async (e) => {
    //   let newFeatures = e.features;

    //   const drawMode = drawRef.current?.getMode();
    //   const feature: Feature<LineString> = e.features[0];
    //   const id = feature.id;
    //   if (drawMode == "draw_line_string") {
    //     const line: Feature<LineString> = feature;
    //     const points = line.geometry.coordinates.map((pt) => point(pt));
    //     const routes = await getDirections(points).then((res) => {
    //       const routes = res.body.routes;
    //       const features: Feature[] = routes.map((route) =>
    //         lineString(route.geometry.coordinates, { type: "route" })
    //       );
    //       return features;
    //     });

    //     if (routes.length == 0) return;

    //     return setFeatures((features) => {
    //       const newFeatures = features.filter(
    //         (feature) => feature.id !== line.id
    //       );
    //       return [...newFeatures, ...routes];
    //     });
    //   }

    //   // is Object
    //   if (drawMode == "draw_point") {
    //     const point: Feature<Point> = feature;
    //     const coord = getCoord(point);
    //     const pt = mapObject?.project(coord);

    //     const feature = getBuilding(pt);

    //     if (!feature) return;

    //     // setFeatures((features) => {
    //     //   const newFeatures = features.filter(
    //     //     (feature) => feature.id !== line.id
    //     //   );
    //     //   return [...newFeatures, ...routes];
    //     // });

    //     drawRef.current?.add(featureCollection([feature]));
    //     drawRef.current?.delete(id);
    //   }

    //   if (drawMode == "draw_polygon") {
    //     drawRef.current?.setFeatureProperty(id, "type", "area");
    //   }

    //   const features = drawRef.current?.getAll().features;
    //   setFeatures(features);
    // };

    // // onselected or deselected
    // const onSelected = async (e) => {
    //   let newFeatures: Feature[] = e.features;
    //   const feature = newFeatures[0];
    //   if (!feature) return;
    //   setSelectedID(feature?.id);
    //   const bbox0 = bbox(feature);
    //   const sw = bbox0.slice(0, 2);
    //   const ne = bbox0.slice(2);

    //   mapObject?.fitBounds([sw, ne], { duration: 200, padding: 50 });
    // };

    // const onDelete = () => {
    //   setFeatures(drawRef.current?.getAll().features);
    // };
    const { mapViewState, setMapViewState } = useContext(MapStateContext);

    return (
      <>
        <DrawControl
          // ref={drawRef}
          // defaultMode="simple_select"
          defaultMode="draw_line_string"
          // modes={{
          //   ...MapboxDraw.modes,
          //   //   draw_polygon: FreehandMode,
          // }}
          // onCreate={onUpdate}
          onCreate={(e) => {
            console.log(e);
            console.log(e);
            console.log(e);
          }}
          onUpdate={(e) => {
            console.log(e);
            console.log(e);
            console.log(e);
          }}
          // onUpdate={onUpdate}
          // onDelete={onDelete}
          // onSelected={onSelected}
          // staticStyles={staticStyles}
          // features={features}
          // {...mapViewState}
          features={features}
        />
        {/* <AccessaryFeatures features={features} /> */}
      </>
    );
    // }, [mapObject, features]);
  };

  const [location, setLocation] = useState({ x: 0, y: 0 });

  const SaveButton = () => {
    // features: Feature[]
    const onPress = () => {
      //   const name = "toubab";

      if (features.length == 0) return;
      const geojson = featureCollection(features);

      testsRef
        .doc(testID)
        .set({ geojson: JSON.stringify(geojson) }, { merge: true })
        .then(() => {
          setSaved(true);
          setTimeout(() => {
            setSaved(false);
          }, 2000);
        });
    };

    const [saved, setSaved] = useState(false);

    return (
      <FAB
        small
        style={{ position: "absolute", right: 20, bottom: 20 }}
        icon={"content-save"}
        label={saved ? "Saved!!" : undefined}
        onPress={onPress}
      />
    );
  };

  const [selectedID, setSelectedID] = useState(null);

  const InfoPanel = () => {
    const geojson = drawRef.current?.getSelected();
    const feature = geojson?.features[0];

    if (!feature) return <></>;
    const id = feature.id;
    const properties = feature?.properties;
    type AreaKeys = keyof AreaPropertiesType;
    const areaProperties: AreaKeys[] = [
      "title",
      "type",
      "color",
      "opacity",
      "showName",
      "outlinePattern",
      "status",
    ];

    const Input = ({
      defaultValue,
      property,
    }: {
      defaultValue: string | number;
      property: string;
    }) => {
      const [value, setValue] = useState(defaultValue);

      const [saved, setSaved] = useState(false);
      const onChangeText = (value) => {
        setValue(value);
      };

      const onSubmitEditing = () => {
        drawRef.current?.setFeatureProperty(id, property, value);
        const geojson = drawRef.current?.getAll();
        setFeatures(geojson?.features);
        setSaved(true);
      };

      useEffect(() => {
        if (saved)
          setTimeout(() => {
            setSaved(false);
          }, 2000);
      }, [saved]);

      return (
        <StyledTextInput
          value={value}
          onChangeText={onChangeText}
          onSubmitEditing={onSubmitEditing}
          right={saved && <TextInput.Icon icon="check" />}
        ></StyledTextInput>
      );
    };

    {
      /* <ShapeSource   /> */
    }

    const type = feature.properties["type"];

    const icons = { area: "vector-square", route: "vector-polyline" };

    const features = [];

    if (getType(feature) == "Polygon") {
      feature.geometry = polygonToLine(feature).geometry;
      // feature.geometry.type == "LineString";
    } else if (getType(feature) == "LineString") {
      const coords = getCoords(feature);
      const points = multiPoint([coords[0], coords.reverse()[0]], {
        "marker-color": "#FF7F50",
      });
      features.push(points);
    }

    feature.properties["stroke"] = "#FF7F50";
    feature.properties["stroke-width"] = 3;

    features.push(feature);

    const url = getStaticMapDataWithFeatures(features);
    const isImage = true;

    return (
      <Card style={{ position: "absolute" }}>
        <Card.Content>
          {isImage ? (
            <Image style={{ height: 450, width: 800 }} source={{ uri: url }} />
          ) : (
            <ScrollView>
              <StyledText> {`${feature.id}: ${getType(feature)}`} </StyledText>
              <IconButton icon={icons[type]} />

              {areaProperties.map((name) => (
                <View key={name} style={{ flexDirection: "row" }}>
                  <StyledText> {name} </StyledText>
                  <Input property={name} defaultValue={properties[name]} />
                </View>
              ))}
            </ScrollView>
          )}
        </Card.Content>
      </Card>
    );
  };

  const renderPanel = useMemo(() => <InfoPanel />, [mode, selectedID]);

  const [image, setImage] = useState(null);

  // return (
  //   <Image
  //     style={{ height: 300, width: 600 }}
  //     source={{ uri: image }}
  //     resizeMode="contain"
  //   />
  // );

  // return <img src={image} style={{ width: 300, height: 300 }} />;

  const Test = (props) => {
    console.log(props);
    return <></>;
  };

  const ViewFrame = ({ children }) => {
    if (isWebOS) {
      return (
        <View
          style={{ height: "100%", width: "100%" }}
          // https://reactnative.dev/docs/gesture-responder-system.html#content

          // onResponderMove={onResponderMove}
        >
          <div
            style={{ height: "100%", width: "100%" }}
            onContextMenu={(e) => e.preventDefault()}
          >
            {children}
          </div>
          {mode !== "static" && renderPanel}
        </View>
      );
    } else {
      return (
        <View
          style={{ height: "100%", width: "100%" }}
          // https://reactnative.dev/docs/gesture-responder-system.html#content

          // onResponderMove={onResponderMove}
        >
          {children}
          {mode !== "static" && renderPanel}
        </View>
      );
    }
  };

  // return <SaveButton />;

  const CheckAlignment = () => {
    const line = lineString([sw, ne]); /* あなたのラインデータ */
    const bearing0 = bearing(sw, ne);

    const units = "meters"; // "kilometers"

    const distance = 1; // 移動させたい距離（5メートル）

    const lineDistanced = lineDistance(line, { units });
    const steps = lineDistanced / distance;

    const points = [];
    for (let i = 0; i < lineDistanced; i += distance) {
      const point = along(line, i, { units });
      const largeScale = i % 5 == 0;
      if (largeScale) point.properties["large"] = "true";
      // point.properties["distance"] = i.toString();
      point.properties["distance"] = i;
      points.push(point);
    }

    // const isLarge = [["==", ["get", "large"], "true"], "{distance}"];
    const isLarge = ["==", ["get", "large"], "true"];

    const checkLarge = (num: number) => [
      "==",
      ["%", ["get", "distance"], num],
      0,
    ];
    // const
    const layers: SymbolLayerProps[] = [
      {
        id: "0",
        minZoomLevel: 10,
        maxZoomLevel: 13,
        filter: checkLarge(1000),
      },
      { id: "1", minZoomLevel: 13, maxZoomLevel: 16, filter: checkLarge(100) },
      { id: "2", minZoomLevel: 16, maxZoomLevel: 18, filter: checkLarge(20) },
      { id: "3", minZoomLevel: 18, maxZoomLevel: 20, filter: checkLarge(10) },
      { id: "4", minZoomLevel: 20, maxZoomLevel: 24, filter: checkLarge(1) },
    ];

    const sourceID = "test-align-points";

    return (
      <>
        <ShapeSource id={sourceID} data={featureCollection([...points, line])}>
          <LineLayer id="measure-line" style={{}} />
          {/* <CircleLayer id="measure-scale1" style={{}} />
          <CircleLayer id="measure-scale2" style={{}} /> */}
          {layers.map((layer) => (
            <>
              <CircleLayer
                {...layer}
                sourceID={sourceID}
                id={"measure-s-" + layer.id}
                filter={["all", layer.filter]}
                style={{
                  // textAllowOverlap: true,
                  // textField: "|",
                  circleRadius: ["case", isLarge, 3, 1.5],
                  // textSize: ["case", isLarge, 10, 6],
                  // textRotate: bearing0 + 90,
                  // symbolPlacement: "line",
                }}
                // "{distance}"
              />
              <SymbolLayer
                {...layer}
                sourceID={sourceID}
                id={"measure-l-" + layer.id}
                filter={["all", layer.filter, isLarge]}
                style={{
                  textField: "{distance}",
                  textHaloColor: "white",
                  textHaloWidth: 1,
                  // textRotate: bearing0 - 90,
                  // textTranslateAnchor: "map",
                  // textTranslateAnchor: "viewport",
                  textOffset: [1, 1],

                  // textOffset: []
                }}
                // "{distance}"
              />
            </>
          ))}
        </ShapeSource>
      </>
    );
  };

  const GeojsonModules = () => {
    const generateSquare = (center: [number, number], x: number, y: number) => {
      // const center = [Math.random() * i, Math.random() * 60];
      const centerPoint = point(center);

      const toLon = (feature: Feature<Point>, distance: number) =>
        destination(getCoord(feature), distance, 90);
      const toLat = (feature: Feature<Point>, distance: number) =>
        destination(getCoord(feature), distance, 0);

      const nw = toLat(toLon(centerPoint, -x / 2), y / 2);
      const ne = toLat(toLon(centerPoint, x / 2), y / 2);
      const se = toLat(toLon(centerPoint, x / 2), -y / 2);
      const sw = toLat(toLon(centerPoint, -x / 2), -y / 2);

      const points = [nw, ne, se, sw];
    };

    const generate = (lat: number) => {
      const features = [];
      for (let index = 0; index < 1500; index++) {
        // const element = array[index];
        const center = [(index * 1) / 10, lat];
        const out = circle(center, 50 / 10);
        const inside = circle(center, 15 / 10);
        var differenced = difference(out, inside);
        features.push(differenced);
      }

      const box = [0, -1 + lat, 180, 1 + lat];

      const linearced = lineArc([0, lat + 10], 500, 0, 90, {
        units: "kilometers",
        steps: 64,
        // properties: {},
      });
      features.push(linearced);
      // features.push(
      //   bboxPolygon(box, {
      //     properties: { height: bottomThickness },
      //   })
      // );
      // features.push(
      //   bboxPolygon(box, {
      //     properties: { height: topThickness, base: pillarHeight * 10 },
      //   })
      // );
      return features;
    };

    const [features, setFeatures] = useState<Array<Feature<any>>>(generate(0));
    const [count, setCount] = useState(0);

    const km = 1000;
    const topThickness = 30 * km;
    const pillarHeight = 100 * km;
    const bottomThickness = 30 * km;

    // const onPress = () => {
    // };

    // useEffect(() => {
    //   console.log("ren");
    //   setTimeout(() => {
    //     const features = generate(Math.random() * 80);
    //     setFeatures(features);
    //     setCount(count + 1);
    //   }, 1000);
    // }, [count]);

    return (
      <ShapeSource
        id="modules"
        data={featureCollection(features)}
        // onPress={onPress}
      >
        <FillExtrusionLayer
          id="modules-pillars"
          style={{
            fillExtrusionHeight: ["coalesce", ["get", "height"], pillarHeight],
            // fillExtrusionBase: ["coalesce", ["get", "base"], 0],
            fillExtrusionOpacity: 0.8,
            fillExtrusionColor: "red",
          }}
        />
      </ShapeSource>
    );
  };

  const TestAlign = () => {
    const random = randomPolygon(20, {
      bbox: toubabBbox,
      max_radial_length: 0.001,
      num_vertices: 4,
    });

    const line = lineString([sw, ne]);

    const snapped = snapToLineString(line, random.features);

    return (
      <>
        <ShapeSource id="align" data={featureCollection([...snapped, line])}>
          <FillLayer id="rest" />
          <LineLayer id="liii" />
        </ShapeSource>
        <ShapeSource id="align-origin" data={random}>
          <FillLayer id="rest-origin" style={{ fillColor: "red" }} />
          {/* <LineLayer id="liii" /> */}
        </ShapeSource>
      </>
    );
  };

  return (
    <ViewFrame>
      <MapView
        id={mapID}
        onTouchMove={(e) => console.log(e)}
        // onRegionDidChange={(e) => {
        //   console.log(e);
        // }}
        // defaultSettings={{ bounds: { ne, sw } }}
        // bounds={{ ne, sw }}
      >
        <TestAlongLIne />
        {/* <TestAlign /> */}
        {/* <Buildings /> */}
        {/* <RouteTest
          data={featureCollection(
            randomLineString(10, {
              bbox: toubabBbox,
              max_length: 0.001,
            }).features.map((f) => {
              f.properties["color"] = "blue";
              return f;
            })
          )}
        />
        <PolygonTest
          data={randomPolygon(10, { bbox: toubabBbox, max_length: 0.001 })}
        /> */}
        <GeojsonModules />
        <Test />
        {/* <InitialMapImages /> */}
        {/* {renderTents} */}
        {/* <TentFillExtrusion tents={tents} /> */}
        {/* <Pulses /> */}
        {/* {renderStatic} */}

        {/* <SenegalBoundaries /> */}
        {/* <RenderDraw /> */}
        {/* {mode !== "static" && mapObject && renderDraw} */}
        {/* <PolyLabels features={features} textField={["get", "title"]} />
        <BuildingFillExtrusion features={features} />
        <RoutePoints features={features} /> */}
        {/* <MovingPoints
            mode="round-trip"
            features={features}
            sourceID={"bus-line"}
            // layerID={"bus"}
            animationDuration={30000}
            // distance={100}
            layerComponent={
              // <CircleLayer
              //   id="bus"
              //   style={{ circleRadius: 15, circleColor: "red" }}
              // />
              <SymbolLayer
                id={"bus-line"}
                belowLayerID={""}
                style={{
                  iconImage: "bus",
                  iconAllowOverlap: true,
                  iconIgnorePlacement: true,
                  iconSize: 1.5,
                }}
              />
            }
          /> */}
        <Ruler features={[lineString([sw, ne], { type: "ruler" })]} />
        {/* <CheckAlignment /> */}
        {/* <GPSMapping onFinish={() => console.log("finished")} /> */}
      </MapView>

      {/* {renderTools} */}
      <SaveButton />
    </ViewFrame>
  );
}

const isGeometryType = (type: "Polygon" | "LineString" | "Point" | string) => [
  "==",
  ["geometry-type"],
  type,
];

const staticStyles: MergedLayersType[] = [
  {
    id: "area",
    type: "fill",
    beforeId: "area-name",
    filter: isGeometryType("Polygon"),
    style: {
      fillColor: "green",
      fillOutlineColor: "#3bb2d0",
      // fillOpacity: 0.1,
      fillOpacity: 0,
    },
  },
  {
    id: "line",
    type: "line",
    beforeId: "road-label",
    // beforeId: "poi-label",
    style: {
      lineDasharray: [
        "match",
        ["get", "type"],
        // "route",
        // ["literal", [2, 2, 6, 2]],
        "area",
        ["literal", [2, 2, 6, 2]],
        ["literal", [1, 0]],
      ],
      // lineColor: "orange",
      lineColor: [
        "match",
        ["get", "type"],
        // "route",
        // ["literal", [2, 2, 6, 2]],
        "area",
        "yellow",
        "route",
        "coral",
        "red",
      ],
      lineOpacity: [
        "case",
        ["all", ["==", ["get", "type"], "area"], getBboxFilter()],
        0.5,
        1,
      ],
      // lineOpacity: [
      //   "match",
      //   ["get", "type"],
      //   "route",
      //   1,
      //   "area",
      //   0.5,
      //   // ["match", getBboxFilter(), true, 1, 0.5],
      //   // "yellow",
      //   1,
      // ],
      lineWidth: 5,
      lineCap: "round",
      // lineOffset:
    },
  },
  {
    id: "point-marker-circle-stroke",
    type: "circle",
    filter: isGeometryType("Point"),
    layout: {},
    style: {
      circleRadius: 7,
      circleColor: "white",
      // "circle-color": "#3bb2d0",
    },
  },
  {
    id: "point-marker-circle",
    type: "circle",
    filter: isGeometryType("Point"),
    layout: {},
    style: {
      circleRadius: 5,
      circleColor: "orange",
      // "circle-color": "#3bb2d0",
    },
  },

  {
    id: "point-label",
    type: "symbol",
    filter: isGeometryType("Point"),
    layout: {},
    style: {
      // textAllowOverlap
      textAnchor: "top",
      textOffset: [0, 0.5],
      textField: "Test shop",
      textColor: "#666",
      // circleRadius: 10,
      // circleColor: "yellow",
      // "circle-color": "#3bb2d0",
    },
  },
  {
    id: "line-name",
    type: "symbol",
    // type: "circle",

    filter: [
      "all",
      ["in", ["geometry-type"], ["literal", ["LineString", "Polygon"]]],
      ["!=", ["get", "type"], "building"],
    ],
    style: {
      symbolPlacement: "line",
      visibility: ["in", ["geometry-type"], ["literal", ["LineString"]]],
      // iconPitchAlignment: "viewport",
      // iconRotationAlignment: "viewport",
      // iconImage: ["match", ["get", "type"], "route", "bus", "..."],
      // iconAnchor: "right",
      textOffset: [0.5, 0],
      textAnchor: "left",
      textField: [
        "match",
        ["get", "type"],
        "route",
        ["get", "title"],
        // " Airport - Toubab Dialao 1h ",
        "Area name",
      ], //which explain this route
      textSize: ["match", ["geometry-type"], "LineString", 15, "Polygon", 8, 5],
      textHaloWidth: 2,
      // textHaloColor: "white",
      textPadding: 1,
      textMaxAngle: 30,
      textPitchAlignment: "viewport",
      textRotationAlignment: "map",
      textColor: [
        "interpolate",
        ["linear"],
        ["zoom"],
        // zoom is 14 (or less) -> circle radius will be 1px
        14,
        "#666",
        // zoom is 16 (or greater) -> circle radius will be 5px
        16,
        "black",
      ],
      textHaloColor: "rgba(255,255,255,0.75)",
    },
  },
];

const TestAlongLIne = () => {
  const originalFeature = createRectangle([0, 0], 10, 10);

  const line = lineString([sw, ne]);

  // const line = lineString([
  //   [0, 20],
  //   [20, 30],
  //   [40, -30],
  // ]);
  const features = createFeaturesOnLineString(originalFeature, line, {
    interval: 30,
    maxNum: 50,
  });
  const { fitBoundsByBbox } = useMapboxFunctions(mapID);
  useEffect(() => {
    fitBoundsByBbox && fitBoundsByBbox(bbox(line));
  }, [fitBoundsByBbox]);
  return (
    <>
      <ShapeSource id="test-loine" data={line}>
        <LineLayer id="testt-line" style={{ lineColor: "red" }} />
      </ShapeSource>
      <ShapeSource id="testttt" data={featureCollection(features)}>
        <FillLayer id="testt" style={{ fillColor: "red" }} />
      </ShapeSource>
    </>
  );
};

export interface langCheck {
  /** タイトル */
  welcome: string;
  login: string;
  logout: string;
  home: string;
  chat: string;
  userSettings: string;
  mapSettings: string;
  addFriend: string;
  searchMap: string;
  search: string;
  chatInput: string;
  sendImages: string;
  existingPlaces: string;
  newPlaces: string;
  createMap: string;
  updateMap: string;
  deleteMap: string;
  showInSearch: string;
  cancel: string;
  choosePlace: string;
  createPlace: string;
  addMembers: string;
  friends: string;
  title: string;
  description: string;
  tags: string;
  friendCode: string;
  mapIsCreated: string;
  mapIsUpdated: string;
  uploadImage: string;
  addOverlays: string;
  exploreSentence: string;
  currentLocation: string;
  fitToCoordinates: string;
  mapStyle: string;
  tutorial: string;
  hitResults: string;
  noTitle: string;
  language: string;
  members: string;
  userIsUpdated: string;
  saveUser: string;
  street: string;
  satellite: string;
  white: string;
  black: string;
  where: string;
  deleteConfirmation: string;
  storage: string;
  searchPlaces: string;
  familyName: string;
  givenName: string;
  noMember: string;
  noTag: string;
  createUser: string;
  updateUser: string;
  emailCopied: string;
  friendIsAdded: string;
  goToMap: string;
  orJoin: string;
  uploading: string;
  overlays: string;
  mapImage: string;
  advancedSettings: string;
  showAllMessages: string;
  howTo: string;
  nonMember: string;
  notifications: string;
  underDevelopment: string;
  canJoin: string;
  userPage: string;
  mapHome: string;
  requestToMapkid: string;
  editPlaces: string;
  post: string;
  placePosts: string;
  pinOn: string;
  pinOff: string;
  reportAsSpam: string;
  reportAsSpamDescription: string;
  report: string;
  delete: string;
  comment: string;
  accessByLink: string;
  nonMemberEdit: string;
  canCreatePlace: string;
  canComment: string;
  conceptWelcomeTitle: string;
  conceptMapWithUSTitle: string;
  conceptWarmMapTitle: string;
  conceptPostWithPlaceTitle: string;
  conceptTogetherTitle: string;
  conceptFindAndWalkTitle: string;
  conceptFromNowOnTitle: string;
  conceptHowWeAreTitle: string;
  conceptClosingTitle: string;
  conceptSloganTitle: string;
  conceptWelcomeText: string;
  conceptMapWithUSText: string;
  conceptWarmMapText: string;
  conceptPostWithPlaceText: string;
  conceptTogetherText: string;
  conceptFindAndWalkText: string;
  conceptFromNowOnText: string;
  conceptHowWeAreText: string;
  conceptClosingText: string;
  conceptSloganText: string;
  seeOurConceptBook: string;
  underBetaTest: string;
  askMoreMaps: string;
  searchMoreMaps: string;
  showQrCode: string;
  shareUrl: string;
  download: string;
  saved: string;
  addPlace: string;
  changePlace: string;
  recent: string;
  placeName: string;
  savePlace: string;
  fallbackText: string;
  privacyPolicy: string;
  concept: string;
  termsOfService: string;
  and: string;
  continueWithConsent: string;
  mapTypeTitle: string;
  mapTypeDescription: string;
  changeMapStyle: string;
  background: string;
  originalStyle: string;
  printMap: string;
  emailVerification: string;
  blockUser: string;

  //subscription page
  subscription: string;
  functions: string;
  comparison: string;

  moreMapsTitle: string;
  moreMapsDescription: string;
  styleMapsTitle: string;
  styleMapsDescription: string;
  connectWithTitle: string;
  connectWithDescription: string;
  autoTranslation: string;
  autoTranslationDescription: string;
  choosePricingPlan: string;
  "check the price in the website": string;
  "By continuing, you agree to terms of service and acknowledge you’ve read our privacy policy": string;

  "Pan map to take the snapshot": string;
  style: string;
  "Error occured. Please attempt to login again.": string;
  "Smileys & Emotion": string;
  "People & Body": string;
  "Animals & Nature": string;
  "Food & Drink": string;
  "Travel & Places": string;
  Activities: string;
  Objects: string;
  Symbols: string;
  Flags: string;
  "Sexual contents": string;
  "Illegal product or service": string;
  Violence: string;
  "Political ads": string;
  Discrimination: string;
  Other: string;
  "Your subscription is started!": string;
  "Now, you can use all functions on Mapnom!": string;
  "Back to Home": string;
  "Reported and Blocked this user": string;
  "Reported as spam": string;
  "Sent a mail link to your email box. Please login from it.": string;
  sentEmailLink: string;

  //subscription page
  Subscribe: string;
  "Free 1 month trial": string;
  "Free plan": string;

  SubscriptionPageTitle: string;
  SubscriptionPageDescription: string;

  Outline: string;
  FunctionsTitle: string;
  FunctionsDescription: string;

  PlansTitle: string;
  PlansDescription: string;
  TableTitle: string;
  TableDescription: string;
  mo: string;
  "Thank you for subscribing!": string;
  "Check more details on mapnom.com!": string;
  detailsOnWeb: string;
  "Free trial": string;
  "More freedom": string;
  "Create memory maps with friends": string;
  "Search maps of the world": string;
  "Unlimited posts": string;
  "Up to 3000 places per map, 30 maps": string;
  "Styling maps and print out": string;
  map: string;
  account: string;
  //features on table on SUbscription
  Price: string;
  Prices: string;
  Maps: string;
  Places: string;
  Posts: string;
  Printing: string;
  Styling: string;
  Emoji: string;
  Join: string;
  global: string;
  bbox: string;
  nearby: string;
  whereToSearch: string;
}

export const langDict: langCheck = {
  welcome: "welcome",
  login: "login",
  logout: "logout",
  home: "home",
  map: "map",
  chat: "chat",
  userSettings: "userSettings",
  mapSettings: "mapSettings",
  addFriend: "addFriend",
  searchMap: "searchMap",
  search: "search",
  chatInput: "chatInput",
  sendImages: "sendImages",
  existingPlaces: "existingPlaces",
  newPlaces: "newPlaces",
  createMap: "createMap",
  updateMap: "updateMap",
  deleteMap: "deleteMap",
  showInSearch: "showInSearch",
  cancel: "cancel",
  choosePlace: "choosePlace",
  createPlace: "createPlace",
  addMembers: "addMembers",
  friends: "friends",
  title: "title",
  description: "description",
  tags: "tags",
  friendCode: "friendCode",
  mapIsCreated: "mapIsCreated",
  mapIsUpdated: "mapIsUpdated",
  uploadImage: "uploadImage",
  addOverlays: "addOverlays",
  exploreSentence: "exploreSentence",
  currentLocation: "currentLocation",
  fitToCoordinates: "fitToCoordinates",
  mapStyle: "mapStyle",
  tutorial: "tutorial",
  hitResults: "hitResults",
  noTitle: "noTitle",
  language: "language",
  members: "members",
  userIsUpdated: "userIsUpdated",
  saveUser: "saveUser",
  street: "street",
  satellite: "satellite",
  white: "white",
  black: "black",
  where: "where",
  deleteConfirmation: "deleteConfirmation",
  storage: "storage",
  searchPlaces: "searchPlaces",
  familyName: "familyName",
  givenName: "givenName",
  noMember: "noMember",
  noTag: "noTag",
  createUser: "createUser",
  updateUser: "updateUser",
  emailCopied: "emailCopied",
  friendIsAdded: "friendIsAdded",
  goToMap: "goToMap",
  orJoin: "orJoin",
  uploading: "uploading",
  overlays: "overlays",
  mapImage: "mapImage",
  advancedSettings: "advancedSettings",
  showAllMessages: "showAllMessages",
  howTo: "howTo",
  nonMember: "nonMember",
  notifications: "notifications",
  underDevelopment: "underDevelopment",
  canJoin: "canJoin",
  userPage: "userPage",
  mapHome: "mapHome",
  requestToMapkid: "requestToMapkid",
  editPlaces: "editPlaces",
  post: "post",
  placePosts: "placePosts",
  pinOn: "pinOn",
  pinOff: "pinOff",
  reportAsSpam: "reportAsSpam",
  reportAsSpamDescription: "reportAsSpamDescription",
  report: "report",
  delete: "delete",
  comment: "comment",
  accessByLink: "accessByLink",
  nonMemberEdit: "nonMemberEdit",
  canCreatePlace: "canCreatePlace",
  canComment: "canComment",
  conceptWelcomeTitle: "conceptWelcomeTitle",
  conceptMapWithUSTitle: "conceptMapWithUSTitle",
  conceptWarmMapTitle: "conceptWarmMapTitle",
  conceptPostWithPlaceTitle: "conceptPostWithPlaceTitle",
  conceptTogetherTitle: "conceptTogetherTitle",
  conceptFindAndWalkTitle: "conceptFindAndWalkTitle",
  conceptFromNowOnTitle: "conceptFromNowOnTitle",
  conceptHowWeAreTitle: "conceptHowWeAreTitle",
  conceptClosingTitle: "conceptClosingTitle",
  conceptSloganTitle: "conceptSloganTitle",
  conceptWelcomeText: "conceptWelcomeText",
  conceptMapWithUSText: "conceptMapWithUSText",
  conceptWarmMapText: "conceptWarmMapText",
  conceptPostWithPlaceText: "conceptPostWithPlaceText",
  conceptTogetherText: "conceptTogetherText",
  conceptFindAndWalkText: "conceptFindAndWalkText",
  conceptFromNowOnText: "conceptFromNowOnText",
  conceptHowWeAreText: "conceptHowWeAreText",
  conceptClosingText: "conceptClosingText",
  conceptSloganText: "conceptSloganText",
  seeOurConceptBook: "seeOurConceptBook",
  underBetaTest: "underBetaTest",
  askMoreMaps: "askMoreMaps",
  searchMoreMaps: "searchMoreMaps",
  showQrCode: "showQrCode",
  shareUrl: "shareUrl",
  download: "download",
  saved: "saved",
  addPlace: "addPlace",
  changePlace: "changePlace",
  recent: "recent",
  placeName: "placeName",
  savePlace: "savePlace",
  fallbackText: "fallbackText",
  concept: "concept",
  privacyPolicy: "privacyPolicy",
  termsOfService: "termsOfService",
  and: "and",
  continueWithConsent: "continueWithConsent",
  mapTypeTitle: "mapTypeTitle",
  mapTypeDescription: "mapTypeDescription",

  changeMapStyle: "changeMapStyle",
  background: "background",
  originalStyle: "originalStyle",
  printMap: "printMap",
  emailVerification: "emailVerification",
  blockUser: "blockUser",
  subscription: "subscription",
  functions: "functions",
  comparison: "comparison",
  moreMaps: "moreMaps",
  autoTranslation: "autoTranslation",
  autoTranslationDescription: "autoTranslationDescription",
  choosePricingPlan: "choosePricingPlan",
  "check the price in the website": "check the price in the website",
  "By continuing, you agree to terms of service and acknowledge you’ve read our privacy policy":
    "By continuing, you agree to terms of service and acknowledge you’ve read our privacy policy",
  "Pan map to take the snapshot": "Pan map to take the snapshot",
  style: "style",
  "Error occured. Please attempt to login again.":
    "Error occured. Please attempt to login again.",
  "Smileys & Emotion": "Smileys & Emotion",
  "People & Body": "People & Body",
  "Animals & Nature": "Animals & Nature",
  "Food & Drink": "Food & Drink",
  "Travel & Places": "Travel & Places",
  Activities: "Activities",
  Objects: "Objects",
  Symbols: "Symbols",
  Flags: "Flags",
  "Sexual contents": "Sexual contents",
  "Illegal product or service": "Illegal product or service",
  Violence: "Violence",
  "Political ads": "Political ads",
  Discrimination: "Discrimination",
  Other: "Other",
  "Your subscription is started!": "Your subscription is started!",
  "Now, you can use all functions on Mapnom!":
    "Now, you can use all functions on Mapnom!",
  "Back to Home": "Back to Home",
  "Reported and Blocked this user": "Reported and Blocked this user",
  "Reported as spam": "Reported as spam",
  "Sent a mail link to your email box. Please login from it.":
    "Sent a mail link to your email box. Please login from it.",
  sentEmailLink: "sentEmailLink",

  moreMapsTitle: "moreMapsTitle",
  moreMapsDescription: "moreMapsDescription",
  styleMapsTitle: "styleMapsTitle",
  styleMapsDescription: "styleMapsDescription",
  connectWithTitle: "connectWithTitle",
  connectWithDescription: "connectWithDescription",
  Subscribe: "Subscribe",
  "Free 1 month trial": "Free 1 month trial",
  "Free plan": "Free plan",
  SubscriptionPageTitle: "SubscriptionPageTitle",
  SubscriptionPageDescription: "SubscriptionPageDescription",
  FunctionsTitle: "FunctionsTitle",
  FunctionsDescription: "FunctionsDescription",
  PlansTitle: "PlansTitle",
  PlansDescription: "PlansDescription",
  TableTitle: "TableTitle",
  TableDescription: "TableDescription",
  mo: "mo",
  "Thank you for subscribing!": "Thank you for subscribing!",
  "Check more details on mapnom.com!": "Check more details on mapnom.com!",
  detailsOnWeb: "detailsOnWeb",
  "Free trial": "Free trial",
  "More freedom": "More freedom",
  "Create memory maps with friends": "Create memory maps with friends",
  "Search maps of the world": "Search maps of the world",
  "Unlimited posts": "Unlimited posts",
  "Up to 3000 places per map, 30 maps": "Up to 3000 places per map, 30 maps",
  "Styling maps and print out": "Styling maps and print out",
  account: "account",
  Prices: "Prices",
  Price: "Price",

  Maps: "Maps",
  Places: "Places",
  Posts: "Posts",
  Printing: "Printing",
  Styling: "Styling",
  Emoji: "Emoji",
  Join: "Join",
  global: "global",
  bbox: "bbox",
  nearby: "nearby",
  whereToSearch: "whereToSearch",
};

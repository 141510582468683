import { lazy } from "react";
import { LinkingOptions, ParamListBase } from "@react-navigation/native";
import { langCheck, langDict } from "../languages/langCheck";
import i18n from "../languages/language";
// import { iconNames } from "./constants";
import { iconNames } from "./icon-names";
import { appDeepLinkURL, webSiteURL } from "../app.config";

const getText = (text: string) => i18n.t(text);

export const drawerScreenNames = {
  // Home: "Home",
  Landing: "Landing",
  Main: "Main",
  Login: "Login",
  PrivacyPolicy: "PrivacyPolicy",
  TermsOfService: "TermsOfService",
  Help: "Help",
  Article: "Article",
  Libraries: "Libraries",
  // Licenses: "Licenses",
  // Payment: "Payment",
  EmailVerification: "EmailVerification",
  UserSettings: "UserSettings",
  Notifications: "Notifications",
  UserPlan: "UserPlan",
  GlobalSettings: "GlobalSettings",
  UserProfile: "UserProfile",
  Payments: "Payments",
  Chat: "Chat",
  // Subscription: "Subscription",
  // Concept: "Concept",
  // Activities: "Activities",
  // CreateActivity: "CreateActivity",
};

export const chatScreenNames = {
  Chats: "Chats",
  Rooms: "Rooms",
};

export const stackScreenNames = {
  SearchMaps: "SearchMaps",
  Places: "Places",
  // EditFeature: "EditFeature",
  MapSettings: "MapSettings",
  // PlaceDetail: "PlaceDetail",
  PlacePanel: "PlacePanel",
  PlaceScanner: "PlaceScanner",
  GroupScreen: "GroupScreen",
  MapHome: "MapHome",
  Home: "Home",
  TextScreen: "TextScreen",
  SelectedPlaces: "SelectedPlaces",
};

export const screenNames = {
  ...drawerScreenNames,
  ...stackScreenNames,
  ...chatScreenNames,
};

// linking: allow user come from url.

//Drawer
const drawerLinkingPaths = {
  // Home: "Home",
  Landing: "landing",
  Main: "main",
  // [linkedPage]: linkedPage + "/:mapID",
  PrivacyPolicy: "privacy_policy",
  TermsOfService: "terms_of_service",
  Licenses: "licenses",
  // SearchMaps: "SearchMaps",
  Concept: "concept",
  Payment: "payment" + "/:success/:price",
  Subscription: "subscription",
  EmailVerification: "email_verification/:verify",
  UserSettings: "user_settings",
  Help: "help",
  Article: "article" + "/:number",
  [drawerScreenNames.UserProfile]: "user_profile",
  [drawerScreenNames.GlobalSettings]: "global_settings",
  [drawerScreenNames.UserPlan]: "user_plan",
  [drawerScreenNames.Payments]: "payments",
  [drawerScreenNames.Activities]: "activities",
  [drawerScreenNames.CreateActivity]: "create_activity" + "/:activityID",
  Chat: "chat",
};

// path: 'user/:id/:section',
// parse: {
//   id: (id) => `user-${id}`,
// },
// stringify: {
//   id: (id) => id.replace(/^user-/, ''),
// },

const stackLinkingPaths = {
  SearchMaps: "search_maps",
  MapHome: "map" + "/:mapID",
  Places: "places" + "/:mapID",
  Home: "home",
  // PlaceDetail: { path: "place_detail" + "/:mapID" + "/:placeID" },
  PlacePanel: { path: "place_detail" + "/:mapID" + "/:placeID" }, //we use param only for editing {isEditing: true}
  GroupScreen: { path: "group_screen" + "/:mapID" + "/:groupID" },
  MapSettings: "map_settings" + "/:mapID" + "/:mode",
  TextScreen: "post" + "/:mapID" + "/:placeID",
};

const chatLinkingPaths = {
  Chats: "chats" + "/:room",
  Rooms: "rooms",
};

//matched paths
const config = {
  screens: {
    ...drawerLinkingPaths,
    Main: {
      screens: stackLinkingPaths,
    },
    Chat: {
      screens: chatLinkingPaths,
    },
  },
};

// dynamic path is not supported in react native!!

// const createLazyScreen = (name: string) => {
//   const path = `../screens/${name}`;
//   console.log(path);
//   return lazy(() => import(path));
// };

// const allScreens = {};
// Object.keys(screenNames).forEach((key) => {
//   allScreens[key] = createLazyScreen(key);
// });

// allScreens[screenNames.Main] = createLazyScreen("SecondNavigator");
// export const lazyScreens = allScreens;

export const lazyScreens = {
  Landing: lazy(() => import("../screens/Landing")),
  // Main: lazy(() => import("../screens/Main")),
  Main: lazy(() => import("../screens/SecondNavigator")),
  Login: lazy(() => import("../screens/Login")),
  PrivacyPolicy: lazy(() => import("../screens/PrivacyPolicy")),
  TermsOfService: lazy(() => import("../screens/TermsOfService")),
  // Licenses:lazy(()=>import("../screens/Licenses")) ,
  Concept: lazy(() => import("../screens/Concept")),
  Payment: lazy(() => import("../screens/Payment")),
  Subscription: lazy(() => import("../screens/Subscription")),
  EmailVerification: lazy(() => import("../screens/EmailVerification")),
  UserSettings: lazy(() => import("../screens/UserSettings")),
  // // Help:lazy(()=>import("../screens/Help")) ,
  Article: lazy(() => import("../screens/Article")),
  Libraries: lazy(() => import("../screens/Libraries")),
  Notifications: lazy(() => import("../screens/Notifications")),
  UserPlan: lazy(() => import("../screens/UserPlan")),
  GlobalSettings: lazy(() => import("../screens/GlobalSettings")),
  UserProfile: lazy(() => import("../screens/UserProfile")),
  Payments: lazy(() => import("../screens/Payments")),
  // // Chat:lazy(()=>import("../screens/Chat")) ,
  Activities: lazy(() => import("../screens/Activities")),
  CreateActivity: lazy(() => import("../screens/CreateActivity")),
  SearchMaps: lazy(() => import("../screens/SearchMaps")),
  // // Places:lazy(()=>import("../screens/Places")) ,
  MapSettings: lazy(() => import("../screens/MapSettings")),
  PlacePanel: lazy(() => import("../screens/PlacePanel")),
  PlaceScanner: lazy(() => import("../screens/PlaceScanner")),

  // GroupScreen: lazy(() => import("../screens/GroupScreen")),
  MapHome: lazy(() => import("../screens/MapHome")),
  Home: lazy(() => import("../screens/Home")),
  TextScreen: lazy(() => import("../screens/TextScreen")),
  SelectedPlaces: lazy(() => import("../screens/SelectedPlaces")),
  // EditFeature: "EditFeature",
  // PlaceDetail: "PlaceDetail",
};

export const linking: LinkingOptions<any> = {
  prefixes: [
    "http://localhost:19006/",
    appDeepLinkURL + "/",
    webSiteURL + "/",
    "https://rounds-test-fe5b9.firebaseapp.com/",
    "https://drivers-d1a9f.firebaseapp.com/",
    /* your linking prefixes */
  ],
  config,
  // getStateFromPath: (path, options) => {
  //   console.log(path, options);
  //   // Handle the case where you want to redirect from Screen B to Screen A
  //   // if (path === 'screen-b') {
  //   //   return {
  //   //     routes: [{ name: 'ScreenA' }],
  //   //   };
  //   // }
  // },
};

// items on drawer menu
// not ionicon but paper icon mui
export const drawerContentOptions = {
  [drawerScreenNames.Chat]: {
    icon: iconNames["chat-outline"],
    title: "Chat",
  },
  [drawerScreenNames.Main]: {
    icon: "map-outline",
    title: getText(langDict.map),
  },
  [drawerScreenNames.UserSettings]: {
    icon: iconNames["account-outline"],
    title: getText(langDict.account),
  },
  [drawerScreenNames.Help]: {
    // icon: iconNames["account-outline"],
    // title: getText(langDict.account),
    title: "Test Help",
  },

  [drawerScreenNames.Notifications]: {
    icon: iconNames["bell-outline"],
    title: getText(langDict.notifications),
  },
  [drawerScreenNames.Login]: {
    icon: iconNames["login-variant"],
    title: getText(langDict.login),
  },
  [drawerScreenNames.PrivacyPolicy]: { title: getText(langDict.privacyPolicy) },
  [drawerScreenNames.TermsOfService]: {
    title: getText(langDict.termsOfService),
  },
  [drawerScreenNames.Activities]: {
    icon: iconNames["walk"],
    title: "Activities",
  },

  // [drawerScreenNames.Licenses]: { icon: "home-outline", title: "Home" },,
  // [drawerScreenNames.Payment]: { icon: "home-outline", title: "Home" },,
  // [drawerScreenNames.Licenses]: { icon: "home-outline", title: "Home" },,
  // [drawerScreenNames.Licenses]: { icon: "home-outline", title: "Home" },,
};

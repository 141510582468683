import { useRef, useState } from "react";
import {
  NativeSyntheticEvent,
  TextInputContentSizeChangeEventData,
  TextInputProps,
  View,
} from "react-native";
import { Caption, HelperText, Paragraph, TextInput } from "react-native-paper";
import { TextInputLabelProp } from "react-native-paper/lib/typescript/components/TextInput/types";

type StyledTextInputProps = TextInputProps & {
  label?: TextInputLabelProp | undefined;
};

// label: Label
//place holder : caption before typing
// error: error style outline, emphasize outline

export default function (props: StyledTextInputProps) {
  // Number input

  const [text, setText] = useState(props.defaultValue || props.value);

  const onChangeText = (value: string) => {
    setText(value);
    props.onChangeText && props.onChangeText(value);
  };

  const [visible, setVisible] = useState(text?.length > props.maxLength);
  const [height, setHeight] = useState<number>(
    props.multiline ? 200 : undefined
  );

  const onContentSizeChange = (
    e: NativeSyntheticEvent<TextInputContentSizeChangeEventData>
  ) => {
    // console.log(e.nativeEvent.contentSize);

    if (!props.multiline) return;

    // setHeight(e.nativeEvent.contentSize.height);
  };

  const inputRef = useRef<TextInput>();

  const { maxLength } = props;

  const MaxLength = () => {
    return (
      <>
        {visible && (
          <HelperText visible={visible}>Reached to max text length.</HelperText>
        )}
        <Caption style={{ alignSelf: "flex-end" }}>
          {`${text?.length} / ${maxLength}`}
        </Caption>
      </>
    );
  };

  return (
    <View>
      <TextInput
        mode="outlined"
        ref={inputRef}
        onPressIn={() => inputRef.current.focus()}
        style={[props.style, { height, maxHeight: "100%" }]}
        onChangeText={onChangeText}
        value={text}
        onContentSizeChange={onContentSizeChange}
        // right={<TextInput.Affix text="/100" />}

        {...props}
      />
      {visible && props.maxLength && (
        <>
          <Paragraph>/ {text?.length} </Paragraph>
          <HelperText visible={visible}>Reached to max text length.</HelperText>
        </>
      )}
      {props.maxLength && <MaxLength />}
    </View>
  );
}

// const StyledTextInput = () => {
//     const [text, setText] = useState(defaultValue);
//     const onChangeText = (value) => {
//       onChange(value);
//       setText(value);
//     };

//     return (
//       <TextInput
//         mode="outlined"
//         onChangeText={onChangeText}
//         value={text}
//         label={props.label}
//         // defaultValue={defaultValue}
//       />
//     );
//   };

import { Platform } from "react-native";
import { langCheck } from "./langCheck";
const ja: langCheck = {
  welcome:
    "ようこそ！Mapnomはカジュアルな地図作成プラットフォームです。公開リリースに向けて開発中です。バグの報告やこんな機能があったらいいなどありましたらぜひ連絡して下さい！",
  createMap: "地図を作る",
  login: "ログイン",
  logout: "ログアウト",
  home: "ホーム",
  map: "地図",
  chat: "チャット",
  userSettings: "アカウント",
  mapSettings: "地図の設定",
  addFriend: "友達を追加する",
  searchMap: "ほかの人の地図を探す",
  search: "検索",
  chatInput: "テキストを入力",
  sendImages: "画像を送る",
  existingPlaces: "この地図内",
  newPlaces: "検索結果",
  updateMap: "変更を保存",
  deleteMap: "地図を削除",
  showInSearch: "検索に表示する",
  cancel: "キャンセル",
  createPlace: "新しく作成",
  choosePlace: "この場所を選ぶ",
  addMembers: "メンバーを追加",
  friends: "友達",
  title: "タイトル",
  description: "説明",
  tags: "タグ",
  friendCode: "フレンドコード",
  mapIsCreated: "地図が作成されました！",
  mapIsUpdated: "地図が更新されました！",
  uploadImage: "画像をアップロード",
  addOverlays: "地図に画像を貼る",
  exploreSentence: "ほかの人が作った世界を見てみよう。",
  currentLocation: "現在地",
  fitToCoordinates: "すべてのマーカー",
  mapStyle: "地図のスタイル",
  tutorial:
    "Welcome to Rounds! Now, you can start editing your map. Simply say, we create a map by chatting. it means you just send a message and choose the place, then this map is developped. You press the ",
  hitResults: "件の検索結果",
  noTitle: "タイトルがありません",
  language: "言語",
  members: "メンバー",
  userIsUpdated: "ユーザー情報が更新されました。",
  street: "ストリート",
  satellite: "衛星写真",
  white: "ホワイト",
  black: "ダーク",
  where: "どこ？",
  deleteConfirmation:
    "本当にこの地図を消しますか？削除後は元に戻せません。この地図の全ての画像とメッセージが削除されます",
  storage: "ストレージ",
  searchPlaces: "場所を検索",
  familyName: "姓",
  givenName: "名",
  noMember: "メンバーがいません",
  noTag: "タグがありません",
  createUser: "アカウントを作成する",
  updateUser: "ユーザー情報を更新する",
  emailCopied: "Eメールアドレスがコピーされました。",
  friendIsAdded: "友達が追加されました！",
  goToMap: "地図に移動する",
  orJoin: "あるいは、ほかの人の地図に参加しましょう。",
  uploading: "アップロード中",
  overlays: "地図上の画像",
  mapImage: "トップ画像",
  advancedSettings: "追加設定",
  showAllMessages: "すべてのメッセージを表示",
  howTo: "使い方",
  nonMember: "メンバー以外",
  notifications: "お知らせ",
  underDevelopment: "ただいま、開発中です。もう少し待ってくださいね。",
  canJoin: "だれでも参加",
  userPage: "プロファイル",
  mapHome: "地図トップ",
  requestToMapkid: "Mapnomにリクエストする",
  editPlaces: "場所を編集",
  post: "投稿",
  placePosts: "ここになにがあるかな？",
  pinOn: "ピン留め",
  pinOff: "はがす",
  reportAsSpam: "通報",
  reportAsSpamDescription: "スパムとしてこの投稿を通報します。",
  report: "通報",
  delete: "削除",
  comment: "コメント",
  accessByLink: "URLでアクセス",
  nonMemberEdit: "投稿",
  canCreatePlace: "場所の追加",
  canComment: "コメント",

  conceptWelcomeTitle: "Mapnomへようこそ",
  conceptMapWithUSTitle: "わたしたちと地図",
  conceptWarmMapTitle: "あったかい地図",
  conceptPostWithPlaceTitle: "かんたん",
  conceptTogetherTitle: "みんなで",
  conceptFindAndWalkTitle: "ちずを探す、そして歩く。",
  conceptFromNowOnTitle: "これからのMapnom",
  conceptHowWeAreTitle: "Mapnomの姿勢",
  conceptClosingTitle: "さいごに",
  conceptSloganTitle: "ちきゅうに、おもいで。",
  conceptWelcomeText:
    "ここでは、はじめてのかたにMapnomとはなにか簡単に説明します。\n\nどんなアプリであるか、なにができるのか、\nそして私たちがどんな姿勢でMapnomを作っていくのか。\n\nきっとどんな雰囲気なのかわかっていただけると思います。",
  conceptMapWithUSText:
    "世の中にはたくさんの地図があります。\n\nカフェやレストランの場所を検索できる地図。\nエリアを案内する地図。\n世界の統計を可視化した地図。\n\nみんなたくさんの情報を載せています。\nわたしたちはこれからも地図を使っていくことでしょう。",
  conceptWarmMapText:
    "自分にとって必要な情報をさっと地図から読み取る。\nおおくのひとにとって、地図の役割としてはそれで十分でしょう。\nその代わりに、無駄な冗談や人の思い出が描かれることは少ないです。\n無駄なものを省いた便利なものがいいに決まっていると思うかもしれません。\n\nでも、わたしたちは地球をもっとおもしろく、おもいでがあふれるものにしたい。\nあなたのみたままの世界を表現してほしい。\nそういった思いでMapnomは生まれました。",
  conceptPostWithPlaceText:
    "難しい操作は要りません。\nSNSのように文章や写真をつけて、投稿するだけ。\n違う点は場所をつけること。\n投稿された場所には住所や詳しい情報はありません。\nあなたの思い出だけが溜まっていくのです。",
  conceptTogetherText:
    "少し大きい地図を一人で作るのは大変です。\nそういう時は、家族や友達、地元の仲間、あるいは世界中の人と作りましょう。\nやることは変わりません。\n一緒に投稿していくだけ。",
  conceptFindAndWalkText:
    "行きたい場所があったら、Mapnomで検索してみましょう。\n地元の人が地図を作っているかも。\n気になることがあったら、質問してみましょう。\n現地に着いたら、地図を時々見ながら地元を探索できます。",
  conceptFromNowOnText:
    "私たちもゆっくり成長していきます。\n\n地図の見た目を変えたり、地図の上に何か置いたり。\nみなさんが好きな地図を作れるようにしたい。\n集まった地図を一つの地図から検索できるようにしたい。\n\nみなさんのリクエストに応えながら、丁寧に作っていきます。",
  conceptHowWeAreText:
    "普遍的なものを追いかけていきます。\n\nゆっくりする時間を作る。\nだれかが大事にしているものをいっしょに楽しむ。\n地域のつながりを感じる。\n\nMapnomはどうやってふやせるか考えます。地球の上をみなさんと一緒に歩き続けます。そうやって地球におもいでをのこしていきます。",
  conceptClosingText:
    "ここまで読んでくださり、ありがとうございます。\nまたお会いしたら、よろしくお願いします。",
  conceptSloganText: "Mapnom",
  seeOurConceptBook: "コンセプトブックを見てみる",
  underBetaTest:
    "(現在、Betaテスト中です。だれでも参加できます。iOS版はAppleが提供するTestFlightというアプリ経由でダウンロードできます。)",
  askMoreMaps: "もっと見ますか?",
  searchMoreMaps: "ほかの地図を検索",
  showQrCode: "QRコードを表示",
  shareUrl: "URLを共有",
  download: "ダウンロード",
  saved: "保存しました。",
  addPlace: "投稿に場所を追加する",
  changePlace: "投稿の場所を変える",
  recent: "最近",
  placeName: "場所の名前",
  savePlace: "場所を保存",
  fallbackText: "画面が変わらない場合は、リロードしてください。",
  concept: "コンセプト",
  privacyPolicy: "プライバシーポリシー",
  termsOfService: "利用規約",
  and: "の",
  mapTypeTitle: "マップタイプ?",
  mapTypeDescription:
    "\n マップタイプはほかの人が何ができるか決めます。 \n \n Close: メンバーだけが見れます. \n Publish: 誰でも見られますが、投稿できません。 \n Open:誰でも投稿できます。",

  changeMapStyle: "地図スタイル変更",
  background: "背景",
  originalStyle: "スタイル",
  printMap: "地図を印刷",
  emailVerification: "Ｅメール認証",
  blockUser: "ブロックする",
  subscription: "購読",
  functions: "機能紹介",
  comparison: "比較する",
  moreMaps: "地図をもっと。",
  autoTranslation: "自動翻訳",
  autoTranslationDescription:
    "どの言語でも自動で翻訳します. 外国の方とも安心して話せます.",
  choosePricingPlan: "プランを選びましょう。1か月無料で使用できます。",
  "check the price in the website": "MapnomのWebサイトで確認ください。",
  "By continuing, you agree to terms of service and acknowledge you’ve read our privacy policy":
    "En continuant, vous acceptez les conditions de service et reconnaissez avoir lu notre politique de confidentialité.",
  "Pan map to take the snapshot": "印刷したいエリアに地図を動かしてください。",
  style: "Style",
  "Error occured. Please attempt to login again.":
    "ログインエラー。もう一度ログインしてください。",

  "Smileys & Emotion": "エモーション",
  "People & Body": "ひと",
  "Animals & Nature": "いきもの",
  "Food & Drink": "食べる・飲む",
  "Travel & Places": "旅行・場所",
  Activities: "イベント",
  Objects: "もの",
  Symbols: "アイコン",
  Flags: "国旗",

  "Sexual contents": "性的",
  "Illegal product or service": "違法な製品、サービス",
  Violence: "暴力",
  "Political ads": "政治広告",
  Discrimination: "差別",
  Other: "その他",
  "Your subscription is started!": "ベーシックプランを開始しました。",
  "Now, you can use all functions on Mapnom!": "Mapnomの全機能を使えます。",

  "Back to Home": "ホームに戻る",
  "Reported and Blocked this user": "ユーザーをブロックしました。",
  "Reported as spam": "ユーザーを報告しました。",
  "Sent a mail link to your email box. Please login from it.":
    "メールボックスにログインURLを送りました。ご確認ください。",
  sentEmailLink: "メールボックスにログインURLを送りました。ご確認ください。",
  moreMapsTitle: "より楽しく",
  moreMapsDescription: `無料版では投稿、場所、作れる地図の数に制限がありました。ベーシックプランではずっと楽しむために十分すぎるほどに 
  自分の日々の思い出も、友達との旅行も、地元のお祭りも。思い出や覚えておきたいことをどんどん地図にしていきましょう。 `,
  styleMapsTitle: "地図のデザイン",
  styleMapsDescription: `Mapnomではさまざまな地図デザインを用意しています。カラフル、ひかえめ、おしゃれなど作っている地図のイメージに合わせましょう。ベースの色も変えられます。デザインはこれからも増えていくのでお楽しみに。自分の手帳に貼ったり、紙のガイドマップを作りたくなったら印刷できます。印刷するエリアも変えられます。 `,
  connectWithTitle: "みんなで",
  connectWithDescription:
    "より多くのメンバーと地図を一緒に作れます。より多くの友達や地元の知り合いを誘って一緒に地図を作りましょう。また、コメントができるようになるので地図を見てくれる人とコミュニケーションをとれ、より地域のことをおしゃべりできます。",

  Subscribe: "購読する",
  "Free 1 month trial": "1か月無料体験する",
  "Free plan": "無料",

  SubscriptionPageTitle: "もっとMapnom!",
  SubscriptionPageDescription: `Mapnomは無料でも十分お楽しみいただけます。
  ただ、長く使っていたり、大人数で使っていると物足りなくなるかもしれません.
  また、単なる投稿できる地図から地図のデザインを変えたり、印刷して書き込んだりできます。
  まだ開発途中ですが、自動翻訳機能や地図上に書き込んだりできる機能など順次追加していきます。
  `,
  FunctionsTitle: "追加機能紹介",
  FunctionsDescription: "プラン購読により追加される機能を簡単にご紹介します。",
  PlansTitle: "プラン一覧",
  PlansDescription: `追加機能を使ってみますか？
  1か月無料体験もあるのでじっさいにためしてから購読を開始できます。
  ぜひ一回試してみてください。
  `,
  TableTitle: "比較表",
  TableDescription: "プランごとにご利用いただける機能をまとめました。",
  mo: "月",
  "Thank you for subscribing!": "ご利用ありがとうございます！",
  "Check more details on mapnom.com!": "詳しくは mapnom.comで!",
  detailsOnWeb: "詳しくは mapnom.comで!",
  "Free trial": "無料お試し",
  "More freedom": "もっと自由に",
  "Create memory maps with friends": "家族や友達と思い出の地図をつくる。",
  "Search maps of the world": "世界中の地図を検索。",
  "Unlimited posts": "投稿、写真、無制限",
  "Up to 3000 places per map, 30 maps":
    "一つの地図につき3000カ所、30個の地図が作成可能",
  "Styling maps and print out": "地図のデザイン変更、自由に印刷可能",
  account: "アカウント",
  Price: "料金",

  Prices: "料金",
  Maps: "地図",
  Places: "場所",
  Posts: "投稿",
  Printing: "印刷",
  Styling: "地図のスタイル",
  Emoji: "絵文字",
  Join: "地図に参加",
  global: "世界中",
  bbox: "表示中のエリア",
  nearby: "現在地",
  whereToSearch: "どこを検索しますか？",
};

export default ja;

import { Suspense, lazy, useContext, useEffect, useState } from "react";
import { View } from "react-native";

import { StatusBar } from "expo-status-bar";

import { firebaseConfig, linking, paperTheme, picsumURL } from "./constants";

import {
  ActivityIndicator,
  Initial,
  PaperButton,
  Snackbar,
} from "./components";
import StyledText from "./components/StyledText";

const Providers = lazy(() => import("./components/Providers"));
const FirstNavigator = lazy(() => import("./screens/FirstNavigator"));

// import FirstNavigator from "./screens/FirstNavigator";
import { NavigationContainer } from "@react-navigation/native";
import FallbackScreen from "./screens/FallbackScreen";

import MapboxDrawTest from "./components/MapboxDrawTest";

const TestScreen = () => {
  const [value, setValue] = useState(0);

  return (
    <View style={{ flex: 1 }}>
      <MapboxDrawTest />
      {/* <StyledText>Test Screen</StyledText> */}
      {/* <PaperButton
        onPress={() => {
          setValue(value + 1);
        }}
      {/* <StyledNumberInput
        value={value}
        onValueChange={(value) => {
          console.log(value);
        }}
      /> */}
    </View>
  );
};

import { setup } from "./localFunctions";
import StyledDialog from "./components/StyledDialog";
import { GlobalSubContext } from "./Context";
import StyledNumberInput from "./components/StyledNumberInput";

export default function App() {
  // // Font.useFonts({
  // //   SFCompactRounded: require("./assets/fonts/SF-Compact-Rounded-Regular.ttf"),
  // // });

  // const isTestScreen = true;
  const isTestScreen = false;

  const MainScreen = () => {
    setup();

    const GlobalDialog = () => {
      const { dialogState, setDialogState } = useContext(GlobalSubContext);

      return <StyledDialog {...dialogState} />;
    };

    return (
      <Providers>
        <NavigationContainer
          theme={paperTheme}
          linking={linking}
          fallback={<FallbackScreen />}
        >
          <StatusBar />
          {isTestScreen ? (
            <TestScreen />
          ) : (
            <>
              <Initial />
              <FirstNavigator />
            </>
          )}
        </NavigationContainer>
        <GlobalDialog />
        {/* <Snackbar /> */}
      </Providers>
    );
  };

  const Test = () => {};

  return (
    <Suspense fallback={<FallbackScreen />}>
      <MainScreen />
    </Suspense>
  );
}

import * as FileSystem from "expo-file-system";

import * as ImagePicker from "expo-image-picker";

import * as ImageManipulator from "expo-image-manipulator";
import AWS from "aws-sdk";
import * as SecureStore from "expo-secure-store";
import * as Localization from "expo-localization";
import { Platform, Image, useWindowDimensions, Share } from "react-native";

import {
  drawerScreenNames,
  dynamicLinkDomain,
  dynamicLinkURL,
  firebaseConfig,
  imageSizes,
  limitNameKeyType,
  mapboxStyleUrls,
  mapViewID,
  markerSourceIDs,
  minBottomSheetHeight,
  panelMinWidth,
  screenNames,
  userLimits,
} from "../constants";

import AsyncStorage from "@react-native-async-storage/async-storage";

export const isWebOS = Platform.OS == "web";

// https://firebase.google.com/docs/app-check/web/debug-provider#web-version-9

export const geojson = { type: "FeatureCollection", features: [] };

export const debug = (obj) => __DEV__ && alert(" " + JSON.stringify(obj));
export const devLog = (obj, params?) => __DEV__ && console.log(obj, params);

// import { AWS_ACCESS_KEY_ID, AWS_SECRET_ACCESS_KEY, MAPBOX_TOKEN } from "@env";

export const ALGOLIA_APP_ID = "J4WV3DPJL3";
export const ALGOLIA_API_KEY = "0d0eec1848d8f5d42fc7f9bf02f40bb0";

const AWS_ACCESS_KEY_ID = "IU615KEHQSKDI9HMBEQH";
const AWS_SECRET_ACCESS_KEY = "cgS5CUKR3Rg6DuQBObozRGdSFtYIKnHdfyvljeSz";

AWS.config.update({
  region: "us-west-1",
  accessKeyId: AWS_ACCESS_KEY_ID,
  secretAccessKey: AWS_SECRET_ACCESS_KEY,
});

export const getLanguage = (availableLanguages: string[]) => {
  const deviceLanguage = i18n.locale;
  const isAvailiable = availableLanguages.includes(deviceLanguage);
  const language = isAvailiable ? deviceLanguage : "en";
  return language;
};

export const getText = () => {
  const deviceLanguage = i18n.locale;

  return;
};

const s3Endpoint = "https://s3.us-west-1.wasabisys.com";

export const generatePlaceImageURL = (
  mapID: string,
  placeID: string,
  id: string,
  index?: number
) => {
  return (
    s3Endpoint + "/maps/" + mapID + "/places/" + placeID + "/" + id + ".jpg"
  );
};

export const generateS3ImageURL = (key: string) => {
  // https://d12345abcdefg.cloudfront.net/image.jpg
  return s3Endpoint + "/" + key;
};

export const s3 = new AWS.S3({
  correctClockSkew: true,
  endpoint: s3Endpoint, //Specify the correct endpoint based on where your bucket is
});

// export const toDataURL = (url: string) =>
//   fetch(url)
//     .then((response) => response.blob())
//     .then(
//       (blob) =>
//         new Promise((resolve, reject) => {
//           const reader = new FileReader();
//           reader.onloadend = () => resolve(reader.result);
//           reader.onerror = reject;
//           reader.readAsDataURL(blob);
//         })
//     );

export const useAsyncEffect = ({
  callback,
  dependencies,
  cleanUp,
}: {
  callback: () => void;
  dependencies: any[];
  cleanUp: () => void;
}) =>
  useEffect(() => {
    async () => {
      await callback();

      return cleanUp;
    };
  }, dependencies);

export const getS3Size = (key: string) => {
  let newKey;
  if (key.indexOf("wasabisys.com") > -1) {
    newKey = key.split("wasabisys.com/")[1];
  } else {
    newKey = key;
  }
  // debug(newKey);
  return s3
    .headObject({ Key: newKey, Bucket: "mapkid" })
    .promise()
    .then((res) => res.ContentLength)
    .catch((err) => err);
};

export const getBlob = async (uri: string) => {
  const response = await fetch(uri);
  const blob = await response.blob();
  return blob;
};

// url link or base64
export const shareURL = async ({ url, title }) => {
  try {
    const obj = Platform.select({
      ios: {
        // message: 'Have a look on : ',
        url: url,
      },
      android: {
        url,
        message: url,
      },
      web: { url },
    });
    return Share.share({
      // ...obj,
      url,
      title,
    });
  } catch (error) {
    return;
    // alert(error.message);
  }
};

export const compressImage = async (uri, maxSize) => {
  let width;
  // debug(uri);

  const promise = new Promise(function (resolve, reject) {
    Image.getSize(uri, (originWidth, height) => {
      // debug(originWidth);
      width = originWidth;
      resolve("");
    });
  });

  await promise.then();

  let file;

  if (!isWebOS) {
    file = await FileSystem.getInfoAsync(uri, {
      // md5: true,
      size: true,
    });
  }

  if (isWebOS) {
    // const targetImageWidth = width / Math.cbrt(sizeRate);
    const targetImageWidth = width;

    const manipResult = await manipulateAsync(uri, {
      width: targetImageWidth,
    });

    // return uri;
    return manipResult.uri;
  } else {
    if (file?.size) {
      //kB
      const currentFileSize = file.size / 1000;
      const sizeRate = currentFileSize / maxSize;

      if (sizeRate > 1) {
        const targetImageWidth = width / Math.cbrt(sizeRate);

        const manipResult = await manipulateAsync(uri, {
          width: targetImageWidth,
        });

        var file2 = await FileSystem.getInfoAsync(manipResult.uri, {
          // md5: true,
          size: true,
        });
        // debug(
        //   "now: " +
        //     currentFileSize +
        //     ", max " +
        //     maxSize +
        //     ", final: " +
        //     file2.size / 1000
        // );

        return manipResult.uri;
      } else {
        return uri;
      }
    }

    return null;
  }
};

//depreciated use pickMedias
export const pickImage = async (aspect?: [number, number] | undefined) => {
  let result = await ImagePicker.launchImageLibraryAsync({
    // mediaTypes: ImagePicker.MediaTypeOptions.Images,

    allowsEditing: true,
    aspect: aspect || undefined,
    quality: 0.8,
  });

  // var file = await FileSystem.getInfoAsync(result.uri, {
  //   // md5: true,
  //   size: true,
  // });

  if (!result.canceled) {
    console.log(result);

    // const { uri, width, height, type } = result;
    // const obj: MediaType = {uri, width, height, mediaType: type}
    // return result.uri;

    return result.assets.find((asset) => asset.uri);
  } else {
    return null;
  }
};

export const pickMedias = async ({
  aspect,
  maxNum,
  mediaTypes,
}: {
  aspect?: [number, number] | undefined;
  maxNum?: number;
  mediaTypes?: "all" | "image" | "video";
  // allowsMultipleSelection?: boolean
}) => {
  let result = await ImagePicker.launchImageLibraryAsync({
    // mediaTypes: ImagePicker.MediaTypeOptions.,
    allowsEditing: true,
    aspect: aspect || undefined,
    quality: 0.8,
    allowsMultipleSelection: Boolean(maxNum),
    selectionLimit: maxNum,
  });

  // var file = await FileSystem.getInfoAsync(result.uri, {
  //   // md5: true,
  //   size: true,
  // });

  if (!result.canceled) {
    const medias = result.assets.map((asset) => {
      // asset.
      const { uri, width, height, type } = asset;
      const media: MediaType = {
        uri,
        width,
        height,
        mediaType: type || "image",
      };
      return media;
    });

    return medias;
  } else {
    return null;
  }
};

export const manipulateAsync = (image, resize: object) =>
  ImageManipulator.manipulateAsync(image, [{ resize: resize }], {
    //don't forget compress if original is compressed, or size will be bigger without it
    compress: 0.5,
    format: ImageManipulator.SaveFormat.JPEG,
  });

// target = image, overlays, messages, posts
export const uploadMapImage = async (
  image,
  mapID,
  target: "image" | "overlay" | "post" | "post-displays",
  metadata?: any = {}
) => {
  let targetImage;

  const sizes = {
    image: imageSizes["mapCover"],
    post: imageSizes["postDetail"],
    "post-displays": imageSizes["postDisplay"],
  };

  targetImage = await compressImage(image, sizes[target]);

  // if (target == "post-displays") {
  //   const maxDimension = 300;

  //   const manipResult: { uri: string; width: number; height: number } =
  //     await manipulateAsync(image, { height: maxDimension });

  //   targetImage = manipResult.uri;
  // } else {
  //   targetImage = image;
  // }

  const blob = await getBlob(targetImage);
  const imageID = getRandomID();
  const key = `maps/${mapID}/${target}/${imageID}.jpg`;

  const uploadRequest = s3Upload(key, blob, "image/jpeg", metadata);

  const uri = await uploadRequest.promise().then((data) => {
    return data.Location;
  });

  return uri;
};

export const uploadReportMedia = async (
  image
  // type: "display" | "profile"
) => {
  const blob = await getBlob(image);

  const imageID = getRandomID();

  const key = `reports/${imageID}.jpg`;
  const uploadRequest = s3Upload(key, blob, "image/jpeg");

  const uri = await uploadRequest.promise().then((data) => {
    console.log(data.Location);
    return data.Location;
  });

  return uri;
};

export const s3Upload = (
  key: string,
  blob: Blob,
  contentType: "video/mp4" | "image/jpeg",
  metadata?: any
) =>
  new AWS.S3.ManagedUpload({
    params: {
      Bucket: "mapkid",
      Key: key,
      Body: blob,
      ContentType: contentType,
      Metadata: metadata || {},
    },
    service: s3,
  });

export const uploadMessageVideo = async (video, mapID) => {
  const blob = await getBlob(video);

  const imageID = getRandomID();
  // const key = `videos/maps/${mapID}/video/${imageID}.mp4`;
  const key = `maps/${mapID}/video/${imageID}.jpg`;

  const uploadRequest = s3Upload(key, blob, "video/mp4");

  const uri = await uploadRequest
    .promise()
    .then((data) => {
      return data.Location;
    })
    .catch((err) => debug(err));

  return uri;
};

export const uploadUserImage = async (
  image,
  userID,
  type: "display" | "profile"
) => {
  let imageSource;
  if (type == "display") {
    const manipResult = await manipulateAsync(image, { width: 50 });

    imageSource = manipResult.uri;
  } else {
    imageSource = image;
  }

  const blob = await getBlob(imageSource);
  const key = `users/${type}/${userID}.jpg`;
  const uploadRequest = s3Upload(key, blob, "image/jpeg");

  const uri = await uploadRequest.promise().then((data) => {
    return data.Location;
  });

  return uri;
};

export const loginWithUid = async ({ uid }) =>
  // userRef(uid, "private")
  //   .get()
  getUserData(uid, "private").then(async (res) => {
    console.log(416, res);

    if (res?.email) {
      return { type: "exist", userData: res };
    } else {
      let email;
      let familyName;
      let givenName;

      if (isWebOS) {
        const user = authUser.currentUser;
        email = user?.email;
      } else {
        email = await getLocalStorageItemAsync("email");
        familyName = await getLocalStorageItemAsync("familyName");
        givenName = await getLocalStorageItemAsync("givenName");
      }

      const deviceLang = Localization.locale;
      const language = (function () {
        if (deviceLang.indexOf("fr") > -1) {
          return "fr";
        } else if (deviceLang.indexOf("ja") > -1) {
          return "ja";
        } else {
          return "en";
        }
      })();

      const newCode = Math.random().toString(36).slice(-8);

      const userData = {
        uid,
        email,
        givenName,
        familyName,
        language,
        friends: [],
        friendCode: newCode,
        joinMaps: [],
        hostMaps: [],
        userName: null,
        createdAt: Date.now(),
        photoURL: null,
        bookmarks: [],
        likes: [],
      };

      return { type: "notExist", userData: userData };
    }
  });

export const useLogout = () => {
  // excute logout by logout button and forcedly logout when going to login screen upnproperly
  //   console.log("aaaaa");

  const { setUserInfo, setLogin, login, userInfo } = useContext(GlobalContext);
  const { setSnackbarText } = useContext(GlobalSubContext);
  const navigation = useNavigation();

  const isLogined = userInfo?.uid;
  const callback = () => {
    if (!isLogined) return;

    signOut().then((res) => {
      navigation.reset({
        index: 0,
        routes: [{ name: screenNames.Main }],
      });
      setLogin(false);
      setUserInfo(null);
      // setUserMaps({});
      if (!isWebOS) {
        SecureStore.deleteItemAsync("uid");
      }
      setSnackbarText("You logged out!");
    });
  };

  return callback;
};

import {
  appRef,
  getFeatureCollection,
  getMapInfo,
  getUserData,
} from "./database";

export * from "./database";

export const uploadMedias = async (mapID: string, medias) => {
  const promises = medias.map(async (media) => {
    const mediaType = media.mediaType || media.type || "image";
    const uri = media.localUri || media.uri;

    if (mediaType == "video") {
      media.uri = await uploadMessageVideo(uri, mapID);
    } else {
      media.uri = await uploadImage(uri, mapID, "post");
      const fileSize = await getS3Size(media.uri);
      //fileSize = B
      // imageSizes["postDisplay"] = kB
      if (fileSize > imageSizes["postDisplay"] * 1000) {
        media["displayUri"] = await uploadImage(uri, mapID, "post-displays");
      }
    }

    // const fileSize = await getS3Size(media.uri);

    const simplifiedMedia = {
      uri: media.uri,
      displayUri: media?.displayUri || null,
      mediaType,
    };

    return simplifiedMedia;
  });

  return Promise.all(promises);
  // await Promise.all(promises).then((res) => {
  //   // debug(res);
  //   post["medias"] = res;
  //   // setMedias(null);
  // });
};

//anytime user visit certain pages like MapHome, Notifications
export const updateHistory = async (
  key: string | "notifications",
  type: "visit" | "update"
) => {
  const now = Date.now();

  const value = await SecureStore.getItemAsync(key).then(
    async (stringValue) => {
      if (type == "visit") {
        //check data already exists
        if (stringValue) {
          const value = JSON.parse(stringValue);
          //visit already

          if (value.visitAt) {
            value.visitAt = String(now);
            await SecureStore.setItemAsync(key, JSON.stringify(value));
            return value;
            //not yet visit
          } else {
            value["visitAt"] = String(now);
            await SecureStore.setItemAsync(key, JSON.stringify(value));
            return value;
          }
        } else {
          await SecureStore.setItemAsync(
            key,
            JSON.stringify({ visitAt: String(now) })
          );
        }
      } else if (type == "update") {
        if (stringValue) {
          const value = JSON.parse(stringValue);
          //update already
          if (value.updatedAt) {
            value.updatedAt = String(now);
            await SecureStore.setItemAsync(key, JSON.stringify(value));
            return value;

            //not yet updated
          } else {
            value["updatedAt"] = String(now);
            await SecureStore.setItemAsync(key, JSON.stringify(value));
            return value;
          }
        } else {
          await SecureStore.setItemAsync(
            key,
            JSON.stringify({ updatedAt: String(now) })
          );
        }
      }
    }
  );

  return value;
};

//notification badge get this when page is focused
export const getHistory = async (key: string) => {
  return SecureStore.getItemAsync(key).then((stringValue: string) => {
    return JSON.parse(stringValue);
  });
};

const layerProp = {
  layout: [
    "textField",
    "textMaxWidth",
    "textOptional",
    "iconAllowOverlap",
    "iconSize",
    "iconImage",
    "textOffset",
  ],
  paint: ["textColor", "textHaloColor", "textHaloWidth", "textHaloBlur"],
};

export const registerDir = "mapkid/client/register/";

export const loginAction = ({ res, setUserInfo, setLogin, navigation }) => {
  const { type } = res;
  if (type !== "error") {
    const { userData } = res;
    console.log(res, userData);

    if (type == "exist") {
      setUserInfo(userData);
      setLogin(true);

      navigation.reset({
        index: 0,
        routes: [{ name: screenNames.Main }],
      });
    } else {
      setUserInfo(userData);
      navigation.push("UserSettings", { mode: "create" });
    }
  }
};

// import { domain } from "./constants";
import {
  linkedPage,
  isProduct,
  appDeepLinkURL,
  webSiteURL,
} from "../app.config";

export const getDynamicLink = async (body) => {
  // const { title, description, image } = mapInfo;
  // // console.log("dynamic", dynamicLinkInfo(mapID));
  // const path = "map/" + mapID;
  // const body = {
  //   dynamicLinkInfo: createDynamicLinkInfo(path, title, description, image),
  // };

  const promise = new Promise(function (resolve, reject) {
    var req = new XMLHttpRequest();

    // req.open("GET", postURL, true);
    req.open("Post", dynamicLinkURL, true);
    req.setRequestHeader("Content-Type", "application/json");

    req.onload = function () {
      var json = JSON.parse(req.response);
      console.log(json);
      resolve(json);
    };
    // req.send();
    req.send(JSON.stringify(body));
  });

  const res = await promise.then((res) => {
    return res;
  });

  return res;
};

export const createDynamicLinkInfo = (
  // mapID,
  path: string,
  socialTitle: string,
  socialDescription: string,
  socialImageLink: string
) => {
  const domain = appDeepLinkURL + "/";
  // const link = domain + linkedPage + "/" + mapID;
  const link = domain + path;

  return {
    domainUriPrefix: isProduct
      ? "https://" + dynamicLinkDomain
      : "https://roundstest.page.link",
    link,
    androidInfo: {
      androidPackageName: isProduct
        ? "com.mapkid.rounds4"
        : "com.mapkid.rounds_test2",
      androidFallbackLink: link,
    },
    iosInfo: {
      iosBundleId: isProduct ? "com.mapkid.rounds" : "com.mapkid.roundsTest",
      iosFallbackLink: link,
    },
    navigationInfo: {
      enableForcedRedirect: true,
    },
    socialMetaTagInfo: {
      socialTitle: socialTitle,
      socialDescription: socialDescription,
      socialImageLink: socialImageLink,
    },
  };
};

import Intl from "intl";
import "intl/locale-data/jsonp/en";
import i18n from "../languages/language";
import { langCheck } from "../languages/langCheck";
import React, { useCallback, useContext, useEffect, useState } from "react";

export const createPlaceData = (postID, place) => ({
  title: place.properties.title,
  // region: place.region,
  posts: [postID],
  createdAt: Date.now(),
  updatedAt: Date.now(),
});

// curate Feature data
export const createFeatureData = (feature) => {
  // from geocode
  const { text, place_name } = feature.properties;

  const { title, bbox, emoji, placeID, description } = feature.properties;
  const properties = {
    title: title || text,
    bbox,
    placeID,
    emoji,
    description: description || place_name,
  };
  const { geometry } = feature;
  // devLog(" origin feature", feature);

  // devLog("feature", properties);

  return {
    type: "Feature",
    properties,
    geometry,
  };
};

export const putDatasetsFeature = (datasetId, featureId, feature) =>
  datasetsClient
    .putFeature({
      datasetId,
      featureId,
      feature,
    })
    .send()
    .then((response) => {
      // const datasetMetadata = response.body;
      return;
      // debug(datasetMetadata);
    });

export const canCreateNewFeature = (features, limit) => {
  const len = Object.keys(features).length;
  // const limit = mapInfo.featureLimit || 100;

  if (len > limit) {
    alert("This map already reached to " + limit + " places");
    return;
  } else {
    return true;
  }
};

import { parse } from "twemoji-parser";
import * as turf from "@turf/turf";
import { GlobalContext, GlobalSubContext, MapStateContext } from "../Context";
import { useNavigation, useNavigationState } from "@react-navigation/native";
import { Expression, SymbolLayerStyle } from "../components/MapComponentStyle";

export const getTwemojiUrl = (emoji) => {
  if (emoji) {
    const emojis = parse(emoji, {});
    const url = emojis[0].url
      // .replace("/v/latest", "")
      .replace("svg", "72x72")
      .replace("svg", "png");

    return url;
  } else {
    return null;
  }
};

export async function getAnyRenderedFeature(
  event,
  mapObject,
  layers?: string[],
  filter?: string[]
) {
  let features: Feature[];
  let feature: Feature;
  if (isWebOS) {
    feature = turf.point([event.lngLat.lng, event.lngLat.lat], {});

    const { x, y } = event.point;
    const dist = 20;

    const leftTop = [x - dist, y - dist];
    const rightBottom = [x + dist, y + dist];
    const bbox = [leftTop, rightBottom];

    features = await mapObject.queryRenderedFeatures(
      //right x, right y, leftx, left y
      bbox,
      { filter, layers }
    );
  } else {
    feature = event;

    const point = event.properties;

    const x = point.screenPointX;
    const y = point.screenPointY;

    const dist = 20;

    const bbox = [y - dist, x + dist, y + dist, x - dist];

    const geojson = await mapObject.queryRenderedFeaturesInRect(
      bbox,
      filter,
      layers
    );
    features = geojson.features;
  }

  // const targetFeature = features.find(
  //   (feature) => feature.geometry.type == "Point"
  // );
  // return features;
  return features[0];
}

export const getStyleJSONAsync = async (styleURL, color) => {
  const splittedId = styleURL.split("/");
  const ownerId = splittedId[3];
  const styleId = splittedId[4];

  const promise = stylesClient
    .getStyle({
      styleId,
      ownerId,
    })
    .send();
  const styleJSON = await promise.then((response) => {
    const style = response.body;

    if (color) {
      const backgroundLayer = style.layers.find(
        (layer) => layer.type == "background"
      );
      // HSLは、色相(Hue)、彩度(Saturation)、輝度(Lightness)
      backgroundLayer["paint"]["background-color"] = color;
    }
    return JSON.stringify(style);
    // setStyleJSON();
    // setEditSettings({ ...editSettings, styleURL });
  });

  return styleJSON;
};

export const setLocalStorageItemAsync = (key, value) => {
  if (isWebOS) {
    return window.localStorage.setItem(key, value);
  } else {
    return SecureStore.setItemAsync(key, value).then((value) => value);
  }
};

export const getLocalStorageItemAsync = async (key) => {
  if (isWebOS) {
    return window.localStorage.getItem(key);
  } else {
    return SecureStore.getItemAsync(key).then((value) => value);
  }
};
export const removeLocalStorageItemAsync = async (key) => {
  if (isWebOS) {
    window.localStorage.removeItem(key);
  } else {
    return SecureStore.deleteItemAsync(key).then((value) => value);
  }
};

//mapbox functions

export const useFitBoundsPadding = () => {
  const isMobile = useIsMobile();
  const pad = 50;
  const initialPadding = { top: pad, bottom: pad, left: pad, right: pad };

  const { width } = useWindowDimensions();
  const left = Math.max((width * 44) / 100, 500);
  // minWidth: 500,
  // width: "44%",

  const padding: PaddingType = isMobile
    ? { ...initialPadding, bottom: minBottomSheetHeight + pad }
    : {
        ...initialPadding,
        // left: panelMinWidth + pad,
        left: left + pad,
      };
  // console.log(padding);

  return padding;
};

import { useMap } from "../components/MapComponents";

type PaddingType =
  | { top: number; bottom: number; left: number; right: number }
  | number;
type BboxType = number[];

export const fitBounds = (
  mapObject: any,
  bounds: number[][],
  padding?:
    | { top: number; bottom: number; left: number; right: number }
    | number
) => {
  if (isWebOS) {
    const option = { padding } || { padding: 20 };
    mapObject.fitBounds(
      bounds,
      option
      // 1000
    );
    // .setPitch(60);
  } else {
    console.log(925, bounds);
    const option = { padding } || { padding: { bottom: 200 } };

    mapObject.fitBounds(
      bounds,
      // { padding: 20 }
      option
      // 1000
    );
  }
};

// initial fitBounds is defined by padding on initialViewState on Mapcomponents
//for marker bounds
export const fitBoundsByBbox = (
  mapObject,
  bbox: number[],
  padding?:
    | { top: number; bottom: number; left: number; right: number }
    | number
) => {
  const option = { padding } || { padding: 10 };

  mapObject?.fitBounds(
    [
      [bbox[0], bbox[1]],
      [bbox[2], bbox[3]],
    ],
    option
    // option
  );
};

export const fitGeojson = (
  mapObject: any,
  geojson: FeatureCollection,
  padding?:
    | { top: number; bottom: number; left: number; right: number }
    | number
) => {
  const bbox = turf.bbox(geojson);
  fitBoundsByBbox(mapObject, bbox, padding);
};

export const flyTo = (
  mapObject: any,
  { center, padding, zoom, pitch, bearing }: EaseToOptions
) => {
  console.log(pitch);

  if (isWebOS) {
    mapObject?.easeTo({
      center,
      zoom: zoom || 14,
      duration: 1000,
      maxDuration: 1000,
      padding,
      pitch: pitch || 0,
      // bearing,
      // animationDuration: 1000,
    });
  } else {
    console.log(981, mapObject);
    mapObject?.flyTo({
      center,
      zoom: 14,
    });
  }
};

export const getCenter = async (mapObject: any) => {
  if (isWebOS) {
    const lngLatCenter = await mapObject.getCenter();
    // console.log(lngLatBounds);
    const { lng, lat } = lngLatCenter;
    return [lng, lat];
  } else {
    return mapObject.getVisibleBounds();
  }
};

export const getZoom = async (mapObject: any) => {
  if (isWebOS) {
    const zoom = await mapObject.getZoom();
    return zoom;
  }
};

export const getBounds = async (mapObject: any) => {
  if (isWebOS) {
    const lngLatBounds = await mapObject.getBounds();
    console.log(lngLatBounds);
    const { _sw, _ne } = lngLatBounds;
    const bounds = [
      [_sw.lng, _sw.lat],
      [_ne.lng, _ne.lat],
    ];
    return bounds;
  } else {
    return mapObject.getVisibleBounds();
  }
};

export const getCameraDefaultSettings = ({ center, bounds }) => {
  let defaultSettings;

  if (bounds) {
    defaultSettings = {
      bounds: {
        ne: bounds[0],
        sw: bounds[1],
      },
    };
  } else if (center) {
    defaultSettings = {
      centerCoordinate: center,
      zoomLevel: 14,
    };
  }
  return defaultSettings;
};

export const useNewPlace = () => {
  const {} = useMapboxFunctions();
  const { navigate } = useNavigation();

  const goToPlace = (feature: Feature) => {
    navigate(screenNames.PlacePanel, { feature });
  };
};

// https://docs.mapbox.com/api/search/geocoding/#data-types
// types: ["region"], //TOkyo prefect, Delaware
// types: ["place"], // Stryn, Oslo, Tokyo
// types: ["postcode"],
// types: ["locality"], //Suginami

export const geocodeAddress = async (req: GeocodeRequest) => {
  const result = await geocodingClient
    .forwardGeocode({
      // types: ["address", "district", "postcode", "region"],
      limit: 10,
      ...req,
    })
    .send()
    .then((response) => {
      const match = response.body;
      // debug(match.features.find(feature => feature.properties));

      return match;
    });

  return result.features;
};

// result does not include
// https://github.com/mapbox/mapbox-sdk-js/blob/main/docs/services.md#forwardgeocode

export const geocode = async (req: GeocodeRequest) => {
  if (!req.query) return;

  // {
  //   query,
  //   limit,
  //   bbox,
  //   language: [i18n.locale],
  //   // proximity: filters?.proximity || undefined,
  //   proximity,
  //   // autocomplete: false,
  //   // https://docs.mapbox.com/api/search/geocoding/#data-types
  //   // types: ["place", "address", "neighborhood", "poi.landmark"],
  //   // types: [
  //   //   "region",
  //   //   "country",
  //   //   "district",
  //   //   "locality",
  //   //   "neighborhood",
  //   //   "place",
  //   //   "address",
  //   // ],
  //   types: ["poi", "poi.landmark"],
  // }

  const result = await geocodingClient
    .forwardGeocode(req)
    .send()
    .then((response) => {
      const match = response.body;
      // debug(match.features.find(feature => feature.properties));

      return match;
    });

  const cleansed = result.features.map((feature) => {
    const {
      bbox,
      text,
      place_name,
      address,
      language,
      place_type,
      properties: { category },
    } = feature;
    // devLog("clean", f);

    const { type, geometry } = feature;

    let properties;

    if (bbox) {
      properties = { title: text, description: place_name, bbox };
    } else {
      properties = { title: text, description: place_name };
    }

    const newFeature = {
      type,
      geometry,
      properties,
    };

    // why nested: properties.properties ????
    // tell meeeee
    newFeature.properties = newFeature.properties;

    return newFeature;
  });

  return cleansed;
  // return result;
};

export const reverseGeocode = async (req: GeocodeRequest) => {
  const result = await geocodingClient
    .reverseGeocode(req)
    .send()
    .then((response) => {
      const match = response.body;
      // debug(match.features.find(feature => feature.properties));

      return match;
    });

  return result;
};
// poi category
//Geocode earth: None???
//Open cage: _type
// Mapbox : properties.category

//used for NewPlace screen
export const getDataFromGeocode = (feature: Feature) => {
  //text: Place name, place_name: Including Address
  console.log(feature);
  const { text, place_name } = feature.properties;
  const poiProperties = feature.properties?.properties;

  const properties = { text, place_name };
  if (poiProperties && poiProperties.category) {
    properties["category"] = poiProperties.category;
  }
  return properties;
};

// the feature includes properties, layer , source, sourceLayer
// polygon => outlined, fill extrusion,
export const getRenderedFeatures = () => {};

export const bboxToBounds = (bbox: [number, number, number, number]) => {
  return [
    [bbox[0], bbox[1]],
    [bbox[2], bbox[3]],
  ];
};

// feature: touched point, targetFeature: rendered feature from OSM
export async function getRenderedPointFeature(event, mapObject) {
  // check symbol on map
  // placeID for existing
  // const labelFilter: Expression = ["any", ["has", "name"]];
  // const markerFilter: Expression = ["any", ["has", "placeID"]];

  const filter: Expression = ["==", ["geometry-type"], "Point"];
  // const filter: Expression = [];
  const dist = 20;
  const getFeatures = async (filter: Expression) => {
    if (isWebOS) {
      // feature = turf.point([event.lngLat.lng, event.lngLat.lat], {});

      const { x, y } = event.point;
      // const bbox =
      const leftTop = [x - dist, y - dist];
      const rightBottom = [x + dist, y + dist];
      const bounds = [leftTop, rightBottom];

      return await mapObject.queryRenderedFeatures(
        //right x, right y, leftx, left y
        bounds,
        { filter }
      );
    } else {
      const point = event.properties;

      const x = point.screenPointX;
      const y = point.screenPointY;

      const bbox = [y - dist, x + dist, y + dist, x - dist];

      const geojson = await mapObject.queryRenderedFeaturesInRect(bbox, filter);
      return geojson.features;
    }
  };

  // if there is symbol on map, get label
  const getLabel = (feature: Feature) => {
    //is there better way to get exact rendered label??
    const deviceLanguage = Localization.locale; // ja-JP
    const properties = feature.properties;

    const names = Object.keys(properties).filter(
      (key) => key.indexOf("name_") > -1
    );
    const languages = names.map((name) => {
      // const lang = name.substring(5);
      const lang = name.substr(5);
      return lang;
    });

    // maybe name on the current map
    const featureLanguage = languages.find((language) =>
      deviceLanguage.includes(language)
    );

    const title = featureLanguage
      ? properties["name_" + featureLanguage]
      : properties.name;

    // const coordinates = targetFeature.geometry?.coordinates;

    // geocode({ query: title, proximity: coordinates, types: ["poi"] })
    //   .then((features) => console.log(features))
    //   .catch((err) => console.log(err));

    return title;
  };

  const features = await getFeatures(filter);
  devLog("get features", features);
  // mapID, placeID or
  // Mapbox vt feature include
  const isCompositeFeature = (feature: any) => feature.source == "composite";

  const isIncludingMarkers = features.find((f) => !isCompositeFeature(f));
  if (isIncludingMarkers) return;

  const feature = features[0];

  if (feature) {
    const properties = feature ? { title: getLabel(feature) } : {};
    feature.properties = properties;
    return feature;
  } else {
    const point = turf.point([event.lngLat.lng, event.lngLat.lat], {});
    return point;
  }
}

export const fitFeature = (feature: Feature) => {
  const { bbox, bounds } = feature.properties;

  // if()
};

export const useMapboxFunctions = (id?: string) => {
  // const mapObject = useMap().current;
  const mapObject = useMap()[id || mapViewID];
  const padding = useFitBoundsPadding();

  if (mapObject) {
    return {
      flyTo: (option: EaseToOptions) =>
        flyTo(mapObject, { padding, ...option }),
      fitBoundsByBbox: (bbox: number[]) =>
        fitBoundsByBbox(mapObject, bbox, padding),
      fitBounds: (bounds: number[][]) => fitBounds(mapObject, bounds, padding),
      fitGeojson: (geojson: FeatureCollection) =>
        fitGeojson(mapObject, geojson, padding),
      getRenderedPointFeature: (event: any) =>
        getRenderedPointFeature(event, mapObject),
      getBounds: () => getBounds(mapObject),
      getCenter: () => getCenter(mapObject),
      getZoom: () => getZoom(mapObject),
      // fitFeature: (feature:Feature ) =>
    };
  } else {
    return {};
  }
};

//   const credential = firebase.auth.GoogleAuthProvider.credential(id_token);
export const useSignInWithCredential = () => {
  const { setLogin, setUserInfo } = useContext(GlobalContext);
  const navigation = useNavigation();

  const callback = async (
    credential: firebase.auth.OAuthCredential,
    userInfo?: { email: string; givenName: string; familyName: string }
  ) => {
    const result = await signInWithCredential(credential);

    const { uid, email } = result.user;
    await setLocalStorageItemAsync("uid", uid);
    await setLocalStorageItemAsync("email", email);

    //apple login gives given name and family name only at first time.
    if (userInfo?.givenName) {
      const { givenName, familyName } = userInfo;
      await setLocalStorageItemAsync("givenName", givenName);
      await setLocalStorageItemAsync("familyName", familyName);
    } else if (result.additionalUserInfo?.profile) {
      try {
        const { profile } = result.additionalUserInfo;

        let givenName = profile.given_name;
        let familyName = profile.family_name;

        await setLocalStorageItemAsync("givenName", givenName);
        await setLocalStorageItemAsync("familyName", familyName);
      } catch {}
    }

    const res = await loginWithUid({
      uid,
    });

    console.log(res, navigation, setLogin, setUserInfo);
    // debug(res.type);
    loginAction({ res, navigation, setLogin, setUserInfo });
  };

  return { signInWithCredential: callback };
};

// https://stripe.com/docs/currencies
export const noDecimals = [
  "BIF",
  "CLP",
  "DJF",
  "GNF",
  "JPY",
  "KMF",
  "KRW",
  "MGA",
  "PYG",
  "RWF",
  "UGX",
  "VND",
  "VUV",
  "XAF",
  "XOF",
  "XPF",
];

export const threeDecimals = ["BHD", "JOD", "KWD", "OMR", "TND"];

export const currencySymbols = { jpy: "¥", usd: "$", eur: "€", cny: "¥" };

// amount 10000 JPY = 10000 USD
export const useConvertedAmount = (
  amount: number,
  originalCurrency: string,
  targetCurrency: string
) => {
  const { appConfig } = useContext(GlobalContext);
  const currencies: CurrencyJsonType = appConfig.currencies;

  const { rates } = currencies;

  // console.log(rates);

  const convertedAmount =
    (amount / rates[originalCurrency]) * rates[targetCurrency];

  const capitalCurrency = targetCurrency.toUpperCase();
  const isThreeDecimal = threeDecimals.includes(capitalCurrency);
  const isNoDecimal = noDecimals.includes(capitalCurrency);

  let currencyAmount = convertedAmount;

  if (isThreeDecimal) {
    currencyAmount = convertedAmount * 1000;
  } else if (isNoDecimal) {
    currencyAmount = convertedAmount;
  } else {
    currencyAmount = convertedAmount * 100;
  }

  // return convertedAmount;
  return currencyAmount;
};

// amount 10000 JPY = 100 USD

export const getDecimalAmount = (amount: number, currency: string) => {
  const capitalCurrency = currency.toUpperCase();

  const isThreeDecimal = threeDecimals.includes(capitalCurrency);
  const isNoDecimal = noDecimals.includes(capitalCurrency);
  let currencyAmount = amount;

  if (isThreeDecimal) {
    currencyAmount = amount / 1000;
  } else if (isNoDecimal) {
    currencyAmount = amount;
  } else {
    currencyAmount = amount / 100;
  }

  return currencyAmount;
};

// stripe amount: 12345
// purpose to show price aproximately like $143 USD
export const getCurrency = (
  amount: number,
  currency: string,
  locale: string
) => {
  const capitalCurrency = currency.toUpperCase();

  const currencyAmount = getDecimalAmount(amount, currency);
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat

  const options = {
    style: "currency",
    // style: "decimal",
    currency: capitalCurrency,
    // maximumFractionDigits: 1,
    maximumSignificantDigits: 3,
  };

  return {
    value: Intl.NumberFormat(locale, options).format(currencyAmount),
    symbol: "",
    capitalCurrency,
    parts: Intl.NumberFormat(locale, options).formatToParts(currencyAmount),
  };

  // ISO 4217
  // const currencySymbols = { euro: "€", jpy: "円", usd: "$" };

  // const region = Localization.region;
  // const regionToCurrency = {
  //   US: "usd",
  //   JP: "jpy",
  //   FR: "eur",
  // };

  // const localizationCurrency = Localization.currency;
  // const currencyToCurrency = {
  //   USD: "usd",
  //   JPY: "jpy",
  //   EUR: "eur",
  // };

  // const language = i18n.locale;
  // const languageToCurrency = {
  //   en: "usd",
  //   ja: "jpy",
  //   fr: "eur",
  // };

  // const currency =
  //   currencyToCurrency[localizationCurrency] ||
  //   regionToCurrency[region] ||
  //   languageToCurrency[language] ||
  //   "$";

  // return { name: currency, symbol: currencySymbols[currency] };
};

const useMapStyleProps = (mapInfo) => {
  const { backgroundColor, styleURL } = mapInfo.mapStyle;
  const { mapID } = mapInfo;

  const { userInfo, userMapView, userMaps } = React.useContext(GlobalContext);

  const styleJSON = backgroundColor ? userMaps[mapID].styleJSON : undefined;

  return backgroundColor ? { styleJSON } : { styleURL };
};

//maps, places,posts, print, style, join
//used in EditMapSettings: style, TextScreen: post and places, EditplaceONMap: places, HeaderRightComponent: print, postCard: comment
export const getUserLimit = (userInfo, valueType: limitNameKeyType) => {
  //inherit from subscriptions on firestore
  const user = userInfo?.subscriptions?.user;

  const plan = user?.plan;
  const currentPeriodEnd = user?.currentPeriodEnd;

  // debug(currentPeriodEnd);

  let planType;
  if (plan == "admin") {
    planType = "admin";
  } else if (currentPeriodEnd > Date.now()) {
    planType = plan;
  } else {
    planType = "a";
  }

  const limits = userLimits[planType][valueType];

  return limits;
};

export const getCurrenciesJson = async () => {
  const url =
    "https://s3.us-west-1.wasabisys.com/mapkid/assets/currencies.json";

  const data = await s3
    .getObject({ Bucket: "mapkid", Key: "assets/currencies.json" })
    .promise();

  if (data?.Body) {
    const obj = JSON.parse(data.Body);

    return obj;
  }
};

// JPY, AED
export const exchangeCurrency = (
  value: number,
  input: string,
  output: string
) => {
  const json: any = {
    rates: {
      AED: 3.6731,
      AFN: 87.861117,
      ALL: 114.227964,
      AMD: 406.227774,
      ANG: 1.804948,
    },
  };

  const inputRate = json.rates[input];
  const outputRate = json.rates[output];

  return (value / inputRate) * outputRate;
};

export const useMapAuth = (mapID: string) => {
  const { userMaps, userInfo, users, caches, login } =
    React.useContext(GlobalContext);

  const mapInfo = userMaps[mapID]?.info;

  const isHost = userInfo?.uid == mapInfo?.host;
  const isMember = isHost || mapInfo?.members?.includes(userInfo?.uid);

  return { isHost, isMember };
};

import * as WebBrowser from "expo-web-browser";

export const activateAuthPopup = () => {
  //if it is done in google button, login popup will open another site....
  //we should write here
  if (isWebOS) {
    WebBrowser.maybeCompleteAuthSession();
  }
};

export const setUpLanguage = () => {
  const langs = ["ja", "fr", "en"];
  const lang = langs.find((lang) => Localization.locale.indexOf(lang) > -1);
  // debug(lang);
  i18n.locale = lang || "en";
  i18n.fallbacks = true;
};

import * as Sentry from "sentry-expo";
import {
  BBox,
  Feature,
  featureCollection,
  FeatureCollection,
  getCoord,
  getCoords,
  getType,
  Point,
  point,
  round,
} from "@turf/turf";
// Sentry.Native.setUser({ email: "john.doe@example.com" });
// Sentry.Native.captureException(new Error("error!"));

export const setUpSentry = () => {
  // Sentry.Native.setRelease(Constants.manifest.revisionId || "DEV");
  Sentry.init({
    dsn: "https://efd8e60ae582445eaaaa8c30f64dc65e@o906385.ingest.sentry.io/5981837",
    enableInExpoDevelopment: true,
    debug: true,
  });
};

// export const getMorePosts = (
//   mapID: string,
//   date: number,
//   successProces: (morePosts: any[]) => void,
//   failProcess: () => void
// ) =>
//   mapRef(mapID)
//     .collection("posts")
//     .orderBy("updatedAt", "desc")
//     .startAfter(date)
//     .limit(10)
//     .get()
//     .then((snapshot) => {
//       if (snapshot.docs.length == 0) {
//         failProcess();
//         // return setNoMorePosts(true);
//       }

//       const nextPosts = snapshot.docs.map((doc) => {
//         return doc.data();
//       });

//       successProces(nextPosts);

//       // setPosts([...posts, ...nextPosts]);
//     });

export const sortObject = (obj: any, sortKey: string) => {
  const arr = Object.values(obj).sort((a: any, b: any) => {
    const first = a[sortKey];
    const second = b[sortKey];

    if (first > second) return -1;
    if (first < second) return 1;
    return 0;
  });
  return arr;
};

// import * as Geofirestore from "geofirestore";
// import { EditLineType, EditPointType } from "./components/EditFeaturePanel";
import { useBottomSheet } from "@gorhom/bottom-sheet";
import {
  CheckoutSessionDataType,
  CurrencyJsonType,
  DatesType,
  LayerStateType,
  MediaType,
  ReviewType,
} from "../types/main";

import {
  BottomSheetMethods,
  BottomSheetVariables,
} from "@gorhom/bottom-sheet/lib/typescript/types";
import { EaseToOptions, FlyToOptions } from "mapbox-gl";
import Stripe from "stripe";
import polylabel from "polylabel";
import {
  authUser,
  signInWithCredential,
  deletePayoutAccount as deletePayout,
  sendSignInLinkToEmailFirebase,
} from "./firebase";

if (getApps().length === 0) {
  // __DEV__ && (window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = true);
  initializeApp(firebaseConfig);
}

import { datasetsClient } from "./mapbox";
import { getApps, initializeApp } from "firebase/app";
import { getRandomID } from "./tools";

// const geocollection = geoFirestore.collection(mapsCollectionRef.path);

// useIsPortrait <=> useIsLandscape
export const useIsMobile = () => {
  const { width, height, fontScale, scale } = useWindowDimensions();
  // fontScale: Galaxy - 4, iphone SE - 2, iPad air - 2, surface pro - 2
  // width: iPad air - 820, iPad mini - 768
  // const isSmallScreen = width < 600;
  const isSmallScreen = width < 800;
  // const isPortrait = width / height < 0.7;
  // console.log(width, height, fontScale, scale, isPortrait);
  const isMobile = isSmallScreen;
  return isMobile;
};

type RootScreensType = "Home" | "Main" | "PrivacyPolicy";
type MapScreensType = "Search" | "Map";
type MapPanelScreensType =
  | "Places"
  | "Edit"
  | "Settings"
  | "Home"
  | "PlaceDetail";

export const useCustomNavigation = () => {
  const navigation = useNavigation();

  const resetNavigation = (name: string, params?: any) =>
    navigation.reset({
      index: 0,
      routes: [{ name, params }],
    });

  // const navigate = (
  //   name: RootScreensType | MapScreensType | MapPanelScreensType,
  //   params?: any
  // ) => originalNavigaition.navigate(name, params);

  const navigateInMap = (screen: string, params: any) =>
    navigation.navigate(screenNames.Main, {
      screen,
      params,
    });

  return { resetNavigation, navigateInMap };
};

const fitToCoordinates = async (mapObject, geojson: FeatureCollection) => {
  const features = Object.values(geojson.features);

  if (features?.length > 0) {
    const bbox = turf.bbox({
      type: "FeatureCollection",
      features: features,
    });

    const bounds = [
      [bbox[2], bbox[3]],
      [bbox[0], bbox[1]],
    ];
    fitBounds(mapObject, bounds);
  }
};

export const mapBboxToPointsGeojson = (
  data: { title: string; mapID: string; bbox: BBox }[]
) => {
  const features = [];

  data.map((item) => {
    const { title, bbox, mapID } = item;

    if (!title) {
      return;
    }
    const polygon = turf.bboxPolygon(bbox);
    const line: Feature = turf.polygonToLine(polygon);

    const coords = getCoords(line);
    // console.log(coords);
    coords.pop();

    coords.forEach((coord) => {
      console.log(coord);
      const pt = point(coord);
      pt.properties["title"] = title;
      pt.properties["mapID"] = mapID;
      features.push(pt);
    });
    // line.geometry
    // const pt = point(center);

    // pt.properties["title"] = title;
    // pt.properties["bbox"] = bbox;
    // pt.properties["mapID"] = key;

    // features.push(pt);
  });

  return featureCollection(features);
};

//get current route in global navigation container or undefined
export const useCurrentRoute = () => {
  //Drawer
  const rootRoutes = useNavigationState((state) => state?.routes);
  if (!rootRoutes) return;
  // rootRoutes[rootRoutes.length]

  const mainRoute = rootRoutes.find(
    (route) => route.name == drawerScreenNames.Main
  );

  const stackRoutes = mainRoute?.state?.routes;

  if (!stackRoutes) {
    return mainRoute;
  }

  const lastIndex = stackRoutes?.length - 1;
  // const mapRoute = stackRoutes?.find((route) => route.name =="Map" );
  const mapRoute = stackRoutes[lastIndex];

  if (!mapRoute) {
    return undefined;
  }
  return mapRoute;
};

export const useMapFunctions = () => {
  const { userMaps, setUserMaps } = React.useContext(GlobalContext);

  const getMapInfo = (mapID: string) => {
    return userMaps[mapID]?.info;
  };

  const getMapFeatures = (mapID: string) => {
    return userMaps[mapID]?.features;
  };

  const getMapFeature = (mapID: string, featureID: string) => {
    return userMaps[mapID]?.features[featureID];
  };

  const setMapFeature = (mapID: string, featureID: string, data: any) => {
    if (!mapID || !featureID) return;
    const mapFeatures = userMaps[mapID].features;
    mapFeatures[featureID] = data;
    setUserMaps({ ...userMaps });
  };

  return { getMapInfo, getMapFeatures, getMapFeature, setMapFeature };
};

export const useMapInfo = (mapID: string) => {
  const { userMaps } = useContext(GlobalContext);
  const mapInfo = userMaps[mapID]?.info;
  return mapInfo;
};

export const useMapFeatures = (mapID: string) => {
  const { userMaps } = useContext(GlobalContext);
  const mapInfo = userMaps[mapID]?.features;
  return mapInfo;
};

export const useMapFeature = (mapID: string, featureID: string) => {
  const { userMaps } = React.useContext(GlobalContext);
  return userMaps[mapID].features[featureID];
};

export const useMapPlace = (mapID: string, placeID: string) => {
  const { userMaps } = React.useContext(GlobalContext);
  return userMaps[mapID].places[placeID];
};

export const setTestUserMaps = () => {
  const { userMaps, setUserMaps } = React.useContext(GlobalContext);
};

//userMaps is stored data, we use geojson
export const useGeojsonFromUserMaps = (mapID: string) => {
  const { userMaps } = useContext(GlobalContext);
  if (!userMaps[mapID]?.features) return null;

  const features = Object.values(userMaps[mapID].features);

  //there bugs when creating places....
  const filtered = features.filter((feature) => feature?.geometry?.coordinates);

  const geojson = {
    type: "FeatureCollection",
    features: filtered,
  };

  return geojson;
};

export const getBboxFromGeojson = (geojson: FeatureCollection) =>
  turf.bbox(geojson);

export function useDebounce(value, delay) {
  // debounce の対象 state と setter
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // delay 後 debounce の対象 state をアップデート
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // 次の effect が実行される直前に timer キャンセル
    return () => {
      clearTimeout(timer);
    };

    // value、delay がアップデートするたびに effect 実行
  }, [value, delay]);

  // 最終的にアップデートされた state をリターン
  return debouncedValue;
}

export const getSubscription = async (uid) => {
  const currentPeriodEnd = "current_period_end";

  const subscriptionsRef = appRef
    .collection("subscription")
    .doc("v1/customers/" + uid)
    .collection("subscriptions");

  const data = await subscriptionsRef
    // .where("status", "==", "active")
    .where("status", "in", ["active", "trialing"])
    .where(currentPeriodEnd, ">", new Date())
    .get()
    .then((snapshot) => {
      // debug("ren");
      const isEmpty = snapshot.empty;
      if (isEmpty) {
        // return setData("No");
        return "No";
      }

      let data;
      snapshot.docs.forEach((doc) => {
        data = doc.data();
      });

      return data;
      // debug(data);
      // setData(data);
    })
    .catch((err) => debug(err));

  return data;
};

export const emulate = () => {
  // const isActive = false;
  const isActive = true;
  // console.warn("emu");
  const isEmulating = isActive && window.location.hostname === "localhost";
  if (isEmulating) {
    // const auth = getAuth()
    console.log(isEmulating);
    // useEmulator("localhost", 19004);
    // useEmulator("localhost", 19005);

    // connectAuthEmulator(auth, 'http://localhost:9099')
    // const functions = getFunctions(getApp())
    // connectFunctionsEmulator(functions, 'localhost', 5001)
    // const db = getFirestore()
    // connectFirestoreEmulator(db, 'localhost', 8080)
  }
};

export const useLoginStatus = () => {
  const { userInfo } = useContext(GlobalContext);

  const { currentUser } = authUser;
  // console.log(currentUser);
  const alreadyLogined = currentUser?.email && userInfo?.userName;
  // debug(currentUser.)
  const anonymouslyLogined = currentUser && currentUser?.isAnonymous;

  if (alreadyLogined) {
    return "sign-in";
  } else if (anonymouslyLogined) {
    return "anonymous";
  } else {
    return null;
  }
};

// //if we use useBottomSheet without Bottomsheet, it crashes.
// //useEffect functions
// export const useMobileBottomSheetEffect = (key: "expand" | "collapse") => {
//   const isMobile = useIsMobile();

//   let func;

//   if (isMobile) {
//     func = useBottomSheet()[key];
//   }

//   useEffect(() => {
//     if (isMobile) {
//       func && func();
//     }
//   }, [isMobile]);
// };

type MapDataType = {
  info: object;
  features: object;
  posts: object;
  places: object;
};

//depend on userMaps, userInfo
export const useInitializeMapEffect = (
  mapID: string,
  onLoad?: (data: MapDataType) => void
) => {
  const { login, userMaps, userInfo, setUserMaps, appConfig, users } =
    React.useContext(GlobalContext);

  // const { setMapStyleJSON } = useMapStyleJSON();

  //if not yet load, get mapInfo and features

  useEffect(() => {
    (async () => {
      if (userMaps[mapID]?.features) return;

      const mapInfo = await getMapInfo(mapID);

      userMaps[mapID] = {
        info: mapInfo,
        // ...mapInfo,
        // features: featuresDict,
        features: {},
        places: {},
        messages: {},
        posts: {},
        // styleJSON: {},
      };
      // mapInfo;

      console.log(mapInfo);

      const uid = mapInfo.host;

      if (!users[uid]) {
        const userInfo = await getUserData(uid, "public");
        users[uid] = userInfo;
      }
      const mapBackgroundColor = mapInfo.mapStyle?.backgroundColor;

      // if (mapBackgroundColor) {
      //   await setMapStyleJSON(mapInfo);
      // }

      const datasetId = mapInfo.datasetId;

      // Responses from the Datasets API set both the device and CDN TTLs to 5 minutes.
      // https://docs.mapbox.com/api/maps/datasets/#retrieve-a-feature
      // cache json...

      const listFeatures = datasetsClient.listFeatures({ datasetId });

      // listFeatures.headers = { "cache-control": "max-age=10" };
      // listFeatures.

      const features = await listFeatures
        .send()
        .then((response) => {
          // console.log(response);
          const { features } = response.body;

          return features;

          // //up to 5MB in each http request, so maybe there is nex page
          // if(response.hasNextPage()){
          //   response.nextPage
          //   const nextPageReq = response.nextPage();
          //   nextPageReq.send().then(..);
          // }
        })
        .catch((err) => {
          alert("Problem happens while requesting data...");
          //hide Post button
        });

      features.forEach((feature) => {
        console.log(feature);
        feature.properties["placeID"] = feature.id;
        userMaps[mapID].features[feature.id] = feature;
      });

      if (onLoad) onLoad(userMaps[mapID]);
    })();
  }, []);
};

export const useMapStyleJSON = () => {
  const { login, userMaps, userInfo, setUserMaps, appConfig } =
    React.useContext(GlobalContext);

  const setMapStyleJSON = async (mapInfo) => {
    // const mapInfo = userMaps[mapID].info;
    const { styleName, backgroundColor } = mapInfo.mapStyle;

    const styleURL = mapboxStyleUrls[styleName] || mapboxStyleUrls.Street;
    const styleJSON = await getStyleJSONAsync(styleURL, backgroundColor);
    const { mapID } = mapInfo;
    userMaps[mapID]["styleJSON"] = styleJSON;
  };

  return { setMapStyleJSON };
};

// new: never subscribed
// refresh: re subscribe after subscription is cancell
// active: is subscribing

export const useSubscriptionStatus = () => {
  //status in firestore can be "incomplete" or "active"
  const [status, setStatus] = useState<
    "new" | "refresh" | "active" | undefined
  >();

  const currentPeriodEnd = "current_period_end";

  const { userInfo } = useContext(GlobalContext);
  const uid = userInfo?.uid;
  useEffect(() => {
    (async () => {
      // debug(uid);

      if (uid) {
        // debug("ren");
        const subscriptionsRef = appRef
          .collection("subscription")
          .doc("v1/customers/" + uid)
          .collection("subscriptions");

        subscriptionsRef
          .where("status", "==", "active")
          .where(currentPeriodEnd, ">", new Date())
          .get()
          .then((snapshot) => {
            const isEmpty = snapshot.empty;
            if (isEmpty) {
              return setStatus("new");
            }

            let data;
            snapshot.docs.forEach((doc) => {
              data = doc.data();
            });

            if (data?.status == "active") {
              setStatus("active");
            } else {
              //if status is "incomplete" or
              setStatus("refresh");
            }
          })
          .catch((err) => debug(err));
      }
      // debug(userInfo.uid);
    })();
  }, [uid]);

  return status;
};

export const createRGBColor = (r, g, b, a) => `rgba(${r}, ${g}, ${b}, ${a})`;

export const useCalling = () => {
  const { videoChat } = useContext(GlobalSubContext);

  // const isSyncing = videoChat?.calling && mapID == videoChat?.mapID;
};

// if use useBottomSheet out of bottom sheet, it omit error
// so we check at first
export const useCustomBottomSheet = () => {
  const isMobile = useIsMobile();
  let method: (BottomSheetMethods & BottomSheetVariables) | {};

  try {
    // method = {};
    if (isMobile) {
      method = useBottomSheet();
    } else {
      method = {};
    }
  } catch (error) {
    method = {};
  }

  return method;
};

// export const createDynamicSessionData = httpsCallable(
//   functions,
//   "createDynamicSessionData"
// );

// const price = isLiveMode
// ? "price_1KrDOpLPU8HhQ6W7pdRCmZeJ"
// : "price_1IruefLPU8HhQ6W7QhiAmZgJ";

// const checkoutUrl = isLiveMode
//   ? "https://mapnom.com/Payment"
//   : "https://localhost:19006/Payment";
// const successUrl = checkoutUrl + "/success/" + price;

// const cancelUrl = checkoutUrl + "/cancel/" + price;
const cancelUrl = __DEV__
  ? "https://localhost:19006/Subscription"
  : "https://mapnom.com/Subscription";

export const createDynamicSessionData = ({
  mapID,
  currency,
  unit_amount,
  name,
  description,
}: CheckoutSessionDataType) => {
  // const storeData = { currency: "eur", unit_amount: 500 };

  // const accountDoc = await accountRef(host).get();
  // const { id } = accountDoc.data();

  // const { currency, unit_amount } = storeData;

  const price_data: Stripe.Checkout.SessionCreateParams.LineItem.PriceData = {
    product_data: {
      name,
      description:
        description + " You don't pay before the partner accept your request.",
    },
    // product_data: { name, description },
    currency,
    unit_amount,
    recurring: { interval: "day", interval_count: 6 },
    // tax_behavior: "inclusive",
  };

  const line_items: Stripe.Checkout.SessionCreateParams.LineItem[] = [
    {
      price_data,
      quantity: 1,
    },
  ];

  const payment_intent_data: Stripe.Checkout.SessionCreateParams.PaymentIntentData =
    {
      capture_method: "manual", // only Auth now, capture later  https://stripe.com/docs/payments/place-a-hold-on-a-payment-method?locale=ja-JP
      transfer_group: mapID + "_" + Date.now(), // refer in
      // metadata: { hostId: host },
    };

  const data: Stripe.Checkout.SessionCreateParams = {
    success_url: webSiteURL + "/map_home/" + mapID,
    cancel_url: webSiteURL + "/cancel/" + mapID,
    line_items,
    // mode: "payment",
    mode: "subscription",
    // subscription_data: {"items": [{""}] },
    payment_intent_data,
    // automatic_tax: { enabled: true },
  };

  // data.subscription_data.

  return data;
};

// export const getMaps = (userInfo) => {
//   // Object.values(userInfo.maps).map()

//   const { hostMaps, joinMaps, bookmarks } = userInfo;
//   const maps = [...hostMaps, ...joinMaps, ...bookmarks];
//   return maps;
// };

// hyphen: ISO 8601 '2021-10-13T14:00:00+0900'
// Date: Date Object by (1999, 02, 03)
// const now = new Date('2021-12-25 00:00:00');
// milisecond: Unix time
// hyphenDate: yyyy-mm-dd by getHyphenDate

// date = new Date(1999, 11, 31);

// date.toUTCString: Fri, 27 Jan 2023 02:18:01 GMT
// date.toDateString: Fri Jan 27 2023
// date.toTimeString: 11:18:01 GMT+0900 (日本標準時)
// date.toJSON: 2023-01-27T02:18:01.835Z
// date.toLocaleString: 2023/1/27 11:18:01
// date.toISOString: 2023-01-27T02:22:28.929Z
//  toLocaleDateString(): 2023/1/27
// toLocaleDateString('de-DE', options): Donnerstag, 20. Dezember 2012
// getTime: 1328022000000

// react-native-calendar: hypehn
// post card time: milisecond

export const getHyphenDate = (date: Date) => {
  // console.log(date.toLocaleDateString().replace(/[/]/g, "-"));

  return date.toISOString().split("T")[0];
};

export const getDateFromHyphenDate = (hyphenDate: string) => {
  const splitted = hyphenDate
    .split("-")
    .map((str, index) => (index == 1 ? Number(str) - 1 : Number(str)));
  const date = new Date(splitted[0], splitted[1], splitted[2]);

  return date;
};

// from : 2022-01-02
// [2022-01-02, 2022-01-03]
// export const getDatesList = (from: string, to: string, days: number) => {
export const getDatesList = (from: string, to: string) => {
  const startDate = getDateFromHyphenDate(from);

  const endDate = getDateFromHyphenDate(to);

  const time = endDate - startDate;
  const days = parseInt(time / 1000 / 60 / 60 / 24) + 1;

  const dates = [];

  for (let day = 1; day < days + 1; day++) {
    const addedDay = startDate.getDate() + day;

    const date = new Date(startDate);
    date.setDate(addedDay);
    dates.push(getHyphenDate(date));
  }

  return dates;
};

export const getDateByLanguage = (date: Date) => {
  // const date = new Date(timeInMs);
  const day = date.getDate();
  const int = new Intl.DateTimeFormat("en-US", { month: "long" });
  const month = int.format(date);
  return month.slice(0, 3) + " " + day;
};

export function showTime(timeInMs) {
  const now = Date.now();
  const passedTime = now - timeInMs;
  const seconds = Math.round(passedTime / 1000);
  const passedHours = seconds / 60 / 60;

  //over 24 hours ago
  if (passedHours > 24) {
    const date = new Date(timeInMs);
    return getDateByLanguage(date);
  } else if (24 > passedHours && passedHours > 1) {
    return Math.round(passedHours) + "h";
  } else {
    const minutes = Math.round(passedHours * 60);
    if (minutes > 0) {
      return minutes + "mins";
    } else {
      return "1min";
    }
  }
}

export const setFocusedFeature = (mapID: string, placeID: string) => {
  // const geojson = useGeojsonFromUserMaps(mapID);
  const { updateFeatureProperties, layersState } = useContext(MapStateContext);

  const layerState: LayerStateType = layersState.find(
    (layer) => layer.id == mapID
  );
  const { focused } = layerState.options;

  const newFocused = focused ? focused.push(placeID) : [placeID];
  layerState.options = { ...layersState.options, focused: newFocused };
  // const focused = {focused:  }
  // updateLayerOptions(layerID, placeID)
};

export const setGroupedPlaces = (mapID: string, placeIDs: string[]) => {
  const geojson = useGeojsonFromUserMaps(mapID);
  const { setLayersState } = useContext(MapStateContext);
  const isInPlace: Expression = ["in", ["get", "placeID"], placeIDs];

  const status = {};
  placeIDs.forEach((placeID) => (status[placeID] = "focus"));

  geojson?.features.forEach((feature) => {
    const placeID = feature.properties?.placeID;

    if (placeIDs.includes(placeID)) {
      status[placeID] = "focus";
    } else {
      status[placeID] = "mask";
    }
  });

  const layer: LayerStateType = {
    data: geojson,
    id: "group",
    type: "map",
  };

  // const maskedLayer: LayerStateType = {
  //   data: geojson,
  //   id: `mapID-mask`,
  //   type: "mask",
  //   // options: { filter: ["!", isInPlace] },
  // };
  // const focusedLayer: LayerStateType = {
  //   data: geojson,
  //   id: `mapID-focus`,
  //   type: "map",
  //   // options: { filter: isInPlace },
  // };
  // setLayersState([maskedLayer, focusedLayer]);
};

// every = "all"
// some = "any" or "in" for array

// or = "coalesce"
//  if else = "case"
//  find = "match"

//used for Markers for Search maps
// hide by zoom
export const getBboxFilter = (props?: { padding?: number; length: number }) => {
  // https://docs.mapbox.com/help/glossary/zoom-level/

  const getZoomAlpha: Expression = ["^", 0.5, ["zoom"]];
  const coordByPix = 360 / 512;
  const getClusterlength: Expression = [
    "*",
    coordByPix,
    getZoomAlpha,
    props?.length || 40,
  ];

  const getBbox = (index: number) => ["at", index, ["get", "bbox"]];

  const indexes = [
    [0, 2],
    [1, 3],
  ];

  // const lengthes = indexes.map((item) => {
  //   const getW: Expression = getBbox(0);
  //   const getE: Expression = getBbox(2);
  //   const getLength: Expression = ["-", getE, getW];
  //   return getLength;
  // });

  const getW: Expression = getBbox(0);
  const getE: Expression = getBbox(2);
  const getLonLength: Expression = ["-", getE, getW];

  const getS: Expression = getBbox(1);
  const getN: Expression = getBbox(3);
  const getLatLength: Expression = ["-", getS, getN]; //coord

  const inLon: Expression = [">", getClusterlength, getLonLength];
  const inLat: Expression = [">", getClusterlength, getLatLength];

  const bboxFilter: Expression = ["all", inLon, inLat];
  return bboxFilter;
};

export const getLineDasharray = ({
  propertyName,
  arrays,
}: {
  propertyName: string;
  arrays: { [value: string]: number[] }[];
}) => [
  "match",
  ["get", propertyName],
  // "route",
  // ["literal", [2, 2, 6, 2]],
  // ...arrays.map(obj => )
  "area",
  ["literal", [2, 2, 6, 2]],
  ["literal", [1, 0]],
];

export const getPolyLabels = (features: Feature[]) =>
  features
    .filter((feature) => getType(feature) == "Polygon")
    .map((feature) => {
      const bbox = turf.bbox(feature);

      // const point = turf.centroid(feature, { properties: { bbox: bboxB } });

      // how can we get a center of polygon in view? polylabel
      // https://github.com/mapbox/polylabel
      const [x, y] = polylabel(getCoords(feature), 50);
      const point0 = point([x, y], { ...feature.properties, bbox });
      return point0;
    });

export const requestMediaPermissions = async () => {
  if (Platform.OS !== "web") {
    {
      // alert(JSON.stringify("permission"))
      const { status } =
        await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (status !== "granted") {
        alert("Sorry, we need camera roll permissions to make this work!");
      }
    }

    {
      const { status } = await ImagePicker.requestCameraPermissionsAsync();
      if (status !== "granted") {
        alert("Sorry, we need camera roll permissions to make this work!");
      }
    }
    {
      const { status } = await MediaLibrary.requestPermissionsAsync();
      status !== "granted" &&
        alert("Sorry, we need camera roll permissions to make this work!");
    }
  }
};

export const getSearchResults = ({
  category,
  time,
  language,
  coordinate,
  period,
}: {
  category?: string[];
  time?: string[]; // morning, night
  language?: string[];
  coordinate?: [number, number];
  period: { from: string; to: string };
}) => {
  let queryInstance = firebase.firestore().collection("search");

  const fields = [
    {
      field: "category",
      value: category,
    },
    {
      field: "time",
      value: time,
    },
    {
      field: "language",
      value: language,
    },
    {
      field: "coodinate",
      value: language,
    },
    {
      field: "period",
      value: period,
    },
  ];
  // const fieldNames = ["category", "time", "language", "coordinate"];

  fields.forEach(({ field, value }) => {
    if (!value) return;
    if (field == "period") {
      const { from, to } = period;
      // ???????????
      return (queryInstance = queryInstance.where(
        "from",
        "array-contains",
        value
      ));
    }

    queryInstance = queryInstance.where(field, "array-contains", value);
  });

  return queryInstance.get().then((snapshot) => {
    if (snapshot.empty) return null;
    const res = [];

    snapshot.docs.forEach((doc) => {
      res.push(doc.data());
    });

    return res;
  });
};

export const deletePayoutAccount = async (uid: string, accountID: string) => {
  const callback = deletePayout;
  const res = await callback({ uid, accountID });
  return res;
};

// line-join => lineJoin
export function toCapitalCase(str: string) {
  return str
    .split("-")
    .map((str, index) => {
      if (index == 0) return str;
      const first = str[0];
      const replaced = str.replace(first, first.toUpperCase());
      return replaced;
    })
    .join("");
  // .split(/(?=[A-Z])/)
  // .join("-")
  // .toLowerCase();
}

export const sendSignInLinkToEmail = (email: string) => {
  const actionCodeSettings = isWebOS
    ? {
        // / URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        // url: "https://www.example.com/finishSignUp?cartId=1234",

        url: __DEV__ ? "https://localhost:19006" + prefix : webSiteURL + prefix,
        // url: isWebOS
        //   ? "https://app.mapkid.info/EmailVerification"
        //   : "https://mapkid.info/EmailVerification",
        // This must be true.
        handleCodeInApp: true,
        dynamicLinkDomain,
      }
    : {
        url: appDeepLinkURL + prefix,
        iOS: {
          bundleId: "com.mapkid.rounds",
        },
        android: {
          packageName: "com.mapkid.rounds4",
          installApp: true,
          // minimumVersion: "12",
        },
        handleCodeInApp: true,
        dynamicLinkDomain,
      };

  return sendSignInLinkToEmailFirebase(email, actionCodeSettings);
};

export const useUserMaps = () => {
  const { userMaps, setUserMaps, setLayersState } = useContext(GlobalContext);

  // create new features object
  const setFeatures = (
    mapID: string,
    featuresObj: object,
    callback?: (featuresObj: object) => void
  ) => {
    setUserMaps((userMaps) => {
      const newFeaturesObj = { ...userMaps[mapID].features, ...featuresObj };
      userMaps[mapID].features = newFeaturesObj;
      callback && callback(newFeaturesObj);
      return { ...userMaps };
    });
  };

  const deleteFeatures = (
    mapID: string,
    featureIDs: string[],
    callback?: (featuresObj: object) => void
  ) => {
    setUserMaps((userMaps) => {
      const newFeaturesObj = { ...userMaps[mapID].features };
      featureIDs.forEach((featureID) => {
        delete newFeaturesObj[featureID];
      });
      userMaps[mapID].features = newFeaturesObj;
      callback && callback(newFeaturesObj);
      return { ...userMaps };
    });
  };

  const setMapLayer = (mapID: string, featuresObj: object) => {
    const features = Object.values(featuresObj);
    const geojson = featureCollection(features);
    setLayersState([{ data: geojson, layerID: mapID, type: "map" }]);
  };

  return { setFeatures, setMapLayer, deleteFeatures };
};

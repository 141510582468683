import React, { useContext, useEffect, useState } from "react";
import { StyleSheet, Text, View, Button, TouchableOpacity } from "react-native";
import { FAB, IconButton, useTheme, Avatar } from "react-native-paper";
import { GlobalProvider, GlobalContext } from "../Context";

import CachedImage from "../components/CachedImage";

import { useNavigation } from "@react-navigation/native";

const UserAvatar = ({
  //   navigation,
  type,
  uid,
}: {
  type: "myself" | "others";
}) => {
  const navigation = useNavigation();

  const MyProfile = () => {
    const { userInfo, login } = useContext(GlobalContext);

    if (userInfo && login) {
      return (
        <View
          style={{ padding: 3 }}
          // onPress={() =>
          //   navigation.push("UserSettings", { userInfo: userInfo })
          // }
        >
          {userInfo.photoURL ? (
            <CachedImage type="user-icon" size={40} url={userInfo.photoURL} />
          ) : (
            <Avatar.Icon
              // source={{ uri: userInfo.photoURL }}
              icon="account"
              // color={Colors.red500}
              size={40}
            />
          )}
        </View>
      );

      //if not logined, show login button
    } else {
      return (
        <IconButton
          icon="login"
          onPress={() => {
            navigation.push("Login");
          }}
        />
      );
    }
  };
  const OtherProfile = () => {
    const { users } = useContext(GlobalContext);

    //
    const [userInfo, setUserInfo] = useState(users[uid]);

    useEffect(() => {
      if (!userInfo) {
        userRef(uid, "public")
          .get()
          .then((doc) => {
            setUserInfo(doc.data());
          });
      }
    }, []);

    return (
      <TouchableOpacity
        style={{ padding: 3 }}
        onPress={() => navigation.push("UserPage", { uid: uid })}
      >
        {userInfo && userInfo.photoURL ? (
          <CachedImage type="user-icon" size={40} url={userInfo.photoURL} />
        ) : (
          <Avatar.Icon
            // source={{ uri: userInfo.photoURL }}
            icon="account"
            // color={Colors.red500}
            size={40}
          />
        )}
      </TouchableOpacity>
    );

    //if not logined, show login button
  };

  return type == "myself" ? <MyProfile /> : <OtherProfile />;
};

export default UserAvatar;

import React, { useState, useRef, useReducer, useMemo } from "react";
import { Platform } from "react-native";
import { Feature, featureCollection, FeatureCollection } from "@turf/turf";
import {
  FeatureActionNames,
  LayerActionNames,
  LayerStateType,
  MapStateActionInputsType,
  MapStateContextType,
  PutFeatureType,
} from "./types/main";
import { MapViewProps } from "./components/MapComponentStyle";

export const GlobalContext: any = React.createContext([]);
export const GlobalSubContext: any = React.createContext([]);

export const GlobalDataContext: any = React.createContext([]);
export const UserDataContext: any = React.createContext([]);

export const UserActionContext: any = React.createContext([]);

// export interface GlobalSubContextInterface {}

export type GlobalSubContextType = {
  snackbarText: any;
  setSnackbarText: any;
  videoChat: any;
  setVideoChat: any;
  isDrawMode: any;
  setIsDrawMode: any;
  editingMap: any;
  setEditingMap: any;
  visibleLayers: { data: FeatureCollection; type: string }[];
  setVisibleLayers: any;
};

export const GlobalSubProvider = ({ children }) => {
  const [snackbarText, setSnackbarText] = useState(null);
  const [dialogState, setDialogState] = useState<{
    visible: boolean;
    onDismiss: () => void;
    title?: string;
  } | null>(null);
  const [isDrawMode, setIsDrawMode] = useState(false);
  const [videoChat, setVideoChat] = useState({ mapID: null, calling: false });

  const [editingMap, setEditingMap] = useState({
    mapStyle: null,
    geojson: null,
    icon: null,
  });

  const [visibleLayers, setVisibleLayers] = useState<
    { data: FeatureCollection; type: string }[]
  >([]);

  return (
    <GlobalSubContext.Provider
      value={{
        dialogState,
        setDialogState,
        snackbarText,
        setSnackbarText,
        videoChat,
        setVideoChat,
        isDrawMode,
        setIsDrawMode,
        editingMap,
        setEditingMap,
        visibleLayers,
        setVisibleLayers,
      }}
    >
      {children}
    </GlobalSubContext.Provider>
  );
};

export const GlobalProvider = ({ children }) => {
  const [appConfig, setAppConfig] = useState(null);

  const [userInfo, setUserInfo] = useState(null);
  // const [userInfo, setUserInfo] = useState({ user_id: "ItSqm6COztXWzsvurdV19P9WLon2", familyName: "ttt", givenName: "watau", maps: [] })

  // for test
  // const [login, setLogin] = useState(true)
  // for production
  const [login, setLogin] = useState(false);

  const [users, setUsers] = useState({});
  const [usersListeners, setUsersListeners] = useState(null);

  const [userMaps, setUserMaps] = useState({});
  const [mapInfosListeners, setMapInfosListeners] = useState(null);
  const [placesListeners, setPlacesListeners] = useState(null);

  const [mapID, setMapID] = useState(null);

  const isWebOS = Platform.OS == "web" ? true : false;
  //{date: {}}
  const searchRef = useRef(null);

  // mapGL : {viewport, mapType }, native:{region, mapType}
  const userMapView = useRef({
    region: {
      longitude: 0,
      latitude: 0,
      longitudeDelta: 60,
      latitudeDelta: 60,
    },
    mapStyleType: isWebOS ? "mapbox://styles/mapbox/streets-v10" : "standard",
  });
  // const [userMapView, setUserMapView] = useState({ longitude: 0, latitude: 0, longitudeDelta: 60, latitudeDelta: 60 })

  //{web_uri, local_uri}
  const caches = useRef([]);

  const searchPlacesRef = useRef({
    searchQuery: null,
    proximity: { icon: "earth", name: "global" },
  });

  return (
    <GlobalContext.Provider
      value={{
        appConfig,
        setAppConfig,
        userMaps,
        setUserMaps,
        mapInfosListeners,
        setMapInfosListeners,
        placesListeners,
        setPlacesListeners,
        users,
        setUsers,
        usersListeners,
        setUsersListeners,
        userInfo,
        setUserInfo,
        mapID,
        setMapID,
        login,
        setLogin,
        userMapView,
        caches,
        searchRef,
        searchPlacesRef,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export interface SubContextType {
  snackbarText: any;
  setSnackbarText: any;
}

export const GlobalDataProvider = ({ children }) => {
  const [users, setUsers] = useState({});
  const [userMaps, setUserMaps] = useState({});

  return (
    <GlobalDataContext.Provider value={{}}>
      {children}
    </GlobalDataContext.Provider>
  );
};

export const UserDataProvider = ({ children }) => {
  const [appConfig, setAppConfig] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [login, setLogin] = useState(false);

  return (
    <UserDataContext.Provider value={{}}>{children}</UserDataContext.Provider>
  );
};

export const MapStateContext = React.createContext<MapStateContextType | null>(
  null
);

type ActionsType = FeatureActionNames | LayerActionNames;

const layerActions: ActionsType[] = [
  "addLayer",
  "deleteLayer",
  "updateLayer",
  "setLayersState",
];
// const featureActions: FeatureActionNames[] = [
const featureActions: ActionsType[] = [
  "deleteFeature",
  "putFeature",
  "updateFeatureProperties",
  "updateFeatureGeometry",
  "getFeature",
];

// state: get current value
// action: get input value
// reducer treats process inside
const reducer = (
  state: LayerStateType[],
  action: { type: ActionsType; value: MapStateActionInputsType }
) => {
  const actionType = action.type;

  // console.log(state, action);
  const isFeatureAction = featureActions.includes(actionType);
  const isLayerAction = layerActions.includes(actionType);
  const { value } = action;

  if (isFeatureAction) {
    // const geojson = state.find((item) => item.type == "map")?.data;
    const { layerID } = value;
    const layer = state.find((layer) => layer.id == layerID);
    const geojson = layer?.data || featureCollection([]);
    const id = value?.id;
    const feature = geojson?.features.find((f) => f?.id == id);

    let properties = feature?.properties;
    let geometry = feature?.geometry;

    switch (actionType) {
      case "putFeature":
        if (feature) {
          properties = { ...properties, ...value.propertyObj };
        } else {
          geojson.features.push(value);
        }
        return [...state];
      case "deleteFeature":
        const features = geojson.features.filter((f) => f.id !== id);
        layer.data = featureCollection(features);
        return [...state];
      case "updateFeatureProperties":
        properties = { ...properties, ...value.propertyObj };
        return [{ type: "map", data: geojson }];
      case "updateFeatureGeometry":
        geometry = { ...properties, ...value.propertyObj };
        return [{ type: "map", data: geojson }];
      default:
        return state;
    }
  }

  if (isLayerAction) {
    const { layerID } = action.value;
    const isMatchedLayerID = (layer: LayerStateType) => layer.id == layerID;
    const alreadyAddedLayer = state.find(isMatchedLayerID);
    const alreadyAddedLayerIndex = state.findIndex(isMatchedLayerID);

    switch (actionType) {
      case "addLayer":
        if (alreadyAddedLayer) return state;
        return [...state, value];
      case "deleteLayer":
        if (!alreadyAddedLayer) return state;
        return state.filter((layer) => layer.id !== layerID);
      case "updateLayer":
        if (!alreadyAddedLayer) return state;
        state[alreadyAddedLayerIndex] = {
          ...alreadyAddedLayer,
          ...value,
        };
        return [...state];
      case "setLayersState":
        return value;
      default:
        return state;
    }
  }
};

export const MapStateProvider = ({ children }) => {
  const searchRef = useRef(null);

  // mapGL : {viewport, mapType }, native:{region, mapType}
  const userMapView = useRef({
    region: {
      longitude: 0,
      latitude: 0,
      longitudeDelta: 60,
      latitudeDelta: 60,
    },
    // mapStyleType: isWebOS ? "mapbox://styles/mapbox/streets-v10" : "standard",
  });

  // // {mapStyle, data }
  // const [isEditing, setIsEditing] = useState(false);

  //  left: reducer is process, right: initial value
  const [layersState, dispatch] = useReducer(reducer, []);

  const actions = useMemo(() => {
    const mapStateActionNames: ActionsType[] = [
      ...layerActions,
      ...featureActions,
    ];

    const obj = {};
    mapStateActionNames.forEach((type) => {
      obj[type] = (value: PutFeatureType) => dispatch({ type, value });
    });
    return obj;
  }, []);

  // follow MapView props. Control from DrawControl native.
  const [mapViewState, setMapViewState] = useState<
    MapViewProps & { isEditing: boolean }
  >({
    isEditing: true,
    // onTouchMove: ()=>{}
  });

  // control map draw function
  const [mapDrawState, setMapDrawState] = useState();

  const [location, setLocation] = useState<{
    coords: any;
    timestamp: string;
    active: boolean;
  } | null>();
  const locationRef = useRef<{ coords: any; timestamp: string } | null>(null);

  return (
    <MapStateContext.Provider
      value={{
        layersState,
        ...actions,
        mapViewState,
        setMapViewState,
        mapDrawState,
        setMapDrawState,
        location,
        setLocation,
        // locationRef,
        // isEditing,
        // setIsEditing,
      }}
    >
      {children}
    </MapStateContext.Provider>
  );
};

export const UserActionProvider = ({ children }) => {
  const [snackbarText, setSnackbarText] = useState(null);
  const [videoChat, setVideoChat] = useState({ mapID: null, calling: false });

  return (
    <UserActionContext.Provider value={{}}>
      {children}
    </UserActionContext.Provider>
  );
};

import React, { useEffect, useRef, useState } from "react";
import {
  View,
  StyleSheet,
  Dimensions,
  useWindowDimensions,
} from "react-native";
import ReactMapGL, {
  Marker as OriginalMarker,
  // CanvasOverlay,
  Source,
  Layer,
  useMap,
  Popup as OriginalPopup,
  // WebMercatorViewport,
  GeolocateControl,
  ViewState,
  MapLayerMouseEvent,
  PopupProps,
  LayerProps,
  GeolocateControlRef,
} from "react-map-gl";

import "mapbox-gl/dist/mapbox-gl.css";

import spec from "./v8.json";

const { layout, paint } = spec;

//
let paintProperties: string[] = [];
let layoutProperties: string[] = [];

layout.forEach((layoutCategory) => {
  const properties = Object.keys(spec[layoutCategory]);
  layoutProperties = [...layoutProperties, ...properties];
});

paint.forEach((paintCategory) => {
  const properties = Object.keys(spec[paintCategory]);
  paintProperties = [...paintProperties, ...properties];
});

export { paintProperties, layoutProperties };

import {
  CameraSettings,
  CircleLayerProps,
  FillExtrusionLayerProps,
  FillLayerProps,
  ImagesProps,
  LayerBaseProps,
  LineLayerProps,
  MapViewProps,
  MarkerViewProps,
  CalloutProps,
  newMapViewProps,
  ShapeSourceProps,
  SymbolLayerProps,
  UserLocationProps,
  VectorSourceProps,
} from "./MapComponentStyle";
import { mapboxStyleUrls } from "../constants";
import {
  bboxPolygon,
  distance,
  featureCollection,
  getCoord,
  pointsWithinPolygon,
} from "@turf/turf";
import { MapMouseEvent } from "mapbox-gl";
const debug = (obj: any) => __DEV__ && alert(JSON.stringify(obj));

function toHyphenCase(str) {
  return str
    .split(/(?=[A-Z])/)
    .join("-")
    .toLowerCase();
}

export { Source, Layer };

export const Marker = (props: MarkerViewProps) => (
  <OriginalMarker
    {...props}
    longitude={props.coordinate[0]}
    latitude={props.coordinate[1]}
  >
    {props.children}
  </OriginalMarker>
);

export const Popup = (props: PopupProps) => <OriginalPopup {...props} />;

// export const Callout = (props: CalloutProps) => (
//   <OriginalPopup
//     {...props}

//     longitude={props.co}
//     latitude={props.coordinate[1]}
//   >
//     {props.children}
//   </OriginalPopup>
// );

export const VectorSource = ({
  id,
  url,
  children,
  onPress,
}: VectorSourceProps) => {
  if (onPress)
    useSourceInteractionEffect({ id, onPress, sourceLayerID: "building" });

  return (
    <Source id={id} type="vector" url={url}>
      {children}
    </Source>
  );
};

const useSourceInteractionEffect = ({ id, onPress, sourceLayerID }) => {
  const map = useMap().current;

  useEffect(() => {
    if (map) {
      //id is layer name
      // map.on("click", id, onPress);

      const onClick = (e: MapMouseEvent) => {
        e.preventDefault();
        // map.off("dblclick")
        // e.originalEvent.cancelBubble = true;
        // e.originalEvent.stopPropagation();
        const { x, y } = e.point;

        const { lng, lat } = e.lngLat;
        const touchCoord = [lng, lat];

        //query same features in the screen.. so need to filter, fsort

        // const initFeatures = map.querySourceFeatures(id, {
        //   sourceLayer: sourceLayerID,
        // });

        // console.log(id, initFeatures);

        const gap = 20;

        const swPt = [x - 20, y - 20];
        const sw = map.unproject(swPt);
        const nePt = [x + 20, y + 20];
        const ne = map.unproject(nePt);

        const queryRenderedFeatures = map.queryRenderedFeatures([swPt, nePt]);
        const features = queryRenderedFeatures
          .filter((f) => {
            return f.source == id;
          })
          .map((f) => {
            const { properties, geometry } = f;
            return { type: "Feature", properties, geometry };
          });
        // console.log(testFeatures);

        // const bbox = [sw.lng, sw.lat, ne.lng, ne.lat];
        // const poly = bboxPolygon(bbox);

        // const filteredGeojson = pointsWithinPolygon(
        //   featureCollection(initFeatures),
        //   poly
        // );

        // const sorted = filteredGeojson.features.sort((a, b) => {
        //   const ptA = getCoord(a);
        //   const ptB = getCoord(b);

        //   const distA = distance(ptA, touchCoord);
        //   const distB = distance(ptB, touchCoord);

        //   return distA - distB;
        // });

        // onPress({ features: sorted });
        onPress({ features });
      };

      map.on("click", onClick);

      return () => map.off("click", onClick);
    }
  }, [map]);
};

export const ShapeSource = React.memo((props: ShapeSourceProps) => {
  // console.log(props);
  const { onPress, id, children, data, cluster, clusterRadius } = props;

  if (onPress) useSourceInteractionEffect({ id, onPress });

  const newProps = {};

  Object.keys(props).forEach((key) => {
    if (["onPress"].includes(key)) return;
    newProps[key] = props[key];
  });

  return (
    <Source {...newProps} type="geojson">
      {children}
    </Source>
  );
});

export const TerrainSource = () => {
  return (
    <Source
      id="mapbox-dem"
      type="raster-dem"
      url="mapbox://mapbox.mapbox-terrain-dem-v1"
      tileSize={512}
      maxzoom={14}
    />
  );
};

const skyLayer = {
  id: "sky",
  type: "sky",
  paint: {
    "sky-type": "atmosphere",
    "sky-atmosphere-sun": [0.0, 0.0],
    "sky-atmosphere-sun-intensity": 15,
  },
};

export const SkyLayer = () => <Layer {...skyLayer} />;

// export const ImageOverlay = ({ id, url, coordinates }) => (
//   <Source id={id} type="image" url={url} coordinates={coordinates}>
//     <Layer
//       id="overlay"
//       source={id}
//       type="raster"
//       paint={{ "raster-opacity": 0.5 }}
//     />
//   </Source>
// );

export const createWebProps = (props: LayerBaseProps) => {
  const { style, id, sourceID, sourceLayerID } = props;

  const {
    minZoomLevel,
    maxZoomLevel,
    belowLayerID,
    aboveLayerID,
  }: LayerBaseProps = props;

  const map = useMap().current;

  //convert into one with hyphn
  let webStyle = {};
  if (style) {
    Object.keys(style)?.forEach((styleName) => {
      const newStyleName = toHyphenCase(styleName);
      webStyle[newStyleName] = style[styleName];
    });
  }

  const layoutObj = {};
  const paintObj = {};

  Object.keys(webStyle).forEach((property) => {
    if (layoutProperties.includes(property)) {
      layoutObj[property] = webStyle[property];
    }
    //transition exists only in paint props
    if (paintProperties.includes(property) || property.includes("transition")) {
      paintObj[property] = webStyle[property];
    }
  });

  const out = {
    ...props,
    layout: layoutObj,
    paint: paintObj,
  };

  if (sourceID) {
    out["source"] = sourceID;
  }

  if (sourceLayerID) {
    out["source-layer"] = sourceLayerID;
  }

  if (minZoomLevel) {
    out["minzoom"] = minZoomLevel;
    delete out["minZoomLevel"];
  }

  if (maxZoomLevel) {
    out["maxzoom"] = maxZoomLevel;
    delete out["maxZoomLevel"];
  }

  if (belowLayerID) {
    out["beforeId"] = belowLayerID;
    delete out["belowLayerID"];
  }

  // if()

  if (sourceLayerID) {
    out["source-layer"] = sourceLayerID;
    delete out["sourceLayerID"];
  }

  delete out["style"];
  // console.log(out);
  return out;
};

const LayerTemplate = ({ type, props }: { type: string; props: any }) => (
  <Layer type={type} {...createWebProps(props)} />
);

export const SymbolLayer = (props: SymbolLayerProps) => {
  return <LayerTemplate type="symbol" props={props} />;
};
export const CircleLayer = (props: CircleLayerProps) => {
  return <LayerTemplate type="circle" props={props} />;
};

export const LineLayer = (props: LineLayerProps) => (
  <LayerTemplate type="line" props={props} />
);

export const FillLayer = (props: FillLayerProps) => (
  <LayerTemplate type="fill" props={props} />
);

export const FillExtrusionLayer = (props: FillExtrusionLayerProps) => (
  <LayerTemplate type="fill-extrusion" props={{ ...props }} />
);

export const HeatmapLayer = (props: HeatmapLayer) => (
  <LayerTemplate type="heatmap" props={props} />
);

// export function MapView({ mapStyle, children, onClick }: MapViewProps) {
export const MapView = React.memo((props: newMapViewProps) => {
  const mapInst = useMap().current;

  const {
    styleURL,
    children,
    onPress,
    onLongPress,
    id,
    zoomLevel,
    centerCoordinate,
    onDidFinishRenderingMapFully,
    // initialViewState,
    defaultSettings,
    styleJSON,
    onRegionDidChange,
    padding,
  } = props;
  const mapRef = useRef();

  ///tto muchj onLoad!!! find problems!!
  const onLoad = () => {
    const map = mapRef?.current?.getMap();

    if (!map) return;

    //need to fit map (not about width, height)
    map.resize();
  };

  useEffect(() => {
    setTimeout(() => {
      onLoad();
    }, 500);
    // debug("mapstyle");
  }, [styleURL]);

  interface initialViewStateProps extends ViewState {
    bounds?: mapboxgl.LngLatBoundsLike | undefined;
    fitBoundsOptions?: mapboxgl.FitBoundsOptions | undefined;
  }

  const longitude = centerCoordinate && centerCoordinate[0];
  const latitude = centerCoordinate && centerCoordinate[1];

  let viewState: ViewState =
    longitude && zoomLevel
      ? {
          zoom: zoomLevel,
          longitude,
          latitude,
        }
      : {};

  // if(bounds) viewState = {bounds}

  // const { maxZoomLevel, bounds } = defaultSettings || {};

  const initialViewState: initialViewStateProps = {
    // bounds: bounds ? [bounds.sw, bounds.ne] : undefined,
    // zoom: zoomLevel,
    // longitude,
    // latitude,
    // padding defines first fitBounds padding.
    padding,
    // padding: { left: 200 },
    // fitBoundsOptions: { },
    // longitude: centerCoordinate[0],
    // latitude: centerCoordinate[1],
  };

  let touchRef = false;

  const onMouseDown = (e: MapLayerMouseEvent) => {
    // e.stopPropagation();
    touchRef = true;

    const id: number = setTimeout(() => {
      if (touchRef == false) return clearTimeout(id);
      // onPress(e);
      touchRef = false;

      if (onLongPress) onLongPress(e);
      // debug(touchRef);
    }, 800);
  };

  const onMouseUp = () => {
    if (touchRef == true) {
      // debug("up");
      touchRef = false;
    }
  };

  const styleJson =
    styleJSON && typeof styleJSON == "string" ? JSON.parse(styleJSON) : null;

  const mapStyle = styleJson || styleURL || mapboxStyleUrls.Street;
  // const mapStyle = styleURL || mapboxStyleUrls.Street;
  // styleJson && debug(styleJson);

  const { width, height } = useWindowDimensions();
  const isMobile = width < 600;

  return (
    <ReactMapGL
      id={id} // used with useMap()
      style={props.style || { width: "100%", height: "100%" }}
      // projection="globe"
      // reuseMaps // cause error when back by keyboard
      // initialViewState={initialViewState}
      {...viewState}
      // initialViewState={{  }}
      maxPitch={85}
      touchRotate
      dragRotate
      //touch starts
      onMouseDown={onMouseDown}
      onTouchStart={onMouseDown}
      //end
      // logoPosition={isMobile ? "top-left" : "bottom-left"}
      // logoPosition={isMobile ? "top-left" : "bottom-left"}
      onTouchCancel={onMouseUp}
      onMouseUp={onMouseUp}
      onTouchEnd={onMouseUp}
      //clear event by moving map
      onMoveStart={onMouseUp}
      onTouchMove={onMouseUp}
      // onTouchMove={onMouseUp}
      onMoveEnd={onRegionDidChange}
      onClick={onPress}
      onRender={onDidFinishRenderingMapFully}
      mapStyle={mapStyle}
      // mapStyle={"mapbox://styles/mapbox/dark-v10"}
      // mapStyle="mapbox://styles/mapbox/satellite-v9"
      ref={mapRef}
      mapboxAccessToken={
        "pk.eyJ1IjoibWFwa2lkIiwiYSI6ImNrZ3U0a3d6aTBrOWgyeXRtanh0aHUzOTcifQ.ss4jGIc7hG9VyW80bQ81jw"
      }
      onLoad={onLoad}
      // {...prosp}
      // terrain={{ source: "mapbox-dem", exaggeration: 1.5 }}
    >
      <SkyLayer />
      {/* <TerrainSource /> */}
      {/* <NavigationControl /> */}
      {children}
    </ReactMapGL>
  );
});

// {/* https://github.com/rnmapbox/maps/blob/main/docs/UserLocation.md */}
// {/* https://visgl.github.io/react-map-gl/docs/api-reference/geolocate-control */}
//no appearance change api?
export const UserLocation = (props: UserLocationProps) => {
  const ref = useRef<GeolocateControlRef>();

  useEffect(() => {
    if (ref) {
      // Activate as soon as the control is loaded

      setTimeout(() => {
        console.log(ref.current?.trigger());
      }, 1000);
      console.log(ref);
      // ref.trigger();
    }
  }, [ref]);

  return (
    <GeolocateControl
      ref={ref}
      // style={{
      //   props: props.
      //   position: "absolute",
      //   right: 10,
      //   top: 60,
      // }}

      positionOptions={{ enableHighAccuracy: true }}
      showUserHeading
      trackUserLocation={false}
      onGeolocate={(e) => props.onUpdate && props.onUpdate(e)}
      // onTrackUserLocationStart={(e) => debug("start")}
      // onGeolocate={(e) => debug(e.coords)}
    />
  );
};

export const MapboxStyleURLs = {
  Street: "mapbox://styles/mapbox/streets-v11",
  SatelliteStreet: "mapbox://styles/mapbox/satellite-streets-v11",
  Dark: "mapbox://styles/mapbox/dark-v10",
  Light: "mapbox://styles/mapbox/light-v10",
};

export const MapImages = ({ images }: ImagesProps) => {
  const map = useMap().current;

  // useEffect(() => {
  if (map) {
    // debug("images");

    //key is imageID, images[key] is dir or {uri: url}
    Object.keys(images).forEach((key) => {
      const sourceImage = images[key];
      const uri = sourceImage.uri || sourceImage;

      map.loadImage(uri, (error, image) => {
        if (error) return;
        if (map.hasImage(key)) return;
        const { sdf } = sourceImage;

        map.addImage(key, image, sourceImage);
        //for fitting with score text
        // map.addImage(key, image, {
        //   // stretchX: [
        //   //   // [25, 55],
        //   //   [10, 60],
        //   // ],
        //   // The one (red) row of pixels that can be stretched vertically:
        //   //   - the pixels between y: 25 and y: 100 can be stretched
        //   // stretchY: [[10, 60]],
        //   // This part of the image that can contain text ([x1, y1, x2, y2]):
        //   content: [13, 10, 60, 60],
        //   // This is a high-dpi image:
        //   pixelRatio: 2,
        // });
      });
    });
  }
  // }, [map]);

  return <View />;
};

export { useMap, MapProvider } from "react-map-gl";

const styles = StyleSheet.create({
  mapContainer: {
    // position: "relative",
    // width: "100%",
    // flex: 1,
    // height: 500,
    // width: Dimensions.get("window").width,
    // height: Dimensions.get('window').height - 50,
  },
});

// APP_ENV="product"
//export APP_ENV
//firebase use project_name //for deploying functions

export const appName = "Mapnom";
export const schemName = "mapnom";

// import { appName, schemName } from "./constants";

// name, version, icon, splash, scheme, ios- buildnumber, bundleIdentifier , android- package, versionCode
//slug is directory of Expo, like watarutsuchida@mapkid.info/Rounds, only for eas build

//config is app.json, static value is written there.
//Some values are related to app components directly.

export const isProduct = true;
// export const appSiteURL = "mapkid.info";

export const webSiteDomain = "mapnom.com";
export const appDeepLinkDomain = "app.mapnom.com";

export const webSiteURL = "https://" + webSiteDomain;
export const appDeepLinkURL = "https://" + appDeepLinkDomain;

//linkedpage is used in App, app.config
export const linkedPage = "MapHome";
export const appLogoPath = "assets/mapkid_logo.png";

export default ({ config }) => {
  const appEnv = process.env.APP_ENV; // test or product
  const targetApp = process.env.Target; // app name = rounds, mapkid

  //check env
  //slug: Expo directory used for Google 0auth
  //scheme:
  // return config;

  //name: shown name in home screen
  //slug: expo server dir, which affect upload certificate for play google

  if (isProduct) {
    // config.name = "Mapkid";
    config.name = appName;
    // config.slug = "Mapkid";
    config.slug = "Rounds";
    config.scheme = schemName;
    config.ios.bundleIdentifier = "com.mapkid.rounds";
    config.android.package = "com.mapkid.rounds4";
    config.ios.associatedDomains = [
      // "applinks:" + appSiteURL,
      // "applinks:" + appSiteURL + "?mode=developer",
      "applinks:" + appDeepLinkDomain,
      "applinks:" + appDeepLinkDomain + "?mode=developer",
    ];
    // config.ios.associatedDomains = ["applinks:rounds.mapkid.info"];
    config.android.intentFilters = [
      {
        action: "VIEW",
        autoVerify: true,
        data: {
          scheme: "https",
          // host: appSiteURL,
          host: appDeepLinkDomain,
          // pathPattern: "/.*",
          // pathPattern: "/" + linkedPage + "/.*",
        },
        category: ["BROWSABLE", "DEFAULT"],
      },
      // {
      //   action: "VIEW",
      //   autoVerify: true,
      //   data: {
      //     scheme: "https",
      //     // host: appSiteURL,
      //     host: appDeepLinkDomain,
      //     pathPattern: "/" + "EmailVerification" + "/.*",
      //   },
      //   category: ["BROWSABLE", "DEFAULT"],
      // },
    ];
    config.ios.googleServicesFile = "./product/GoogleService-Info.plist";
    config.android.googleServicesFile = "./product/google-services.json";
  } else {
    config.name = "Rounds-test";
    config.slug = "Rounds-test";
    config.scheme = "rounds-test";
    config.ios.bundleIdentifier = "com.mapkid.roundsTest";
    config.android.package = "com.mapkid.rounds_test2";
    config.ios.associatedDomains = [
      "applinks:rounds-test-fe5b9.firebaseapp.com",
    ]; // for deep link
    config.android.intentFilters = [
      {
        action: "VIEW",
        data: [
          {
            scheme: "https",
            host: "rounds-test-fe5b9.firebaseapp.com",
            pathPrefix: "/",
          },
        ],
        category: ["BROWSABLE", "DEFAULT"],
      },
    ];
    config.ios.googleServicesFile = "./dev/GoogleService-Info.plist";
    config.android.googleServicesFile = "./dev/google-services.json";
  }

  return {
    ...config,
    jsEngine: "hermes",
    extra: {
      eas: {
        projectId: "33eb8feb-db9d-42a0-a201-5c116b256f05",
      },
      //   apiKey,
    },
  };
};

import { StatusBar } from "expo-status-bar";
import React, { useState } from "react";
import {
  SafeAreaView,
  StyleSheet,
  Text,
  View,
  Image,
  ScrollView,
  Dimensions,
} from "react-native";
import {
  Switch,
  Chip,
  FAB,
  Paragraph,
  Avatar,
  Dialog,
  Button,
  TextInput,
  IconButton,
  Card,
  Title,
  Portal,
} from "react-native-paper";

import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";

type AdditionalProps = {
  visible: boolean;
  onDismiss: () => void;
  title?: string;
  contents: React.FC;
  actions: React.FC;
};

export default function StyledDialog(props: typeof Dialog & AdditionalProps) {
  return (
    <Portal>
      {/* keyboard avoid??? or in styled text input */}
      {props?.visible && (
        <Dialog
          visible={props?.visible}
          onDismiss={props?.onDismiss}
          style={{ alignSelf: "center", width: "90%", maxWidth: 500 }}
        >
          {props?.title && <Dialog.Title>{props?.title}</Dialog.Title>}
          <Dialog.Content>{<props.contents />}</Dialog.Content>
          <Dialog.Actions>{<props.actions />}</Dialog.Actions>
        </Dialog>
      )}
    </Portal>
  );
}

import { Image, LogBox, SafeAreaView, View } from "react-native";

import "expo-dev-client";
import "setimmediate"; // for error in reanimated 3
import "react-native-gesture-handler";

import {
  activateAppCheck,
  activateAuthPopup,
  emulate,
  // initializeFirebase,
  isWebOS,
  setUpLanguage,
} from "./localFunctions";

export const setup = () => {
  LogBox.ignoreLogs(["EventEmitter.removeListener"]);

  // FIXME need reanimated update, see https://github.com/software-mansion/react-native-reanimated/issues/3355
  if (isWebOS) {
    // @ts-ignore
    window._frameTimestamp = null;
  }

  //set up
  // initializeFirebase();
  setUpLanguage();
  activateAuthPopup();
  // emulate();
  // activateAppCheck();
};

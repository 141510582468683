import React, { createRef, useEffect, useState } from "react";

import { Button } from "react-native-paper";
import { debug } from "../localFunctions";

type PaperButtonProps = React.ComponentProps<typeof Button>;

const PaperButton = (props: PaperButtonProps) => {
  //   debug(typeof props.children);

  const { children } = props;
  let processedChildren;
  if (typeof children == "string") {
    const toUppercase = (key: string) => {
      const initial = key[0].toUpperCase();
      const back = key.substring(1);
      return initial + back;
    };
    processedChildren = toUppercase(children);
  } else {
    processedChildren = children;
  }

  let elevation = 6;

  const flatModes = ["outlined", "text"];

  if (flatModes.includes(props.mode)) elevation = 0;

  return (
    <Button
      uppercase={false}
      labelStyle={{ color: "black" }}
      {...props}
      style={{ elevation, ...props.style }}
    >
      {processedChildren}
    </Button>
  );
};

export default PaperButton;

import {
  StyleSheet,
  View,
  TouchableOpacity,
  ImageBackground,
  ViewStyle,
} from "react-native";

import React from "react";

import { mapboxStyleImages } from "../constants";

import {
  Avatar,
  DefaultTheme as PaperDefaultTheme,
  Provider as PaperProvider,
  Title,
  FAB,
} from "react-native-paper";
import { isWebOS, pickImage, debug, compressImage } from "../localFunctions";

type dataTypes = {
  text: string;
  styleURL?: string;
  backgroundColor?: string;
  source?: any;
};

type PropsType = { styleName: string; style?: ViewStyle; onPress: any };

export default function (props: PropsType) {
  const style = { ...styles.mapStyleImage, ...props.style };
  const imageStyle = isWebOS
    ? { ...style, borderRadius: 10 }
    : { ...styles.mapStyleImage, ...props.style };

  const styleName = props.styleName || "Street";

  // const mapStyle= {
  //   uri: mapboxStyleImages[styleName]||mapbo
  // }

  return (
    <TouchableOpacity style={styles.mapStyleContainer} onPress={props.onPress}>
      <ImageBackground
        style={style}
        imageStyle={imageStyle}
        resizeMode="cover"
        source={{ uri: mapboxStyleImages[styleName] }}
      >
        <View style={styles.mapStyleBackground}>
          <Title style={{ color: "white" }}>{styleName}</Title>
        </View>
      </ImageBackground>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  mapStyleContainer: {
    justifyContent: "center",
    padding: 10,
    alignItems: "center",
    width: 200,
    // height: "100%",
  },
  mapStyleImage: {
    width: "100%",
    height: 100,
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  mapStyleBackground: {
    // position: "absolute",
    alignSelf: "center",
    backgroundColor: "black",
    opacity: 0.7,
    justifyContent: "center",
    borderRadius: 10,
    padding: 10,
  },
});

import React, { useContext, useEffect, useState } from "react";
import { StyleSheet, Text, View, Button, TouchableOpacity } from "react-native";

import { GlobalProvider, GlobalContext } from "../Context";

import { FAB, IconButton, useTheme, Avatar } from "react-native-paper";

export default function App({ navigation }) {
  const { userInfo, login, setMapID } = useContext(GlobalContext);

  if (userInfo && login) {
    return (
      <IconButton
        onPress={() => {
          setMapID(null);
          navigation.push("MapSettings", { mode: "create" });
        }}
        icon="map-plus"
      />
    );
  } else {
    return <View />;
  }
}

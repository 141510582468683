import { StatusBar } from "expo-status-bar";
import React, { useContext, useEffect, useState } from "react";
import { Ionicons } from "@expo/vector-icons";
import {
  StyleSheet,
  Text,
  View,
  Button,
  TouchableOpacity,
  Platform,
  Alert,
} from "react-native";
import {
  FAB,
  IconButton,
  useTheme,
  Avatar,
  Provider,
  Menu,
  ActivityIndicator,
} from "react-native-paper";
import { GlobalProvider, GlobalContext } from "../Context";
import i18n from "../languages/language";
import { langDict } from "../languages/langCheck";
import { debug, getUserLimit, isWebOS } from "../localFunctions";
import { screenNames, userLimits, userLimitsKeys } from "../constants";
import { useNavigation, useRoute } from "@react-navigation/native";

const HeaderRightComponent = ({}) => {
  const navigation = useNavigation();

  const route = useRoute();

  if (!route.params) {
    return <ActivityIndicator />;
  }

  const { mapID } = route.params;

  const { userMaps, setUserMaps, setMapID, userInfo, login } =
    React.useContext(GlobalContext);

  const [visible, setVisible] = useState(false);

  if (userMaps[mapID]) {
    const mapInfo = userMaps[mapID].info;
    const uid = userInfo ? userInfo?.uid : undefined;

    const isHost = mapInfo.host == uid;

    const isMember =
      uid && userMaps
        ? mapInfo.members.includes(uid) || mapInfo.host == uid
        : false;

    const routeName = route.name;

    // const color = useTheme().colors.text;
    const color = "grey";

    const userType = "a";

    // const limits = userLimits[userLimitsKeys[userType]];

    // debug(getUserLimit(userInfo, "print"));
    // const canPrint = __DEV__ || getUserLimit(userInfo, "print");

    let buttonsArray = [
      // {
      //   title: i18n.t(langDict.editPlaces),
      //   icon: "map-marker-multiple",
      //   route: "EditPlaces",
      //   params: { mapID: mapID },
      //   disabled: false,
      // },
      {
        title: i18n.t(langDict.mapSettings),

        icon: () => <Ionicons name="settings" size={25} color={color} />,
        // icon: "square-edit-outline",
        route: screenNames.MapSettings,
        params: { mode: "edit", mapID },
        disabled: false,
      },

      // {
      //   // title: "Print",
      //   title: i18n.t(langDict.printMap),

      //   icon: "printer",
      //   // icon: "square-edit-outline",
      //   route: "PrintMap",
      //   params: { mode: "edit", mapID },
      //   // disabled: !isWebOS && canPrint,
      //   disabled: canPrint ? false : true,
      // },
    ];

    // if (!isWebOS) {
    //   buttonsArray.push({
    //     // title: "Print",
    //     title: i18n.t(langDict.printMap),

    //     icon: "printer",
    //     // icon: "square-edit-outline",
    //     route: "PrintMap",
    //     params: { mode: "edit", mapID },
    //   });
    // }

    return (
      <View style={{ flexDirection: "row" }}>
        {(isHost || __DEV__) && (
          <Menu
            visible={visible}
            anchor={
              <IconButton
                onPress={() => setVisible(true)}
                icon={"dots-vertical"}
                color="black"
              />
            }
            onDismiss={() => setVisible(false)}
          >
            {buttonsArray.map((button, index) => (
              <Menu.Item
                key={index}
                style={{ opacity: button.disabled ? 0.5 : 1 }}
                // disabled={button.disabled}
                title={button.title}
                icon={button.icon}
                onPress={() => {
                  if (button.disabled) {
                    Alert.alert(
                      "Upgrade plan",
                      "This function is available on upgraded plan.",
                      [
                        {
                          text: "Cancel",

                          onPress: () => {},
                        },
                        {
                          text: "Upgrade",
                          onPress: () => {
                            navigation.navigate("Subscription");
                          },
                        },
                      ],
                      { cancelable: true }
                    );
                  } else {
                    setVisible(false);
                    navigation.navigate(button.route, button.params);
                  }
                }}
              />
            ))}

            {/* {
            return (
              <IconButton
                onPress={() => navigation.push(button.route, button.params)}
                icon={button.icon}
                // color={Colors.red500}
                // small
                // title="Info"
                color="black"
              />
            )})} */}
          </Menu>
        )}
      </View>
    );
  } else {
    return <View />;
  }
};

export default HeaderRightComponent;

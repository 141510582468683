import type { LayerProps } from "react-map-gl";
import { Expression } from "../MapComponentStyle";
import { getBboxFilter, isWebOS, toCapitalCase } from "../../localFunctions";

import { AreaPropertiesType, MergedLayersType } from "../../types/main";

// static is unselected features. It is original marker style
// active is selected features. Mapbox Draw

const polygonOrLine: Expression = [
  "match",
  "$type",
  "Polygon",
  true,
  "LineString",
  true,
  false,
];

const isGeometryType = (type: "Polygon" | "LineString" | "Point" | string) => [
  "==",
  ["geometry-type"],
  type,
];

// https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/#paint-line-line-dasharray
// expression of lineDashArray is not supported in native, works for gl js
// const routeDashArray: Expression = [
//   "match",
//   ["get", "type"],
//   // "route",
//   // ["literal", [2, 2, 6, 2]],
//   "area",
//   ["literal", [2, 2, 6, 2]],
//   ["literal", [2, 2, 6, 2]],
//   // ["literal", [1, 0]],
// ];

const routeDashArray = [2, 2, 6, 2];

const strokeColor: Expression = [
  "match",
  ["get", "type"],
  // "route",
  // ["literal", [2, 2, 6, 2]],
  "area",
  "yellow",
  "route",
  "coral",
  "red",
];

const areaStrokeOpacity = [
  "case",
  ["all", ["==", ["get", "type"], "area"], getBboxFilter()],
  0.5,
  1,
];

const routeNameColor: Expression = [
  "interpolate",
  ["linear"],
  ["zoom"],
  // zoom is 14 (or less) -> circle radius will be 1px
  14,
  "#666",
  // zoom is 16 (or greater) -> circle radius will be 5px
  16,
  "black",
];

const routeLabelText = [
  "match",
  ["get", "type"],
  "route",
  ["get", "title"],
  // " Airport - Toubab Dialao 1h ",
  "Area name",
];

// lineOpacity: [
//   "match",
//   ["get", "type"],
//   "route",
//   1,
//   "area",
//   0.5,
//   // ["match", getBboxFilter(), true, 1, 0.5],
//   // "yellow",
//   1,
// ],
const staticStyles: MergedLayersType[] = [
  {
    id: "area",
    type: "fill",
    beforeId: "area-name",
    filter: isGeometryType("Polygon"),
    style: {
      fillColor: "green",
      fillOutlineColor: "#3bb2d0",
      // fillOpacity: 0.1,
      fillOpacity: 0,
    },
  },
  {
    id: "line",
    type: "line",
    beforeId: "road-label",
    // beforeId: "poi-label",
    style: {
      lineDasharray: routeDashArray,
      lineColor: strokeColor,
      lineOpacity: areaStrokeOpacity,
      lineWidth: 5,
      lineCap: "round",
    },
  },
  {
    id: "point-marker-circle-stroke",
    type: "circle",
    filter: isGeometryType("Point"),
    layout: {},
    style: {
      circleRadius: 7,
      circleColor: "white",
      // "circle-color": "#3bb2d0",
    },
  },
  {
    id: "point-marker-circle",
    type: "circle",
    filter: isGeometryType("Point"),
    layout: {},
    style: {
      circleRadius: 5,
      circleColor: "orange",
      // "circle-color": "#3bb2d0",
    },
  },

  {
    id: "point-label",
    type: "symbol",
    filter: isGeometryType("Point"),
    layout: {},
    style: {
      // textAllowOverlap
      textAnchor: "top",
      textOffset: [0, 0.5],
      textField: "Test shop",
      textColor: "#666",
      // circleRadius: 10,
      // circleColor: "yellow",
      // "circle-color": "#3bb2d0",
    },
  },
  {
    id: "line-name",
    type: "symbol",
    // type: "circle",

    filter: [
      "all",
      ["in", ["geometry-type"], ["literal", ["LineString", "Polygon"]]],
      ["!=", ["get", "type"], "building"],
    ],
    style: {
      symbolPlacement: "line",
      visibility: ["in", ["geometry-type"], ["literal", ["LineString"]]],
      // iconPitchAlignment: "viewport",
      // iconRotationAlignment: "viewport",
      // iconImage: ["match", ["get", "type"], "route", "bus", "..."],
      // iconAnchor: "right",
      textOffset: [0.5, 0],
      textAnchor: "left",
      textField: routeLabelText, //which explain this route
      textSize: ["match", ["geometry-type"], "LineString", 15, "Polygon", 8, 5],
      textHaloWidth: 2,
      // textHaloColor: "white",
      textPadding: 1,
      textMaxAngle: 30,
      textPitchAlignment: "viewport",
      textRotationAlignment: "map",
      textColor: routeNameColor,
      textHaloColor: "rgba(255,255,255,0.75)",
    },
  },
];

const inactiveStyles: LayerProps[] = [
  // area or buildings
  {
    id: "gl-draw-polygon-fill-inactive",
    type: "fill",
    filter: [
      "all",
      ["==", "active", "false"],
      ["==", "$type", "Polygon"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "fill-color": "red",

      //   "fill-color": "#3bb2d0",
      "fill-outline-color": "#3bb2d0",
      // "fill-opacity": 0.1,
      "fill-opacity": 0,
    },
  },
  //   route
  {
    id: "gl-draw-polygon-stroke-inactive",
    type: "line",
    beforeId: "road-label",
    filter: [
      "all",
      ["==", "active", "false"],
      ["==", "$type", "Polygon"],
      ["!=", "mode", "static"],
    ],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "orange",

      //   "line-color": "#3bb2d0",
      "line-width": 3,
      "line-dasharray": routeDashArray,
      // "line-dasharray": [1, 1],
    },
  },
  // needed????
  {
    id: "gl-draw-polygon-and-line-vertex-stroke-inactive",
    type: "circle",
    filter: [
      "all",
      ["==", "meta", "vertex"],
      ["==", "$type", "Point"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": 5,
      "circle-color": "#fff",
    },
  },
  {
    id: "gl-draw-line-inactive",
    type: "line",

    filter: [
      "all",
      ["==", "active", "false"],
      ["==", "$type", "LineString"],
      ["!=", "mode", "static"],
    ],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-opacity": 0.2,
      // "line-color": "#3bb2d0",
      // "line-color": "red",
      "line-color": "green",
      "line-dasharray": routeDashArray,
      "line-width": 2,
    },
  },
  {
    id: "gl-draw-polygon-and-line-vertex-inactive",
    type: "circle",
    filter: [
      "all",
      ["==", "meta", "vertex"],
      ["==", "$type", "Point"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": 3,
      "circle-color": "#fbb03b",
    },
  },
];

// maybe the points can be "handle" to manipulate the shape like rotating
const inactivePointStyles: LayerProps[] = [
  {
    id: "gl-draw-point-point-stroke-inactive",
    type: "circle",
    filter: [
      "all",
      ["==", "active", "false"],
      ["==", "$type", "Point"],
      ["==", "meta", "feature"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": 5,
      "circle-opacity": 0.1,
      "circle-color": "#fff",
    },
  },
  //   not needed?
  // {
  //   id: "gl-draw-point-label-inactive",
  //   type: "symbol",
  //   beforeId: "tent-3d",
  //   filter: [
  //     "all",
  //     ["==", "active", "false"],
  //     ["==", "$type", "Point"],
  //     ["==", "meta", "feature"],
  //     ["!=", "mode", "static"],
  //   ],
  //   // layout: {
  //   //   // "text-field": "Test shop is here.",
  //   //   "text-field": ["coalesce", ["get", "title"], "No title"],
  //   //   "text-anchor": "top",
  //   //   "text-offset": [0, 0.5],
  //   //   "text-size": 13,
  //   // },
  //   paint: {

  //     // "text-opacity"
  //     // "circle-radius": 5,
  //     // "circle-opacity": 1,
  //     // "circle-color": "#fff",
  //   },
  // },
  //   not needed?
  {
    id: "gl-draw-point-inactive",
    type: "circle",
    filter: [
      "all",
      ["==", "active", "false"],
      ["==", "$type", "Point"],
      ["==", "meta", "feature"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": 3,
      // "circle-color": "#3bb2d0",
      "circle-color": "red",
      "circle-opacity": 0.5,
    },
  },
];

const activeStyles: LayerProps[] = [
  {
    id: "gl-draw-polygon-fill-active",
    type: "fill",
    filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
    paint: {
      "fill-color": "#fbb03b",
      "fill-outline-color": "#fbb03b",
      "fill-opacity": 0.1,
    },
  },
  {
    id: "gl-draw-polygon-midpoint",
    type: "circle",
    filter: ["all", ["==", "$type", "Point"], ["==", "meta", "midpoint"]],
    paint: {
      "circle-radius": 3,
      "circle-color": "#fbb03b",
    },
  },
  {
    id: "gl-draw-polygon-stroke-active",
    type: "line",
    filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#fbb03b",
      "line-dasharray": [0.2, 2],
      "line-width": 2,
    },
  },
  {
    id: "gl-draw-line-active",
    type: "line",
    filter: ["all", ["==", "$type", "LineString"], ["==", "active", "true"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "#fbb03b",
      "line-dasharray": [0.2, 2],
      // "line-pattern":
      "line-width": 2,
    },
  },
  {
    id: "gl-draw-point-stroke-active",
    type: "circle",
    filter: [
      "all",
      ["==", "$type", "Point"],
      ["==", "active", "true"],
      ["!=", "meta", "midpoint"],
    ],
    paint: {
      // "circle-radius": 7,
      "circle-radius": 12,
      "circle-color": "#fff",
    },
  },
  {
    id: "gl-draw-point-active",
    type: "circle",
    filter: [
      "all",
      ["==", "$type", "Point"],
      ["!=", "meta", "midpoint"],
      ["==", "active", "true"],
    ],
    paint: {
      // "circle-radius": 5,
      "circle-radius": 9,
      "circle-color": "#fbb03b",
    },
  },
];

// // mapbox draw static mode use this. But we need that becuase we use inactive for original styles???
// const staticStyles: LayerProps[] = [
//   {
//     id: "gl-draw-polygon-fill-static",
//     type: "fill",
//     filter: ["all", ["==", "mode", "static"], ["==", "$type", "Polygon"]],
//     paint: {
//       "fill-color": "#404040",
//       "fill-outline-color": "#404040",
//       "fill-opacity": 0.1,
//     },
//   },
//   {
//     id: "gl-draw-polygon-stroke-static",
//     type: "line",
//     filter: ["all", ["==", "mode", "static"], ["==", "$type", "Polygon"]],
//     layout: {
//       "line-cap": "round",
//       "line-join": "round",
//     },
//     paint: {
//       "line-color": "#404040",
//       "line-width": 2,
//     },
//   },
//   {
//     id: "gl-draw-line-static",
//     type: "line",
//     filter: ["all", ["==", "mode", "static"], ["==", "$type", "LineString"]],
//     layout: {
//       "line-cap": "round",
//       "line-join": "round",
//     },
//     paint: {
//       "line-color": "#404040",
//       "line-width": 2,
//     },
//   },
//   {
//     id: "gl-draw-point-static",
//     type: "circle",
//     filter: ["all", ["==", "mode", "static"], ["==", "$type", "Point"]],
//     paint: {
//       "circle-radius": 5,
//       "circle-color": "#404040",
//     },
//   },
// ];

const basicStyles: LayerProps[] = [
  ...inactiveStyles,
  ...inactivePointStyles,
  ...activeStyles,
  //   ...staticStyles,
  {
    id: "line-name",
    type: "symbol",
    // type: "circle",
    filter: ["==", ["geometry-type"], "LineString"],
  },
];

// "$type" not work in native?
const additionalAppStyles: LayerProps[] = [
  {
    id: "gl-draw-line-polygon-vertex-active",
    type: "circle",
    filter: [
      "all",
      polygonOrLine,
      // ["==", "$type", "Point"],
      ["!=", "meta", "midpoint"],
      ["==", "active", "true"],
    ],
    paint: {
      "circle-radius": 3,
      "circle-color": "#fbb03b",
    },
  },
  {
    id: "gl-draw-line-polygon-vertex-stroke-active",
    type: "circle",
    filter: [
      "all",
      polygonOrLine,
      ["==", "active", "true"],
      ["!=", "meta", "midpoint"],
    ],
    paint: {
      "circle-radius": 5,
      "circle-color": "#fff",
    },
  },
];

let defaultStyles: LayerProps[] | any[] = basicStyles;

if (!isWebOS) {
  const webProps = ["paint", "layout"];
  const nativeStyles = [...basicStyles, ...additionalAppStyles].map((layer) => {
    const style = {};
    // key is id, filter, paint, layout
    Object.keys(layer).forEach((layerProp) => {
      if (!webProps.includes(layerProp)) return;
      // for converting layout to style
      const obj = layer[layerProp];

      Object.keys(obj).map((styleProp) => {
        const newKey = toCapitalCase(styleProp);
        style[newKey] = layer[layerProp][styleProp];
      });
    });
    const { id, type, filter } = layer;
    return { id, type, filter, style };
  });

  defaultStyles = nativeStyles;
}

// https://github.com/mapbox/mapbox-gl-draw/blob/main/src/lib/theme.js
// https://github.com/mapbox/mapbox-gl-draw/blob/main/docs/EXAMPLES.md
export { defaultStyles };

import * as Localization from "expo-localization";
import i18n from "i18n-js";
import en from "./en";
import fr from "./fr";
import ja from "./ja";

i18n.fallbacks = true;
i18n.translations = { ja, en, fr };
i18n.locale = Localization.locale;

export const languages = ["ja", "en", "fr"];
export const languageLabels = { ja: "日本語", en: "English", fr: "Français" };

export default i18n;

import { isProduct } from "../app.config";
import * as Linking from "expo-linking";
const appEnv = process.env.APP_ENV; // test or product
// const isProduct = appEnv == "product";

// for functions, but not used???
export const algoliaIndex = isProduct
  ? { maps: "maps" }
  : {
      maps: "maps_test",
    };

//2: google play console, 1: drivers, 7: Rounds test  in credential in Cloud

const webApiKey = isProduct
  ? "AIzaSyCVMR6xgRoJUGP7dKx1b_8yUACPXculjD0"
  : "AIzaSyCVz4A7v2pEFjxMLrv9DmUFcuyHOXZjScs"; //from Firebase Console gs

export const dynamicLinkURL =
  "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=" + webApiKey;

export const dynamicLinkDomain = "mapnom.page.link";
// export const domain = isProduct
//   ? "https://" + appSiteURL + "/"
//   : "https://rounds-test-fe5b9.firebaseapp.com/";

const gb = 10 ** 9;

// export interface userLimitsType {
//   maps: number;
//   places: number;
//   posts: number;
//   print: boolean;
//   mapStyle: boolean;
//   comments: boolean;
//   members: number;
//   join: number;
// }

// export type limitNameKeyType =
//   | "maps"
//   | "places"
//   | "posts"
//   | "print"
//   | "mapStyle"
//   | "comments"
//   | "members"
//   | "join";

// export const userLimitsKeys = { admin: "admin", a: "a", b: "b" };

// export const userLimits: {
//   a: userLimitsType;
//   b: userLimitsType;
//   admin: userLimitsType;
// } = {
//   admin: {
//     maps: 500,
//     places: 10000,
//     posts: 1000000,
//     print: true,
//     mapStyle: true,
//     comments: true,
//     members: 500,
//     join: 10000,
//   },
//   // custom: {},
//   //a is freee
//   a: {
//     maps: 3,
//     places: 100,
//     posts: 100,
//     print: false,
//     mapStyle: false,
//     comments: false,
//     members: 3,
//     join: 3,
//   },
//   //b is basic, refer to stripe
//   b: {
//     maps: 30,
//     places: 3000,
//     posts: 1000000,
//     print: true,
//     mapStyle: true,
//     comments: true,
//     members: 8,
//     join: 30,
//   },
//   // c: { maps: 30, places: 5000 },
// };

const prefix = "https://s3.us-west-1.wasabisys.com/mapkid/assets/";

export const assets = {
  // landing
  screen1: { uri: prefix + "mapHome.png" },
  screen2: { uri: prefix + "mapScreen.png" },
  screen3: { uri: prefix + "searchMaps.png" },
  mapkidLogo: require("../assets/mapnom_logo.png"),
  // mapkidLogo: { uri: prefix + "mapkid_logo.png" },
  mapkidLogoText: { uri: prefix + "mapkid_logo_with_text.png" },
  background: { uri: prefix + "IMG_0183.jpg" },
  howToVideo: { uri: prefix + "howTo.mp4" },
  warmMap: { uri: prefix + "Maps_With_Warm_Memories.png" },
  currentMaps: { uri: prefix + "Maps.png" },
  conceptBook: { uri: prefix + "conceptBook.png" },
  together: { uri: prefix + "together.png" },
  thankYou: { uri: prefix + "thankYou.png" },
  seeds: { uri: prefix + "seeds.jpg" },
  flowers: { uri: prefix + "flowers.jpg" },
  postWithPlace: { uri: prefix + "postWithPlace.png" },
  walking: { uri: prefix + "walking.png" },
};

// export const cameraPadding= {}

export const picsumURL = "https://picsum.photos/200/300";
export const randomPicsumURL = "https://picsum.photos/200/300?random=1";

// https://m2.material.io/design/typography/the-type-system.html#type-scale
// font headline: 24 title: 16, paragraph: 14, caption: 12 - #666666
// v3 of RN paper: headline = title, title = subtitle, body, label

export const activities = ["tour", "look for", "meetup", "photoshooting"];
export const categories = [
  "nature",
  "art",
  "history",
  "food",
  "animal",
  "landscape",
  "education",
];
export const times = ["morning", "midday", "night"];

const notificationTypes = [
  "welcome",
  "sentRequest", // user sent a booking request
  "receivedRequest", // partner received
  "receivedMessages", //any user get chat message
  "acceptedRequest", // visitor’s request is acceepted
  "comingActivities", // any user’s activity is coming
  "finishedActivities",
  "requestReview", // remind review
  "createdReview", // partner or visitor reviewed
  "finishedReview", // both are finished
];

export const articles = {
  1: {
    title: "Visitor Guideline",
    description: "Basic guideline for beginners.",
  },
  2: {
    title: "Local partner Guideline",
    description: "Basic guideline for local partner.",
  },
  111: {
    title: "Test Visitor Guideline",
    description: "Basic guideline for beginners.",
  },
};

export const mailAddress =
  "Mapnom, Inc., 2093 PHILADELPHIA PIKE #4076 CLAYMONT, DE 19703";

// export const mailingAddress = '2093 PHILADELPHIA PIKE #4076 CLAYMONT, DE19703'

export {
  mapboxStyleUrls,
  mapViewID,
  mapboxStyleImages,
  markerImagesNames,
  markerSourceIDs,
} from "./map";

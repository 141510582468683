import {
  ParamListBase,
  RouteProp,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import {
  along,
  bbox,
  bboxPolygon,
  bearing,
  booleanPointOnLine,
  buffer,
  centerOfMass,
  circle,
  destination,
  distance,
  envelope,
  Feature,
  featureCollection,
  FeatureCollection,
  getCoord,
  getCoords,
  getType,
  length,
  lineDistance,
  LineString,
  lineString,
  Point,
  point,
  randomPoint,
  simplify,
  transformRotate,
  transformTranslate,
  Units,
} from "@turf/turf";
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTheme } from "react-native-paper";
import {
  CircleLayer,
  FillExtrusionLayer,
  FillLayer,
  LineLayer,
  MapView,
  ShapeSource,
  SymbolLayer,
  VectorSource,
  useMap,
} from "../components/MapComponents";
import {
  markerSourceIDs,
  screenNames,
  stackScreenNames,
  streetSourceLayerIds,
} from "../constants";
import {
  GlobalContext,
  MapStateContext,
  MapStateContextType,
} from "../Context";
import {
  addFeatures,
  addPlace,
  deleteFeatures,
  getDirections,
  getMapInfo,
  getMaps,
  getRandomID,
  getSearchMapInfos,
  isWebOS,
  updateFeatureCollection,
  useCurrentRoute,
  useCustomNavigation,
  useMapInfo,
  useMapboxFunctions,
} from "../localFunctions";
import { LayerStateType, layerTypes } from "../types/main";

import {
  BuildingFillExtrusion,
  Buildings,
  CustomLine,
  CustomPolygon,
  CustomSymbols,
  MapsCluster,
  PolyLabels,
  RoutePoints,
  markerStyle,
} from "./CustomMapComponents";
import {
  Expression,
  OnPressEvent,
  SymbolLayerProps,
} from "./MapComponentStyle";
import DrawControl from "./DrawControl";
import { defaultStyles } from "./DrawControl/styles";

export const MyMapsLayers = () => {
  const { userInfo, userMaps } = useContext(GlobalContext);

  const { navigate } = useNavigation();

  // const StaticMaps = useCallback(() => {
  //   const mapIDs = getMaps(userInfo);

  //   const filteredMapIDs = mapIDs.filter((mapID) => userMaps[mapID]);

  //   const mapInfos = {};

  //   filteredMapIDs.forEach((mapID) => {
  //     const mapInfo = userMaps[mapID].info;
  //     const { title, bbox } = mapInfo;

  //     mapInfos[mapID] = { title, bbox };
  //   });

  //   const onPress = async (e) => {
  //     const feature = e.features[0];
  //     const mapID = feature?.properties?.mapID;
  //     if (mapID) navigate(screenNames.MapHome, { mapID });
  //   };

  //   return <MapsCluster mapInfos={mapInfos} onPress={onPress} />;
  // }, [userMaps, userInfo]);

  if (!userInfo) return <></>;

  // return <StaticMaps />;
  return null;
};

export const SearchMapsLayers = () => {
  useEffect(() => {
    (async () => {
      const mapInfos = await getSearchMapInfos();
      setMapInfos(mapInfos);
    })();
  }, []);

  const [mapInfos, setMapInfos] = useState();

  const { navigate } = useNavigation();

  const onPress = async (e) => {
    const feature = e.features[0];
    const mapID = feature?.properties?.mapID;
    if (mapID) navigate(screenNames.MapHome, { mapID });
  };

  //show all or filtered
  return (
    <>{mapInfos && <MapsCluster mapInfos={mapInfos} onPress={onPress} />}</>
  );
};

// import{layouts } from "@mapbox/maki"

export const SearchPlacesLayers = (props: { data: FeatureCollection }) => {
  const { navigate } = useNavigation();
  const route = useCurrentRoute();

  const { navigateInMap } = useCustomNavigation();

  const onPress = async (e) => {
    const feature = e.features[0];
    const mapID = route?.params?.mapID;
    if (!mapID || !feature) return;

    if (mapID) {
      navigateInMap(screenNames.PlacePanel, { feature, mapID });

      // navigate(screenNames.PlacePanel, );
    }
  };

  const [isLoaded, setIsLoaded] = useState(false);

  // console.log(props.data);

  // // TypeError: Cannot read properties of undefined (reading 'get')

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 200);
  }, []);

  return (
    isLoaded && (
      <ShapeSource
        id={markerSourceIDs["search-places"]}
        data={props.data}
        onPress={onPress}
      >
        <SymbolLayer id="search-places" style={markerStyle("search")} />
        {/* <CircleLayer id="search-places" /> */}
      </ShapeSource>
    )
  );
};

const StaticMarkers = (props) => {
  const currentRoute: RouteProp<ParamListBase, string> = useCurrentRoute();
  const mapID = currentRoute?.params?.mapID;

  const { data, id, style } = props;

  const { navigateInMap } = useCustomNavigation();

  const navigation = useNavigation();

  const onPress = (e: { features: Feature[] }) => {
    // props.mode disable access to placePanel to prioritize edit panel
    if (e.features.length == 0 || props.mode) return;
    const feature = e.features[0];
    const placeID = feature?.properties?.placeID;
    if (!placeID) return;
    const params = { mapID, placeID, updated: Date.now() };
    navigateInMap(screenNames.PlacePanel, params);
  };

  return (
    <>
      <CustomPolygon
        id={props.layerID}
        data={props.data}
        // style={style}
        filter={["==", ["get", "type"], "area"]}
        onPress={onPress}
        sourceID={"polygon"}
        opacity={0.1}
      />
      <CustomLine
        id={props.layerID}
        data={props.data}
        // style={style}
        filter={["==", ["get", "type"], "route"]}
        onPress={onPress}
        sourceID={"route"}
      />
      <CustomSymbols
        id={props.layerID}
        data={props.data}
        style={{
          ...style,
          textField: ["coalesce", ["get", "title"], "No title"],
        }}
        onPress={onPress}
        sourceID={markerSourceIDs["map-markers"]}
      />
    </>
  );
};

const DrawFeatures = memo((props) => {
  const { data, id, style, mode } = props;

  const navigation = useNavigation();
  const route = useCurrentRoute();
  const { mapID } = route.params;
  const {
    putFeature,
    setLayersState,
    setMapDrawState,
    mapDrawState,
    setMapViewState,
  }: MapStateContextType = useContext(MapStateContext);
  const mapInfo = useMapInfo(mapID);

  const featureCollectionID = mapInfo?.featureCollection.id;

  const { navigate, setParams } = navigation;
  const navigateToEditPlace = (mapID, placeID) =>
    navigate(stackScreenNames.PlacePanel, {
      mapID,
      placeID,
      isEditing: true,
    });

  const [featureIDs, setFeatureIDs] = useState<Array<string>>([]);
  const [features, setFeatures] = useState(data?.features || []);

  let drawMode = mapDrawState.mode || "simple_select";

  // get feature from event => process by condition => setLayers => navigate
  const onCreate = useCallback(
    async (e) => {
      let newFeatures = e.features;

      let feature: Feature<LineString> = e.features[0];
      const id = feature.id;

      // if re-create feature
      // const currentPlaceID = route.params?.placeID;
      // refreshing shape or new shape
      // const placeID = currentPlaceID || getRandomID();
      const placeID = getRandomID();
      let geometry;

      feature["id"] = placeID;
      feature.properties["placeID"] = placeID;

      if (mapDrawState.type == "route") {
        const line: Feature<LineString> = feature;
        const points = line.geometry.coordinates.map((pt) => point(pt));

        const routes: Feature[] = await getDirections(points).then((res) => {
          const routes = res.body.routes;

          return routes.map(({ geometry: { coordinates } }) =>
            lineString(coordinates, { type: "route" })
          );
        });

        // reset
        if (routes.length == 0) return setFeatures([...features]);

        // set up feature
        // const newFeature = routes[0];
        // newFeature["id"] = placeID;
        // newFeature.properties["placeID"] = placeID;

        geometry = routes[0].geometry;
        feature.geometry = geometry;
        feature.properties = { type: "route" };
        // putFeature(newFeature);
      }

      if (mode == "drag") {
        // replace existing features
        // return setFeatureIDs([...featureIDs, feature.id]);
      }

      if (mapDrawState.type == "multi-select") {
        return setFeatureIDs([...featureIDs, feature.id]);
      }

      if (mapDrawState.type == "area") {
        feature.properties = { type: "area" };
      }

      if (mapDrawState.type == "place") {
        feature.properties = { type: "place" };
      }
      console.log(mapDrawState);
      if (mapDrawState.type == "pre-built") {
        const originalFeature: Feature = mapDrawState.feature;
        const interval = mapDrawState.interval;
        const units: Units = "meters";

        console.log(originalFeature, feature);

        const newFeatures = createFeaturesOnLineString(
          originalFeature,
          feature,
          { interval: 10 }
        ).map((f) => {
          const placeID = getRandomID();
          f["id"] = placeID;
          f.properties["type"] = "place";
          f.properties["placeID"] = placeID;
          return f;
        });

        const featuresObj = {};
        newFeatures.forEach((f) => {
          const placeID = f.id;
          featuresObj[placeID] = f;
        });
        const { mapID } = route.params;

        console.log(newFeatures);

        // addFeatures({
        //   mapID,
        //   features: featuresObj,
        //   initialFeatureCollectionID: featureCollectionID,
        // });

        return setFeatures((features) => [...features, ...newFeatures]);
      }

      if (mapDrawState.type == "ruler") {
        feature.properties["type"] = "ruler";
        return setFeatures((features) => [...features, feature]);
      }

      setFeatures((features) => [...features, feature]);
      const { mapID } = route.params;
      // if (currentPlaceID) {
      //   updateFeatureGeometry(mapID, placeID, geometry);
      // } else {

      addPlace({
        mapID,
        feature,
        initialFeatureCollectionID: featureCollectionID,
      });
      putFeature(feature);
      // navigateToEditPlace(mapID, placeID);
      // }
    },
    [mode, mapDrawState]
  );

  // after moving
  const onUpdate = useCallback((e) => {
    const updateDB = () => {
      const featuresObj = {};

      e.features.forEach((f) => {
        featuresObj[f.id] = f;
      });

      updateFeatureCollection({
        id: featureCollectionID,
        features: featuresObj,
        mapID: mapID,
      });
    };

    const addLayer = () => {
      const updatedFeatures: Feature[] = e.features;
      const newFeatures = features.map((f) => {
        const id = f.id;
        const updatedFeature = updatedFeatures.find((f2) => f2.id == id);
        return updatedFeature || f;
      });
      setLayersState([
        { data: featureCollection(newFeatures), layerID: mapID, type: "map" },
      ]);
    };

    updateDB();
    addLayer();
  }, []);

  // after moving
  const onDelete = useCallback((e) => {
    // const { mapID, placeID } = route?.params;
    // // const feature: Feature<LineString> = e.features[0];
    // const features: Feature<LineString>[] = e.features;
    // const ids = features.map((f) => f.properties.placeID);
    // deleteFeatures({mapID, id: "...", "features": ""})
  }, []);

  const PickUpBuildings = () => {
    const onPress = (e: OnPressEvent) => {
      const feature = e.features[0];
      if (!feature) return;

      // putFeature(feature);

      const { mapID } = route?.params;
      const placeID = getRandomID();

      putFeature(feature);
      navigateToEditPlace(mapID, placeID);
      // const currentPlaceID =
      // if (currentPlaceID) {
      //   updateFeatureGeometry(mapID, placeID, geometry);
      // } else {
      //   putFeature(feature);
      //   navigateToEditPlace(mapID, placeID);
      // }
    };

    return (
      <Buildings
        id="pick-up"
        // style={{ fillExtrusionColor: "orange", fillExtrusionOpacity: 0.4 }}
        style={{ fillExtrusionColor: "red", fillExtrusionOpacity: 0.8 }}
        onPress={onPress}
      />
    );
  };

  const onSelected = (e) => {
    let features: Feature[] = e.features;
    console.log(493, features.length);
    if (features.length == 0) return setFeatureIDs([]);

    const featureIDs = features.map((f) => f.properties.placeID);

    setFeatureIDs((state) => {
      const newFeatureIDs = featureIDs.filter((id) => !state.includes(id));
      return Array.from(new Set([...state, ...newFeatureIDs]));
    });

    // share with PlaceList
    setMapDrawState((state) => ({ ...state, featureIDs }));
  };

  // only for native to control mapview onpress map
  const onChangedMapState = (props) => {
    // console.log(setMapViewState);
    setMapViewState((state) => ({ ...state, ...props }));
  };

  useEffect(() => {
    // set bearing 0 if draw_rectangle
  }, []);

  return (
    <>
      <DrawControl
        // ref={drawRef}
        mode={drawMode}
        onCreate={onCreate}
        onUpdate={onUpdate}
        // onDelete={onDelete}
        onSelected={onSelected}
        // staticStyles={staticStyles}
        featureIDs={featureIDs}
        features={features}
        onChangedMapState={onChangedMapState}
      />
      {/* {renderDraw} */}
      {mode == "pick-up" && <PickUpBuildings />}

      {/* <GPSMapping/> */}
    </>
  );
});

//pure component
export const MapMarkers = React.memo((props: LayerStateType) => {
  const { mapDrawState, layersState } = useContext(MapStateContext);
  const mode = mapDrawState?.mode;

  return (
    <>
      <StaticMarkers {...props} mode={mode} />
      {mode && <DrawFeatures mode={mode} {...props} />}
      <AccessaryFeatures features={props.data?.features || []} />
    </>
  );
  // return render;
});

// non-responsive components. filter
export const AccessaryFeatures = ({ features }: { features: Feature[] }) => {
  // generate new features after filtering

  return (
    <>
      {/* <InitialMapImages /> */}
      {/* <TentFillExtrusion filter={["type", "tent"]}  /> */}
      {/* <Pulses filter={["focused", "true"]} /> */}
      <PolyLabels
        id="poly-labels-place"
        features={features}
        textField={["coalesce", ["get", "title"], "No title"]}
        style={{ textColor: "black", textAllowOverlap: false }}
        // filter={["type", "building"]}
        filter={["==", ["get", "type"], "place"]}
        mode="circle"
      />
      <PolyLabels
        id="poly-labels-area"
        features={features}
        style={{
          textField: ["coalesce", ["get", "title"], "No title"],
          textSize: 20,
        }}
        // textField={["coalesce", ["get", "title"], "No title"]}
        // filter={["type", "building"]}
        filter={["==", ["get", "type"], "area"]}
        labelVisibilityMode="outside"
      />
      <BuildingFillExtrusion
        features={features}
        filter={["type", "building"]}
      />
      <RoutePoints features={features} filter={["type", "route"]} />
      {/* <MovingPoints
         filter={["type", "route"]}
            mode="round-trip"
            features={features}
            sourceID={"bus-line"}
            // layerID={"bus"}
            animationDuration={30000}
            // distance={100}
            layerComponent={
              // <CircleLayer
              //   id="bus"
              //   style={{ circleRadius: 15, circleColor: "red" }}
              // />
              <SymbolLayer
                id={"bus-line"}
                belowLayerID={""}
                style={{
                  iconImage: "bus",
                  iconAllowOverlap: true,
                  iconIgnorePlacement: true,
                  iconSize: 1.5,
                }}
              />
            }
          /> */}
    </>
  );
};

const TestFill = () => {
  const polygon = bboxPolygon([0, 0, 150, 50]);

  return (
    <ShapeSource id={"test-poly"} data={polygon}>
      <FillLayer
        id={"test-poly"}
        style={{ fillColor: "red", fillOpacity: 0.3 }}
      />
    </ShapeSource>
  );
};

export const TestCamp = () => {
  const { fitBoundsByBbox } = useMapboxFunctions;

  const center = [0, 0];

  const x = 100 * 1;
  const y = 100 * 1;

  const mm = 1000;
  const height = mm * 100;

  const circleRadius = 10;

  const data = useMemo(() => {
    const features: Feature[] = [];

    Array(1000)
      .fill(1)
      .forEach((n, i) => {
        const center = [Math.random() * i, Math.random() * 60];
        const centerPoint = point(center);

        const toLon = (feature: Feature<Point>, distance: number) =>
          destination(getCoord(feature), distance, 90);
        const toLat = (feature: Feature<Point>, distance: number) =>
          destination(getCoord(feature), distance, 0);

        const nw = toLat(toLon(centerPoint, -x / 2), y / 2);
        const ne = toLat(toLon(centerPoint, x / 2), y / 2);
        const se = toLat(toLon(centerPoint, x / 2), -y / 2);
        const sw = toLat(toLon(centerPoint, -x / 2), -y / 2);

        const points = [nw, ne, se, sw];

        console.log(points.map((f) => getCoords(f)));
        points.forEach((feature) => {
          const f = circle(getCoord(feature), 10, {
            properties: { height, color: "red" },
          });

          features.push(f);
        });

        const basePolygon = bboxPolygon([...getCoord(sw), ...getCoord(ne)], {
          properties: { baseHeight: height, height, color: "red" },
        });

        features.push(basePolygon);

        // const baseGeometry = envelope(buffer(firstCircle, 0.01).geometry;
        const baseGeometry = basePolygon.geometry;

        let levelsOfCone = 20;

        const length = 100;

        for (let i = 0; i < levelsOfCone; i++) {
          let currentCircleGeometry = circle(
            center,
            length - length * (((i + 1) * 0.8) / levelsOfCone),
            { properties: { baseHeight: (height * i) / length } }
          );
          console.log(currentCircleGeometry);

          features.push(currentCircleGeometry);
        }

        // const baseGeometry = envelope(buffer(firstCircle, 0.01).geometry

        // return pole1;
      });

    return featureCollection(features);
  }, []);

  useEffect(() => {
    if (!fitBoundsByBbox) return;
    console.log(bbox(data), data);
    fitBoundsByBbox(bbox(data));
  }, [fitBoundsByBbox]);

  return (
    <ShapeSource id={"test-poly"} data={data}>
      <FillExtrusionLayer
        id={"test-pole"}
        style={{
          fillExtrusionHeight: ["coalesce", ["get", "height"], height],
          fillExtrusionBase: ["coalesce", ["get", "baseHeight"], 0],
          fillExtrusionColor: ["coalesce", ["get", "color"], "black"],
        }}
      />
    </ShapeSource>
  );
};

// new place, focused for place detail, editing
export const FocusedMarker = (props: LayerStateType) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 200);
  }, []);

  return (
    isLoaded && (
      // <TestFill />
      // <THeLayer />
      <CustomSymbols
        id={props.layerID}
        data={props.data}
        // onPress={onPress}
        sourceID={props.layerID}
      />
    )
  );
};

export const TourRoutes = (props: { data: FeatureCollection }) => {
  const {
    colors: { accent },
  } = useTheme();

  const lineColor = accent;

  return (
    <ShapeSource id="tour-routes" data={props.data}>
      <LineLayer
        id="tour-routes"
        style={{ lineWidth: 5, lineColor, lineDasharray: [3, 0.5] }}
      />

      {/* <SymbolLayer
    id="tour-routes"
    style={{ iconImage: "existing", iconAllowOverlap: true }}
  /> */}
      {/* <CircleLayer
    id="masked-markers"
    style={{ circleRadius: 15, circleColor: accent }}
  /> */}
    </ShapeSource>
  );
};

export const VisibleLayers = React.memo(() => {
  const currentRoute = useCurrentRoute();
  const routeName = currentRoute?.name;

  // return <></>;
  // render is hooked by layersState with useCallback?

  const { layersState, setLayersState }: MapStateContextType =
    useContext(MapStateContext);

  const layers = layersState.map((layer) => layer?.id);

  const Static = useMemo(() => {
    switch (routeName) {
      case screenNames.Home:
        return <MyMapsLayers />;
      case screenNames.SearchMaps || screenNames.Main:
        return <SearchMapsLayers />;
      default:
        console.log(layersState);

        return layersState?.map((layer, index) => {
          const layerType: layerTypes = layer.type;
          const prop = { ...layer, key: index };
          if (layerType == "map") return <MapMarkers {...prop} />;
          // if (layerType == "map") return <></>;
          if (layerType == "mask") return <MaskedMarkers {...prop} />;
          if (layerType == "search") return <SearchPlacesLayers {...prop} />;

          // if (layerType == "focus") return <TestFill key={"focus"} />;
          // if (layerType == "focus")
          //   return <FocusedMarker key={"focus"} {...prop} />;
          return <></>;
        });
    }
  }, [layers]);
  // }, [routeName, layers]);

  return Static;
  // return <Static />;
});

export const DimLights = () => {
  const points = randomPoint(100, { bbox: [0, -45, 90, 45] });

  return (
    <ShapeSource id="dim" data={points}>
      {/* <SymbolLayer id="dim" /> */}
      <CircleLayer
        id="dim-light"
        style={{
          circleColor: "white",
          circleBlur: 2,
          circleRadius: 30,
          circleOpacity: 0.5,
        }}
      />
      <CircleLayer
        id="dim-marker"
        style={{ circleColor: "yellow", circleRadius: 5, circleBlur: 1 }}
      />
    </ShapeSource>
  );
};

export const PlaceGroupsLayer = (props: { data: FeatureCollection }) => {
  const { navigate } = useNavigation();
  const route = useCurrentRoute();

  const { navigateInMap } = useCustomNavigation();

  const onPress = async (e) => {
    const feature = e.features[0];
    const mapID = route?.params?.mapID;
    if (!mapID || !feature) return;

    if (mapID) {
      navigateInMap(screenNames.PlacePanel, { feature, mapID });

      // navigate(screenNames.PlacePanel, );
    }
  };

  const [isLoaded, setIsLoaded] = useState(false);

  // // TypeError: Cannot read properties of undefined (reading 'get')

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 200);
  }, []);

  const features = props.data.features.map((f, i) => {
    f.properties["index"] = i;
    return f;
  });

  const [line, setLine] = useState();
  const { fitBoundsByBbox } = useMapboxFunctions();

  // useEffect(() => {
  //   if (!fitBoundsByBbox || line) return;
  //   getDirections(features).then((res) => {
  //     const data = res.body.routes[0];
  //     const coordinates = data.geometry.coordinates;

  //     const feature = lineString(coordinates);
  //     console.log(bbox(feature));
  //     setTimeout(() => {
  //       setLine(feature);

  //       fitBoundsByBbox(bbox(feature));
  //     }, 3000);
  //     // const geojson = {
  //     //   type: 'Feature',
  //     //   properties: {},
  //     //   geometry: {
  //     //     type: 'LineString',
  //     //     coordinates: route
  //     //   }
  //     // };
  //   });
  // }, [fitBoundsByBbox]);

  console.log(line);

  return (
    isLoaded && (
      <>
        (
        <ShapeSource
          id={markerSourceIDs["search-places"]}
          data={featureCollection(features)}
          onPress={onPress}
        >
          <SymbolLayer
            id="search-places"
            style={{ ...markerStyle("search") }}
          />
          {/* <CircleLayer
          id="index-circle"
          style={{
            circleRadius: 15,
            circleColor: "white",

            // circleTranslate: [20, -40],
          }}
        /> */}
          <SymbolLayer
            id="index"
            style={{
              textField: ["get", "index"],
              textOffset: [1.5, -2],
              // textTranslate: [20, -40],
              iconTextFitPadding: [4, 4, 4, 4],
              // iconPadding: ,
              textPadding: 4,
              iconTextFit: "both",
              iconImage: "circle",
            }}
          />
        </ShapeSource>
        {line && (
          <ShapeSource
            id={"line-route"}
            data={line}
            // onPress={onPress}
          >
            <LineLayer id="line-route" />
          </ShapeSource>
        )}
      </>
    )
  );
};

export const UserLocationLayer = (props: {
  mapID: string;
  groupID: string;
}) => {
  useEffect(() => {}, []);

  const { navigate } = useNavigation();
  const route = useCurrentRoute();

  const { navigateInMap } = useCustomNavigation();

  const onPress = async (e) => {
    const feature = e.features[0];
    const mapID = route?.params?.mapID;
    if (!mapID || !feature) return;

    if (mapID) {
      navigateInMap(screenNames.PlacePanel, { feature, mapID });

      // navigate(screenNames.PlacePanel, );
    }
  };

  const [isLoaded, setIsLoaded] = useState(false);

  // // TypeError: Cannot read properties of undefined (reading 'get')

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 200);
  }, []);

  const features = props.data.features.map((f, i) => {
    f.properties["index"] = i;
    return f;
  });

  const [line, setLine] = useState();
  const { fitBoundsByBbox } = useMapboxFunctions();

  // useEffect(() => {
  //   if (!fitBoundsByBbox || line) return;
  //   getDirections(features).then((res) => {
  //     console.log();
  //     const data = res.body.routes[0];
  //     const coordinates = data.geometry.coordinates;
  //     console.log(data);
  //     console.log(route);

  //     const feature = lineString(coordinates);
  //     console.log(bbox(feature));
  //     setTimeout(() => {
  //       setLine(feature);

  //       fitBoundsByBbox(bbox(feature));
  //     }, 3000);
  //     // const geojson = {
  //     //   type: 'Feature',
  //     //   properties: {},
  //     //   geometry: {
  //     //     type: 'LineString',
  //     //     coordinates: route
  //     //   }
  //     // };
  //   });
  // }, [fitBoundsByBbox]);

  console.log(line);

  return (
    isLoaded && (
      <>
        (
        <ShapeSource
          id={markerSourceIDs["search-places"]}
          data={featureCollection(features)}
          onPress={onPress}
        >
          <SymbolLayer
            id="search-places"
            style={{ ...markerStyle("search") }}
          />
          {/* <CircleLayer
          id="index-circle"
          style={{
            circleRadius: 15,
            circleColor: "white",

            // circleTranslate: [20, -40],
          }}
        /> */}
          <SymbolLayer
            id="index"
            style={{
              textField: ["get", "index"],
              textOffset: [1.5, -2],
              // textTranslate: [20, -40],
              iconTextFitPadding: [4, 4, 4, 4],
              // iconPadding: ,
              textPadding: 4,
              iconTextFit: "both",
              iconImage: "circle",
            }}
          />
        </ShapeSource>
        {line && (
          <ShapeSource
            id={"line-route"}
            data={line}
            // onPress={onPress}
          >
            <LineLayer id="line-route" />
          </ShapeSource>
        )}
      </>
    )
  );
};

import * as Location from "expo-location";
import { AppState, AppStateStatus } from "react-native";
import {
  createFeaturesOnLineString,
  createPointsOnLineString,
  createPolygonOnLineString,
  getPointsOnLineString,
  getRect,
} from "../localFunctions/map";
// https://docs.expo.dev/versions/latest/sdk/location/
export const GPSMapping = ({
  onStart,
  onFinish,
}: {
  onStart?: () => void;
  onFinish?: (features: Feature[]) => void;
}) => {
  const [features, setFeatures] = useState([]);
  const [isStarted, setIsStarted] = useState(false);
  const [isGranted, setIsGranted] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    (async () => {
      let { status } = await Location.requestForegroundPermissionsAsync();
      console.log(status);
      if (status == "granted") {
        console.log("granted");
        setIsGranted(true);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (!isGranted) return;

      const id = setTimeout(async () => {
        // getLocation
        let location = await Location.getCurrentPositionAsync({});

        const { longitude, latitude, accuracy } = location.coords;
        console.log("accuracy", accuracy);
        if (accuracy > 10) return setCount((count) => count + 1);

        setIsStarted((value) => {
          if (!value) {
            onStart && onStart();
          }
          return true;
        });
        // setIsStarted(true)

        setFeatures((features) => {
          return [...features, point([longitude, latitude])];
        });

        setCount((count) => count + 1);
      }, 1000);

      return () => clearTimeout(id);
    })();
  }, [count, isGranted]);

  const onPress = () => {
    const simplified = simplify(featureCollection(features), {
      tolerance: 0.01,
    });
    onFinish && onFinish(simplified.features);
  };

  // const isLine = getCoords(feature).length > 1;

  // const simplified = simplify(featureCollection(features), {
  //   tolerance: 0.01,
  // })

  const color = useTheme().colors.accent;

  return (
    <>
      <ShapeSource
        id="gps-mapping"
        data={featureCollection(features)}
        onPress={onPress}
      >
        <CircleLayer
          id="gps-mapping"
          style={{ circleColor: color, circleOpacity: 0.6, circleBlur: 0.5 }}
        />
        {/* <SymbolLayer id="gps-mapping" /> */}
        {/* {defaultStyles.map(layer =>   )} */}
      </ShapeSource>
    </>
  );
};

export const Ruler = ({
  features,
}: {
  features: Feature[];
  filter?: Expression;
}) => {
  const line = features.find(
    (f) => f.properties.type == "ruler" && f.geometry.type == "LineString"
  );
  if (!line) return null;
  // const line = lineString([sw, ne]); /* あなたのラインデータ */
  // const bearing0 = bearing(sw, ne);
  const units = "meters"; // "kilometers"
  const distance = 1; // 移動させたい距離（5メートル）
  const lineDistanced = lineDistance(line, { units });

  const points = [];
  for (let i = 0; i < lineDistanced; i += distance) {
    const point = along(line, i, { units });
    const largeScale = i % 5 == 0;
    if (largeScale) point.properties["large"] = "true";
    point.properties["distance"] = i;
    points.push(point);
  }

  const isLarge = ["==", ["get", "large"], "true"];

  const checkLarge = (num: number) => [
    "==",
    ["%", ["get", "distance"], num],
    0,
  ];

  const layers: SymbolLayerProps[] = [
    {
      minZoomLevel: 10,
      maxZoomLevel: 13,
      filter: checkLarge(1000),
    },
    { minZoomLevel: 13, maxZoomLevel: 16, filter: checkLarge(100) },
    { minZoomLevel: 16, maxZoomLevel: 18, filter: checkLarge(20) },
    { minZoomLevel: 18, maxZoomLevel: 20, filter: checkLarge(10) },
    { minZoomLevel: 20, maxZoomLevel: 24, filter: checkLarge(1) },
  ];

  const sourceID = "test-align-points";

  return (
    <>
      <ShapeSource id={sourceID} data={featureCollection([...points, line])}>
        <LineLayer id="measure-line" style={{}} />
        {layers.map((layer, index) => (
          <>
            <CircleLayer
              {...layer}
              sourceID={sourceID}
              id={"measure-s-" + index}
              filter={["all", layer.filter]}
              style={{
                circleRadius: ["case", isLarge, 3, 1.5],
              }}
            />
            <SymbolLayer
              {...layer}
              sourceID={sourceID}
              id={"measure-l-" + index}
              filter={["all", layer.filter, isLarge]}
              style={{
                textField: "{distance}",
                textHaloColor: "white",
                textHaloWidth: 1,
                // textRotate: bearing0 - 90,
                textOffset: [1, 1],
              }}
            />
          </>
        ))}
      </ShapeSource>
    </>
  );
};

import { isProduct } from "../app.config";

type extraEnvType = {
  FB_API_KEY: string;
  FB_AUTH_DOMAIN: string;
  FB_DATABASE_URL: string;
  FB_PROJECT_ID: string;
  FB_STORAGE_BUCKET: string;
  FB_MESSAGING_SENDER_ID: string;
  FB_APP_ID: string;
  FB_MEASUREMENT_ID: string;
  FB_WEB_API_KEY: string;
  FACEBOOK_AUTH_ID: string;
  GOOGLE_IOS_CLIENT_ID: string;
  GOOGLE_ANDROID_CLIENT_ID: string;
  GOOGLE_WEB_CLIENT_ID: string;
  ALGOLIA_APP_ID: string;
  ALGOLIA_API_KEY: string;
  MAPBOX_TOKEN: string;
  AWS_ACCESS_KEY_ID: string;
  AWS_SECRET_ACCESS_KEY: string;
};

export const FB_API_KEY = "AIzaSyCVMR6xgRoJUGP7dKx1b_8yUACPXculjD0";
// export const FB_AUTH_DOMAIN = "drivers-d1a9f.firebaseapp.com";
// export const FB_AUTH_DOMAIN = "mapkid.info";
export const FB_AUTH_DOMAIN = "mapnom.com";
export const FB_DATABASE_URL =
  "https://drivers-d1a9f-default-rtdb.firebaseio.com";
export const FB_PROJECT_ID = "drivers-d1a9f";
export const FB_STORAGE_BUCKET = "drivers-d1a9f.appspot.com";
export const FB_MESSAGING_SENDER_ID = "1030494336026";
export const FB_APP_ID = "1:1030494336026:web:33e4aaffa61ab462304da3";
export const FB_MEASUREMENT_ID = "G-KC46KYT31V";
export const FB_WEB_API_KEY = "AIzaSyCVMR6xgRoJUGP7dKx1b_8yUACPXculjD0";
export const FACEBOOK_AUTH_ID = "363541961714201";
export const GOOGLE_IOS_CLIENT_ID =
  "1030494336026-rmblcugl52p9l2a7i0ibgivc4iv8oim4.apps.googleusercontent.com";
export const GOOGLE_ANDROID_CLIENT_ID =
  "1030494336026-d7268ml4kqbqt18q9e4svek6co9f2p1o.apps.googleusercontent.com";
export const GOOGLE_WEB_CLIENT_ID =
  "1030494336026-5faqif4pomo9677l07r5v3al569t4d77.apps.googleusercontent.com";
export const ALGOLIA_APP_ID = "J4WV3DPJL3";
export const ALGOLIA_API_KEY = "0d0eec1848d8f5d42fc7f9bf02f40bb0";
export const MAPBOX_TOKEN =
  "sk.eyJ1IjoibWFwa2lkIiwiYSI6ImNrdm9kcWcxMTVzdjQycm9rNzMxd25yenQifQ.exEP7x2UJB29LDfx3NdF8w";
export const PUBLIC_MAPBOX_TOKEN =
  "pk.eyJ1IjoibWFwa2lkIiwiYSI6ImNrZ3U0a3d6aTBrOWgyeXRtanh0aHUzOTcifQ.ss4jGIc7hG9VyW80bQ81jw";

export const AWS_ACCESS_KEY_ID = "IU615KEHQSKDI9HMBEQH";
export const AWS_SECRET_ACCESS_KEY = "cgS5CUKR3Rg6DuQBObozRGdSFtYIKnHdfyvljeSz";

export const firebaseConfig = isProduct
  ? {
      apiKey: FB_API_KEY,
      authDomain: FB_AUTH_DOMAIN,
      databaseURL: FB_DATABASE_URL,
      projectId: FB_PROJECT_ID,
      storageBucket: FB_STORAGE_BUCKET,
      messagingSenderId: FB_MESSAGING_SENDER_ID,
      appId: FB_APP_ID,
      measurementId: FB_MEASUREMENT_ID,
    }
  : // ? {
    //       apiKey: Constants.manifest?.extra.  "AIzaSyCVMR6xgRoJUGP7dKx1b_8yUACPXculjD0",
    //       authDomain: "drivers-d1a9f.firebaseapp.com",
    //       databaseURL: "https://drivers-d1a9f-default-rtdb.firebaseio.com",
    //       projectId: "drivers-d1a9f",
    //       storageBucket: "drivers-d1a9f.appspot.com",
    //       messagingSenderId: "1030494336026",
    //       appId: "1:1030494336026:web:33e4aaffa61ab462304da3",
    //       measurementId: "G-KC46KYT31V",
    //     }
    {
      apiKey: "AIzaSyCVz4A7v2pEFjxMLrv9DmUFcuyHOXZjScs",
      authDomain: "rounds-test-fe5b9.firebaseapp.com",
      databaseURL: "https://rounds-test-fe5b9-default-rtdb.firebaseio.com",
      projectId: "rounds-test-fe5b9",
      storageBucket: "rounds-test-fe5b9.appspot.com",
      messagingSenderId: "789137726755",
      appId: "1:789137726755:web:ce37b8affc6f53e69e4096",
      measurementId: "G-5JZWQ9DE0H",
    };

// import {
//   FB_API_KEY,
//   FB_AUTH_DOMAIN,
//   FB_DATABASE_URL,
//   FB_PROJECT_ID,
//   FB_STORAGE_BUCKET,
//   FB_MESSAGING_SENDER_ID,
//   FB_APP_ID,
//   FB_MEASUREMENT_ID,
//   FB_WEB_API_KEY,
//   FACEBOOK_AUTH_ID,
//   GOOGLE_IOS_CLIENT_ID,
//   GOOGLE_ANDROID_CLIENT_ID,
//   GOOGLE_WEB_CLIENT_ID,
// } from "@env";
// https://medium.com/swlh/how-to-properly-use-environment-variables-in-an-expo-react-native-app-7ab852590b30

export const googleAuthIDs = isProduct
  ? {
      expoClientId:
        "789137726755-5idcdcta36i3fa97bid6au1pr7gmng0m.apps.googleusercontent.com",

      iosClientId: GOOGLE_IOS_CLIENT_ID,
      androidClientId: GOOGLE_ANDROID_CLIENT_ID,
      webClientId: GOOGLE_WEB_CLIENT_ID,
    }
  : {
      expoClientId:
        "789137726755-5idcdcta36i3fa97bid6au1pr7gmng0m.apps.googleusercontent.com",
      iosClientId:
        "789137726755-oe7107pl3af7ckjtnqgjnhbtjqvl3i4c.apps.googleusercontent.com",
      androidClientId:
        "789137726755-gb84dcff12dlr4s1rp5bi558tqcmctta.apps.googleusercontent.com",
      webClientId:
        "789137726755-5idcdcta36i3fa97bid6au1pr7gmng0m.apps.googleusercontent.com",
    };

import React, { useState } from "react";
import * as FileSystem from "expo-file-system";

import Image from "./Image";

import { StyleSheet, View, ViewStyle } from "react-native";
import { debug, isWebOS } from "../localFunctions";

interface imageInterface {
  type: "image";
  width: number | string;
  height: number | string;
  url: string;
  resizeMode: string;
}
interface userInterface {
  type: "user-icon";
  url: string;
  size: number;
}
interface mapInterface {
  type: "map-icon";
  url: string;
  size: number;
}

//{ type: "image", width, height,url }, {type: "user-icon", size, url}, {"map-icon", size, url}
export default function ({
  type,
  url,
  width,
  height,
  size,
  noRound,
  resizeMode,
  altUrl,
}: imageInterface | userInterface) {
  const [aspectRatio, setAspectRatio] = useState(null);

  const onLoad = (e) => {
    const { width, height } = e.nativeEvent;
    setAspectRatio(width / height);
  };

  // const Authorization = getS3Header(url);

  const props = isWebOS
    ? {
        source: {
          uri: url,

          // headers: { Authorization: Authorization },
        },
      }
    : //inherit from FastImage
      {
        source: {
          uri: url,
          priority: Image.priority.normal,
          // headers: { Authorization: Authorization },
        },
      };

  const [isLoaded, setIsLoaded] = useState(!altUrl);
  const onLoadEnd = () => {
    if (altUrl) {
      // debug("loaded" + " : " + altUrl);
      setIsLoaded(true);
    }
  };

  if (type == "image") {
    return (
      <View
        style={{
          height,
          width: width ? width : "100%",
          aspectRatio: height ? undefined : aspectRatio,
        }}
      >
        {!isLoaded && (
          <Image
            resizeMode={resizeMode}
            style={{
              // position: "absolute",
              height,
              minWidth: width ? width : "100%",
              aspectRatio: height ? undefined : aspectRatio,
              width: width ? width : "100%",
              borderRadius: noRound ? 0 : 10,
            }}
            // onLoad={onLoad}
            source={{ uri: altUrl }}
            // {...props}
          />
        )}

        <Image
          resizeMode={resizeMode}
          style={{
            position: "absolute",
            height,
            minWidth: width ? width : "100%",
            aspectRatio: height ? undefined : aspectRatio,
            width: width ? width : "100%",
            borderRadius: noRound ? 0 : 10,
          }}
          // onLoad={onLoad}
          onLoadEnd={onLoadEnd}
          {...props}
        />
      </View>
    );
  } else if (type == "user-icon" || type == "map-icon") {
    return (
      <Image
        style={{
          width: size || 60,
          height: size || 60,
          borderRadius: noRound ? 0 : 10,
        }}
        {...props}
        // onLoad={(event) => debug(event.nativeEvent)}
      />
    );
  } else {
    return <View />;
  }
}

function nextImage({
  type,
  url,
  resizeMode,
  altUrl,
}: imageInterface | userInterface) {
  const [aspectRatio, setAspectRatio] = useState(null);

  const onLoad = (e) => {
    const { width, height } = e.nativeEvent;
    setAspectRatio(width / height);
  };

  // const Authorization = getS3Header(url);
  // headers: { Authorization: Authorization },

  const source = {
    uri: url,
  };
  if (!isWebOS) {
    source["priority"] = Image.priority.normal;
  }

  const [isLoaded, setIsLoaded] = useState(!altUrl);
  const onLoadEnd = () => {
    if (altUrl) {
      // debug("loaded" + " : " + altUrl);
      setIsLoaded(true);
    }
  };

  const style: ViewStyle = {
    // position: "absolute",
    height,
    minWidth: width ? width : "100%",
    aspectRatio: height ? undefined : aspectRatio,
    width: width || "100%",
    borderRadius: noRound ? 0 : 10,
  };

  const AltImage = () => (
    <Image
      resizeMode={resizeMode}
      style={style}
      // onLoad={onLoad}
      source={{ uri: altUrl }}
    />
  );

  const OriginalImage = () => (
    <Image
      resizeMode={resizeMode}
      style={style}
      // onLoad={onLoad}
      onLoadEnd={onLoadEnd}
      source={source}
    />
  );

  if (type == "image") {
    return (
      <View
        style={{
          height,
          width: width ? width : "100%",
          aspectRatio: height ? undefined : aspectRatio,
        }}
      >
        {!isLoaded && <AltImage />}
        <OriginalImage />
      </View>
    );
  } else {
    return <View />;
  }
}

const styles = StyleSheet.create({
  // image:{
  //   position: "absolute",
  //   height,
  //   minWidth: width ? width : "100%",
  //   aspectRatio: height ? undefined : aspectRatio,
  //   width: width ? width : "100%",
  //   borderRadius: noRound ? 0 : 10,
  // }
});

import { StatusBar } from "expo-status-bar";
import React, { useContext, useEffect, useState } from "react";
import { StyleSheet, Text, View, Image, Platform } from "react-native";
import { FAB, Paragraph, Avatar, Dialog, Button } from "react-native-paper";
import * as Facebook from "expo-auth-session/providers/facebook";
// import * as Facebook from 'expo-facebook';
import * as GoogleSignIn from "expo-google-sign-in";
// import * as Google from 'expo-google-app-auth';

import firebase from "firebase/compat/app";
import "firebase/compat/auth";

// import firebase from "firebase";
import ActivityIndicator from "../components/ActivityIndicator";
import * as Crypto from "expo-crypto";
import * as AppleAuthentication from "expo-apple-authentication";

import {
  debug,
  loginAction,
  loginWithUid,
  useSignInWithCredential,
} from "../localFunctions";

import * as WebBrowser from "expo-web-browser";
import * as Google from "expo-auth-session/providers/google";

import { GlobalContext } from "../Context";

import * as SecureStore from "expo-secure-store";

import { googleAuthIDs } from "../app.config";

const isWebOS = Platform.OS === "web";

/// must in Web!!
if (isWebOS) {
  WebBrowser.maybeCompleteAuthSession();
}

export default function App({ navigation, route, setLoggingIn }) {
  const { setUserInfo, setLogin, login, setUserMaps, setSnackbarText } =
    useContext(GlobalContext);
  const { signInWithCredential } = useSignInWithCredential();

  const AppleSignin = async () => {
    setLoggingIn(true);

    if (isWebOS) {
      var provider = new firebase.auth.OAuthProvider("apple.com");

      return (
        firebase
          .auth()
          // .signInWithRedirect(provider)
          .signInWithPopup(provider)
          // .signInWithPopup(provider)
          .then(async (result) => {
            // /** @type {firebase.auth.OAuthCredential} */
            var credential = result.credential;

            // The signed-in user info.
            var user = result.user;
            const { uid } = user;

            const res = await loginWithUid({
              uid,
            });

            // debug(res.type);
            loginAction({ res, navigation, setLogin, setUserInfo });
            // You can also get the Apple OAuth Access and ID Tokens.
            var accessToken = credential.accessToken;
            var idToken = credential.idToken;

            return credential;
            // ...
          })
          .catch((error) => {
            // Handle Errors here.
            alert(JSON.stringify(error));
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;

            // ...
          })
      );
    }

    //native process from here

    async function nativeCredential() {
      const digestedNonce = await Crypto.digestStringAsync(
        Crypto.CryptoDigestAlgorithm.SHA256,
        nonce
      ); // SHA256でノンスをハッシュ化
      const credential = await AppleAuthentication.signInAsync({
        requestedScopes: [
          AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
          AppleAuthentication.AppleAuthenticationScope.EMAIL,
        ],
        nonce: digestedNonce,
      });

      return credential;
    }

    const nonce = nonceGen(32); // ランダム文字列（ノンス）を生成

    try {
      const credential = isWebOS
        ? await webCredential()
        : await nativeCredential();

      const { email } = credential;

      const { familyName, givenName } = credential.fullName;
      const userInfo = { email, familyName, givenName };
      // console.log(credential)
      let provider = new firebase.auth.OAuthProvider("apple.com");
      let credential2 = provider.credential({
        idToken: credential.identityToken,
        rawNonce: nonce, // Firebase側には元のノンスを渡して検証させる
      });

      // debug(credential2.idToken);
      // signInWithCredential(credential2, userInfo);
      await signInWithCredential(credential2, userInfo);
      setLoggingIn(false);

      // signed in
    } catch (e) {
      if (e.code === "ERR_CANCELED") {
        // handle that the user canceled the sign-in flow
      } else {
        console.log(e);

        // handle other errors
      }
    }
  };

  return isWebOS ? (
    // <View />
    <Button
      uppercase={false}
      color="black"
      icon="apple"
      mode="contained"
      onPress={AppleSignin}
    >
      Sign in with Apple
    </Button>
  ) : (
    <AppleAuthentication.AppleAuthenticationButton
      buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
      buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.BLACK}
      cornerRadius={10}
      style={{ width: 250, height: 40 }}
      onPress={AppleSignin}
    />
  );
}

function nonceGen(length) {
  let result = "";
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

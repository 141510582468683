import { StyleSheet, Text, View, TouchableOpacity } from "react-native";

import { StatusBar } from "expo-status-bar";
import React, { useState, useMemo } from "react";
import { Card, Dialog, IconButton, Menu, Portal } from "react-native-paper";
import { debug, getS3Size } from "../localFunctions";
import { LinearGradient } from "expo-linear-gradient";
import StyledText from "./StyledText";
export default function ({
  onPress,
  defaultColor,
  from,
  size = 40,
  mode = "compact",
}: {
  onPress: Function;
  defaultColor: string;
  from?: "bottom-right" | "bottom-left" | "top-right" | "top-left";
  size?: number;
  mode?: "compact" | "open";
}) {
  //hsl color
  // "hsl(" + value * 360 + ",100%,50%)"
  const hues = useMemo(
    () =>
      Array.from(Array(12))
        .fill(0)
        .map((i, index) => index * 30),
    []
  );

  const lights = useMemo(
    () =>
      Array.from(Array(10))
        .fill(0)
        .map((i, index) => index * 8 + 10),
    []
  );

  const colors = hues.map((hue) =>
    lights.map((light) => "hsl(" + hue + ", " + 100 + "%," + light + "%)")
  );

  const [color, setColor] = useState(defaultColor);

  const onPressGrid = (color) => {
    setColor(color);
    onPress && onPress(color);
  };

  const [visible, setVisible] = useState(false);

  const flatedColors = colors.map((c) => c[7]);

  const DeleteColor = () => {
    return (
      <IconButton
        // icon={"restore"}
        // style={{position: "absolute", }}
        onPress={() => {
          onPress(null);
          setColor(null);
        }}
        // color={color}
        icon="water-off"
      />
    );
  };

  const openMenu = () => setVisible(true);

  const closeMenu = () => setVisible(false);

  const MainButton = () => {
    const ButtonContainer = ({ children }) => {
      const defaultSize = 40;
      const sizeStyles = {
        width: size || defaultSize,
        height: size || defaultSize,
      };
      return color ? (
        <View
          style={{
            backgroundColor: color,
            ...styles.buttonStyle,
            ...sizeStyles,
          }}
        >
          {children}
        </View>
      ) : (
        <LinearGradient
          colors={flatedColors}
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 1 }}
          style={{
            ...styles.buttonStyle,

            ...sizeStyles,
          }}
        >
          {children}
        </LinearGradient>
      );
    };

    return (
      <TouchableOpacity
        style={{ borderRadius: 5 }}
        onPress={() => setVisible(!visible)}
      >
        <ButtonContainer>
          {visible && (
            <IconButton icon={"close"} onPress={() => setVisible(!visible)} />
          )}
        </ButtonContainer>
      </TouchableOpacity>
    );
  };

  const ColorsGrid = () => {
    return (
      <View style={{ flexDirection: "row" }}>
        {colors.map((lights, index) => (
          <View key={index}>
            {lights.map((bColor) => (
              <TouchableOpacity
                key={bColor}
                style={{
                  width: 20,
                  height: 20,
                  backgroundColor: bColor,
                  borderWidth: bColor == color ? 2 : undefined,
                }}
                onPress={() => onPressGrid(bColor)}
              />
            ))}
          </View>
        ))}
      </View>
    );
  };

  const showPalette = true;

  return (
    <View
      style={
        {
          // flexDirection: "row",
          // alignItems: "center",
          // justifyContent: "center",
        }
      }
    >
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        {color && <DeleteColor />}
        <MainButton />
      </View>
      {/* <Menu visible={visible} onDismiss={closeMenu} anchor={<MainButton />}>
        <Card.Content>
          <ColorsGrid />
        </Card.Content>
      </Menu> */}

      {showPalette ? (
        <ColorsGrid />
      ) : (
        <Portal>
          <Dialog
            style={{ alignSelf: "center" }}
            visible={visible}
            onDismiss={() => setVisible(false)}
          >
            <Dialog.Content>
              <ColorsGrid />
            </Dialog.Content>
          </Dialog>
        </Portal>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  buttonStyle: {
    borderWidth: 2,
    borderRadius: 10,
    borderColor: "grey",
    alignItems: "center",
    justifyContent: "center",
  },
});

import React, {
  useState,
  createRef,
  useCallback,
  useEffect,
  useContext,
} from "react";
import {
  KeyboardAvoidingView,
  View,
  Text,
  StyleSheet,
  Platform,
  TouchableOpacity,
  Dimensions,
  Image,
  ScrollView,
  FlatList,
  SafeAreaView,
} from "react-native";
import { IconButton } from "react-native-paper";
import * as ImagePicker from "expo-image-picker";
import firebase from "firebase";
import "firebase/functions";
import { SwipeablePanel } from "rn-swipeable-panel";
import SearchList from "./SearchList";
import * as Clipboard from "expo-clipboard";
import { GlobalContext, GlobalSubContext, GlobalProvider } from "../Context";

import Modal from "../components/Modal";

import CachedImage from "../components/CachedImage";

import {
  pickImage,
  uploadImage,
  mapRef,
  isWebOS,
  debug,
} from "../localFunctions";

import { Audio, Video } from "expo-av";

import i18n from "i18n-js";

// import { ImageBrowser } from 'expo-image-picker-multiple';
import EditPlace from "./EditPlace";

import * as MediaLibrary from "expo-media-library";

// LeftAction, ChatInput, SendButton
const ITEM_WIDTH = Dimensions.get("window").width;

import { useRef } from "react";
import { filterProperties } from "@turf/turf";
import { langDict } from "../languages/langCheck";

const getMediaTypes = (onlyImage) =>
  onlyImage
    ? [MediaLibrary.MediaType.photo]
    : //temporarily checking video compress....
      // : [MediaLibrary.MediaType.photo, MediaLibrary.MediaType.video];
      [MediaLibrary.MediaType.photo];

export default function ({
  onPressCancel,
  onPressOK,
  onlyImage,
}: {
  onPressCancel: () => void;
  onPressOK: (imageUris: any[]) => void;
  onlyImage: boolean;
}) {
  const [albums, setAlbums] = useState(null);

  //original images. it will be filtered by albums
  const [deviceImages, setDeviceImages] = useState(null);
  const [shownMedias, setShownMedias] = useState(deviceImages);
  const [selectedMedias, setselectedMedias] = useState([]);

  const { setSnackbarText } = useContext(GlobalSubContext);

  const mediaType = getMediaTypes(onlyImage);

  useEffect((albums) => {
    (async () => {
      await MediaLibrary.requestPermissionsAsync();

      const media = await MediaLibrary.getAssetsAsync({
        first: 100,
        mediaType: mediaType,
        sortBy: MediaLibrary.SortBy.default,
      });
      const albums = await MediaLibrary.getAlbumsAsync({
        includeSmartAlbums: true,
      });

      const assets = media.assets;

      const filtered = assets.filter(
        (asset) =>
          asset.mediaType !== "video" ||
          (asset.mediaType == "video" && asset.duration < 10)
      );

      albums.unshift({ type: "recent" });

      setAlbums(albums);

      setDeviceImages(filtered);

      media.assets = filtered;

      setShownMedias(media);
    })();
  }, []);

  const pressCancel = () => {
    onPressCancel();
    // setIsPanelActiveImages(false);
  };

  const pressOK = () => {
    if (selectedMedias && selectedMedias.length > 0) {
      //get mediaObj from ID

      // return debug(selectedMedias);

      // return  debug ( await MediaLibrary.getAssetInfoAsync(mediaID))
      const promises = selectedMedias.map(async (mediaID) => {
        const info = await MediaLibrary.getAssetInfoAsync(mediaID);
        // return debug(info);
        // debug(info);
        return info;

        const targetMedia = await deviceImages.find(
          (media) => media.id === mediaID
        );
        debug(targetMedia);
        //without this, fetch in uploadMessageVideo is not working....
        if (targetMedia.uri.indexOf("assets-library") > -1) {
          // alert(JSON.stringify(targetMedia));
          const res = await MediaLibrary.getAssetInfoAsync(targetMedia);
          targetMedia.uri = res.localUri;
          // alert(JSON.stringify(res));
        }
        return targetMedia;
      });

      Promise.all(promises).then((res) => {
        // alert(JSON.stringify(res));
        onPressOK(res);
      });
    }
  };

  const touchImage = useCallback(
    (item, setChecked, selectedRef) => {
      // const index = selectedMedias.indexOf(item.id);
      const index = selectedMedias.findIndex((media) => item.id == media.id);

      const maxMedias = 4;

      if (index == -1) {
        if (selectedMedias.length < maxMedias) {
          selectedMedias.push(item);
          selectedRef.current.push(item);
          setselectedMedias([...selectedMedias]);
          setChecked(true);
        } else {
          // setSnackbarText("max 4 files!");
        }
      } else {
        selectedMedias.splice(index, 1);
        selectedRef.current.splice(index, 1);
        setselectedMedias([...selectedMedias]);
        setChecked(false);
      }
    },
    [shownMedias]
  );

  const pressAlbum = useCallback(async (item) => {
    const params =
      item.type == "recent"
        ? {
            first: 100,
            mediaType: mediaType,
            sortBy: MediaLibrary.SortBy.creationTime,
          }
        : {
            album: item.id,
            first: 100,
            mediaType: mediaType,
          };

    const media = await MediaLibrary.getAssetsAsync(params);

    const assets = media.assets;

    const filtered = assets.filter(
      (asset) =>
        asset.mediaType !== "video" ||
        (asset.mediaType == "video" && asset.duration < 10)
    );

    media.assets = filtered;

    setShownMedias(media);
  }, []);

  const selectedRef = useRef([]);

  const SelectedImage = ({ id }) => {
    // get first image in album

    useEffect(() => {
      (async () => {
        const media = await MediaLibrary.getAssetInfoAsync(id);

        setImage(media.uri);
      })();
    }, []);

    const [image, setImage] = useState(null);

    const onPress = () => {
      const index = selectedMedias.indexOf(id);

      selectedMedias.splice(index, 1);
      setselectedMedias([...selectedMedias]);
    };

    return (
      image && (
        <TouchableOpacity
          style={{ alignItems: "center", padding: 5 }}
          // onPress={onPress}
        >
          <Image source={{ uri: image }} style={styles.selectedImage} />
        </TouchableOpacity>
      )
    );
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <View
        style={{
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <IconButton icon="arrow-left" onPress={pressCancel} />

        <View style={{ flexDirection: "row" }}>
          {selectedMedias.map((mediaID) => (
            <SelectedImage id={mediaID} />
          ))}
        </View>

        {/* <IconButton icon="image" /> */}
        {/* <Title>Images</Title> */}
        <IconButton
          style={{
            backgroundColor: selectedMedias.length > 0 ? "coral" : undefined,
          }}
          color={selectedMedias.length > 0 ? undefined : "grey"}
          icon="check"
          onPress={pressOK}
        />
        <Text style={{ position: "absolute", right: 50 }}>
          {selectedMedias.length}
        </Text>
      </View>
      <Albums albums={albums} pressAlbum={pressAlbum} />
      <MediasList
        shownMedias={shownMedias}
        touchImage={touchImage}
        onlyImage={onlyImage}
        selectedRef={selectedRef}
      />
    </SafeAreaView>
  );
}

const Albums = React.memo(({ albums, pressAlbum }) => {
  console.log("albums?", albums);

  const AlbumImage = ({ item }) => {
    // get first image in album

    useEffect(() => {
      (async () => {
        const media = await MediaLibrary.getAssetsAsync({ album: item.id });

        if (media.assets.length > 0 && media.assets[0].uri) {
          setFirstImage(media.assets[0].uri);
        }
      })();
    }, []);

    const [firstImage, setFirstImage] = useState(null);

    return item.type == "recent" ? (
      <TouchableOpacity
        style={{ alignItems: "center", width: 80 }}
        onPress={() => pressAlbum(item)}
      >
        <View style={styles.recentStyle}>
          <IconButton icon="image" size={40} style={{ alignSelf: "center" }} />
        </View>
        <Text style={{ position: "absolute", top: 50 }}>
          {i18n.t(langDict.recent)}
        </Text>
      </TouchableOpacity>
    ) : (
      firstImage && (
        <TouchableOpacity
          style={{ alignItems: "center", width: 80 }}
          onPress={() => pressAlbum(item)}
        >
          <Image source={{ uri: firstImage }} style={styles.albumStyle} />

          <Text numberOfLines={2} ellipsizeMode="tail">
            {item.title}
          </Text>
        </TouchableOpacity>
      )
    );
  };

  return (
    <FlatList
      style={{ maxHeight: 100 }}
      horizontal={true}
      data={albums}
      // numColumns={3}
      renderItem={({ item }) => <AlbumImage item={item} />}
      keyExtractor={(item, index) => index.toString()}
    />
  );
});

const MediasList = React.memo(
  ({ shownMedias, touchImage, onlyImage, selectedRef }) => {
    const ListedImage = ({ item }) => {
      // console.log("media is", item.mediaType);
      const mediaType = item.mediaType;

      const Minute = () => {
        const time = Math.round(Number(item.duration));
        const seconds = time % 60 < 10 ? "0" + (time % 60) : time % 60;
        const minutes = "0" + (time - Number(seconds)) / 60;

        return (
          <View
            style={{
              position: "absolute",
              bottom: 10,
              right: 10,
              backgroundColor: "black",
              padding: 3,
              borderRadius: 10,
            }}
          >
            <Text
              style={{
                color: "white",

                //   height: 30,
                //   width: 30,
              }}
            >
              {minutes + ":" + seconds}
            </Text>
          </View>
        );
      };

      const [checked, setChecked] = useState(
        selectedRef.current.includes(item.id)
      );

      return (
        <TouchableOpacity
          onPress={() => touchImage(item, setChecked, selectedRef)}
        >
          <Image source={{ uri: item.uri }} style={styles.imageStyle} />

          {checked ? (
            <IconButton
              style={{
                position: "absolute",
                top: 0,
                //   backgroundColor: "white",
                //   height: 30,
                //   width: 30,
                right: 0,
                height: 30,
                width: 30,
                backgroundColor: "white",
              }}
              size={30}
              color="green"
              icon="check-circle"
            />
          ) : (
            <View
              style={{
                width: 30,
                height: 30,
                borderRadius: 30 / 2,
                position: "absolute",
                top: 5,
                borderColor: "white",
                borderWidth: 2,
                right: 5,
              }}
            />
          )}
          {mediaType == "video" && (
            <IconButton
              style={{
                position: "absolute",
                bottom: -5,
                //   backgroundColor: "white",
                //   height: 30,
                //   width: 30,
              }}
              icon="video"
            />
          )}
          {mediaType == "video" && <Minute />}
        </TouchableOpacity>
      );
    };

    try {
      console.log("shownmedias", shownMedias.endCursor);
      console.log("shownmedias", shownMedias.assets[0]);
    } catch {}

    const [medias, setMedias] = useState(
      shownMedias && shownMedias.assets ? shownMedias : []
    );

    //hook change of shownmedias in initial state because we use useCallback
    useEffect(() => {
      if (shownMedias) {
        setMedias(shownMedias);
      }
    }, [shownMedias]);

    // const data = shownMedias && shownMedias.assets ? shownMedias.assets : [];

    const onEndReached = async () => {
      if (medias.hasNextPage) {
        const media = await MediaLibrary.getAssetsAsync({
          first: 100,
          after: medias.endCursor,
          mediaType: getMediaTypes(onlyImage),
          sortBy: MediaLibrary.SortBy.creationTime,
        });

        const filtered = media.assets.filter(
          (asset) =>
            asset.mediaType !== "video" ||
            (asset.mediaType == "video" && asset.duration < 10)
        );

        const newData = medias.assets.concat(filtered);

        media.assets = newData;

        setMedias(media);
      }
    };

    return (
      <FlatList
        data={medias.assets}
        initialNumToRender={10}
        numColumns={3}
        onEndReached={onEndReached}
        onEndReachedThreshold={0.3}
        renderItem={({ item }) => <ListedImage item={item} />}
        keyExtractor={(item, index) => index.toString()}
      />
    );
  }
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: Dimensions.get("window").width,
    // height: Dimensions.get('screen').height,
  },
  map: {
    width: Dimensions.get("window").width,
    // height: Dimensions.get('window').height,
    // height: "auto",
    height: "100%",
  },
  sendContainer: {
    justifyContent: "center",
    alignItems: "center",
    // alignSelf: 'center',
    marginRight: 5,
  },
  sendButtonTitile: {
    color: "#4fa9ff",
    fontWeight: "bold",
  },
  placeInfo: {
    flexDirection: "row",
    alignItems: "center",
  },
  textInput: {
    backgroundColor: "grey",
  },
  fab: {
    position: "absolute",
    bottom: 30,
    right: 30,
  },
  imageStyle: {
    width: ITEM_WIDTH / 3,
    height: ITEM_WIDTH / 3,
    resizeMode: "cover",
    borderWidth: 1,
    borderColor: "#333",
  },
  selectedImage: {
    width: 30,
    height: 30,
    resizeMode: "cover",
    // justifyContent: "center",
    borderWidth: 1,
    // borderColor: "#333",
    borderRadius: 10,
  },
  albumStyle: {
    width: 50,
    height: 50,
    resizeMode: "cover",
    // justifyContent: "center",
    borderWidth: 1,
    // borderColor: "#333",
    borderRadius: 10,
  },
  recentStyle: {
    width: 50,
    height: 50,
    resizeMode: "cover",
    justifyContent: "center",
    // borderWidth: 1,
    // borderColor: "#333",
    // borderRadius: 10,
  },
});

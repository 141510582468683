export const toDataURL = (url: string) =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    );

import * as Crypto from "expo-crypto";

export const getRandomID = () => {
  const random: string = Crypto.randomUUID();

  return random.replaceAll("-", "");

  // const randomBytes = Random.getRandomBytes(20);
  // return randomBytes.reduce((a, c) => a + (c % 32).toString(32));
};

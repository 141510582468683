import { GlobalProvider, GlobalContext, GlobalSubContext } from "../Context";
import React, { useContext, useEffect, useState } from "react";
import { StyleSheet, Text, View, Button, TouchableOpacity } from "react-native";

import { Portal, Snackbar, IconButton } from "react-native-paper";

const UpdateSnackBar = () => {
  // text,
  const { snackbarText, setSnackbarText } = useContext(GlobalSubContext);

  const onDismissSnackBar = () => setSnackbarText(null);

  return (
    <Portal>
      <Snackbar
        visible={Boolean(snackbarText)}
        onDismiss={onDismissSnackBar}
        duration={6000}
        style={{ alignSelf: "flex-end" }}
        action={{
          label: "OK",
          onPress: () => {
            setSnackbarText(null);
            // Do something
          },
        }}
      >
        {snackbarText}
      </Snackbar>
    </Portal>
  );
};

export default UpdateSnackBar;

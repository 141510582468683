import React, { useState } from "react";
import { ActivityIndicator } from "react-native";

type ActivityIndicatorProps = React.ComponentProps<typeof ActivityIndicator>;

export default function (props: ActivityIndicatorProps) {
  return (
    <ActivityIndicator
      animating={true}
      color="#0000aa"
      size={props.size || "large"}
    />
  );
}

import { updateUser } from "../functions";
import { langCheck } from "./langCheck";
const en: langCheck = {
  welcome:
    " Welcome to Mapnom!\n This is a casual map creator for communication.\n We are still developping for the upcoming official release.\n If you have any suggestion, please let us know! Enjoy!",
  createMap: "Create map ",
  login: "Login",
  logout: "Logout",
  home: "Home",
  map: "Map",
  chat: "Chat",
  userSettings: "Account",
  mapSettings: "Map settings",
  addFriend: "Add friend",
  searchMap: "Search new map",
  search: "Search",
  chatInput: "type your message...",
  sendImages: "send images",
  existingPlaces: "Existing place",
  newPlaces: "New places",
  updateMap: "Update map",
  deleteMap: "Delete map",
  showInSearch: "Show in Search",
  cancel: "Cancel",
  createPlace: "Create as New",
  choosePlace: "Choose this place",
  addMembers: "Add members",
  friends: "Friends",
  title: "Title",
  description: "Description",
  tags: "Tags",
  friendCode: "Friend code",
  mapIsCreated: "Map is created!",
  mapIsUpdated: "Map is updated!",
  uploadImage: "Upload image",
  addOverlays: "Add image overlays",
  exploreSentence: "Explore the world other people created!!",
  currentLocation: "Here",
  fitToCoordinates: "Fit screen to markers",
  mapStyle: "Map style",
  tutorial:
    "Welcome to Rounds! Now, you can start editing your map. Simply say, we create a map by chatting. it means you just send a message and choose the place, then this map is developped. You press the ",
  hitResults: "results for",
  noTitle: "No title!",
  language: "Language",
  members: "Members",
  userIsUpdated: "User data is updated",
  street: "Street",
  satellite: "Satellite",
  white: "White",
  black: "Black",
  where: "Where?",
  deleteConfirmation:
    "Are you sure to delete the map? You cannot restore it after it's done. All messages and images on it will be deleted.",
  storage: "Storage",
  searchPlaces: "Search places",
  familyName: "Family name",
  givenName: "Given name",
  createUser: "Create a user account",
  updateUser: "Update your information",
  emailCopied: "E-mail is copied!",
  friendIsAdded: "A friend is added!",
  goToMap: "Go to map",
  orJoin: "or join other's map",
  uploading: "uploading...",
  overlays: "overlays",
  mapImage: "title image",
  advancedSettings: "Advanced settings",
  showAllMessages: "Show all messages",
  howTo: "How to use",
  nonMember: "For non-member",
  notifications: "Notifications",
  underDevelopment:
    "Sorry, we are developping this contents. It is coming soon!",
  canJoin: "Anyone can join",
  userPage: "User page",
  mapHome: "Map home",
  requestToMapkid: "Request to Mapnom",
  editPlaces: "Edit places",
  post: "Post",
  placePosts: "Posts in place",
  pinOn: "Pin on this page",
  pinOff: "Delete pin from this page",
  reportAsSpam: "Report as spam",
  reportAsSpamDescription: "Report as spam",
  report: "Report",
  delete: "Delete",
  comment: "Comment",
  accessByLink: "Access by link",
  nonMemberEdit: "Can edit",
  canCreatePlace: "Can create place",
  canComment: "Can comment",
  conceptWelcomeTitle: "Welcome to Mapnom",
  conceptMapWithUSTitle: "Map with us",
  conceptWarmMapTitle: "Warm map",
  conceptPostWithPlaceTitle: "Post with place",
  conceptTogetherTitle: "Create together",
  conceptFindAndWalkTitle: "Find maps and walk on it",
  conceptFromNowOnTitle: "Mapnom from now on",
  conceptHowWeAreTitle: "How we are",
  conceptClosingTitle: "Closing",
  conceptSloganTitle: "Memories on the Earth",
  conceptWelcomeText:
    "Thanks for visiting our concept page.\nHere, We explain Mapnom's concept.\n\nWhat is Mapnom?\nWhat you can in Mapnom?\nAnd how we face to Mapnom?\n\nYou will feel the air.",
  conceptMapWithUSText:
    "There are many kinds of maps in this world.\n\nThey tell you where restaurants and cafe is.\nThey guide you around in a certain area.\nThey visualize stats in the world.\n\nThey have information a lot and make us understand our world easily.\nMaps will exists with us now and forever.",
  conceptWarmMapText:
    "It is rare to see useless jokes and warm memories on maps.\nWe tend to think convenience is everything.\nThis is a main stream in this era.\nBut it creates our happiness?.\n\nMapnom wanna make the earth interesting and filled with warm memories.\nUsers express the world the way they felt.\nMapnom was born for the purpose.",
  conceptPostWithPlaceText:
    "There is no difficult UI.\nJust post with text and medias like SNS.\nA different point is the need of a place.\nThe added place does not have much information, like addresses and store information etc...\nOnly your memories are stored over the time.",
  conceptTogetherText:
    "It is a tough work to create a big map.\nLet’s create it with your families, friends, and people around the world.\nNothing changes to create.\nJust post with them.",
  conceptFindAndWalkText:
    "When you wanna go somewhere, search on Mapnom.\nMaybe, local poeple create the map suitable to your needs.\nAnd you can ask them of how to walk there.\nYou can walk with the map",
  conceptFromNowOnText:
    "We grow up together with you.\nWe work for you to create your favorite maps.\n\nChange map styling, Put objects on map... and search any maps from one map.\n\nWe create new things in a polite way.",
  conceptHowWeAreText:
    "We pursuit universal things across generations.\n\nMake a relaxing time.\nEnjoy things someone values together.\nFeel connections in local.\n\nWe always think of how we increase the chance.\nWe leave warm memories on the earth with you.",
  conceptClosingText: "Thank you for reading.\nSee you again in Mapnom!",
  conceptSloganText: "Mapnom",
  seeOurConceptBook: "See our concept book",
  underBetaTest:
    "(We are in Beta test currently, iOS version is available through TestFlight)",
  askMoreMaps: "Do you wanna see more maps?",
  searchMoreMaps: "Search more maps",
  showQrCode: "Show QR code",
  shareUrl: "Share URL",
  download: "Download",
  saved: "Saved",
  addPlace: "Add place to this post",
  changePlace: "Change place for this post",

  recent: "Recent",
  placeName: "Place name",
  savePlace: "Save place",
  fallbackText: "If this screen still appears, try reloading.",
  concept: "Concept",
  privacyPolicy: "Privacy Policy",
  termsOfService: "Terms of Service",
  and: "and",
  continueWithConsent: "Welcome to Mapnom. Continue with consent to our",
  mapTypeTitle: "What is map type?",
  mapTypeDescription:
    "\n Map type determines what visitors can do. \n \n Close: Only members can see this map. \n Publish: Anyone can search and view this map but cannot edit. \n Open: Anyone can search and edit it together.",

  changeMapStyle: "Change map style",
  background: "Background",
  originalStyle: "Original style",
  printMap: "Print map",
  emailVerification: "Email verification",
  blockUser: "Block user",
  subscription: "Subscription",
  functions: "Functions",
  comparison: "Comparison",
  moreMaps: "More maps",
  autoTranslation: "Auto translation",
  autoTranslationDescription:
    "You can look up posts in any language. easy to communicate with foreigners.",
  choosePricingPlan: "Choose pricing plan. try it for free in 1 month",
  "check the price in the website": "Check the price in the website",
  "By continuing, you agree to terms of service and acknowledge you’ve read our privacy policy":
    "By continuing, you agree to terms of service and acknowledge you’ve read our privacy policy",
  "Pan map to take the snapshot": "Pan map to take the snapshot",
  style: "Style",
  "Error occured. Please attempt to login again.":
    "Error occured. Please attempt to login again.",
  "Smileys & Emotion": "Smileys & Emotion",
  "People & Body": "People & Body",
  "Animals & Nature": "Animals & Nature",
  "Food & Drink": "Food & Drink",
  "Travel & Places": "Travel & Places",
  Activities: "Activities",
  Objects: "Objects",
  Symbols: "Symbols",
  Flags: "Flags",
  "Sexual contents": "Sexual contents",
  "Illegal product or service": "Illegal product or service",
  Violence: "Violence",
  "Political ads": "Political ads",
  Discrimination: "Discrimination",
  Other: "Other",
  "Your subscription is started!": "Your subscription is started!",
  "Now, you can use all functions on Mapnom!":
    "Now, you can use all functions on Mapnom!",
  "Back to Home": "Back to Home",
  "Reported and Blocked this user": "Reported and Blocked this user",
  "Reported as spam": "Reported as spam",
  "Sent a mail link to your email box. Please login from it.":
    "Sent a mail link to your email box. Please login from it.",
  sentEmailLink: "Sent a mail link to your email box. Please login from it.",

  moreMapsTitle: "For more maps",
  moreMapsDescription: `There was limits for the number of posts, places, and maps with free plan. It is enough to create more contents on map with basic plan. 
  Daily memories, Travel with your friends, local festivals,,, Mapnom enables you to create maps with all of your memories.
  `,
  styleMapsTitle: "Styling maps",
  styleMapsDescription:
    "Mapnom provide various map styles for you. Change the style and the color so that matches the map's theme. We continue adding more map styles over the time. You can print your maps out to attach it to your notes and create paper maps.",
  connectWithTitle: "Together with more people",
  connectWithDescription:
    "You can create maps with more people. Let's invite more friends and local people for more contents. And you can enable to open comments for everyone. ",

  Subscribe: "Subscribe",
  "Free 1 month trial": "Free 1 month trial",
  "Free plan": "Free plan",

  SubscriptionPageTitle: "More on Mapnom!",
  SubscriptionPageDescription: `We can enjoy Mapnom with free plan. But if you use Mapnom long time or together with your friends, the functions are not enough for your needs. You can change your map styles and print it out to write down on it instead of just posting. We will continue adding functions like auto translation and writing down on map.`,
  FunctionsTitle: "Additional functions",
  FunctionsDescription:
    "Here, we explain additional functions by subscribing our plan.",
  PlansTitle: "Plans list",
  PlansDescription: `Do you wanna use the functions? You can start using them with free trial. Please try all functions before subscribing.
  `,
  TableTitle: "Comparison",
  TableDescription: "We summarize what functions are available on one table.",
  mo: "mo",
  "Thank you for subscribing!": "Thank you for subscribing!",
  "Check more details on mapnom.com!": "Check more details on mapnom.com!",
  detailsOnWeb: "Check more details on mapnom.com!",
  "Free trial": "Free trial",
  "More freedom": "More freedom",
  "Create memory maps with friends": "Create memory maps with friends",
  "Search maps of the world": "Search maps of the world",
  "Unlimited posts": "Unlimited posts",
  "Up to 3000 places per map, 30 maps": "Up to 3000 places per map, 30 maps",
  "Styling maps and print out": "Styling maps and print out",
  account: "account",
  Price: "Price",
  Prices: "Prices",
  Maps: "Maps",
  Places: "Places",
  Posts: "Posts",
  Printing: "Printing",
  Styling: "Styling",
  Emoji: "Emoji",
  Join: "Join",
  global: "Global",
  bbox: "Current area",
  nearby: "Nearby",
  whereToSearch: "Where do you wanna search?",
};
export default en;

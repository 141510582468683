import React, { createRef, useEffect, useState } from "react";
import {
  SafeAreaView,
  StyleSheet,
  Text,
  View,
  Image,
  Dimensions,
  FlatList,
  Modal,
  TouchableOpacity,
  KeyboardAvoidingView,
} from "react-native";
import { Title } from "react-native-paper";
import { contentMaxWidth } from "../constants";

export default function ({ children }) {
  return (
    <View
      style={{
        width: Dimensions.get("window").width,
        flex: 1,
        alignSelf: "center",
        maxWidth: 1000,
        padding: 3,
      }}
    >
      {/* <View
        style={{
          
        }}
      > */}
      {children}
      {/* </View> */}
    </View>
  );
}

import "expo-dev-client";

import {
  KeyboardAvoidingView,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Dimensions,
  ViewProps,
  TouchableHighlight,
  FlatList,
} from "react-native";

import { StatusBar } from "expo-status-bar";
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
  createRef,
  Suspense,
  lazy,
  useMemo,
} from "react";
// import { langDict } from "./languages/langCheck";

import {
  MapboxStyleURLs,
  MapProvider,
  MapView,
} from "../components/MapComponents";

import BottomSheet, {
  BottomSheetHandle,
  BottomSheetFlatList,
  BottomSheetModal,
  BottomSheetModalProvider,
} from "@gorhom/bottom-sheet";

import {
  domain as appDomain,
  mapboxStyleUrls,
  mapboxStyleImages,
} from "../constants";

import {
  Avatar,
  DefaultTheme as PaperDefaultTheme,
  Provider as PaperProvider,
  useTheme,
  Menu,
  Surface,
  Title,
} from "react-native-paper";
import { isWebOS, pickImage, debug, compressImage } from "../localFunctions";

import ColorPicker from "./ColorPicker";
import MapStyle from "./MapStyle";
import { PaperButton } from ".";

type dataTypes = {
  styleURL?: string;
  styleName?: string;
  backgroundColor?: string;
  source?: any;
};

type PropsType = {
  onPressStyle: ({
    styleUrl,
    styleName,
  }: {
    styleUrl: string;
    styleName: string;
  }) => any;
  onPressColor: (color: string) => any;
  onClose: () => void;
  mode: "modal" | "normal" | "menu";
  // setVisible: any;
  visible: boolean;
  anchor: JSX.Element;
};

const App = React.memo((props: PropsType) => {
  const { mode, visible, anchor, onPressColor, onPressStyle, onClose } = props;
  const Anchor = props.anchor;
  // ref
  const bottomSheetModalRef = useRef<BottomSheetModal>(null);

  // variables
  const snapPoints = useMemo(() => [150], []);

  // callbacks
  const handlePresentModalPress = useCallback(() => {
    bottomSheetModalRef.current?.present();
  }, []);

  const handleCloseModalPress = useCallback(() => {
    bottomSheetModalRef.current?.close();
  }, []);

  const handleSheetChanges = useCallback((index: number) => {
    console.log("handleSheetChanges", index);
    if (index == -1) {
      // debug(-1);
      onClose && onClose();
      // setVisible(false);
    }
  }, []);

  console.log("choose map style");

  //map data or horizontal flatlist
  const StyleList = useCallback(() => {
    const [text, setText] = useState();

    const TouchableMapStyle = useCallback((item: dataTypes) => {
      // const imgSource = source.includes("/")? source:
      //require becomes number!

      const { styleName, styleURL, uri } = item;

      const onPress = () => {
        if (onPressStyle) {
          // debug("Press");
          setColor(null);
          // setText(Date.now());
          onPressStyle({ styleURL, styleName });
        } else {
          debug("No function is assigned!");
        }
      };

      return (
        <MapStyle
          style={{ width: 180, height: 100 }}
          styleName={styleName}
          onPress={onPress}
        />
      );
    }, []);

    const stylesData = Object.keys(mapboxStyleImages).map((key) => ({
      uri: mapboxStyleImages[key],
      styleName: key,
      styleURL: mapboxStyleUrls[key],
    }));

    //avoid tilesets Street v8, instead use v7
    const activeStyles = ["Street", "Outdoors", "Dark", "Light"];

    const filteredStylesData = stylesData.filter((style) =>
      activeStyles.includes(style.styleName)
    );

    const HorizontalList = useCallback(() => {
      const FlatlistItem = ({ item, index }) => {
        return <TouchableMapStyle key={index} {...item} />;
      };

      const keyExtractor = useCallback((item) => item.styleName, []);

      return (
        <FlatList
          data={filteredStylesData}
          horizontal
          keyExtractor={keyExtractor}
          renderItem={FlatlistItem}
        />
      );
    }, []);

    const VerticalList = () => (
      <>
        {stylesData.map((item, index) => (
          <TouchableMapStyle key={index} {...item} />
        ))}
      </>
    );

    return mode == "menu" ? <VerticalList /> : <HorizontalList />;
  }, []);

  // useEffect(() => {
  //   handlePresentModalPress();
  // }, []);
  useEffect(() => {
    if (mode == "modal") {
      if (visible) {
        handlePresentModalPress();
      } else {
        handleCloseModalPress();
        // handlePresentModalPress();
      }
    }
  }, [visible]);

  const Sheet = mode == "modal" ? BottomSheetModal : BottomSheet;

  const [color, setColor] = useState(null);

  const BottomModal = useCallback(
    (props: ViewProps) => {
      const Handle = () => (
        <>
          <BottomSheetHandle />
          <View style={{ position: "absolute", right: 10 }}>
            <ColorPicker
              from="bottom-right"
              defaultColor={color}
              onPress={onPressColor}
            />
          </View>
        </>
      );

      return (
        <Sheet
          handleComponent={Handle}
          // onDismiss={() => setVisible && setVisible(false)}
          // enableDismissOnClose={false}
          ref={bottomSheetModalRef}
          index={0}
          snapPoints={snapPoints}
          onChange={handleSheetChanges}
        >
          {props.children}
        </Sheet>
      );
    },
    [visible]
  );

  return (
    <>
      {mode == "modal" && props.anchor}
      {mode == "menu" ? (
        <Menu visible={visible} anchor={anchor} onDismiss={onClose}>
          <StyleList />
        </Menu>
      ) : (
        <BottomModal>
          <StyleList />
        </BottomModal>
      )}
    </>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 24,
    justifyContent: "center",
    backgroundColor: "grey",
  },
  contentContainer: {
    flex: 1,
    alignItems: "center",
  },

  map: {
    // width: "100%",
    height: "100%",
    width: Dimensions.get("window").width,
    // height: Dimensions.get('window').height - 100,
  },
  ring: {
    position: "absolute",
    width: 80,
    height: 80,
    borderRadius: 40,
    borderColor: "tomato",
    borderWidth: 10,
  },
  fabGroup: {
    backgroundColor: "white",
    // size: "small",
    width: 40,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    // padding: 20,
    // paddingBottom: 90,
    // paddingRight: 15,
    // bottom: 90,
    // right: 20
  },
  mapStyleContainer: {
    justifyContent: "center",
    padding: 10,
    alignItems: "center",
    width: 200,
    // height: "100%",
  },
  mapStyleImage: {
    width: "100%",
    height: 100,
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  mapStyleBackground: {
    // position: "absolute",
    backgroundColor: "black",
    opacity: 0.7,
    justifyContent: "center",
    borderRadius: 10,
    padding: 10,
  },
  annotationContainer: {
    width: 30,
    height: 30,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    borderRadius: 15,
  },
  annotationFill: {
    width: 30,
    height: 30,
    borderRadius: 15,
    backgroundColor: "green",
    transform: [{ scale: 0.6 }],
  },
});

export default App;

import {
  Coord,
  Feature,
  LineString,
  Point,
  Polygon,
  Position,
  Units,
  along,
  bbox,
  bboxPolygon,
  bearing,
  booleanPointInPolygon,
  booleanPointOnLine,
  buffer,
  centerOfMass,
  distance,
  ellipse,
  envelope,
  featureCollection,
  getCoord,
  getCoords,
  getType,
  length,
  lineString,
  nearestPoint,
  nearestPointOnLine,
  point,
  pointOnFeature,
  pointOnLine,
  pointToLineDistance,
  polygon,
  transformRotate,
  transformTranslate,
} from "@turf/turf";
import { Expression } from "../components/MapComponentStyle";
import { translate } from "i18n-js";

export const createPointsOnLineString = (
  feature: Feature<LineString>,
  len: number,
  units: Units = "meters"
) => {
  const alongPoints = [];
  let currentDistance = 0;

  while (currentDistance <= length(feature, { units })) {
    const alongPoint = along(feature, currentDistance, { units });
    alongPoints.push(alongPoint);
    currentDistance += len;
  }
  return alongPoints;
};

export const createPolygonOnLineString = (
  points: Feature<Point>[],
  poly: Feature<Polygon>,
  angle: number,
  units: Units = "meters"
) => {
  const initCenterPoint = points[0];
  const initCoord = getCoord(initCenterPoint);

  return points.map((f) => {
    const centerPoint = f.geometry.coordinates;
    const dist = distance(initCoord, centerPoint, { units });
    const transformed = transformTranslate(poly, dist, angle);
    return transformed;
  });
};

// meter
export const createRectangle = (
  center: [number, number],
  length: number,
  width: number
) => {
  const ellipseFeature = ellipse(center, length / 1000, width / 1000);
  const envelopedFeature = envelope(featureCollection([ellipseFeature]));
  return envelopedFeature;
};

export const getRect = (center: [number, number], len) => {
  // ポリゴンの座標を計算
  const bbox0 = bbox(buffer(point(center), len / 2, { units: "meters" }));
  // ポリゴンを生成
  const polygon = bboxPolygon(bbox0);
  return polygon;
};

export const snapToLineString = (
  line: LineString,
  features: Feature<Point | Polygon>[]
) => {
  const transformed = features.map((f) => {
    const points = getCoords(f)[0];

    let fromCoord: Position;
    let minDistance = Infinity;
    // Iterate over the points and calculate the distance to the line
    points.forEach((point) => {
      const distance = pointToLineDistance(point, line);
      if (distance < minDistance) {
        minDistance = distance;
        fromCoord = point;
      }
    });

    const toCoord = nearestPointOnLine(line, point(fromCoord));

    const dist = distance(fromCoord, toCoord);
    const angle = bearing(fromCoord, toCoord);

    return transformTranslate(f, dist, angle);
  });

  return transformed;
};

const filterByTag = () => {
  const opacity: Expression = ["case", ["get", "focus"], 1, 0.6];
};

const moveFeatureByCoord = (feature, coord) => {
  const from = getCoord(centerOfMass(feature));
  const to = coord;
  const units = "meters";

  if (getType(feature) === "Polygon") {
    // Extract the coordinates of the original polygon
    const originalCoordinates = feature.geometry.coordinates[0];
    const xOffset = to[0] - from[0];
    const yOffset = to[1] - from[1];
    // Calculate new coordinates by applying the offset to each vertex
    const newCoordinates = originalCoordinates.map((coordinate) => [
      coordinate[0] + xOffset,
      coordinate[1] + yOffset,
    ]);

    return polygon([newCoordinates]);
  }

  // const dist = distance(from, to, { units });
  // const angle = bearing(from, to);
  // return transformTranslate(feature, dist, angle, {
  //   units,
  // });
};

// cause shape break when destination is far like more than 10 degrees. use moveFeatureByCoord instead if so
const moveFeature = (feature, center) => {
  const from = getCoord(centerOfMass(feature));
  const to = center;
  const units = "meters";
  const dist = distance(from, to, { units });
  const angle = bearing(from, to);
  console.log(from, to);
  console.log(
    feature,
    transformTranslate(feature, dist, angle, {
      units,
    })
  );
  return transformTranslate(feature, dist, angle, {
    units,
  });
};

// Boolean-contains returns the exact opposite result of the @turf/boolean-within.
// // booleanContains or booleanWithin???
// multiple features along line
export const createFeaturesOnLineString = (
  originalFeature,
  line,
  { interval = 20, maxNum = 50 }
) => {
  const coords = getCoords(line);
  const angles = [];

  coords.forEach((coord, index) => {
    const next = coords[index + 1];
    if (!next) return null;
    return angles.push(bearing(coord, next));
  });

  const units: Units = "meters";

  // get line segment from feature

  const lineSegments = [];
  coords.forEach((coord, index) => {
    const next = coords[index + 1];
    if (!next) return null;
    return lineSegments.push(lineString([coord, next]));
  });
  // .filter((line, index) => line);

  const alongPoints = createPointsOnLineString(line, interval, units).filter(
    (f, index) => index < maxNum
  );

  const alongPointsWithAngle = alongPoints.map((pt) => {
    // get angle in line segment for each point
    const index = lineSegments.findIndex((line) => {
      return booleanPointInPolygon(pt, bboxPolygon(bbox(line)));
      // return booleanPointOnLine(pt, line);
      // { ignoreEndVertices: true }
    });

    console.log(index, angles);
    const angle = angles[index] || 0;
    pt.properties = { ...pt.properties, angle };
    return pt;
    // return transformRotate(pt, angle);
  });

  console.log(alongPointsWithAngle);

  const featureCenter = getCoord(centerOfMass(originalFeature));

  const newFeatures: Feature[] = alongPointsWithAngle.map((f) => {
    const coord = getCoord(f);
    // let translated = moveFeature(originalFeature, coord);
    let translated = moveFeatureByCoord(originalFeature, coord);
    const newCenter = getCoord(centerOfMass(translated));
    const distDiff = distance(coord, newCenter, { units });
    if (distDiff > 0.1) {
      // translated = moveFeature(translated, coord);
    }
    // const delta = [newCenter[0] - coord[0], newCenter[1] - coord[1]];
    const transformOptions = {
      // pivot: featureCenter,
      // mutate: true,
    };
    const angle = f.properties.angle;

    return transformRotate(translated, angle, transformOptions);
  });

  return newFeatures;
};
